import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Chip, Grid, Typography, Link, InputLabel } from '@mui/material';
import { StatusColor } from '../../../contest/contest-definition';
import { Status } from '../../../status';
import { useApp } from '../../../../hooks/use-app';
import { PropertyList } from '../../../property-list';
import { PropertyListItem } from '../../../property-list-item';
import { NumberColor } from '../../../number-color';
import { GoalDateView } from '../../../goal-date-view';

export const ProductSumaryDetail = ({ item, itemcode }) => {
  const { appUrlBase } = useApp();
  const detailRoute = `${appUrlBase}/inventory/products/${itemcode}/gallery`;

  return (
    <Box>
      <Grid container rowSpacing={1} sx={{ padding: 3 }}>
        {/* Información Producto */}
        <Grid item xs={12} sm={6} md={6} lg={4} xl={5}>
          <InputLabel
            sx={{
              marginLeft: 0,
              color: 'black',
              fontWeight: 'bold'
            }}
          >
            Información Producto
          </InputLabel>
          <PropertyList>
            {/* <PropertyListItem
              label="Empresa"
              value={`${item.companyInfo?.id || '---'} - ${item.companyInfo?.name || '---'}`}
            /> */}
            <PropertyListItem
              label="Tipo"
              value={`${item.typeInfo?.id || '---'} - ${item.typeInfo?.name || '---'}`}
              align={'right'}
            />
            <PropertyListItem
              label="Proveedor"
              value={`${item.supplierInfo?.code || '---'} - ${item.supplierInfo?.name || '---'}`}
            />
            <PropertyListItem label="Código" value={item.code || '---'} align={'right'} />
            <PropertyListItem
              label="Código del proveedor"
              value={item.supplier_catalog_code || '---'}
            />
            <PropertyListItem label="Nombre" value={item.name || '---'} />
            <PropertyListItem
              label="Division"
              value={`${item.divisionInfo?.id || '---'} - ${item.divisionInfo?.name || '---'}`}
              align={'right'}
            />
            <PropertyListItem
              label="Marca"
              value={`${item.brandInfo?.id || '---'} - ${item.brandInfo?.name || '---'}`}
              align={'right'}
            />
            <PropertyListItem
              label="Clasificación"
              value={`${item.productClasificationInfo?.id || '---'} - ${
                item.productClasificationInfo?.name || '---'
              }`}
            />
            <PropertyListItem
              label="Categoria"
              value={`${item.productCategoryInfo?.id || '---'} - ${
                item.productCategoryInfo?.name || '---'
              }`}
            />
            <PropertyListItem
              label="Grupo de atributos"
              value={`${item.productAttributeGroupInfo?.id || '---'} - ${
                item.productAttributeGroupInfo?.name || '---'
              }`}
            />
          </PropertyList>
        </Grid>

        {/* Información de Almacenamiento */}
        <Grid item xs={12} sm={6} md={6} lg={4} xl={5}>
          <InputLabel
            sx={{
              marginLeft: 0,
              color: 'black',
              fontWeight: 'bold'
            }}
          >
            Información de Almacenamiento
          </InputLabel>
          <PropertyList>
            <PropertyListItem label="Factor" align={'right'}>
              <NumberColor
                value={`${item.unitMeasureInfo?.name || '---'} x ${item.factor_um || '--'}`}
                format="0"
              />
            </PropertyListItem>
            <PropertyListItem label="Almacenamiento" align={'right'}>
              <NumberColor
                value={`${item.warehouseUnitMeasureInfo?.name || '---'} x ${
                  item.warehousing_factor || '--'
                }`}
                format="0"
              />
            </PropertyListItem>
            <PropertyListItem label="Unidad de compra" align={'right'}>
              <NumberColor
                value={`${item.buy_product_unit_measure?.unit_measure.name || '--'} x ${
                  item.buy_product_factor || '--'
                }`}
                format="0"
              />
            </PropertyListItem>
            <PropertyListItem label="Unidad de venta" align={'right'}>
              <NumberColor
                value={`${item.sale_product_unit_measure?.unit_measure.name || '--'} x ${
                  item.sell_product_factor || '--'
                }`}
                format="0"
              />
            </PropertyListItem>
            <PropertyListItem label="Código de barra" value={item.barcode || '---'} />
            <PropertyListItem label="Precio" align={'right'}>
              <NumberColor value={item.price} format="#,##0.00" />
            </PropertyListItem>
            <PropertyListItem label="Costo" align={'right'}>
              <NumberColor value={item.cost} format="#,##0.00" />
            </PropertyListItem>
            <PropertyListItem label="Stock" align={'right'}>
              <NumberColor value={item.stock} format="#,##0.00" />
            </PropertyListItem>
            <PropertyListItem label="Stock máximo" align={'right'}>
              <NumberColor value={item.stock_max} format="#,##0.00" />
            </PropertyListItem>
            <PropertyListItem label="Stock mínimo" align={'right'}>
              <NumberColor value={item.stock_min} format="#,##0.00" />
            </PropertyListItem>
          </PropertyList>
          <Link
            color="inherit"
            component={RouterLink}
            to={`${detailRoute}`}
            underline="none"
            variant="subtitle2"
          >
            <Button color="primary" variant="contained">
              Ir a galeria
            </Button>
          </Link>
        </Grid>

        {/* Dimensiones */}
        <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
          <InputLabel
            sx={{
              marginLeft: 0,
              color: 'black',
              fontWeight: 'bold'
            }}
          >
            Dimensiones
          </InputLabel>
          <PropertyList>
            <PropertyListItem label="Peso" align={'right'}>
              <NumberColor
                prefix={item.weightUnitMeasureInfo?.code}
                value={item.weight}
                format="#,##0.00"
              />
            </PropertyListItem>
            <PropertyListItem label="Altura" align={'right'}>
              <NumberColor
                prefix={item.dimensionUnitMeasureInfo?.code}
                value={item.height}
                format="#,##0.00"
              />
            </PropertyListItem>
            <PropertyListItem label="Ancho" align={'right'}>
              <NumberColor
                prefix={item.dimensionUnitMeasureInfo?.code}
                value={item.width}
                format="#,##0.00"
              />
            </PropertyListItem>
            <PropertyListItem label="Largo" align={'right'}>
              <NumberColor
                prefix={item.dimensionUnitMeasureInfo?.code}
                value={item.length}
                format="#,##0.00"
              />
            </PropertyListItem>
            <PropertyListItem label="Volumen" align={'right'}>
              <NumberColor
                prefix={item.volumeUnitMeasureInfo?.code}
                value={item.volume}
                format="#,##0.00"
              />
            </PropertyListItem>
            <PropertyListItem label="Creado en" align={'right'}>
              <GoalDateView value={item.created_at} />
            </PropertyListItem>
            <PropertyListItem label="Estado" align={'right'}>
              <Status color={StatusColor(item.status)} label="Activo" />
            </PropertyListItem>
          </PropertyList>
          <Chip
            label="Compra"
            variant={item.for_purchase === 'Y' ? 'filled' : 'outlined'}
            color={item.for_purchase === 'Y' ? 'primary' : 'default'}
            sx={{ marginLeft: 0, marginBottom: 1 }}
          />
          <Chip
            label="Vender"
            variant={item.for_sale === 'Y' ? 'filled' : 'outlined'}
            color={item.for_sale === 'Y' ? 'primary' : 'default'}
            sx={{ marginLeft: 2, marginBottom: 1 }}
          />
          <Chip
            label="Inventariable"
            variant={item.for_inventory === 'Y' ? 'filled' : 'outlined'}
            color={item.for_inventory === 'Y' ? 'primary' : 'default'}
            sx={{ marginLeft: 2, marginBottom: 1 }}
          />
          <Chip
            label="Producción"
            variant={item.for_production === 'Y' ? 'filled' : 'outlined'}
            color={item.for_production === 'Y' ? 'primary' : 'default'}
            sx={{ marginLeft: 2, marginBottom: 1 }}
          />
          {item.labels !== null && (
            <Typography variant="subtitle2" gutterBottom>
              Características
            </Typography>
          )}
          {item.labels !== null &&
            item.labels.map((item, index) => (
              <Typography key={index} color="textSecondary" variant="body2">
                {item.name}
              </Typography>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};

ProductSumaryDetail.propTypes = {
  item: PropTypes.object,
  itemcode: PropTypes.string
};
