import PropTypes from 'prop-types';

import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';
import GoalRecordLink from '../../goal-record-link';

export const GProcDefaultsTable = ({
  setCurrentLine,
  handleOpenEditDialog,
  onRefresh,
  documentState
}) => {
  const handleOpenDialog = (item) => {
    setCurrentLine?.(item);
    handleOpenEditDialog?.();
  };

  const handleRefresh = () => {
    onRefresh?.();
  };

  return (
    <Card variant="outlined">
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title="Configuración de GProc" />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.gCC_GPROC_MNGMT}>
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              {/* <TableCell>#</TableCell> */}
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Modulo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentState.data?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <GoalRecordLink
                    // to={`/gproc/${item.id}`}
                    onClick={() => {
                      handleOpenDialog(item);
                    }}
                    label={item.id}
                    tooltip={'Editar configuración'}
                  />
                </TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell sx={{ maxWidth: 180 }}>{item.value}</TableCell>
                <TableCell>{item.code}</TableCell>
                <TableCell>{item.moduleName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};

GProcDefaultsTable.propTypes = {
  documentState: PropTypes.object.isRequired,
  onRefresh: PropTypes.func.isRequired,
  setCurrentLine: PropTypes.func.isRequired,
  handleOpenEditDialog: PropTypes.func.isRequired
};
