export const WorkOrdersViews = {
  ALL: 'all',
  DRAFT: 'draft',
  PENDING_APPROVAL: 'pending_approval',
  APPROVED: 'approved',
  ACTIVE: 'active',
  COMPLETED: 'completed',
  CLOSED: 'closed'
};

export const WorkOrdersTabsViews = [
  {
    label: 'Todos',
    value: WorkOrdersViews.ALL
  },
  {
    label: 'Borrador',
    value: WorkOrdersViews.DRAFT
  },
  {
    label: 'Aprobación pendiente',
    value: WorkOrdersViews.PENDING_APPROVAL
  },
  {
    label: 'Aprobado',
    value: WorkOrdersViews.APPROVED
  },
  {
    label: 'Activo',
    value: WorkOrdersViews.ACTIVE
  },
  {
    label: 'Completado',
    value: WorkOrdersViews.COMPLETED
  },
  {
    label: 'Cerrado',
    value: WorkOrdersViews.CLOSED
  }
];

const colorMap = {
  Tomato: { r: 255, g: 99, b: 71 },
  Yellow: { r: 255, g: 255, b: 0 },
  Purple: { r: 128, g: 0, b: 128 },
  DodgerBlue: { r: 30, g: 144, b: 255 },
  SeaGreen: { r: 46, g: 139, b: 87 },
  Red: { r: 255, g: 0, b: 0 } // Added red color for deleted items
};

// Function to calculate the luminosity of an RGB color
const getLuminance = ({ r, g, b }) => {
  const [R, G, B] = [r, g, b]
    .map((v) => v / 255)
    .map((v) => (v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4));
  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
};

// Function to get the text color based on the background
export const WorkOrdersGetTextColor = (backgroundColor) => {
  const colorRGB = colorMap[backgroundColor];
  if (!colorRGB) return '#000'; // Default text color (black) if color is not found in the map
  // Check if the background color is Red (deleted state)
  if (backgroundColor === 'Red') return '#FFF';
  return getLuminance(colorRGB) < 0.5 ? '#FFF' : '#000';
};
