import { useCallback, useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
  Link,
  IconButton,
  Menu
} from '@mui/material';

import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../icons/plus';
import { InputField } from '../../components/input-field';
import { PropertyList } from '../../components/property-list';
import { goalInventoryApi } from '../../api/goal/inventory/goal-inventory';
import { AutocompleteField } from '../../components/autocomplete-field';
import { useApp } from '../../hooks/use-app';
import { PropertyListItem } from '../../components/property-list-item';
import { CompanyInputDisabled } from '../../components/common/company-input-disabled';
import { Trash as TrashIcon } from '../../icons/trash';
import { Pencil as PencilIcon } from '../../icons/pencil';
import MultiSelectField from '../../components/common/multi-select-field';
import useFormikMultiSelect from '../../hooks/use-formik-multiselect';
import { ChipField } from '../../components/chip-field';
import { useAuth } from '../../hooks/use-auth';
import { goalServicesApi } from '../../api/goal/services/goal-services';
import { useDialog } from '../../hooks/use-dialog';
import { ImagesUploaderDialog } from '../../components/common/files-uploader-dialog';
import { useMounted } from '../../hooks/use-mounted';
import { useSelection } from '../../hooks/use-selection';
import SelectedImage from '../../components/common/selected-image';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { RenderIf } from '../../components/render-if';
import { CustomerModal } from '../../components/delivery/monitoring/customer-modal';
import ProductUnitMeasuresForSelect from './product-unit-measures-for-select';
import { productClasificationsApi } from '../../api/goal/inventory/product-clasifications-api';
import { useFormChecker } from '../../hooks/use-form-checker';
import TitleLabel from '../../components/common/labels/title-label';

export const ProductForm = ({
  initialValues = {},
  update = {},
  onClose = () => {},
  handleRefresh = () => {},
  itemcode
}) => {
  const { currentCompany, showNotify, showError, appUrlBase, getImageUrl } = useApp();
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState(itemcode == 'new' ? true : false);

  useEffect(() => {
    if (itemcode == 'new') {
      setIsNew(true);
    } else {
      setIsNew(false);
    }
  }, [itemcode]);

  useEffect(() => {
    const createImageObjects = async () => {
      if (initialValues.image !== null && update) {
        const imageResponseConst = await Promise.all(
          initialValues.image.map(async (item) => {
            return {
              id: item,
              width: 1,
              height: 1,
              title: item,
              src: `${getImageUrl({ imageCode: item })}`
            };
          })
        );
        setImageResponse(imageResponseConst);
      }
    };
    if (!isNew) {
      createImageObjects();
    }
  }, []);

  const [categorieData, setCategorieData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [attributesData, setAttributesData] = useState([]);
  const [divisionsData, setDivisionsData] = useState([]);
  const [unitsData, setUnitsData] = useState([]);
  const [unitsDimensionData, setUnitsDimensionData] = useState([]);
  const [unitsVolumeData, setUnitsVolumeData] = useState([]);
  const [unitsWeightData, setUnitsWeightData] = useState([]);
  const [labelsListData, setLabelsListData] = useState([]);
  const [labelsListDataOriginal, setLabelsListDataOriginal] = useState([]);
  const [productClasifications, setProductClasifications] = useState([]);

  const { account } = useAuth();
  const [uploadDialogOpen, handleOpenUploadDialog, handleCloseUploadDialog] = useDialog();
  const [imagesFiles, setImagesFiles] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageResponse, setImageResponse] = useState([]);
  const [selectedItems, handleSelect, handleSelectAll, handleClearSelected] = useSelection([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const [OpenProductUnitMeasureDialog, setOpenProductUnitMeasureDialog] = useState(false);
  const [anchorElBuy, setAnchorElBuy] = useState(null); // anchor element for BuyProductUnitMeasure
  const menuOpenBuyProductUnitMeasure = Boolean(anchorElBuy);
  const [anchorElSale, setAnchorElSale] = useState(null); // anchor element for SaleProductUnitMeasure
  const menuOpenSaleProductUnitMeasure = Boolean(anchorElSale);
  const [optionSelectProductUnitMeasure, setOptionSelectProductUnitMeasure] = useState('');

  const mounted = useMounted();

  useEffect(() => {
    getApiData();
  }, []);

  const getApiData = async () => {
    const [
      categorieData,
      supplierData,
      brandData,
      attributesData,
      divisionsData,
      unitsData,
      labelsData,
      productClasificationsData,
      dimensionsData,
      volumeData,
      weightData
    ] = await Promise.all([
      goalInventoryApi.getCategoriesResources(currentCompany),
      goalInventoryApi.getSuppliersResources(currentCompany),
      goalInventoryApi.getBrandsResources(currentCompany),
      goalInventoryApi.getAttributesResources(currentCompany),
      goalInventoryApi.getDivisionsResources(currentCompany),
      goalInventoryApi.getUnitsResources({ companyId: currentCompany }),
      goalInventoryApi.getLabelsResources(),
      productClasificationsApi.getProductClasificationsForAutocomplete({
        companyId: currentCompany
      }),
      goalInventoryApi.getUnitsResourcesDimension({ companyId: currentCompany }),
      goalInventoryApi.getUnitsResourcesVolume({ companyId: currentCompany }),
      goalInventoryApi.getUnitsResourcesWeight({ companyId: currentCompany })
    ]);

    if (categorieData.success && categorieData.data.length !== 0) {
      setCategorieData(categorieData.data);
    }
    if (supplierData.success && supplierData.data.length !== 0) {
      setSupplierData(supplierData.data);
    }
    if (brandData.success && brandData.data.length !== 0) {
      setBrandData(brandData.data);
    }
    if (attributesData.success && attributesData.data.length !== 0) {
      setAttributesData(attributesData.data);
    }
    if (productClasificationsData.success && productClasificationsData.data.length !== 0) {
      setProductClasifications(productClasificationsData.data);
    }
    if (divisionsData.success && divisionsData.data.length !== 0) {
      setDivisionsData(divisionsData.data);
    }
    if (unitsData.success && unitsData.data.length !== 0) {
      setUnitsData(unitsData.data);
    }
    if (labelsData.success && labelsData.data.length !== 0) {
      setLabelsListData(labelsData.data);
      setLabelsListDataOriginal(labelsData.data);
    } else if (update) {
      setLabelsListData(initialValues.labels || []);
      setLabelsListDataOriginal(initialValues.labels || []);
    }

    if (dimensionsData.success && dimensionsData.data.length !== 0) {
      setUnitsDimensionData(dimensionsData.data);
    }
    if (volumeData.success && volumeData.data.length !== 0) {
      setUnitsVolumeData(volumeData.data);
    }
    if (weightData.success && weightData.data.length !== 0) {
      setUnitsWeightData(weightData.data);
    }
  };

  const formik = useFormik({
    initialValues: {
      company_id: currentCompany,
      productAttributeGroupInfo: initialValues.productAttributeGroupInfo || '',
      product_attribute_group_id: initialValues.product_attribute_group_id || '',
      code: initialValues.code || '',
      type: initialValues.type || '',
      productCategoryInfo: initialValues.productCategoryInfo || '',
      product_category_id: initialValues.product_category_id || '',
      brandInfo: initialValues.brandInfo || '',
      brand_id: initialValues.brand_id || '',
      supplierInfo: initialValues.supplierInfo || '',
      supplier_id: initialValues.supplier_id || '',
      supplier_code: initialValues.supplier_code || '',
      unitMeasureInfo: initialValues.unitMeasureInfo || {},
      unit_measure_id: initialValues.unit_measure_id || '',
      factor_um: initialValues.factor_um || 1,
      productClasificationInfo: initialValues.productClasificationInfo || '',
      product_clasification_id: initialValues.product_clasification_id || '',
      barcode: initialValues.barcode || '',
      name: initialValues.name || '',
      image: initialValues.image || null,
      thumbnail: initialValues.thumbnail || null,
      for_purchase: initialValues.for_purchase || 'Y',
      for_sale: initialValues.for_sale || 'Y',
      for_inventory: initialValues.for_inventory || 'Y',
      for_production: initialValues.for_production || 'Y',
      unit: initialValues.unit || '',
      price: initialValues.price || '0.00',
      cost: initialValues.cost || '0.00',
      // quantity: initialValues.quantity || 0,
      weight: initialValues.weight === '.00' ? 0 : initialValues.weight || 0,
      weightUnitMeasureInfo: initialValues.weightUnitMeasureInfo || '',
      weight_um: initialValues.weightUnitMeasureInfo?.id || '',
      height: initialValues.height === '.00' ? 0 : initialValues.height || 0,
      dimensionUnitMeasureInfo: initialValues.dimensionUnitMeasureInfo || '',
      dimension_um: initialValues.dimensionUnitMeasureInfo?.id || '',
      width: initialValues.width === '.00' ? 0 : initialValues.width || 0,
      length: initialValues.length === '.00' ? 0 : initialValues.length || 0,
      volume: initialValues.volume === '.00' ? 0 : initialValues.volume || 0,
      volumeUnitMeasureInfo: initialValues.volumeUnitMeasureInfo || {},
      volume_um: initialValues.volumeUnitMeasureInfo?.id || '',
      buy_product_unit_measure: initialValues.buy_product_unit_measure || '',
      buy_product_unit_measure_id: initialValues.buy_product_unit_measure_id || '',
      buy_product_factor: initialValues.buy_product_factor || 1,
      sale_product_unit_measure: initialValues.sale_product_unit_measure || '',
      sale_product_unit_measure_id: initialValues.sale_product_unit_measure_id || '',
      sell_product_factor: initialValues.sell_product_factor || 1,
      // dimension_um: initialValues.dimension_um || 0,
      warehouseUnitMeasureInfo: initialValues.warehouseUnitMeasureInfo || '',
      warehousing_factor: initialValues.warehousing_factor || 1,
      warehousing_um: initialValues.warehouseUnitMeasureInfo?.id || {},
      divisionInfo: initialValues.divisionInfo || {},
      division_id: initialValues.division_id || '',
      labels: initialValues.labels || [],
      supplier_catalog_code: initialValues.supplier_catalog_code || '',
      supplier_umes: initialValues.supplier_umes || 0,
      supplier_umes_unit_id: initialValues.supplier_umes_unit_id || '',
      umeUnitMeasureInfo: initialValues.umeUnitMeasureInfo || {}
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es obligatorio'),
      code: Yup.string().required('Este campo es obligatorio'),
      type: Yup.string().required('Este campo es obligatorio'),
      // product_attribute_group_id: Yup.string().required('Este campo es obligatorio'),
      division_id: Yup.string().required('Este campo es obligatorio'),
      brand_id: Yup.string().required('Este campo es obligatorio'),
      product_clasification_id: Yup.string().required('Este campo es obligatorio'),
      product_category_id: Yup.string().required('Este campo es obligatorio'),
      supplier_id: Yup.string().required('Este campo es obligatorio'),
      warehousing_um: Yup.string().required('Este campo es obligatorio'),
      warehousing_factor: Yup.number()
        .positive('El peso debe ser un número positivo')
        .min(1, 'El peso debe ser mayor que cero')
        .required('Este campo es obligatorio'),
      weight: Yup.number().when('for_inventory', {
        is: 'Y',
        then: Yup.number()
          .positive('El peso debe ser un número positivo')
          .min(0, 'El peso debe ser mayor o igual a cero')
          .required('Este campo es obligatorio')
      }),
      height: Yup.number().when('for_inventory', {
        is: 'Y',
        then: Yup.number()
          .positive('El peso debe ser un número positivo')
          .min(0, 'El peso debe ser mayor o igual a cero')
          .required('Este campo es obligatorio')
      }),
      width: Yup.number().when('for_inventory', {
        is: 'Y',
        then: Yup.number()
          .positive('El peso debe ser un número positivo')
          .min(0, 'El peso debe ser mayor o igual a cero')
          .required('Este campo es obligatorio')
      }),
      length: Yup.number().when('for_inventory', {
        is: 'Y',
        then: Yup.number()
          .positive('El peso debe ser un número positivo')
          .min(0, 'El peso debe ser mayor o igual a cero')
          .required('Este campo es obligatorio')
      }),
      volume: Yup.number().when('for_inventory', {
        is: 'Y',
        then: Yup.number()
          .positive('El peso debe ser un número positivo')
          .min(0, 'El peso debe ser mayor o igual a cero')
          .required('Este campo es obligatorio')
      }),
      dimension_um: Yup.string().when('for_inventory', {
        is: 'Y',
        then: Yup.string().required('Este campo es obligatorio')
      }),
      weight_um: Yup.string().when('for_inventory', {
        is: 'Y',
        then: Yup.string().required('Este campo es obligatorio')
      }),
      volume_um: Yup.string().when('for_inventory', {
        is: 'Y',
        then: Yup.string().required('Este campo es obligatorio')
      }),
      supplier_umes: Yup.number()
        .positive('El valor UME debe ser un número positivo')
        .min(0, 'El valor UME debe ser mayor o igual a cero')
        .required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      if (update) {
        const newValues = {};
        Object.entries(values).forEach(([key, value]) => {
          if (value === '') {
            newValues[key] = null;
          } else {
            newValues[key] = value;
          }
        });

        newValues.image =
          initialValues.image !== null ? initialValues.image.concat(values.image) : values.image;
        const response = await goalInventoryApi.updateProductForm({
          id: initialValues.id,
          values: newValues
        });
        if (response.success) {
          showNotify(`${response?.message}. Cambio exitoso `);
          handleRefresh();
          onClose(false);
        } else {
          showError(`${response?.message}. No se proceso la acción `);
        }
      } else {
        console.log('create', values);
        const response = await goalInventoryApi.postProductForm({
          values,
          companyId: currentCompany
        });
        if (response.success) {
          showNotify(`${response?.message}. Cambio exitoso `);
          navigate(`${appUrlBase}/inventory/products/${formik.values.code}`, {
            replace: true
          });
        } else {
          showError(`${response?.message}. No se proceso la acción `);
        }
      }
    }
  });
  const [checkForm] = useFormChecker({ formik, setSaving: () => {} });

  const { handleMultiSelectChange, getOptionsName, handleOptionsDelete } =
    useFormikMultiSelect(formik);

  const typeData = [
    {
      id: 'I',
      name: 'Producto',
      code: 'PRODUCT'
    },
    {
      id: 'S',
      name: 'Servicio',
      code: 'SERVICE'
    },
    {
      id: 'A',
      name: 'Activo fijo',
      code: 'ASSET'
    }
  ];

  const handleValidType = (item) => {
    if (item.id === 'S') {
      formik.setFieldValue('for_inventory', 'N');
    } else {
      formik.setFieldValue('for_inventory', 'Y');
    }
  };

  const handleChangeChip = (value, formikChange) => {
    if (value === 'Y') {
      formik.setFieldValue(formikChange, 'N');
    } else {
      formik.setFieldValue(formikChange, 'Y');
    }
  };

  // Function to open the menu
  const handleMenuOpenBuyProductUnitMeasure = (event) => {
    setAnchorElBuy(event.currentTarget);
  };

  // Function to close the menu
  const handleMenuCloseBuyProductUnitMeasure = () => {
    setAnchorElBuy(null);
  };

  // Function to open the menu
  const handleMenuOpenSaleProductUnitMeasure = (event) => {
    setAnchorElSale(event.currentTarget);
  };

  // Function to close the menu
  const handleMenuCloseSaleProductUnitMeasure = () => {
    setAnchorElSale(null);
  };

  const handleOpenProductUnitMeasureDialog = () => {
    if (menuOpenBuyProductUnitMeasure) {
      handleMenuCloseBuyProductUnitMeasure();
      setOptionSelectProductUnitMeasure('buyProductUnit');
    }
    if (menuOpenSaleProductUnitMeasure) {
      handleMenuCloseSaleProductUnitMeasure();
      setOptionSelectProductUnitMeasure('saleProductUnit');
    }
    setOpenProductUnitMeasureDialog(true);
  };

  const handleCloseProductUnitMeasureDialog = () => {
    setOpenProductUnitMeasureDialog(false);
  };

  const handleSelectProductUnitMeasureDialog = (value) => {
    setOpenProductUnitMeasureDialog(false);
    if (value) {
      if (optionSelectProductUnitMeasure === 'buyProductUnit') {
        formik.setFieldValue('buy_product_unit_measure_id', value.id);
        formik.setFieldValue('buy_product_unit_measure', value);
      }
      if (optionSelectProductUnitMeasure === 'saleProductUnit') {
        formik.setFieldValue('sale_product_unit_measure_id', value.id);
        formik.setFieldValue('sale_product_unit_measure', value);
      }
    }
  };

  useEffect(() => {
    const calculatedVolume = formik.values.height * formik.values.width * formik.values.length;
    formik.setFieldValue('volume', calculatedVolume);
  }, [formik.values.height, formik.values.width, formik.values.length]);

  const openLightbox = () => {
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const onUploadToBackend = async (newSelectedImage) => {
    const imageList = newSelectedImage.map((imageSelected) => {
      // find image in imagesFiles
      const image = imagesFiles.find((imageFile) => imageFile.url === imageSelected);
      return {
        data: image.data_url,
        name: image.file.name,
        type: image.file.type,
        application: 'GOAL',
        folder: 'document',
        owner: account?.userName
      };
    });

    const codesImage = [];
    const imageResponse = [];

    imageList.forEach(async (item) => {
      const backendResponse = await goalServicesApi.postImageForm({ values: item });
      if (backendResponse.success) {
        // const uniqueId = uuidv4();
        //#TODO validate this
        codesImage.push(backendResponse.data.data.code);
        imageResponse.push({
          id: backendResponse.data.data.id,
          width: 1,
          height: 1,
          title: backendResponse.data.data.file_name,
          src: `${getImageUrl({ imageCode: backendResponse.data.code })}`
        });
        showNotify(backendResponse.message);
      } else {
        showError(backendResponse.message);
      }
      return backendResponse.success;
    });

    formik.setFieldValue('image', codesImage);
    setImageResponse(imageResponse);
  };

  const handleSaveImages = async (newSelectedImage) => {
    if (!newSelectedImage || newSelectedImage.length === 0) {
      showError('No hay imagenes seleccionadas');
      return;
    }
    setSelectedImages(newSelectedImage);
    const saved = await onUploadToBackend(newSelectedImage);
    if (saved) {
      handleRefresh();
      handleCloseUploadDialog();
    }
    handleCloseUploadDialog();
  };

  const handleImagenSelect = (e, index) => {
    handleSelect(e, imageResponse[index]?.id);
  };

  const openLightboxImage = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const handleDeleteId = () => {
    console.log('selectedItems', selectedItems);
  };

  useEffect(() => {
    if (mounted.current) handleClearSelected();
  }, []);
  const imageRenderer = useCallback(
    ({ index, left, top, key, photo, id }) => {
      if (mounted.current) {
        return (
          <SelectedImage
            selected={!!selectedItems.find((selectedPhoto) => selectedPhoto === photo.id)}
            onSelect={handleImagenSelect}
            onClick={openLightboxImage}
            key={key}
            margin="2px"
            index={index}
            photo={photo}
            left={left}
            top={top}
            id={id}
          />
        );
      }
      return null;
    },
    [selectedItems]
  );

  const detailRoute = `${appUrlBase}/inventory/products/${itemcode}/gallery`;

  return (
    <Box
      sx={{
        minWidth: 'auto',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#fff',
        borderRadius: 2,
        padding: 2
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid
          sx={{
            pl: 5
          }}
        >
          <Typography variant="h4" color="textPrimary" sx={{ marginBottom: 1 }}>
            {isNew ? 'Nuevo Producto' : 'Información Producto'}
          </Typography>
        </Grid>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2,
            padding: 5,
            pt: 0
          }}
        >
          <Grid item xs={12} md={4}>
            {/* <InputLabel
              sx={{
                marginBottom: 1,
                marginLeft: 0,
                color: 'black',
                fontWeight: 'bold'
              }}
            > */}

            {/* </InputLabel> */}
            <PropertyList>
              <CompanyInputDisabled currentCompany={currentCompany} />
              <InputField
                select
                label="Tipo"
                name="type"
                value={formik.values.type}
                fullWidth
                onChange={formik.handleChange}
                sx={{ marginBottom: 2 }}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
              >
                {typeData.map((item, index) => (
                  <MenuItem key={index} value={item.id} onClick={() => handleValidType(item)}>
                    {item.name}
                  </MenuItem>
                ))}
              </InputField>
              <AutocompleteField
                label="Proveedor"
                name="supplierInfo"
                placeholder="Seleccione proveedor"
                fullWidth
                options={supplierData || []}
                // getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('supplierInfo', value);
                  formik.setFieldValue('supplier_id', value?.id);
                  formik.setFieldValue('supplier_code', value?.code);
                }}
                value={formik.values.supplierInfo}
                sx={{ marginBottom: '1em' }}
                error={formik.touched.supplier_id && Boolean(formik.errors.supplier_id)}
                helperText={formik.touched.supplier_id && formik.errors.supplier_id}
              />
              <InputField
                name="code"
                label="Código"
                value={formik.values.code}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                sx={{ marginBottom: '1em' }}
                message="El código es una etiqueta o número único que identifica de manera exclusiva el producto."
              />
              <InputField
                name="supplier_catalog_code"
                label="Código del proveedor"
                value={formik.values.supplier_catalog_code}
                onChange={formik.handleChange}
                fullWidth
                error={
                  formik.touched.supplier_catalog_code &&
                  Boolean(formik.errors.supplier_catalog_code)
                }
                helperText={
                  formik.touched.supplier_catalog_code && formik.errors.supplier_catalog_code
                }
                sx={{ marginBottom: '1em' }}
                message="El código es una etiqueta o número único que identifica de manera exclusiva el producto en el proveedor."
              />
              <InputField
                name="name"
                label="Nombre"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{ marginBottom: '1em' }}
                message="El nombre actúa como una identificación única para el producto, siendo un distintivo exclusivo que lo diferencia de otros."
              />
              <AutocompleteField
                label="Division"
                name="divisionInfo"
                placeholder="Seleccione una division"
                fullWidth
                options={divisionsData || []}
                // getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('divisionInfo', value);
                  formik.setFieldValue('division_id', value?.id);
                }}
                value={formik.values.divisionInfo}
                sx={{ marginBottom: '1em' }}
                error={formik.touched.division_id && Boolean(formik.errors.division_id)}
                helperText={formik.touched.division_id && formik.errors.division_id}
              />
              <AutocompleteField
                label="Marca"
                name="brandInfo"
                placeholder="Seleccione marca"
                fullWidth
                options={brandData || [{ name: 'Cargando', id: 'Cargando' }]}
                // getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('brand_id', value?.id);
                  formik.setFieldValue('brandInfo', value);
                }}
                value={formik.values.brandInfo}
                sx={{ marginBottom: '1em' }}
                error={formik.touched.brand_id && Boolean(formik.errors.brand_id)}
                helperText={formik.touched.brand_id && formik.errors.brand_id}
              />
              <AutocompleteField
                label="Clasificación"
                name="productClasificationInfo"
                placeholder="Seleccione clasificación"
                fullWidth
                options={productClasifications || []}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('productClasificationInfo', value);
                  formik.setFieldValue('product_clasification_id', value?.id);
                }}
                value={formik.values.productClasificationInfo}
                sx={{ marginBottom: '1em' }}
                error={
                  formik.touched.product_clasification_id &&
                  Boolean(formik.errors.product_clasification_id)
                }
                helperText={
                  formik.touched.product_clasification_id && formik.errors.product_clasification_id
                }
              />
              <AutocompleteField
                label="Categoria"
                name="productCategoryInfo"
                placeholder="Seleccione categoria"
                fullWidth
                options={categorieData || []}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('productCategoryInfo', value);
                  formik.setFieldValue('product_category_id', value?.id);
                }}
                value={formik.values.productCategoryInfo}
                sx={{ marginBottom: '1em' }}
                error={
                  formik.touched.product_category_id && Boolean(formik.errors.product_category_id)
                }
                helperText={formik.touched.product_category_id && formik.errors.product_category_id}
              />
              <AutocompleteField
                label="Grupos de atributos"
                name="productAttributeGroupInfo"
                placeholder="Seleccione atributo"
                fullWidth
                options={attributesData || []}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('productAttributeGroupInfo', value);
                  formik.setFieldValue('product_attribute_group_id', value?.id);
                }}
                value={formik.values.productAttributeGroupInfo}
                sx={{ marginBottom: '1em' }}
                error={
                  formik.touched.product_attribute_group_id &&
                  Boolean(formik.errors.product_attribute_group_id)
                }
                helperText={
                  formik.touched.product_attribute_group_id &&
                  formik.errors.product_attribute_group_id
                }
              />
            </PropertyList>
          </Grid>
          <Grid item xs={12} md={4}>
            <TitleLabel title="Información de Almacenamiento" />
            <PropertyList>
              <TitleLabel title="Unidad mínima" />
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <AutocompleteField
                    label="Unidad Mínima"
                    name="unitMeasureInfo"
                    placeholder="Seleccione unidad de medida"
                    fullWidth
                    options={unitsData || []}
                    // getOptionLabel={(option) => option.name || ''}
                    // isOptionEqualToValue={(option) => option.name || ''}
                    onChange={(e, value) => {
                      console.log('value', value);
                      formik.setFieldValue('unitMeasureInfo', value);
                      formik.setFieldValue('unit_measure_id', value?.id);
                    }}
                    value={formik.values.unitMeasureInfo}
                    sx={{ marginBottom: '1em' }}
                    error={formik.touched.unit_measure_id && Boolean(formik.errors.unit_measure_id)}
                    helperText={formik.touched.unit_measure_id && formik.errors.unit_measure_id}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    type="number"
                    name="factor_um"
                    label="Factor"
                    value={formik.values.factor_um}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.factor_um && Boolean(formik.errors.factor_um)}
                    helperText={formik.touched.factor_um && formik.errors.factor_um}
                    sx={{ marginBottom: '1em' }}
                  />
                </Grid>
              </Grid>
              <TitleLabel title="Paquete" />
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <AutocompleteField
                    label="Unidad de almacenamiento"
                    name="warehouseUnitMeasureInfo"
                    placeholder="Seleccione unidad de almacenamiento"
                    fullWidth
                    options={unitsData || []}
                    // getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option) => option.name || ''}
                    onChange={(e, value) => {
                      formik.setFieldValue('warehousing_um', value?.id);
                      formik.setFieldValue('warehouseUnitMeasureInfo', value);
                    }}
                    value={formik.values.warehouseUnitMeasureInfo}
                    sx={{ marginBottom: '1em' }}
                    error={formik.touched.warehousing_um && Boolean(formik.errors.warehousing_um)}
                    helperText={formik.touched.warehousing_um && formik.errors.warehousing_um}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    type="number"
                    name="warehousing_factor"
                    label="Almacenamiento"
                    value={formik.values.warehousing_factor}
                    onChange={formik.handleChange}
                    fullWidth
                    error={
                      formik.touched.warehousing_factor && Boolean(formik.errors.warehousing_factor)
                    }
                    helperText={
                      formik.touched.warehousing_factor && formik.errors.warehousing_factor
                    }
                    sx={{ marginBottom: '1em' }}
                  />
                </Grid>
              </Grid>
              <ChipField
                label="Compra"
                variant={formik.values.for_purchase === 'Y' ? 'filled' : 'outlined'}
                color={formik.values.for_purchase === 'Y' ? 'primary' : 'default'}
                onClick={() => handleChangeChip(formik.values.for_purchase, 'for_purchase')}
                message="Este chip activa la unidad de compra y su unidad de medida"
              />
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <InputField
                    label="Unidad de medida"
                    name="buy_product_unit_measure"
                    placeholder="Seleccione unidad de compra"
                    value={
                      formik.values.buy_product_unit_measure?.unit_measure?.name || 'Cargando...'
                    }
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <IconButton
                          aria-label="más opciones"
                          onClick={handleMenuOpenBuyProductUnitMeasure}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )
                    }}
                    error={
                      formik.touched.buy_product_unit_measure_id &&
                      Boolean(formik.errors.buy_product_unit_measure_id)
                    }
                    helperText={
                      formik.touched.buy_product_unit_measure_id &&
                      formik.errors.buy_product_unit_measure_id
                    }
                    disabled
                  />

                  <Menu
                    anchorEl={anchorElBuy}
                    open={menuOpenBuyProductUnitMeasure}
                    onClose={handleMenuCloseBuyProductUnitMeasure}
                  >
                    <MenuItem onClick={handleOpenProductUnitMeasureDialog}>Seleccionar</MenuItem>
                  </Menu>
                  {!isNew && (
                    <RenderIf condition={OpenProductUnitMeasureDialog}>
                      <CustomerModal
                        open={OpenProductUnitMeasureDialog}
                        onClose={handleCloseProductUnitMeasureDialog}
                        permission={GadminActions.G2_CONTESTS_ADD}
                        widhtForm="md"
                      >
                        <ProductUnitMeasuresForSelect
                          handleClose={handleCloseProductUnitMeasureDialog}
                          handleSelect={handleSelectProductUnitMeasureDialog}
                          productId={initialValues.id}
                        />
                      </CustomerModal>
                    </RenderIf>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    type="number"
                    name="buy_product_factor"
                    label="Unidad de compra"
                    value={formik.values.buy_product_factor}
                    onChange={formik.handleChange}
                    fullWidth
                    error={
                      formik.touched.buy_product_factor && Boolean(formik.errors.buy_product_factor)
                    }
                    helperText={
                      formik.touched.buy_product_factor && formik.errors.buy_product_factor
                    }
                    sx={{ marginBottom: '1em' }}
                    disabled={formik.values.for_purchase === 'N'}
                  />
                </Grid>
              </Grid>
              <ChipField
                label="Vender"
                variant={formik.values.for_sale === 'Y' ? 'filled' : 'outlined'}
                color={formik.values.for_sale === 'Y' ? 'primary' : 'default'}
                onClick={() => handleChangeChip(formik.values.for_sale, 'for_sale')}
                message="Este chip activa la unidad de venta y su unidad de medida"
              />
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <InputField
                    label="Unidad de medida"
                    name="sale_product_unit_measure"
                    placeholder="Seleccione unidad de venta"
                    value={
                      formik.values.sale_product_unit_measure?.unit_measure?.name || 'Cargando'
                    }
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <IconButton
                          aria-label="más opciones"
                          onClick={handleMenuOpenSaleProductUnitMeasure}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )
                    }}
                    error={
                      formik.touched.sale_product_unit_measure_id &&
                      Boolean(formik.errors.sale_product_unit_measure_id)
                    }
                    helperText={
                      formik.touched.sale_product_unit_measure_id &&
                      formik.errors.sale_product_unit_measure_id
                    }
                    // disabled={formik.values.for_sale === 'N'}
                    disabled
                  />
                  <Menu
                    anchorEl={anchorElSale}
                    open={menuOpenSaleProductUnitMeasure}
                    onClose={handleMenuCloseSaleProductUnitMeasure}
                  >
                    <MenuItem onClick={handleOpenProductUnitMeasureDialog}>Seleccionar</MenuItem>
                  </Menu>
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    type="number"
                    name="sell_product_factor"
                    label="Unidad de venta"
                    value={formik.values.sell_product_factor}
                    onChange={formik.handleChange}
                    fullWidth
                    error={
                      formik.touched.sell_product_factor &&
                      Boolean(formik.errors.sell_product_factor)
                    }
                    helperText={
                      formik.touched.sell_product_factor && formik.errors.sell_product_factor
                    }
                    sx={{ marginBottom: '1em' }}
                    disabled={formik.values.for_sale === 'N'}
                  />
                </Grid>
              </Grid>
              <InputField
                label="Código de barra"
                name="barcode"
                value={formik.values.barcode}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.barcode && Boolean(formik.errors.barcode)}
                helperText={formik.touched.barcode && formik.errors.barcode}
                sx={{ marginBottom: '1em' }}
                message="Información para identificar el producto de manera rápida y precisa en puntos de venta, almacenes y otros lugares."
              />
              <PropertyListItem align="end" label="Precio" style={{ marginTop: '1em', padding: 0 }}>
                <Typography color="textSecondary" variant="body2">
                  {formik.values.price}
                </Typography>
              </PropertyListItem>
              <PropertyListItem
                align="end"
                label="Costo"
                style={{ marginTop: '1em', marginBottom: '1em', padding: 0 }}
              >
                <Typography color="textSecondary" variant="body2">
                  {formik.values.cost}
                </Typography>
              </PropertyListItem>

              {update && (
                <Link
                  color="inherit"
                  component={RouterLink}
                  to={`${detailRoute}`}
                  underline="none"
                  variant="subtitle2"
                  sx={{
                    display: 'block',
                    marginBottom: 3
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{
                      marginLeft: 2
                    }}
                  >
                    Ir a galeria
                  </Button>
                </Link>
              )}

              {update && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenUploadDialog}
                    disabled={formik.values.image && formik.values.image.length >= 10}
                  >
                    Agregar imagen
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={openLightbox}
                    disabled={!imageResponse || imageResponse.length === 0}
                  >
                    Mostrar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    // onClick={() => setOpenConfirmDialog(true)}
                    onClick={handleDeleteId}
                    disabled={!selectedItems || selectedItems.length === 0}
                  >
                    Eliminar
                  </Button>
                  {false && (
                    <Checkbox
                      checked={
                        imageResponse.length > 0 && selectedItems.length === imageResponse.length
                      }
                      indeterminate={
                        selectedItems.length > 0 && selectedItems.length < imageResponse.length
                      }
                      onChange={handleSelectAll}
                    />
                  )}
                </>
              )}
              {update && imageResponse.length > 0 && (
                <>
                  <Gallery photos={imageResponse} renderImage={imageRenderer} />
                  <ModalGateway>
                    {viewerIsOpen ? (
                      <Modal onClose={closeLightbox}>
                        <Carousel
                          currentIndex={currentImage}
                          views={imageResponse.map((x) => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.title,
                            id: x.id
                          }))}
                        />
                      </Modal>
                    ) : null}
                  </ModalGateway>
                </>
              )}
            </PropertyList>
          </Grid>
          <Grid item xs={12} md={4}>
            <TitleLabel title="Datos de inventario" />
            <PropertyList>
              <ChipField
                label="Inventariable"
                variant={formik.values.for_inventory === 'Y' ? 'filled' : 'outlined'}
                color={formik.values.for_inventory === 'Y' ? 'primary' : 'default'}
                onClick={() => handleChangeChip(formik.values.for_inventory, 'for_inventory')}
                disabled={formik.values.type === 'S'}
                message="Este chip activa las dimensiones del producto"
              />
              <TitleLabel title="Peso" />
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <AutocompleteField
                    label="Unidad de medida"
                    name="weightUnitMeasureInfo"
                    placeholder="Seleccione"
                    fullWidth
                    options={unitsWeightData || []}
                    // getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option) => option.name || ''}
                    onChange={(e, value) => {
                      formik.setFieldValue('weight_um', value?.id);
                      formik.setFieldValue('weightUnitMeasureInfo', value);
                    }}
                    value={formik.values.weightUnitMeasureInfo}
                    sx={{ marginBottom: '1em' }}
                    error={formik.touched.weight_um && Boolean(formik.errors.weight_um)}
                    helperText={formik.touched.weight_um && formik.errors.weight_um}
                    disabled={formik.values.for_inventory === 'N'}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    type="number"
                    name="weight"
                    label="Peso"
                    value={formik.values.weight}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.weight && Boolean(formik.errors.weight)}
                    helperText={formik.touched.weight && formik.errors.weight}
                    sx={{ marginBottom: '1em' }}
                    disabled={formik.values.for_inventory === 'N'}
                  />
                </Grid>
              </Grid>
              <TitleLabel title="Dimensiones" />
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <AutocompleteField
                    label="Unidad de medida"
                    name="dimensionUnitMeasureInfo"
                    placeholder="Seleccione"
                    fullWidth
                    options={unitsDimensionData || []}
                    // getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option) => option.name || ''}
                    onChange={(e, value) => {
                      formik.setFieldValue('dimension_um', value?.id);
                      formik.setFieldValue('dimensionUnitMeasureInfo', value);
                    }}
                    value={formik.values.dimensionUnitMeasureInfo}
                    error={formik.touched.dimension_um && Boolean(formik.errors.dimension_um)}
                    helperText={formik.touched.dimension_um && formik.errors.dimension_um}
                    disabled={formik.values.for_inventory === 'N'}
                  />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <InputField
                    type="number"
                    name="height"
                    label="Altura"
                    value={formik.values.height}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.height && Boolean(formik.errors.height)}
                    helperText={formik.touched.height && formik.errors.height}
                    sx={{ marginBottom: '1em' }}
                    disabled={formik.values.for_inventory === 'N'}
                  />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <InputField
                    type="number"
                    name="width"
                    label="Ancho"
                    value={formik.values.width}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.width && Boolean(formik.errors.width)}
                    helperText={formik.touched.width && formik.errors.width}
                    sx={{ marginBottom: '1em' }}
                    disabled={formik.values.for_inventory === 'N'}
                  />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <InputField
                    type="number"
                    name="length"
                    label="Largo"
                    value={formik.values.length}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.length && Boolean(formik.errors.length)}
                    helperText={formik.touched.length && formik.errors.length}
                    sx={{ marginBottom: '1em' }}
                    disabled={formik.values.for_inventory === 'N'}
                  />
                </Grid>
              </Grid>
              <TitleLabel title="Volúmen" />

              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <AutocompleteField
                    label="Unidad de medida"
                    name="volumeUnitMeasureInfo"
                    placeholder="Seleccione"
                    fullWidth
                    options={unitsVolumeData || []}
                    // getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option) => option.name || ''}
                    onChange={(e, value) => {
                      formik.setFieldValue('volume_um', value?.id);
                      formik.setFieldValue('volumeUnitMeasureInfo', value);
                    }}
                    value={formik.values.volumeUnitMeasureInfo}
                    sx={{ marginBottom: '1em' }}
                    error={formik.touched.volume_um && Boolean(formik.errors.volume_um)}
                    helperText={formik.touched.volume_um && formik.errors.volume_um}
                    disabled={formik.values.for_inventory === 'N'}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    type="number"
                    name="volume"
                    label="Volumen"
                    value={formik.values.volume}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.volume && Boolean(formik.errors.volume)}
                    helperText={formik.touched.volume && formik.errors.volume}
                    sx={{ marginBottom: '1em' }}
                    // disabled={formik.values.for_inventory === 'N'}
                    disabled
                  />
                </Grid>
              </Grid>
              <TitleLabel title="UME" />
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <AutocompleteField
                    label="Unidad de medida"
                    name="umeUnitMeasureInfo"
                    placeholder="Seleccione"
                    fullWidth
                    options={unitsWeightData || []}
                    // getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option) => option.name || ''}
                    onChange={(e, value) => {
                      formik.setFieldValue('supplier_umes_unit_id', value?.id);
                      formik.setFieldValue('umeUnitMeasureInfo', value);
                    }}
                    value={formik.values.umeUnitMeasureInfo}
                    sx={{ marginBottom: '1em' }}
                    error={
                      formik.touched.supplier_umes_unit_id &&
                      Boolean(formik.errors.supplier_umes_unit_id)
                    }
                    helperText={
                      formik.touched.supplier_umes_unit_id && formik.errors.supplier_umes_unit_id
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    type="number"
                    name="supplier_umes"
                    label="Valor UME"
                    value={formik.values.supplier_umes}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.supplier_umes && Boolean(formik.errors.supplier_umes)}
                    helperText={formik.touched.supplier_umes && formik.errors.supplier_umes}
                    sx={{ marginBottom: '1em' }}
                  />
                </Grid>
              </Grid>

              <Chip
                label="Producción"
                variant={formik.values.for_production === 'Y' ? 'filled' : 'outlined'}
                color={formik.values.for_production === 'Y' ? 'primary' : 'default'}
                onClick={() => handleChangeChip(formik.values.for_production, 'for_production')}
                sx={{ marginBottom: '1em' }}
              />
              <MultiSelectField
                label="Características"
                name="labels"
                value={labelsListDataOriginal.length === 0 ? [] : formik.values.labels}
                onChange={(e) =>
                  handleMultiSelectChange(e, 'labels', setLabelsListData, true, labelsListData)
                }
                error={formik.touched.labels && Boolean(formik.errors.labels)}
                options={labelsListDataOriginal}
                getOptionName={(value) => getOptionsName(value.code, labelsListDataOriginal)}
                onDeleteOption={(value) =>
                  handleOptionsDelete(
                    value,
                    'labels',
                    formik.values.labels,
                    setLabelsListData,
                    true,
                    labelsListData
                  )
                }
              />
            </PropertyList>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={update ? <PencilIcon /> : <PlusIcon />}
                  variant="contained"
                  onClick={async () => {
                    if (!formik.isSubmitting) {
                      const valid = checkForm();
                      if (!valid) return;
                      formik.submitForm();
                      // if (formik.isSubmitting) setDialogSaving(false);
                    }
                  }}
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                {update && (
                  <Button
                    color="secondary"
                    size="large"
                    startIcon={<TrashIcon />}
                    onClick={() => onClose(false)}
                    sx={{
                      marginLeft: 1
                    }}
                  >
                    Cancelar
                  </Button>
                )}
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
      <ImagesUploaderDialog
        onCancel={handleCloseUploadDialog}
        onSaveImages={handleSaveImages}
        open={uploadDialogOpen}
        selectedImages={selectedImages}
        // imagesFiles={imagesFiles}
        onUpdateImageFiles={setImagesFiles}
      />
    </Box>
  );
};

ProductForm.propTypes = {
  initialValues: Proptypes.object,
  update: Proptypes.bool,
  onClose: Proptypes.func,
  handleRefresh: Proptypes.func,
  itemcode: Proptypes.string
};
