import { createContext, useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMounted } from '../../hooks/use-mounted';
import { useData } from '../../hooks/use-data';
import { expensesApi } from '../../api/goal/rindegastos/rindegastos-api';
import { useApp } from '../../hooks/use-app';
import PropTypes from 'prop-types';
import { ExpenseViews } from '../../api/goal/rindegastos/rindegastos-definitions';

const ExpenseContext = createContext();

const useExpense = () => {
  return useContext(ExpenseContext);
};

const ExpenseProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { search } = useLocation();
  const view = new URLSearchParams(search).get('view') || ExpenseViews.ALL;

  const [editMode, setEditMode] = useState(false);

  const [dataState, handleRefresh] = useData({
    sourceApi: expensesApi.getExpense,
    apiParameter: { companyId: currentCompany, id: id, view: view },
    loadingMessage: 'Cargando reporte de gasto...',
    mounted
  });

  const value = {
    view: view,
    loading: dataState.isLoading,
    expense: dataState.data ?? {},
    handleRefresh,
    error: dataState.displayError ? dataState.message : '',
    editMode,
    setEditMode
  };
  return <ExpenseContext.Provider value={value}>{children}</ExpenseContext.Provider>;
};

ExpenseProvider.propTypes = { children: PropTypes.node.isRequired };

export { ExpenseProvider, useExpense };
