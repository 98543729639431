import Proptypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { Status } from '../../status';
import GadminActions from '../../../contexts/gadmin-actions';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { StatusColor } from '../../contest/contest-definition';
import { useAuth } from '../../../hooks/use-auth';
import GoalButton from '../../goal-button';

export const SurveySumaryDetail = ({ item, setEditMode }) => {
  const { hasPermission } = useAuth();

  return (
    <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
      <CardHeader
        title="Detalle de la encuesta"
        action={<Status color={StatusColor(item.status)} label={item.statusName} />}
      />
      <CardContent>
        <Grid container>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem label="Empresa" align="right">
                <Typography color="textSecondary" variant="body2" align="right">
                  {item.company_id || '--'}
                </Typography>
              </PropertyListItem>
              <PropertyListItem label="Título" align="right">
                <Typography color="textSecondary" variant="body2" align="right">
                  {item.title || '--'}
                </Typography>
              </PropertyListItem>
              <PropertyListItem label="Fecha" align="right">
                <Typography color="textSecondary" variant="body2" align="right">
                  {item.date || '--'}
                </Typography>
              </PropertyListItem>
              <PropertyListItem label="Equipos de ventas" align="right">
                <Typography color="textSecondary" variant="body2" align="right">
                  {item.sales_team_info.name || '--'}
                </Typography>
              </PropertyListItem>
              <PropertyListItem label="Puntos" align="right">
                <Typography color="textSecondary" variant="body2" align="right">
                  {item.point || '--'}
                </Typography>
              </PropertyListItem>
              <PropertyListItem label="Ejecutados" align="right">
                <Typography color="textSecondary" variant="body2" align="right">
                  {item.requestUpdateBy || '--'}
                </Typography>
              </PropertyListItem>
            </PropertyList>
          </Grid>
        </Grid>
      </CardContent>

      {hasPermission(GadminActions.G2_SURVEY_ADD) && (
        <CardActions>
          <GoalButton
            color="primary"
            startIcon={<PencilIcon />}
            onClick={() => {
              setEditMode(true);
            }}
          >
            Editar
          </GoalButton>
        </CardActions>
      )}
    </Card>
  );
};
SurveySumaryDetail.propTypes = {
  item: Proptypes.object,
  setEditMode: Proptypes.func
};
