import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GoalCustomDialog = ({
  open,
  handleClose,
  title = '',
  children,
  maxWidth = 'xl',
  fullScreen = false,
  scroll = 'paper',
  withoutFooter = false,
  disableEscapeKeyDown = false,
  sx = {}
}) => {
  return (
    <Dialog
      PaperProps={{
        sx: { width: '100%' }
      }}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      disableEscapeKeyDown={disableEscapeKeyDown}
      sx={sx}
    >
      {title && (
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ padding: '1rem 1.5rem' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <DialogTitle id="scroll-dialog-title" sx={{ padding: '0px' }}>
                {title}
              </DialogTitle>
            </Box>
          </Box>
          <Box sx={{ position: 'absolute', padding: '0px 10px', top: '10px', right: '10px' }}>
            <Tooltip title="Cerrar">
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}
      <DialogContent dividers>{children}</DialogContent>
      {!withoutFooter && (
        <DialogActions>
          <Button onClick={handleClose} variant="contained" size="large">
            Cerrar
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

GoalCustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  fullScreen: PropTypes.bool,
  scroll: PropTypes.oneOf(['paper', 'body']),
  withoutFooter: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  sx: PropTypes.object
};

export default GoalCustomDialog;
