import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalJournalsApi {
  async getJournal({ transid, companyId }) {
    const response = await dataLoader.goalGet({
      action: `/financial/journals/${transid}`,
      params: {
        companyId
      }
    });
    return response;
  }
}
const goalJournalsApi = new GoalJournalsApi();

export { goalJournalsApi };
