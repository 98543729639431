import PropTypes from 'prop-types';
import { Box, TextField } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { CommonHelpText } from './common/common-help-text';

export const InputField = (props) => {
  const { InputProps, sx, message = '', sxMain = {}, ...other } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sxMain }}>
      <TextField
        inputProps={{
          sx: {
            alignItems: 'center',
            display: 'flex',
            fontSize: 12,
            height: 'unset',
            lineHeight: 2.3,
            px: 1.5,
            py: 0.75,
            '&.MuiInputBase-inputAdornedStart': {
              pl: 0
            }
          }
        }}
        variant="filled"
        InputLabelProps={{
          shrink: true,
          sx: {
            color: 'text.primary',
            fontSize: 12,
            fontWeight: 500,
            mb: 0.5,
            position: 'relative',
            transform: 'none'
          }
        }}
        sx={{
          '& .MuiFilledInput-root': {
            backgroundColor: 'background.paper',
            // borderWidth: 1,
            // borderStyle: 'solid',
            // borderColor: 'neutral.300',
            borderRadius: 1,
            boxShadow: (theme) => `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.1rem`,
            overflow: 'hidden',
            p: 0,
            transition: (theme) => theme.transitions.create(['border-color', 'box-shadow']),
            '&:before': {
              borderBottom: 0
            },
            '&:hover': {
              backgroundColor: 'background.paper'
            },
            '&.Mui-focused': {
              backgroundColor: 'background.paper',
              boxShadow: (theme) => `${alpha(theme.palette.primary.main, 0.51)} 0 0 0 0.2rem`
            },
            '&.Mui-disabled': {
              backgroundColor: 'action.disabledBackground',
              borderColor: alpha('#D6DBE1', 0.5),
              boxShadow: (theme) => `${alpha(theme.palette.divider, 0.45)} 0 0 0 0.1rem`

              // borderColor: 'background.paper'
            },
            '.MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)':
              {
                mt: 0,
                ml: 1.5
              }
          },
          ...sx
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          ...InputProps,
          disableUnderline: true
        }}
        {...other}
      />
      <CommonHelpText message={message} />
    </Box>
  );
};

InputField.propTypes = {
  InputProps: PropTypes.object,
  sx: PropTypes.object,
  message: PropTypes.string,
  sxMain: PropTypes.object
};
