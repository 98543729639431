import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { PromoAssignedLineItems } from '../../components/promo/assigned/assigned-line-items';
import { Status } from '../../components/status';
import { useMounted } from '../../hooks/use-mounted';
import { useApp } from '../../hooks/use-app';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { RenderIf } from '../../components/render-if';
import LoadingContent from '../../components/common/loading-content';
import { PromoAssignedHeaders } from '../../components/promo/assigned/assigned-headers';
import { PromoAssignedTiming } from '../../components/promo/assigned/assigned-timing';
import { PromoAssignedEvals } from '../../components/promo/assigned/assigned-evals';
import { PromoAssignedOrderPreviewTable } from '../../components/promo/assigned/assigned-order-preview-table';
import { DocumentLog } from '../../components/purchases/common/document-log';
import { useData } from '../../hooks/use-data';
import { goalOrdersApi } from '../../api/goal/presale/goal-orders';
import GoalButton from '../../components/goal-button';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CustomerOrderDetails } from '../../components/document/orders/customer-order-details';
import { PromoAvalibleStock } from '../../components/promo/stock/avalible-stock';

export const PromoAssigned = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title, currentCompany } = useApp();

  const [promoAssignedState, refreshPromoAssigned] = useData({
    sourceApi: goalOrdersApi.getPromotionsDetail,
    apiParameter: { id, companyId: currentCompany },
    loadingMessage: 'Cargando documento',
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });

  useEffect(() => {
    refreshPromoAssigned({ id });
  }, [id]);

  const { promo } = promoAssignedState.data || {};
  const hasPromo = promo && promo.lines && promo.lines.length > 0;
  const hasLines =
    promoAssignedState.data &&
    promoAssignedState.data.lines &&
    promoAssignedState.data.lines.length > 0;

  const renderContent = () => {
    if (promoAssignedState.loading || promoAssignedState.error || !promoAssignedState.data) {
      return (
        <LoadingContent
          loadingText={promoAssignedState.message || ''}
          error={promoAssignedState.message || ''}
          state={promoAssignedState}
        />
      );
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={`Detalle de promoción del pedido ${id}`}
              // avatar={
              //   <GoalRecordLink
              //     to={'/sales/orders'}
              //     label={'Pedidos'}
              //     startIcon={<ArrowLeftIcon fontSize="small" color="primary" sx={{ mr: 2 }} />}
              //   />
              // }
              subheader={<Status color="error.main" label={promoAssignedState.data?.statusName} />}
              action={
                <>
                  <GoalButton
                    color="primary"
                    onClick={refreshPromoAssigned}
                    label="Refrescar"
                    startIcon={<RefreshIcon />}
                  />
                </>
              }
            />
            <CardContent>
              <CustomerOrderDetails
                orderInfo={promoAssignedState.data}
                onRefresh={refreshPromoAssigned}
              />
              {/* <PromoAssignedDetails promoAssigned={promoAssignedState.data} /> */}
            </CardContent>
          </Card>
        </Grid>
        {hasLines && (
          <Grid item xs={12}>
            <Card>
              <PromoAssignedOrderPreviewTable promoAssigned={promoAssignedState.data} />
            </Card>
          </Grid>
        )}
        <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
          <RenderIf condition={hasPromo}>
            <Grid item xs={12}>
              <PromoAssignedHeaders promoAssigned={promoAssignedState.data.promo} />
            </Grid>
          </RenderIf>
        </AllowedGuard>
        <RenderIf condition={hasPromo}>
          <Grid item xs={12}>
            <PromoAssignedLineItems promoAssigned={promoAssignedState.data.promo} />
          </Grid>
        </RenderIf>
        <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
          <RenderIf condition={hasPromo}>
            <Grid item xs={12}>
              <PromoAssignedEvals promoAssigned={promoAssignedState.data.promo} />
            </Grid>
          </RenderIf>
        </AllowedGuard>
        <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
          <RenderIf condition={hasPromo}>
            <Grid item xs={12}>
              <PromoAvalibleStock stock={promo.stock} />
            </Grid>
          </RenderIf>
        </AllowedGuard>
        <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
          <RenderIf condition={hasPromo}>
            <Grid item xs={12}>
              <PromoAssignedTiming promoAssigned={promoAssignedState.data.promo} />
            </Grid>
          </RenderIf>
        </AllowedGuard>

        {/* <Grid item xs={12}>
                <PromoAssignedLog promoAssigned={promoAssignedState.data} />
              </Grid> */}
        <Grid item xs={12}>
          <DocumentLog document={{ objType: 124, docEntry: promoAssignedState.data.IdPedido }} />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Detalle de promociones del pedido')}</title>
      </Helmet>

      {renderContent()}
    </>
  );
};
