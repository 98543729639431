import Proptypes from 'prop-types';
import { format } from 'date-fns';
import numeral from 'numeral';

import {
  Box,
  Checkbox,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
// import { Status } from '../../status';
import { InvoiceMenu } from '../common/invoice-menu';
import { Status } from '../../status';
import { NumberColor } from '../../number-color';
import { Code as CodeIcon } from '../../../icons/code';
import { Page as PageIcon } from '../../../icons/page';
import { Check as CheckIcon } from '../../../icons/check';
import { RenderIf } from '../../render-if';
import { invoiceApi } from '../../../api/invoice';
import { purchaseView } from '../../../api/purchase-definitions';
import { useApp } from '../../../hooks/use-app';
import GoalRecordLink from '../../goal-record-link';
import TableHeadEnhanced from '../../common/table-head-enhanced';

const columns = [
  {
    id: 'docEntry',
    disablePadding: true,
    label: 'ID'
  },
  {
    id: 'document',
    label: 'Comprobante'
  },
  {
    id: 'objname',
    label: 'SB1'
  },
  {
    id: 'account',
    label: 'Cuenta'
  },
  {
    id: 'ocrCode',
    label: 'Centro de Costo'
  },
  {
    id: 'cardName',
    label: 'Proveedor'
  },
  {
    id: 'docDate',
    label: 'Fecha'
  },
  {
    id: 'docTotal',
    label: 'Total'
  },
  {
    id: 'paymentName',
    label: 'Termino de Pago'
  },
  {
    id: 'statusName',
    label: 'Estado'
  },
  {
    id: 'actions',
    label: ''
  },
  {
    id: 'sumIgv',
    label: 'IGV'
  },
  {
    id: 'sumGravada',
    label: 'Gravado'
  },
  {
    id: 'sumInafecta',
    label: 'Exonerado'
  }
];

const statusVariants = [
  { color: 'info.main', label: 'Borrador', value: 'D' },
  { color: 'warning.main', label: 'Validado', value: 'V' },
  { color: 'warning.main', label: 'Verificado - alm', value: 'N' },
  { color: 'success.main', label: 'Recibido', value: 'R' },
  { color: 'warning.main', label: 'Aprobado Contable', value: 'Y' },
  { color: 'success.main', label: 'Facturado', value: 'F' },
  { color: 'error.main', label: 'Anulado', value: 'A' },
  { color: 'error.main', label: 'Cancelado', value: 'E' },
  { color: 'success.main', label: 'Confirmado', value: 'X' },
  { color: 'warning.main', label: 'Para anular', value: 'Z' },
  { color: 'error.main', label: 'Devuelto', value: 'T' }
];

export const InvoicesTable = (props) => {
  const {
    error,
    invoices = [],
    invoicesCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedInvoices = [],
    sort = 'desc',
    sortBy = 'issueDate',
    onRefresh
  } = props;
  const { appUrlBase } = useApp();

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !invoices?.length);
  const detailRoute = `/purchases/invoices/`;

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <TableContainer sx={{ maxHeight: '50vh' }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          aria-labelledby="Tabla de facturas de servicios"
        >
          <TableHeadEnhanced
            keyPrefix="invoice-services-column"
            headCells={columns}
            onRequestSort={onSortChange}
            order={sort}
            orderBy={sortBy}
            onSelectAllClick={onSelectAll}
            numSelected={selectedInvoices.length}
            rowCount={invoices.length}
          />
          {/* <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={invoices.length > 0 && selectedInvoices.length === invoices.length}
                  disabled={isLoading}
                  indeterminate={
                    selectedInvoices.length > 0 && selectedInvoices.length < invoices.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead> */}
          <TableBody>
            {invoices.map((invoice) => {
              const statusVariant = statusVariants.find(
                (variant) => variant.value === invoice.status
              );

              return (
                <TableRow
                  hover
                  key={invoice.docEntry}
                  selected={
                    !!selectedInvoices.find(
                      (selectedCustomer) => selectedCustomer === invoice.docEntry
                    )
                  }
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        !!selectedInvoices.find(
                          (selectedCustomer) => selectedCustomer === invoice.docEntry
                        )
                      }
                      onChange={(event) => onSelect(event, invoice.docEntry)}
                    />
                  </TableCell>
                  <TableCell sx={{ minWidth: 95 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <RenderIf condition={!!invoice.xmlCode}>
                        <CodeIcon />
                      </RenderIf>
                      <RenderIf condition={!!invoice.pdfCode}>
                        <PageIcon />
                      </RenderIf>
                      <RenderIf condition={invoice.cpe_status === 1}>
                        <CheckIcon fontSize="small" />
                      </RenderIf>
                    </Box>
                    <GoalRecordLink
                      to={`${detailRoute}${invoice.docEntry}`}
                      label={invoice.docEntry}
                    />
                  </TableCell>
                  <TableCell>{invoice.document}</TableCell>
                  <TableCell>
                    {invoice.objName}-{invoice.targetRef}
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body1">
                      {invoice.account}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {invoice.acctName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body1">
                      {invoice.ocrCode}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {invoice.ocrName}
                    </Typography>
                  </TableCell>
                  <TableCell>{invoice.cardName}</TableCell>
                  <TableCell>{format(new Date(invoice.docDate), 'yyyy-MM-dd')}</TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body1">
                      {invoice.currency}
                    </Typography>
                    <NumberColor value={invoice.docTotal} format="0,0.00" />
                  </TableCell>
                  <TableCell>{invoice.paymentName}</TableCell>
                  <TableCell>
                    <Status color={statusVariant?.color} label={statusVariant?.label} />
                  </TableCell>
                  <TableCell>
                    <InvoiceMenu
                      invoice={invoice}
                      detailPath={`${appUrlBase}/purchases/invoices`}
                      apiHandler={invoiceApi}
                      containerView={purchaseView.PURCHASE_SERVICE}
                      afterProcessHandler={onRefresh}
                    />
                  </TableCell>
                  <TableCell>{numeral(invoice.sumIgv).format(`${'S/'}0,0.00`)}</TableCell>
                  <TableCell>{numeral(invoice.sumGravada).format(`${'S/'}0,0.00`)}</TableCell>
                  <TableCell>{numeral(invoice.sumExonerada).format(`${'S/'}0,0.00`)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={invoicesCount}
      />
    </Box>
  );
};

InvoicesTable.propTypes = {
  invoices: Proptypes.array,
  invoicesCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedInvoices: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  onRefresh: Proptypes.func
};
