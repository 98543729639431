import { useTheme } from '@emotion/react';
import { withStyles } from '@mui/styles';

// ----------------------------------------------------------------------

const GlobalStyles = withStyles(() => {
  const theme = useTheme();
  return {
    '@global': {
      '*': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box'
      },
      html: {
        width: '100%',
        height: '100%',
        '-ms-text-size-adjust': '100%',
        '-webkit-overflow-scrolling': 'touch'
      },
      body: {
        width: '100%',
        height: '100%'
      },
      '#root': {
        width: '100%',
        height: '100%'
      },
      input: {
        '&[type=number]': {
          MozAppearance: 'textfield',
          '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
          '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
        }
      },
      textarea: {
        '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
        '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
        '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
        '&::placeholder': { color: theme.palette.text.disabled }
      },
      a: { color: theme.palette.primary.main },
      img: { display: 'block', maxWidth: '100%' },

      '::-webkit-scrollbar-track': {
        width: '22px',
        height: '10px',
        background: theme.palette.grey[300_16]
      },
      '::-webkit-scrollbar-thumb': {
        background: theme.palette.grey[500],
        borderRadius: '8px'
      },
      '::-webkit-scrollbar': {
        width: '22px',
        height: '10px'
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.primary.main
      },
      [theme.breakpoints.down('md')]: {
        '.MuiContainer-root': {
          paddingLeft: '0px',
          paddingRight: '0px'
        },
        '.MuiDialogContent-root': {
          paddingLeft: '0px',
          paddingRight: '0px'
        },
        '.MuiDialog-paperFullWidth': {
          margin: '0px'
        },
        '::-webkit-scrollbar': {
          width: '8px',
          height: '3px'
        }
      },
      '.MuiGrid-root': {
        paddingBottom: theme.spacing(2)
        // position: 'relative'
      },
      '.MuiBox-root': {
        borderRadius: theme.shape.borderRadiusMd
      }
      // '.notistack-SnackbarContainer': {
      //   position: 'fixed',
      //   top: 0,
      //   left: '50%',
      //   transform: 'translateX(-50%)'
      //   // border: `1px solid ${theme.palette.divider}`,
      //   // zIndex: 9999
      // }
    }
  };
})(() => null);

export default GlobalStyles;
