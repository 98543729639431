import { appDefinitions } from '../app-definitions';
import { dataLoader } from '../data-loader';

class PermissionsApi {
  async getPermissions({
    companyId,
    view = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    search = '',
    sort = '',
    sortBy = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `auth/permissions`,
      params: {
        companyId: companyId,
        view: view,
        page: page,
        per_page: perPage,
        search: search,
        order_direction: sort,
        order_by: sortBy
      }
    });
    return response;
  }

  async getPermission({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `auth/permissions/${id}`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async updatePermission(prop) {
    const { id, changes } = prop;
    const response = await dataLoader.loadGoalUpdate({
      action: `/auth/permissions/${id}`,
      changes
    });
    return response;
  }

  async createPermission({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/auth/permissions',
      changes: values
    });
    return response;
  }

  async deletePermission({ id, companyId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/auth/permissions/${id}?companyId=${companyId}`
    });
    return response;
  }

  async restorePermission({ id, companyId }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/auth/permissions/${id}/restore`,
      changes: { company_id: companyId }
    });
    return response;
  }

  async getRolesOfPermission({ id, companyId, view }) {
    const response = await dataLoader.goalGet({
      action: `auth/permissions/${id}/roles`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async getUsersByRolesOfPermission({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `auth/permissions/${id}/users`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async getPermissionsNotInRole({
    roleId,
    params = {
      companyId: null,
      search: '',
      page: 1,
      per_page: appDefinitions.pageSize,
      view: ''
    }
  }) {
    const response = await dataLoader.goalGet({
      action: `auth/permissions/not-in-role/${roleId}`,
      params: params
    });
    return response;
  }
}

export const permissionsApi = new PermissionsApi();
