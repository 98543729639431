import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import layoutOutline from '@iconify/icons-eva/layout-outline';

import { Box, Button, Card, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { ChevronDoubleRight as ChevronDoubleRightIcon } from '../../../icons/chevron-double-right';
import { Chip as ChipIcon } from '../../../icons/chip';
import { Trash as TrashIcon } from '../../../icons/trash';
import { Plus as PlusIcon } from '../../../icons/plus';
import { documentApi } from '../../../api/document';
import { deliveryApi } from '../../../api/delivery';
import { financialReportApi } from '../../../api/financial-reports';
import { RenderIf } from '../../render-if';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { useApp } from '../../../hooks/use-app';
import { NumberColor } from '../../number-color';
import { ConstantYesNo } from '../../../api/application-enums';
import { appUrls } from '../../../api/app-definitions';
import { isValidPositive } from '../../../hooks/use-bolean';
import { ConfirmationButton } from '../../common/confirmation-button';
import { feManagementApi } from '../../../api/goal/fe/fe-management-api';
import GoalButton from '../../goal-button';
import { Icon } from '@iconify/react';
import GoalRecordLink from '../../goal-record-link';

const objectTypes = {
  SALE: '13',
  RETURN: '14'
};
const receiptStatus = {
  OPEN: 'O',
  CLOSED: 'C'
};
const processReceipt = {
  CANCEL: 'Cancelar',
  NO_FACELE: 'noFacele',
  SI_FACELE: 'siFacele',
  CLONE: 'clone',
  REACTIVATE: 'Reactive',
  REMOVE: 'Remove',
  CREATE_GR: 'CreateGr'
};
export const ReceiptDetails = (props) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmDialogAction, setOpenConfirmDialogAction] = useState(null);
  const { receiptInfo, onRefresh } = props;

  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const { showNotify, appUrlBase, showError } = useApp();

  const portalUrl = appUrls.PORTAL_URL;

  const onCancelConfirmationDialog = () => {
    setOpenConfirmDialog(false);
  };
  const processReceiptHandler = useCallback(async () => {
    let response = '';
    switch (openConfirmDialogAction) {
      case processReceipt.CANCEL:
        response = await documentApi.cancelReceipt({
          objType: receiptInfo.ObjType,
          docEntry: receiptInfo.DocEntry
        });
        break;
      case processReceipt.NO_FACELE:
        response = await financialReportApi.faceleIncludeExclude({
          objType: receiptInfo.ObjType,
          docEntry: receiptInfo.DocEntry,
          valid: 'Y'
        });
        break;
      case processReceipt.SI_FACELE:
        response = await financialReportApi.faceleIncludeExclude({
          objType: receiptInfo.ObjType,
          docEntry: receiptInfo.DocEntry,
          valid: 'N'
        });
        break;
      case processReceipt.REMOVE:
        response = await financialReportApi.faceleRemove({
          objType: receiptInfo.ObjType,
          docEntry: receiptInfo.DocEntry
        });
        break;
      case processReceipt.CLONE:
        response = await documentApi.cloneReceipt({
          objType: receiptInfo.ObjType,
          docEntry: receiptInfo.DocEntry
        });
        break;
      case processReceipt.CREATE_GR:
        response = await deliveryApi.generateDeliveryNote({
          objType: receiptInfo.ObjType,
          docEntry: receiptInfo.DocEntry,
          codJr: receiptInfo.codJr,
          plaVeh: receiptInfo.plaVeh,
          originalDate: 'Y'
        });
        break;
      default:
    }
    if (response.success) {
      showNotify(response.message);
    } else {
      showNotify(response.message, 'error');
    }
    setOpenConfirmDialog(false);
  }, [openConfirmDialogAction]);

  const handleGenerateDeliveryNote = async () => {
    setConfirmDialogMessage('¿Está seguro de generar una GR para el comprobante?');
    setOpenConfirmDialogAction(processReceipt.CREATE_GR);
    setOpenConfirmDialog(true);
  };
  const handleCancelReceipt = async () => {
    setConfirmDialogMessage('¿Está seguro de ANULAR el comprobante?');
    setOpenConfirmDialogAction(processReceipt.CANCEL);
    setOpenConfirmDialog(true);
  };
  const handleRemoveReceiptFromFacele = async () => {
    setConfirmDialogMessage('¿Está seguro de Eliminar el comprobante para envarlo nuevamente?');
    setOpenConfirmDialogAction(processReceipt.REMOVE);
    setOpenConfirmDialog(true);
  };
  const handleCloneReceipt = async () => {
    setConfirmDialogMessage('¿Está seguro de CLONAR el comprobante?');
    setOpenConfirmDialogAction(processReceipt.CLONE);
    setOpenConfirmDialog(true);
  };
  const handleNoFaceleReceipt = async () => {
    setConfirmDialogMessage('¿Está seguro de excluir el comprobante para el envío a Facele?');
    setOpenConfirmDialogAction(processReceipt.NO_FACELE);
    setOpenConfirmDialog(true);
  };
  const handleSiFaceleReceipt = async () => {
    setConfirmDialogMessage('¿Está seguro de incluir el comprobante para el envío a Facele?');
    setOpenConfirmDialogAction(processReceipt.SI_FACELE);
    setOpenConfirmDialog(true);
  };

  const handleNoValidateDiferenceReceipt = async () => {
    const result = await feManagementApi.excludeValidation({
      companyId: receiptInfo.CompanyId ?? 8,
      objtype: receiptInfo.ObjType,
      docentry: receiptInfo.DocEntry
    });
    if (result.success) {
      showNotify(result.message);
      onRefresh();
      // refresh
    } else {
      showError(result.message || 'Error al excluir validacion de diferencia');
    }
  };
  return (
    <Card variant="outlined">
      <CardHeader title="Información del comprobante" />
      <Divider />
      <Grid container>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem label="Cliente">
              <GoalRecordLink
                to={`/busines-partners/customers/${receiptInfo.CardCode}`}
                label={receiptInfo.CardCode}
              />
              <Typography color="textSecondary" variant="body2">
                {receiptInfo.CardName}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Término de pago" value={`#${receiptInfo.PaymentName}`} />
            <PropertyListItem
              label="Almacén"
              value={`${receiptInfo.WhsCode} - ${receiptInfo.WhsName}`}
            />
            <PropertyListItem
              label="Vendedor"
              value={`${receiptInfo.SlpCode}-${receiptInfo.SlpName}`}
            />
            <PropertyListItem
              label="Mesa"
              value={`${receiptInfo.CodigoMesa}-${receiptInfo.mesaName}`}
            />
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem label="Numero">
              <Box xl={1}>
                <Typography color="inherit" variant="body2">
                  {`#${receiptInfo.IdPedido}/${receiptInfo.tipo}`}
                </Typography>
                <RenderIf
                  condition={receiptInfo.IdPedido !== 0 && receiptInfo.ObjType === objectTypes.SALE}
                >
                  <Typography color="textSecondary" variant="body2">
                    <GoalRecordLink
                      to={`/sales/orders/${receiptInfo.IdPedido}`}
                      label="Ver pedido"
                    />
                  </Typography>
                </RenderIf>
              </Box>
            </PropertyListItem>
            <AllowedGuard permission={GadminActions.gCC_ACCT_JOURNAL_VIEW}>
              <PropertyListItem label="Transacción (Asiento)">
                <Box xl={1}>
                  <GoalRecordLink
                    to={`/accounting/journals/${receiptInfo.TransId}`}
                    label={receiptInfo.TransId}
                  />
                </Box>
              </PropertyListItem>
            </AllowedGuard>
            <PropertyListItem
              label="Fecha de document"
              value={moment(receiptInfo.DocDate).format('DD/MM/YYYY')}
            />
            <PropertyListItem
              label="Fecha de vencimiento"
              value={moment(receiptInfo.DocDueDate).format('DD/MM/YYYY')}
            />
            <PropertyListItem
              label="Fecha de creación"
              value={moment(receiptInfo.CreateDate).format('DD/MM/YYYY HH:mm:ss')}
            />
            <PropertyListItem label="Comentario" value={`${receiptInfo.Comments}`} />
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem label="Canal" align="right">
              {receiptInfo.CanalSede}
            </PropertyListItem>
            <PropertyListItem label="Total" align="right">
              <NumberColor value={receiptInfo.DocTotal} format="0.00" />
            </PropertyListItem>
            <PropertyListItem label="Total Aplicado" align="right">
              <NumberColor value={receiptInfo.PaidToDate} format="0.00" />
            </PropertyListItem>

            <PropertyListItem label="Saldo" align="right">
              <NumberColor value={receiptInfo.Balance} format="0.00" />
            </PropertyListItem>

            <PropertyListItem
              label={`Descuento (${numeral(receiptInfo.DiscPrcnt).format(`${'S/'}0,0.00%`)})`}
              align="right"
            >
              <NumberColor value={receiptInfo.DiscSum} format="0.00" />
            </PropertyListItem>
            <PropertyListItem label="Documento" align="right">
              <Box xl={1}>
                <Typography color="inherit" variant="body2">
                  {`${receiptInfo.DocName}/${receiptInfo.SerieSunat}-${receiptInfo.Number}`}
                </Typography>
                <RenderIf condition={!!receiptInfo.facele && receiptInfo.facele.transfered === 'Y'}>
                  <Box>
                    <GoalRecordLink
                      to={`https://www.docele.pe/home/modules/reports/invoice/InvoiceDetails.xhtml?serialNumber=${receiptInfo.SerieSunat}&correlative=${receiptInfo.NumeroSunat}&type=${receiptInfo.TipoSunat}`}
                      label="Ver Facele"
                      fullPath
                      target={'_blank'}
                    />
                  </Box>
                  <Box>
                    <GoalRecordLink
                      to={`${portalUrl}/receipt/${receiptInfo.shortCode}`}
                      label="Descargar XML / PDF"
                      fullPath
                      target={'_blank'}
                    />
                  </Box>
                </RenderIf>
              </Box>
            </PropertyListItem>
            <RenderIf condition={isValidPositive(receiptInfo.NumAtCard)} align="right">
              <PropertyListItem label="Despacho" align="right">
                <Box xl={1}>
                  <Typography color="inherit" variant="body2">
                    {`${receiptInfo.NumAtCard}/${receiptInfo.codJr}/${receiptInfo.plaVeh}`}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {receiptInfo.jrName}
                  </Typography>
                </Box>
              </PropertyListItem>
            </RenderIf>
            <RenderIf condition={receiptInfo.reference}>
              <PropertyListItem label="Referencia" value={`${receiptInfo.reference}`} />
            </RenderIf>
            <RenderIf condition={receiptInfo.excludeValidation}>
              <PropertyListItem label="" value="No han sido validos los importes" />
            </RenderIf>
          </PropertyList>
        </Grid>
        <AllowedGuard permission={GadminActions.gCC_DELIVERI_GR_CREATE}>
          <RenderIf
            condition={
              receiptInfo.ObjType === objectTypes.SALE &&
              receiptInfo.canCreatedGr === ConstantYesNo.YES
            }
          >
            <Button
              color="primary"
              size="large"
              startIcon={<ChipIcon />}
              onClick={handleGenerateDeliveryNote}
              variant="contained"
            >
              Generar GR
            </Button>
          </RenderIf>
        </AllowedGuard>
        <AllowedGuard permission={GadminActions.gCC_DOCUMENT_CANCEL}>
          <RenderIf
            condition={
              receiptInfo.ObjType === objectTypes.SALE &&
              receiptInfo.DocStatus === receiptStatus.OPEN
            }
          >
            <Button
              color="primary"
              size="large"
              startIcon={<TrashIcon />}
              onClick={handleCancelReceipt}
              variant="contained"
            >
              Anular
            </Button>
          </RenderIf>
          <RenderIf
            condition={
              receiptInfo.ObjType === objectTypes.SALE && receiptInfo.canClone === ConstantYesNo.YES
            }
          >
            <Button
              color="primary"
              size="large"
              startIcon={<ChevronDoubleRightIcon />}
              onClick={handleCloneReceipt}
              variant="contained"
            >
              Clonar
            </Button>
          </RenderIf>
        </AllowedGuard>
        <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
          <RenderIf
            condition={
              !receiptInfo.facele && (!receiptInfo.nofacele || receiptInfo.nofacele?.valid !== 'Y')
            }
          >
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={handleNoFaceleReceipt}
              variant="contained"
            >
              Excluir-Facele
            </Button>
          </RenderIf>
          <RenderIf condition={!receiptInfo.excludeValidation}>
            <ConfirmationButton
              icon={<PlusIcon />}
              label="Excluir Dif"
              title="Excluir validacion de diferencia"
              onConfirm={handleNoValidateDiferenceReceipt}
              message="Se excluyo la validacion de diferencia"
            />
          </RenderIf>
          <RenderIf
            condition={
              !receiptInfo.facele && !!receiptInfo.nofacele && receiptInfo.nofacele?.valid === 'Y'
            }
          >
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={handleSiFaceleReceipt}
              variant="contained"
            >
              Incluir-Facele
            </Button>
          </RenderIf>
          <RenderIf condition={!!receiptInfo.facele && receiptInfo.facele.transfered === 'N'}>
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={handleRemoveReceiptFromFacele}
              variant="contained"
            >
              Reenviar Facele
            </Button>
          </RenderIf>
        </AllowedGuard>
        <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
          <RenderIf condition={receiptInfo.TransId}>
            <GoalButton
              component={RouterLink}
              startIcon={<Icon icon={layoutOutline} />}
              to={`${appUrlBase}/accounting/journals/${receiptInfo.TransId}`}
              label={'Asiento'}
              showText={true}
              variant="contained"
            />
          </RenderIf>
        </AllowedGuard>
      </Grid>
      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={onCancelConfirmationDialog}
        onConfirm={processReceiptHandler}
        open={openConfirmDialog}
        title="Confirmación importante"
        variant="warning"
      />
    </Card>
  );
};

ReceiptDetails.propTypes = {
  receiptInfo: PropTypes.object.isRequired,
  onRefresh: PropTypes.func.isRequired
};
