import PropTypes from 'prop-types';

import GadminActions from '../../../contexts/gadmin-actions';
import { GoalMenu } from '../../common/goal-menu';
import GoalMenuItem from '../../common/goal-menu-item';
import { ConfirmationMenuItem } from '../../common/confirmation-menu-item';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { useAuth } from '../../../hooks/use-auth';
import { goalOrdersApi } from '../../../api/goal/presale/goal-orders';
import { useApp } from '../../../hooks/use-app';
import { showError, showNotify } from '../../../hooks/toasty';
import { useBolean } from '../../../hooks/use-bolean';

export const CustomerOrderMenu = (props) => {
  const { orderInfo, afterProcessHandler } = props;
  const { hasPermission } = useAuth();

  const { currentCompany } = useApp();
  const { isTrue } = useBolean();

  return (
    <GoalMenu>
      <GoalMenuItem
        startIcon={<SyncAltIcon />}
        helpText={'Ver los ultimos eventos'}
        to={`/logs/gadmin-logs?domain=124&entity=${orderInfo.IdPedido}`}
        label={'Sucesos'}
      />
      {hasPermission(GadminActions.gCC_GPROC_MNGMT) && isTrue(orderInfo.queueable) && (
        <ConfirmationMenuItem
          startIcon={<AutoModeIcon />}
          helpText={'Poner en cola'}
          onConfirm={async () => {
            const result = await goalOrdersApi.enqueueOrder({
              id: orderInfo.IdPedido,
              companyId: currentCompany
            });

            if (result.success) {
              showNotify(result.message ?? 'Pedido puesto en cola');
            } else {
              showError(result.message);
            }
            return result;
          }}
          label={'Poner en cola'}
          confirmationMessage={'¿Está seguro que desea poner en cola este pedido?'}
          afterConfirm={afterProcessHandler}
        />
      )}
    </GoalMenu>
  );
};

CustomerOrderMenu.propTypes = {
  orderInfo: PropTypes.object.isRequired,
  afterProcessHandler: PropTypes.func
};
