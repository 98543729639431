import { useState } from 'react';
import Proptypes from 'prop-types';
// import moment from 'moment';
import {
  Box,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { Pagination } from '../../pagination';
import SurveyForm from './survey-form';
import { SurveyItemMenu } from '../survey-item-menu';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { GoalRecordLink } from '../../../components/goal-record-link';
import { useGoalDialog } from '../../../hooks/use-goal-dialog';
import { CommonDialog } from '../../common/common-dialog';
import { SurveyQuestionView } from './survey-question-view';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: '#'
  },
  {
    id: 'title',
    label: 'Titulo'
  },
  {
    id: 'category',
    label: 'Categoria'
  },
  {
    id: 'subcategory',
    label: 'Subcategoria'
  },
  {
    id: 'type_question',
    label: 'Tipo de Pregunta'
  },
  {
    id: 'question',
    label: 'Pregunta'
  },
  {
    id: 'options',
    label: 'Opciones'
  },
  {
    id: 'correct',
    label: 'Respuesta'
  },
  {
    id: 'score',
    label: 'Puntaje'
  },
  {
    id: 'action',
    label: ''
  }
];

export const SurveyTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    sort = 'desc',
    sortBy = 'issueDate',
    handleRefresh
  } = props;

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  const [dialogController] = useGoalDialog({});
  const [itemData, setitemEdit] = useState(null);
  const handleClickEditIcon = (item) => {
    setitemEdit(item);
    dialogController.openDialog();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000, marginTop: 1 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.map((document, index) => (
              <TableRow hover key={document.id}>
                <TableCell sx={{ minWidth: 100 }}>
                  <GoalRecordLink
                    onClick={() => handleClickEditIcon(document)}
                    label={index + 1}
                    editable
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.title}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.category} -{document.category}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.subcategory} - {document.subcategory}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.type_question}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.question}
                  </Typography>
                </TableCell>
                <TableCell>
                  {document.type_question === 'multiselect' ||
                  document.type_question === 'select' ||
                  document.type_question === 'range' ? (
                    document.options.map((item, index) => (
                      <Typography key={index} color="textSecondary" variant="body2">
                        {item}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      ---
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {document.type_question === 'multiselect' ? (
                    document.correct.map((item, index) => (
                      <Typography key={index} color="textSecondary" variant="body2">
                        {item}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      {document.correct}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.score}
                  </Typography>
                </TableCell>
                <TableCell>
                  <AllowedGuard permission={GadminActions.G2_QUESTIONARY_UP}>
                    <SurveyItemMenu
                      items={document}
                      clickEdit={() => handleClickEditIcon(document)}
                      // clickDelete={() => handleOpenCornfirm(document)}
                    />
                  </AllowedGuard>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
      <CommonDialog
        controller={dialogController}
        // title="Editar cuestionario"
        Component={SurveyQuestionView}
        componentProps={{
          item: itemData,
          setEditMode: dialogController.setEditMode,
          handleRefresh
        }}
        EditorComponent={SurveyForm}
        editorComponentProps={{
          update: true,
          item: itemData,
          onClose: dialogController.closeDialog,
          onRefresh: handleRefresh,
          oncancel: () => dialogController.setEditMode(false)
        }}
      />
    </Box>
  );
};

SurveyTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  handleRefresh: Proptypes.func
};
