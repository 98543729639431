import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, InputAdornment } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { useMounted } from '../../../hooks/use-mounted';
import { useApp } from '../../../hooks/use-app';
import { useVehicle } from '../../../contexts/operations/vehicle-context';
import { appDefinitions } from '../../../api/app-definitions';
import { useData } from '../../../hooks/use-data';
import { ConstantStatus } from '../../../api/application-enums';
import { InputField } from '../../../components/input-field';
import LoadingContent from '../../../components/common/loading-content';
import { vehicleApi } from '../../../api/goal/operations/vehicles/vehicle';
import WorkOrdersTable from '../../../components/operations/work-orders/work-orders-table';
import { validateSearchText } from '../../../utils/string-utils';
import { strIsNumber } from '../../../utils/number-utiles';

export const VehicleWorkOrders = () => {
  const { vehicle } = useVehicle();
  const { title, currentCompany } = useApp();
  const mounted = useMounted();
  const { search } = useLocation();
  const view = new URLSearchParams(search).get('view')?.toUpperCase() || ConstantStatus.ALL;
  const basePath = `/operations/vehicles/${vehicle.code}/work-orders`;

  const [searchTerm, setSearchTerm] = useState('');
  const [keyDownSearch, setKeyDownSearch] = useState('');
  let data = [];
  let pagination = {
    total: 0,
    count: 0,
    per_page: appDefinitions.pageSize,
    current_page: 1,
    total_pages: 0
  };
  const [sort, setSort] = useState('desc');
  const [sortBy, setSortBy] = useState('created_at');

  const [dataState, handleRefresh] = useData({
    sourceApi: vehicleApi.getVehicleWorkOrders,
    apiParameter: {
      companyId: currentCompany,
      id: vehicle.id,
      view: view,
      perPage: appDefinitions.pageSize,
      page: 1,
      search: '',
      sort: sort,
      sortBy: sortBy
    },
    loadingMessage: 'Cargando órdenes de trabajo del vehículo...',
    mounted
  });

  const handleChangePage = (event, newPage) => {
    handleRefresh({
      companyId: currentCompany,
      id: vehicle.id,
      view: view,
      perPage: appDefinitions.pageSize,
      page: newPage,
      search: searchTerm,
      sort: sort,
      sortBy: sortBy
    });
  };

  const handleChangeRowsPerPage = (event, newPerPage, pagination) => {
    handleRefresh({
      companyId: currentCompany,
      id: vehicle.id,
      view: view,
      perPage: newPerPage,
      page: pagination.current_page,
      search: searchTerm,
      sort: sort,
      sortBy: sortBy
    });
  };

  const handleKeyDownSearch = (event) => {
    setKeyDownSearch(event.key);
  };

  const handleSearch = (event, pagination) => {
    let searchText = event.target.value.replace(/\s{2,}/g, ' ');

    if (searchText.trim() === '' && keyDownSearch === ' ') return;

    if (validateSearchText(searchText)) {
      setSearchTerm(searchText);
      if (strIsNumber(searchText) || searchText.length > 2)
        handleRefresh({
          companyId: currentCompany,
          id: vehicle.id,
          view: view,
          perPage: pagination.per_page,
          page: 1,
          search: searchText,
          sort: sort,
          sortBy: sortBy
        });
      if (searchText.length === 0)
        handleRefresh({
          companyId: currentCompany,
          id: vehicle.id,
          view: view,
          perPage: pagination.per_page,
          page: 1,
          search: '',
          sort: sort,
          sortBy: sortBy
        });
    }
  };

  const handleSortChange = (property, pagination) => {
    const isAsc = sortBy === property && sort === 'asc';
    setSort(isAsc ? 'desc' : 'asc');
    setSortBy(property);
    handleRefresh({
      companyId: currentCompany,
      id: vehicle.id,
      view: view,
      perPage: pagination.per_page,
      page: 1,
      search: searchTerm,
      sort: isAsc ? 'asc' : 'desc',
      sortBy: property
    });
  };

  const renderContent = () => {
    if (dataState.displayError) {
      return (
        <LoadingContent loadingText={dataState.message || ''} error={dataState.message || ''} />
      );
    }

    if (dataState.success) {
      data = dataState?.data?.data || [];
      pagination = {
        total: dataState.data.pagination.total,
        count: dataState.data.pagination.count,
        per_page: dataState.data.pagination.per_page,
        current_page: dataState.data.pagination.current_page,
        total_pages: dataState.data.pagination.total_pages
      };
    }

    return (
      <Card variant="outlined">
        <WorkOrdersTable
          data={dataState.isLoading ? [] : data}
          isLoading={dataState.isLoading}
          pagination={pagination}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          sort={sort}
          sortBy={sortBy}
          onSortChange={(event, property) => handleSortChange(property, pagination)}
          view={view.toLowerCase()}
          handleRefresh={handleRefresh}
          basePath={basePath}
        />
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title('Ordenes de trabajo')} </title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default' }}>
        <Box sx={{ pt: 2 }}>
          <Box sx={{ px: 2, pb: 2 }}>
            <InputField
              label="Buscar"
              name="search"
              value={searchTerm}
              onChange={(e) => handleSearch(e, pagination)}
              onKeyDown={handleKeyDownSearch}
              fullWidth
              placeholder="Buscar por número de orden"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                )
              }}
            />
          </Box>
          {renderContent()}
        </Box>
      </Box>
    </>
  );
};
