export const useBolean = () => {
  const trueValues = ['y', '1', 'true', 'yes', 'on', 'Y'];
  const isTrue = (value) => {
    if (!value) {
      return false;
    }
    return trueValues.includes(value.toString().toLowerCase());
  };

  return { isTrue };
};

export const isValidPositive = (value) => {
  // eslint-disable-next-line no-restricted-globals
  if (!value || isNaN(value)) {
    return false;
  }
  return value > 0;
};
