import PropTypes from 'prop-types';
import { useApp } from '../../../hooks/use-app';
import { useMounted } from '../../../hooks/use-mounted';
import { useGMDataSet } from '../../../components/gm/dataset/use-gm-dataset';
import { GMGrid } from '../../../components/gm/grid/gm-grid';
import { RefreshButton } from '../../../components/common/buttons/refresh-button';
import { Card, CardHeader } from '@mui/material';
import { goalWarehousesApi } from '../../../api/goal/inventory/warehouses/warehouse';

const columns = [
  { field: 'CardName', headerName: 'Proveedor', width: 250 },
  { field: 'marca', headerName: 'Marca' },
  {
    field: 'ItemCode',
    headerName: 'Código',
    link: {
      to: (item) => `/inventory/products/${item.ItemCode}/summary2`,
      tooltip: 'Ver detalle del producto'
    }
  },
  { field: 'ItemName', headerName: 'Nombre', width: 250 },
  { field: 'onhand', headerName: 'Físico', type: 'int' },
  { field: 'iscommited', headerName: 'Comprometido', type: 'int' },
  { field: 'avalible', headerName: 'Disponible', type: 'int' },
  { field: 'minstock', headerName: 'Minimo', type: 'int' },
  { field: 'maxStock', headerName: 'Máximo', type: 'int' },
  { field: 'excess', headerName: 'Excedente', type: 'int' },
  { field: 'locked', headerName: 'Bloqueado', type: 'boolean' },
  { field: 'forSale', headerName: 'Ventas', type: 'boolean' }
];

export const WarehouseStockTable = ({ code }) => {
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { handleRefresh, ...stockState } = useGMDataSet({
    sourceApi: goalWarehousesApi.getStock,
    apiParameters: { companyId: currentCompany, warehouseId: code },
    loadingMessage: 'Cargando almacenes...',
    mounted,
    columns
  });

  return (
    <Card
      variant="outlined"
      sx={{
        marginY: '1em'
      }}
    >
      <CardHeader
        title="Detalle de stock disponible"
        action={<RefreshButton onClick={handleRefresh} />}
      />

      <GMGrid {...stockState} />
    </Card>
  );
};

WarehouseStockTable.propTypes = {
  code: PropTypes.string.isRequired
};
