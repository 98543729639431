import PropTypes from 'prop-types';
import { Box, InputLabel, Typography } from '@mui/material';
import { useAuth } from '../../hooks/use-auth';
import useImageToBase64 from '../../hooks/use-image-to-base-64';
import { goalMediaApi } from '../../api/goal/media/goal-media';
import { useState } from 'react';
import { useApp } from '../../hooks/use-app';
import { GoalIconButton } from '../goal-icon-button';
// import cancel icon from '@mui/icons-material/Cancel';
import CancelIcon from '@mui/icons-material/Cancel';
import { GoalButton } from '../goal-button';

export const CommonImageSelector = ({ afterUpload }) => {
  const { showError } = useApp();
  const [imageLoading, setImageLoading] = useState(false);
  const { account } = useAuth();
  const [selectedImage, setSelectedImage] = useState(null);
  const {
    base64Image: base64ImageFile,
    previewImage: previewImageFile,
    resetImage: resetImageFile
  } = useImageToBase64(selectedImage);

  const handleImagenChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    // formik.setFieldValue('image_code', file);
  };

  const handleSubmitImage = async () => {
    setImageLoading(true);
    const dataImage = {
      data: base64ImageFile,
      name: selectedImage.name,
      type: selectedImage.type,
      application: 'GOAL',
      folder: 'document',
      owner: account?.userName
    };
    const response = await goalMediaApi.submitImage({ values: dataImage });
    if (response.data && response.success) {
      resetImageFile();
      afterUpload?.(response.data);
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
    setImageLoading(false);
  };

  return (
    <Box>
      {!previewImageFile && !imageLoading && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Box
            sx={{
              width: '250px',
              height: '250px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              color: '#fff'
            }}
            style={{
              background: '#131313'
            }}
          >
            {!previewImageFile && (
              <>
                <p>Agregar imagen</p>
                <InputLabel
                  htmlFor="fileUpload"
                  style={{
                    color: '#55d6d6',
                    fontSize: '22px',
                    fontWeight: '500',
                    padding: '6px 12px',
                    cursor: 'pointer'
                  }}
                >
                  Elejir el archivo
                </InputLabel>
                <input
                  accept="image/*"
                  type="file"
                  name="attacments"
                  id="fileUpload"
                  onChange={handleImagenChange}
                  style={{
                    color: 'transparent',
                    position: 'absolute',
                    margin: 0,
                    padding: 0,
                    width: '250px',
                    height: '250px',
                    outline: 'none',
                    opacity: 0,
                    cursor: 'pointer'
                  }}
                />
              </>
            )}
          </Box>
        </Box>
      )}

      {previewImageFile && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography color="textSecondary" variant="body2">
            {selectedImage.name}{' '}
          </Typography>
          <img
            src={previewImageFile}
            style={{
              width: '200px',
              height: '200px',
              objectFit: 'contain'
            }}
            alt="Preview"
          />
        </div>
      )}
      {previewImageFile && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <GoalIconButton
            onClick={() => resetImageFile(null)}
            icon={<CancelIcon color="primary" />}
            tolltip={'Cancelar'}
          />
          <GoalButton color="primary" onClick={handleSubmitImage} loading={imageLoading}>
            Subir imagen
          </GoalButton>
        </Box>
      )}
    </Box>
  );
};

CommonImageSelector.propTypes = {
  selectedImage: PropTypes.object,
  uploadApi: PropTypes.func,
  afterUpload: PropTypes.func
};
