import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalMaintenanceInventory {
  async getBrands(options) {
    const { filters, sort, sortBy, page, query, view, reload, searchText, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: '/inventory/master/brands',
      cacheItem: 'brands',
      apiParams: {
        companyId,
        type: 'list-brands',
        view,
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        searchText
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'code' },
        { type: 'string', name: 'name' }
      ],
      ...options
    });
  }

  async getLines(options) {
    const { filters, sort, sortBy, page, query, view, reload, searchText, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: '/inventory/master/lines',
      cacheItem: 'lines',
      apiParams: {
        companyId,
        type: 'list-lines',
        view,
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        searchText,
        limit: -1
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'code' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'status' },
        { type: 'number', name: 'company_id' },
        { type: 'string', name: 'family_code' },
        { type: 'string', name: 'family_name' },
        { type: 'string', name: 'level' }
      ],
      ...options
    });
  }

  async getBrandApiId(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/master/brands/${id}`
    });
    return response;
  }

  async postBrandForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/inventory/master/brands',
      changes: values
    });
    return response;
  }

  // update brand logo
  async updateBrandLogo({ id, companyId, ...others }) {
    const response = await dataLoader.loadGoalPost({
      action: `/inventory/master/brands/${id}/update-logo?companyId=${companyId}`,
      changes: others
    });
    return response;
  }

  async updateBrandForm({ id, changes }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/inventory/master/brands/${id}`,
      changes
    });
    return response;
  }

  async deleteBrand({ id, companyId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/inventory/master/brands/${id}?companyId=${companyId}`
    });
    return response;
  }

  async restoreBrand({ id, companyId }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/inventory/master/brands/${id}/restore`,
      changes: { company_id: companyId }
    });
    return response;
  }

  async getClassifications(options) {
    const { filters, sort, sortBy, page, query, view, reload, companyId, searchText } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: '/inventory/master/product-clasifications',
      cacheItem: 'clasifications',
      apiParams: {
        companyId,
        type: 'list-product-clasifications',
        view,
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        searchText
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'status' },
        { type: 'string', name: 'company_id' },
        { type: 'string', name: 'code' },
        { type: 'string', name: 'level' }
      ],
      ...options
    });
  }

  async getClassificationList({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/master/product-clasifications?companyId=${companyId}`
    });
    return response;
  }

  async getClassificationContainer({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/master/product-clasifications/${id}/product-clasifications?companyId=${companyId}`
    });
    return response;
  }

  async postClassificationForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/inventory/master/product-clasifications',
      changes: values
    });
    return response;
  }

  async updateClassificationForm(id, data) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/inventory/master/product-clasifications/${id}`,
      changes: data
    });
    return response;
  }

  async deleteClassification({ id, companyId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/inventory/master/product-clasifications/${id}?companyId=${companyId}`
    });
    return response;
  }

  async getClassificationConfig({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/master/product-clasifications/config?companyId=${companyId}`
    });
    return response;
  }

  async getCategoriesApi(options) {
    const { filters, sort, sortBy, page, query, view, reload, searchText, id } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/inventory/master/product-categories?companyId=${id}`,
      cacheItem: 'categories',
      apiParams: {
        type: 'diary',
        view,
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        searchText
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'status' },
        { type: 'string', name: 'company_id' },
        { type: 'string', name: 'code' }
      ],
      ...options
    });
  }

  async getCategoriesList({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/master/product-categories?companyId=${companyId}`
    });
    return response;
  }

  async getCategoriesContainer({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/master/product-categories/${id}/product-categories?companyId=${companyId}`
    });
    return response;
  }

  async postCategorieForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/inventory/master/product-categories',
      changes: values
    });
    return response;
  }

  async updateCategorieForm(id, data) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/inventory/master/product-categories/${id}`,
      changes: data
    });
    return response;
  }

  async deleteCategory({ id, companyId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/inventory/master/product-categories/${id}?companyId=${companyId}`
    });
    return response;
  }

  async getCategorieConfig(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/master/product-categories/config?companyId=${companyId}`
    });
    return response;
  }

  async getAttributeGroups(options) {
    const { filters, sort, sortBy, page, query, view, reload, searchText, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/inventory/master/product-attribute-groups`,
      cacheItem: 'attribute',
      apiParams: {
        companyId,
        type: 'list-product-attribute-groups',
        view,
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        searchText
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'status' },
        { type: 'string', name: 'company_id' },
        { type: 'string', name: 'code' }
      ],
      ...options
    });
  }

  async getAttributeGroupsList({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/master/product-attribute-groups?companyId=${companyId}`
    });
    return response;
  }

  async getAttributeGroupsContainer({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/master/product-attribute-groups/${id}/product-attributes?companyId=${companyId}`
    });
    return response;
  }

  async createAttributeGroup({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/inventory/master/product-attribute-groups',
      changes: values
    });
    return response;
  }

  async updateAttributeGroup(id, data) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/inventory/master/product-attribute-groups/${id}`,
      changes: data
    });
    return response;
  }

  async deleteAttributeGroup({ id, companyId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/inventory/master/product-attribute-groups/${id}?companyId=${companyId}`
    });
    return response;
  }

  async getAttributeGroupsConfig({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/master/product-attribute-groups/config?companyId=${companyId}`
    });
    return response;
  }
}

export const goalMaintenanceInventoryApi = new GoalMaintenanceInventory();
