import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { Status } from '../../../components/status';
import { useMounted } from '../../../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../../../icons/arrow-left';
import { useApp } from '../../../hooks/use-app';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { RenderIf } from '../../../components/render-if';
import LoadingContent from '../../../components/common/loading-content';
import { DocumentLog } from '../../../components/purchases/common/document-log';
import { CustomerOrderDetails } from '../../../components/document/orders/customer-order-details';
import { CustomerOrderLines } from '../../../components/document/orders/customer-order-lines';
import GoalRecordLink from '../../../components/goal-record-link';
import { useData } from '../../../hooks/use-data';
import { goalOrdersApi } from '../../../api/goal/presale/goal-orders';
import GoalButton from '../../../components/goal-button';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CustomerOrderMenu } from '../../../components/document/orders/customer-order-menu';

export const CustomerOrder = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title, currentCompany } = useApp();
  const [editMode, setEditmode] = useState(false);

  const handleEdit = () => {
    if (editMode) {
      setEditmode(false);
      return;
    }
    if (!orderInfoState.data) return;
    if (['D'].includes(orderInfoState.data.status)) {
      setEditmode(true);
    }
  };

  const [orderInfoState, refreshOrderInfo] = useData({
    sourceApi: goalOrdersApi.getOrderDetail,
    apiParameter: { id, companyId: currentCompany },
    loadingMessage: 'Cargando documento',
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });

  useEffect(() => {
    refreshOrderInfo({ id, companyId: currentCompany });
  }, [id]);

  const renderContent = () => {
    if (orderInfoState.isLoading || orderInfoState.error || !orderInfoState.data) {
      return <LoadingContent loadingText={orderInfoState.message} error={orderInfoState.error} />;
    }

    return (
      <Card>
        <CardHeader
          avatar={
            <GoalRecordLink
              to={'/sales/orders'}
              // label={'Pedidos'}
              startIcon={<ArrowLeftIcon fontSize="small" color="primary" sx={{ mr: 2 }} />}
            />
          }
          title={`Detalle de pedido del cliente ${orderInfoState.data?.IdPedido}`}
          subheader={<Status color="error.main" label={orderInfoState.data?.statusName} />}
          action={
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <GoalButton startIcon={<RefreshIcon />} onClick={refreshOrderInfo} />
              <CustomerOrderMenu
                orderInfo={orderInfoState.data}
                afterProcessHandler={refreshOrderInfo}
              />
            </Box>
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid
              container
              item
              lg={12}
              spacing={3}
              sx={{
                height: 'fit-content',
                order: {
                  md: 2,
                  xs: 1
                }
              }}
              xs={12}
              xl={12}
            >
              <Grid item xs={12}>
                <CustomerOrderDetails
                  orderInfo={orderInfoState.data}
                  setEditMode={handleEdit}
                  onRefresh={refreshOrderInfo}
                />
              </Grid>
              <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
                <RenderIf condition={['F', 'G', 'D'].includes(orderInfoState.data.EstadoMigrado)}>
                  <Grid item xs={12}>
                    <GoalRecordLink
                      to={`/promo/assigneds/${orderInfoState.data.IdPedido}`}
                      label="Ver detalle de promociones"
                      variant={'button'}
                    />
                  </Grid>
                </RenderIf>
              </AllowedGuard>
              <RenderIf condition={!!orderInfoState.data.lines}>
                <Grid item xs={12}>
                  <CustomerOrderLines orderInfo={orderInfoState.data} />
                </Grid>
              </RenderIf>
              <Grid item xs={12}>
                <DocumentLog document={{ objType: 124, docEntry: orderInfoState.data.IdPedido }} />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Detalle de pedido del cliente')}</title>
      </Helmet>

      {renderContent()}
    </>
  );
};
