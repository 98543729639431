import Proptypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  DialogActions,
  Grid
} from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import GadminActions from '../../../contexts/gadmin-actions';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { AutocompleteField } from '../../autocomplete-field';
import { PropertyList } from '../../property-list';
import { InputField } from '../../input-field';
import { goalCustomeSurveyApi } from '../../../api/goal/survey/goal-customer-survey';
import { useApp } from '../../../hooks/use-app';
import { documentStatusName, documentStatusViews } from '../../../api/app-definitions';
import { CompanyInputDisabled } from '../../common/company-input-disabled';

export const SurveySummaryDetailEdit = ({ initialValues = {}, onClose, onRefresh }) => {
  const { showNotify, showError, currentCompany } = useApp();
  const formik = useFormik({
    initialValues: {
      company_id: initialValues.company_id || '',
      title: initialValues.title || '',
      date: initialValues.date || moment().format('YYYY-MM-DD'),
      sales_team_id: initialValues.sales_team_id || '',
      sales_team_info: initialValues.sales_team_info || '',
      selectQuestions: initialValues.selectQuestions || [],
      point: initialValues.point || 0,
      status: initialValues.status || documentStatusViews.VIEW_DRAFT,
      statusName: initialValues.status_name || documentStatusName.VIEW_DRAFT_NAME
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      console.log(values);
      const response = await goalCustomeSurveyApi.updateSurveySurveyForm(initialValues._id, values);
      if (response.success) {
        showNotify(`${response?.message}. Cambio exitoso `);
        onRefresh();
        onClose(false);
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
    }
  });

  const salesTeamList = [
    { code: 'T1', name: 'Team 1' },
    { code: 'T2', name: 'Team 2' },
    { code: 'T3', name: 'Team 3' }
  ];

  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader title="Editando  encuesta" />
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <PropertyList>
                <CompanyInputDisabled currentCompany={currentCompany} />
                <InputField
                  label="Título"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  sx={{ marginBottom: '1em' }}
                  message="El título es un identificador unico, que describe esta encuesta"
                />
                <InputField
                  name="date"
                  label="Fecha"
                  type="date"
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.date && Boolean(formik.errors.date)}
                  helperText={formik.touched.date && formik.errors.date}
                  inputProps={{
                    min: moment().add(0, 'day').format('YYYY-MM-DD')
                  }}
                  sx={{ marginBottom: '1em', height: '62px' }}
                  message="La fecha nos indica cuando empezara la encuesta"
                />
                <AutocompleteField
                  label="Equipo de ventas"
                  name="sales_team_info"
                  placeholder="Seleccione equipo de ventas"
                  fullWidth
                  options={salesTeamList || [{ name: 'Cargando', code: 'Cargando' }]}
                  getOptionLabel={(option) => option.name || ''}
                  isOptionEqualToValue={(option) => option.name || ''}
                  onChange={(e, value) => {
                    formik.setFieldValue('sales_team_id', value?.code);
                    formik.setFieldValue('sales_team_info', value);
                  }}
                  value={formik.values.sales_team_info}
                  sx={{ marginBottom: '1em' }}
                  error={formik.touched.sales_team_id && Boolean(formik.errors.sales_team_id)}
                  helperText={formik.touched.sales_team_id && formik.errors.sales_team_id}
                />
                <InputField
                  label="Puntos"
                  name="point"
                  value={formik.values.point}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.point && Boolean(formik.errors.point)}
                  helperText={formik.touched.point && formik.errors.point}
                  sx={{ marginBottom: '1em' }}
                  disabled
                  message="Puntos totales que tendra la encuesta"
                />
              </PropertyList>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              marginBottom: 2,
              marginLeft: 1
            }}
          ></Grid>
        </CardContent>
        <CardActions>
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                <Button
                  color="secondary"
                  size="large"
                  startIcon={<TrashIcon />}
                  onClick={() => onClose(false)}
                  sx={{
                    marginLeft: 1
                  }}
                >
                  Cancelar
                </Button>
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
};

SurveySummaryDetailEdit.propTypes = {
  initialValues: Proptypes.object,
  onClose: Proptypes.func,
  onRefresh: Proptypes.func
};
