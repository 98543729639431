import { createContext, useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMounted } from '../../hooks/use-mounted';
import { useData } from '../../hooks/use-data';
import { expenseReportsApi } from '../../api/goal/rindegastos/rindegastos-api';
import { useApp } from '../../hooks/use-app';
import PropTypes from 'prop-types';
import { ExpenseReportViews } from '../../api/goal/rindegastos/rindegastos-definitions';

const ExpenseReportContext = createContext();

const useExpenseReport = () => {
  return useContext(ExpenseReportContext);
};

const ExpenseReportProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { search } = useLocation();
  const view = new URLSearchParams(search).get('view') || ExpenseReportViews.ALL;

  const [editMode, setEditMode] = useState(false);

  const [dataState, handleRefresh] = useData({
    sourceApi: expenseReportsApi.getExpenseReport,
    apiParameter: { companyId: currentCompany, id: id, view: view },
    loadingMessage: 'Cargando informe de gastos...',
    mounted
  });

  const value = {
    view: view,
    loading: dataState.isLoading,
    expenseReport: dataState.data ?? {},
    handleRefresh,
    error: dataState.displayError ? dataState.message : '',
    editMode,
    setEditMode
  };
  return <ExpenseReportContext.Provider value={value}>{children}</ExpenseReportContext.Provider>;
};

ExpenseReportProvider.propTypes = { children: PropTypes.node.isRequired };

export { ExpenseReportProvider, useExpenseReport };
