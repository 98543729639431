import { CircularProgress, ListItemIcon } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useGoalDialog } from '../../hooks/use-goal-dialog';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import GoalMenuItem from '../common/goal-menu-item';
import { useAuth } from '../../hooks/use-auth';
import { useGoalUserMenu } from '../../contexts/menu/goal-menu-context';

export const GoalMenuDialog = (props) => {
  const {
    children,
    Component,
    componentProps,
    onClose,
    startIcon = <HorizontalRuleIcon />,
    permission,
    ...rest
  } = props;
  const { hasPermission } = useAuth();

  const [loading, setLoading] = useState(false);

  const [dialogControlller] = useGoalDialog({
    handleClose: onClose
  });
  const { handleClose } = useGoalUserMenu();

  const handleCloseDialog = () => {
    setLoading(false);
    dialogControlller.closeDialog();
    handleClose();
  };

  const handleClick = () => {
    setLoading(true);
    dialogControlller.openDialog();
  };

  if (permission && !hasPermission(permission)) {
    return null;
  }

  return (
    <>
      {loading ? (
        <GoalMenuItem disabled autoClose={false}>
          <ListItemIcon>
            <CircularProgress />
          </ListItemIcon>
          Cargando...
        </GoalMenuItem>
      ) : (
        <GoalMenuItem onClick={handleClick} startIcon={startIcon} {...rest} autoClose={false}>
          {children}
        </GoalMenuItem>
      )}
      <Component
        {...componentProps}
        // onClose={dialogControlller.closeDialog}
        onClose={handleCloseDialog}
        open={dialogControlller.open}
      />
    </>
  );
};

GoalMenuDialog.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.element,
  onClose: PropTypes.func,
  Component: PropTypes.func.isRequired,
  componentProps: PropTypes.object.isRequired,
  startIcon: PropTypes.node,
  iconSx: PropTypes.object,
  tooltip: PropTypes.string,
  permission: PropTypes.string
};
