import { appDefinitions } from '../../app-definitions';
import { dataLoader } from '../../data-loader';

const endpointLocal = 'rindegastos/expense-reports';

class RindegastosExpenseReportsApi {
  async getExpenseReports({
    companyId,
    view = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    search = '',
    sort = '',
    sortBy = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}`,
      params: {
        companyId: companyId,
        view: view,
        page: page,
        per_page: perPage,
        search: search,
        order_direction: sort,
        order_by: sortBy
      }
    });
    return response;
  }

  async getExpenseReport({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/${id}`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async getExpensesFromExpenseReport({
    companyId,
    id,
    view = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    search = '',
    sort = '',
    sortBy = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/${id}/expenses`,
      params: {
        companyId: companyId,
        view: view,
        page: page,
        per_page: perPage,
        search: search,
        order_direction: sort,
        order_by: sortBy
      }
    });
    return response;
  }
}

export const rindegastosExpenseReportsApi = new RindegastosExpenseReportsApi();
