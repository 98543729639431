import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { vehicleApi } from '../../api/goal/operations/vehicles/vehicle';
import { useData } from '../../hooks/use-data';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { ConstantStatus } from '../../api/application-enums';

const VehicleContext = createContext();

const useVehicle = () => {
  return useContext(VehicleContext);
};

const VehicleProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { search } = useLocation();
  const view = (new URLSearchParams(search).get('view') || ConstantStatus.ALL).toLowerCase();

  const [editMode, setEditMode] = useState(false);
  const [addOdometer, setAddOdometer] = useState(false);

  const [dataState, handleRefresh] = useData({
    sourceApi: vehicleApi.getVehicle,
    apiParameter: { companyId: currentCompany, id: id, view: view },
    loadingMessage: 'Cargando vehículo...',
    mounted
  });

  const value = {
    view: view,
    loading: dataState.isLoading,
    vehicle: dataState.data ?? {},
    handleRefresh,
    error: dataState.displayError ? dataState.message : '',
    editMode,
    setEditMode,
    addOdometer,
    setAddOdometer
  };
  return <VehicleContext.Provider value={value}>{children}</VehicleContext.Provider>;
};

VehicleProvider.propTypes = { children: PropTypes.node.isRequired };

export { VehicleProvider, useVehicle };
