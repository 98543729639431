import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';

import { NumberColor } from '../../number-color';
import { Sumary } from '../../sumary';
import TableHeadEnhanced from '../../common/table-head-enhanced';

const columns = [
  { id: 'line', label: '#' },
  { id: 'Code', label: 'Cuenta' },
  { id: 'AcctName', label: 'Nombre Cuenta' },
  { id: 'Account', label: 'Referencia' },
  { id: 'Debit', label: 'Débito' },
  { id: 'Credit', label: 'Crédito' },
  { id: 'Balance', label: 'Balance' }
];

export const JournalLinesGroupTable = (props) => {
  const { journalInfo } = props;
  return (
    <Card>
      <CardHeader title="Información del asiento agrupado a 2 dígitos" />
      <CardContent>
        <TableContainer sx={{ maxHeight: '60vh' }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            aria-labelledby="Tabla  de asientos agrupados a 2 dígitos"
            sx={{ minWidth: 500 }}
          >
            <TableHeadEnhanced keyPrefix="journal-lines-group-column" headCells={columns} />
            <TableBody>
              {journalInfo?.grouped?.map((item, index) => (
                <TableRow key={item.line}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.Code}</TableCell>
                  <TableCell>{item.AcctName}</TableCell>
                  <TableCell>{item.Account}</TableCell>
                  <TableCell>
                    <NumberColor value={item.Debit} format="0.00" />
                  </TableCell>
                  <TableCell>
                    <NumberColor value={item.Credit} format="0.00" />
                  </TableCell>
                  <TableCell>
                    <NumberColor value={item.Balance} format="0.00" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Sumary values={journalInfo?.grouped?.lenght} column="gTotal" />
        </TableContainer>
      </CardContent>
    </Card>
  );
};

JournalLinesGroupTable.propTypes = {
  journalInfo: PropTypes.object.isRequired
};
