/**
 * Parámetros de los Expenses
 * Status	int	1: Aprobado; 2: Rechazado; 0: En proceso.
 */

const ExpenseStatus = {
  PROCESSING: { value: 0, label: 'En proceso' },
  APPROVED: { value: 1, label: 'Aprobado' },
  REJECT: { value: 2, label: 'Rechazado' }
};

export const getExpenseStatus = (value) => {
  return ExpenseStatus[Object.keys(ExpenseStatus).find((key) => ExpenseStatus[key].value === value)]
    ?.label;
};

// Value views for Expense Report Tabs
export const ExpenseViews = {
  ALL: 'all',
  INTEGRATED: 'integrated',
  NOT_INTEGRATED: 'not_integrated'
};

// Expense Report Tabs Views
export const ExpenseTabsViews = [
  {
    label: 'Todos',
    value: ExpenseViews.ALL
  },
  {
    label: 'Integrados',
    value: ExpenseViews.INTEGRATED
  },
  {
    label: 'Pendientes',
    value: ExpenseViews.NOT_INTEGRATED
  }
];

/**
 * Parámetros de los ExpenseReports
 * Status (integer): Estado en que se encuentran los informes en donde: 0 = Abierto o En proceso; 1 = Cerrado.
 */
const ExpenseReportStatus = {
  OPEN: { value: 0, label: 'Abierto o En proceso' },
  CLOSED: { value: 1, label: 'Cerrado' }
};

export const getExpenseReportStatus = (value) => {
  return ExpenseReportStatus[
    Object.keys(ExpenseReportStatus).find((key) => ExpenseReportStatus[key].value === value)
  ]?.label;
};

// Value views for Expense Report Tabs
export const ExpenseReportViews = {
  ALL: 'all',
  INTEGRATED: 'integrated',
  NOT_INTEGRATED: 'not_integrated'
};

// Expense Report Tabs Views
export const ExpenseReportTabsViews = [
  {
    label: 'Todos',
    value: ExpenseReportViews.ALL
  },
  {
    label: 'Integrados',
    value: ExpenseReportViews.INTEGRATED
  },
  {
    label: 'Pendientes',
    value: ExpenseReportViews.NOT_INTEGRATED
  }
];

export const transformExpenseData = (expense, category) => {
  const extraFields = {};
  if (expense.ExtraFields && expense.ExtraFields.length > 0) {
    expense.ExtraFields.forEach((el) => {
      if (el.Name === 'Centro de Costo') {
        extraFields.costCenter = { value: el.Value, code: el.Code };
      } else if (el.Name === 'Sucursal') {
        extraFields.branch = { value: el.Value, code: el.Code };
      } else if (el.Name === 'Tipo de pago') {
        extraFields.paymentType = { value: el.Value, code: el.Code };
      } else if (el.Name === 'Tipo de Documento') {
        extraFields.documentType = { value: el.Value, code: el.Code };
      } else if (el.Name === 'Número de Documento') {
        extraFields.documentNumber = { value: el.Value, code: el.Code };
      } else if (el.Name === 'Placa') {
        extraFields.plate = { value: el.Value, code: el.Code };
      }
    });
  }

  return {
    id: expense.Id || null,
    docEntry: expense.Id || null,
    whsCode: null,
    docDate: expense.IssueDate || null,
    cardCode: 'IF00000002', //  Must be modified
    licTradNum: expense?.SunatInfo?.ruc || null,
    document: extraFields?.documentNumber?.value || null,
    status: expense.Status === 0 ? 'F' : null,
    docTotal: expense.Total?.toFixed(6) || null,
    sumIgv: expense.Tax?.toFixed(6) || '.000000',
    sumIsc: '.000000',
    discountSum: '.000000',
    sumGravada: '.0000', // Cambiar según tu lógica
    sumInafecta: expense.Net?.toFixed(4) || '.0000',
    sumExonerada: '.0000',
    sumGratuita: '.000000',
    sumBoni: '.000000',
    vatSum: '.000000',
    createDate: null,
    msg: null,
    serieGuia: null,
    numeroGuia: null,
    docDueDate: expense.IssueDate || null,
    groupNum: -1,
    whsName: null,
    statusName: expense.Status === 0 ? 'Facturado' : null,
    cardName: expense.Supplier || null,
    paymentName: extraFields.paymentType?.value || '---',
    currency: expense.Currency || 'PEN',
    currencySymbol: 'S/',
    objName: extraFields.documentType?.value || 'Factura',
    docType: 'S', // Asumido 'S' para este caso
    comments: 'COMISION POR RECAUDACION - CREDIPAGO',
    taxDate: expense.IssueDate || null,
    account: category?.AccountCode || '6391014', // Depende del resultado de la API
    reason: null,
    fatherReceiptType: null,
    fatherSerie: null,
    fatherNumber: null,
    fatherDate: null,
    fatherType: null,
    fatherEntry: null,
    receiptType: extraFields.documentType?.code || '01',
    isNote: 'N',
    objType: 729,
    targetRef: 834203,
    seriesName: 'IC2018',
    transId: 20991840,
    ocrCode: extraFields.costCenter?.code || 'R1011101',
    ocrName: extraFields.costCenter?.value || 'Ica-Centro Servicios Compartidos(R1011101)',
    ownerUser: null,
    seriesConfig: 2,
    cashAccount: '1011010',
    processPayment: 'N',
    acctName: 'COMISION POR RECAUDACION',
    series: 656,
    branch: 'IC',
    branchName: extraFields.branch?.value || 'ICA',
    cpe_status: 1,
    cpe_memo: `Estado: ${expense.SunatInfo?.documentStatus || '1-ACEPTADO'}, EstadoRuc: ${
      expense.SunatInfo?.taxpayerStatus || '00-ACTIVO'
    }, CondicionDomiRuc: ${expense.SunatInfo?.addressCondition || '00-HABIDO'}, ID: ${
      expense.SunatInfo?.ruc || ''
    }-${extraFields.documentType?.code || '01'}-${extraFields.documentNumber?.value || ''}`,
    xmlCode: 33994, // Ajustar según sea necesario
    pdfCode: 33996, // Ajustar según sea necesario
    control_account: '4549910',
    despatchProcess: -1,
    vehicle: extraFields.plate?.value || null,
    lines: [
      {
        lineNum: 1,
        whsCode: '',
        itemCode: '',
        description: 'CONCEPTO DE PAGO:COMISION PAGO EMPRESA',
        itemName: null,
        quantity: '1.000000',
        useBaseUn: 'N',
        unitMsr: 'ZZ',
        numPerMsr: '.000000',
        price: expense.OriginalAmount?.toFixed(6) || '0.000000',
        priceBefDi: expense.OriginalAmount?.toFixed(6) || '0.000000',
        discPrcnt: '.000000',
        discSum: '.000000',
        lineTotal: expense.Total?.toFixed(6) || '0.000000',
        gTotal: expense.Total?.toFixed(6) || '0.000000',
        taxCode: 'IGV_EXE',
        iscprcnt: '.000000',
        iscsum: '.000000',
        vatPrcnt: '.000000',
        vatSum: '.000000',
        priceAfVat: expense.Total?.toFixed(6) || '0.000000',
        des: '',
        objtype: 18,
        trgetentry: -1,
        docentry: expense.Id || null,
        currency: expense.Currency || 'PEN',
        currencySymbol: 'PEN',
        frgItem: '',
        priceTypeCode: extraFields.documentType?.code || '01',
        reasonName: null,
        reasonCode: null,
        id: 79183,
        Dscription: 'CONCEPTO DE PAGO:COMISION PAGO EMPRESA',
        OcrCode: extraFields.costCenter?.code || 'R1011101',
        account: '6391014',
        acctName: 'COMISION POR RECAUDACION',
        ocrName: extraFields.costCenter?.value || 'Ica-Centro Servicios Compartidos(R1011101)'
      }
    ],
    history: expense.Files.map((file, index) => ({
      id: (index + 1).toString(),
      docentry: expense.Id || null,
      message: `Documento ${file.FileName} registrado`,
      createdate: expense.updated_at || null,
      createuser: expense.created_by_user || null,
      createprog: 'ReactApp',
      memo: null
    }))
  };
};
