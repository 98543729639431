import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Download as DownloadIcon } from '../../../icons/download';
import { Upload as UploadIcon } from '../../../icons/upload';

import { Box, Card, Container, Divider, Typography } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useMounted } from '../../../hooks/use-mounted';
import { useSelection } from '../../../hooks/use-selection';
import { Refresh as RefreshIcon } from '../../../icons/refresh';

import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';

import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { CustomerDeliveryPlanningFilter } from '../../../components/delivery/planning/customer-delivery-planning-filter';
import { CustomerDeliveryPlanningTable } from '../../../components/delivery/planning/customer-delivery-planning-table';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { ConfirmationButton } from '../../../components/common/confirmation-button';
import useProcesor from '../../../hooks/use-procesor';
import { goalCustomeDeleveryPlanningApi } from '../../../api/goal/planning/goal-customer-delivery-planning';
import { RenderIf } from '../../../components/render-if';
import { CustomerModal } from '../../../components/delivery/monitoring/customer-modal';
import { DeliveryPlanningForm } from '../../../components/delivery/planning/delivery-planning-form';
import { useData } from '../../../hooks/use-data';
import { appFormats } from '../../../api/app-definitions';
import { formatDate } from '../../../hooks/use-format-value';
import { useParams, useSearchParams } from 'react-router-dom';
import { dateFromString } from '../../../utils/formatTime';
import GoalButton from '../../../components/goal-button';

export const DeliveryPlanning = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { branchsCode } = useParams();

  const queried = {
    orderDate: searchParams.get('date'),
    type: searchParams.get('type'),
    subtype: searchParams.get('subtype')
  };

  const mounted = useMounted();
  const { currentCompany, title } = useApp();
  const [open, onClose] = useState(false);

  const [currentDate, setCurrentDate] = useState(dateFromString(queried.orderDate));

  // const [currentDate, setCurrentDate] = useState(queried.date ? moment(queried.date) : new Date());
  // const [currentDate, setCurrentDate] = useState(() => {
  //   const savedDate = localStorage.getItem('currentDate');
  //   return savedDate ? new Date(savedDate) : queried.date ? new Date(queried.date) : new Date();
  // });
  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  useEffect(() => {
    handleSetController.setMainFilter({
      ...queried,
      orderDate: moment(currentDate).format(appFormats.backendDateFormat)
    });
  }, []);
  const handleChangeDate = (newValue) => {
    setCurrentDate(newValue);
  };

  useEffect(() => {
    const dateString = moment(currentDate).format(appFormats.backendDateFormat);
    if (dateString !== controller.orderDate) {
      handleSetController.setMainFilter({ ...queried, orderDate: dateString });
      setSearchParams({ ...searchParams, date: dateString });
    }
  }, [currentDate]);

  // const handleDateChanged = (newValue) => {
  //   if (!newValue) return;
  //   setCurrentDate(newValue);
  //   const current = moment(newValue).format('YYYY-MM-DD');
  //   // current !== controller.orderDate
  //   if (current) {
  //     handleSetController.setMainFilter({ ...queried, orderDate: current });
  //   }
  // };

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    ,
    ,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalCustomeDeleveryPlanningApi.getCustomeDeliveryPlanning,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: branchsCode || 78483
    }
    // onPostRefresh: handleClearSelected
  });

  // console.log(returnsState);

  const [selectedDocuments, handleSelect, handleSelectAll] = useSelection(returnsState.documents);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const [stagesState] = useData({
    sourceApi: goalCustomeDeleveryPlanningApi.getStages,
    apiParameter: { companyId: currentCompany },
    loadingMessage: 'Cargando informacion de tabs',
    mounted
  });

  const { procesor: handleGenerateRouteSheets, loading: processingGenerateRouteSheets } =
    useProcesor({
      handler: goalCustomeDeleveryPlanningApi.generateRouteSheets
    });
  return (
    <>
      <Helmet>
        <title>{title('Planificación')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Planificación
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Fecha"
                  inputFormat="dd/MM/yyyy"
                  minDate={moment().add(-7, 'days').toDate()}
                  maxDate={moment().add(4, 'days').toDate()}
                  value={currentDate}
                  // onChange={handleChangeDate}
                  onAccept={handleChangeDate}
                  slotProps={{ textField: { variant: 'outlined' } }}
                  // renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Box sx={{ flexGrow: 1 }} />

              <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
                <GoalButton startIcon={<RefreshIcon />} onClick={handleRefresh} label="Refrescar" />
                <GoalButton
                  startIcon={<UploadIcon />}
                  onClick={() => onClose(!open)}
                  label="Cargar pedidos"
                />

                <ConfirmationButton
                  icon={<DownloadIcon />}
                  label="Generar HRs"
                  title="Generar Hojas de reparto"
                  onConfirm={() =>
                    handleGenerateRouteSheets({
                      account: controller.view,
                      date: formatDate(currentDate, appFormats.backendDateFormat)
                    })
                  }
                  message={`Se proce a generar las hojas de reparto para todos los vehiculos en la fecha seleccionada(${formatDate(
                    currentDate,
                    appFormats.date
                  )})`}
                  loading={processingGenerateRouteSheets}
                />
              </AllowedGuard>
            </Box>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <CustomerDeliveryPlanningFilter
              disabled={returnsState.isLoading}
              onQueryChange={handleQueryChange}
              onViewChange={handleViewChange}
              query={controller.query}
              selectedDocuments={selectedDocuments}
              view={Number(controller.view)}
              tabsList={stagesState.isLoading ? [] : stagesState?.data}
              branchsCode={branchsCode}
            />
            <Divider />
            <CustomerDeliveryPlanningTable
              error={returnsState.error}
              documents={returnsState.documents}
              //   documents={[]}
              documentsCount={returnsState.documentsCount}
              isLoading={returnsState.isLoading}
              onPageChange={handlePageChange}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              selectedDocuments={selectedDocuments}
              sort={controller.sort}
              sortBy={controller.sortBy}
            />
            <Divider />
          </Card>
        </Container>
        <RenderIf condition={open}>
          <CustomerModal
            open={open}
            onClose={onClose}
            permission={GadminActions.gCC_TDOY_LIST}
            widhtForm="xs"
          >
            <DeliveryPlanningForm
              title="Planificacón"
              onClose={onClose}
              handleRefresh={handleRefresh}
              view={Number(controller.view)}
            />
          </CustomerModal>
        </RenderIf>
      </Box>
    </>
  );
};
