import PropTypes from 'prop-types';
import { Button, Card, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { PropertyList } from '../property-list';
import { PropertyListItem } from '../property-list-item';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../icons/plus';
import { Status } from '../status';
import { GetCompanyName } from '../common/get-company-name';
import { useApp } from '../../hooks/use-app';
import { goalSalesTeamsApi } from '../../api/goal/sales-teams/goal-sales-teams';
import { useAuth } from '../../hooks/use-auth';
import GoalImageGallery from '../common/goal-image-gallery';

export const SalesTeamsSumaryDetail = ({ item, setEditMode, onRefresh }) => {
  const statusColorDefinitions = {
    D: 'info.main',
    P: 'warning.main',
    A: 'success.main',
    C: 'error.light',
    L: 'error.main',
    R: 'error.dark'
  };

  const { hasPermission } = useAuth();

  const canEdit = hasPermission(GadminActions.gCC_SALE_TEAMS_UP);
  const { companyState, currentCompany } = useApp();

  const StatusColor = (value) => statusColorDefinitions[value] || 'warning.main';

  const afterUpload = async (result) => {
    item.image_code = result.data.image_code;
    onRefresh();
  };
  return (
    <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
      <CardHeader title="Información general del equipo de ventas" />
      <PropertyListItem label="">
        <Status color={StatusColor(item.status)} label={item.status === 'A' ? 'Activo' : '--'} />
      </PropertyListItem>
      <Divider />
      <Grid container>
        <Grid item md={6} xs={12}>
          <PropertyList>
            <PropertyListItem label="Empresa">
              <Typography color="textSecondary" variant="body2">
                {item.company_id} - {GetCompanyName(item.company_id, companyState)}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Nombre" value={item.name} />
            <PropertyListItem label="Prefijo">
              <Typography color="textSecondary" variant="body2">
                {item.prefix}
              </Typography>
            </PropertyListItem>
          </PropertyList>
        </Grid>
        <Grid item md={6} xs={12}>
          <PropertyList>
            <PropertyListItem label="Canal preferido">
              <Typography color="textSecondary" variant="body2">
                ---
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Lider">
              <Typography color="textSecondary" variant="body2">
                ---
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="División">
              <Typography color="textSecondary" variant="body2">
                ---
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Catálogo">
              <Typography color="textSecondary" variant="body2">
                ---
              </Typography>
            </PropertyListItem>
          </PropertyList>
        </Grid>
        <Grid item xs={12} md={6} sx={{ marginTop: 4 }}>
          <PropertyList>
            <GoalImageGallery
              imageCodes={item.image_code}
              canEdit={canEdit}
              // sizeImage="200px"
              afterUpload={afterUpload}
              imageHandlerApi={goalSalesTeamsApi.postSalesTeamImage}
              ownerEntity={{
                id: item.id,
                companyId: currentCompany,
                name: item.name,
                code: item.code
              }}
            />
          </PropertyList>
        </Grid>

        <Grid item xs={12}>
          <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS_UP}>
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={() => {
                setEditMode(true);
              }}
              variant="contained"
              disabled={item.status !== 'A'}
            >
              Editar
            </Button>
          </AllowedGuard>
        </Grid>
      </Grid>
    </Card>
  );
};

SalesTeamsSumaryDetail.propTypes = {
  item: PropTypes.object,
  setEditMode: PropTypes.func,
  onRefresh: PropTypes.func
};
