import { useParams } from 'react-router-dom';
import { WarehouseStockTable } from './warehouse-stock-table';

const WarehouseStockPanel = () => {
  const { id } = useParams();

  return <WarehouseStockTable code={id} />;
};

export { WarehouseStockPanel };
