import { useCallback, useEffect, useState } from 'react';
import { wsPusherApi } from '../ws-api/ws-pusher-api';

export const usePusher = ({ api, callback, parameters: params = {} }) => {
  const [parameters, setParameters] = useState(params); //[connection, setConnection
  const [connection, setConnection] = useState({});
  // useEffect(() => {
  //   const connectionInfo = api(parameters);
  //   wsPusherApi.connect();
  //   // console.log('connectionInfo', connectionInfo);
  //   const _connection = wsPusherApi.listen(connectionInfo.channel, connectionInfo.event, callback);

  //   setConnection({ connection: _connection, ...connectionInfo });
  //   return () => {
  //     wsPusherApi.disconnect();
  //   };
  // }, []);
  const listenner = useCallback(
    (parameters) => {
      const connectionInfo = api(parameters);
      console.log('connectionInfo', connectionInfo);

      const _connection = wsPusherApi.reconnect(
        connectionInfo.channel,
        connectionInfo.event,
        callback
      );

      setConnection({ ...connection, connection: _connection });
    },
    [api, callback, connection]
  );

  useEffect(() => {
    listenner({ ...parameters });
    return () => {
      wsPusherApi.disconnect();
    };
  }, [parameters, listenner]);

  return [connection, setParameters];
};
