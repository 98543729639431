import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useApp } from '../../../hooks/use-app';
import { DeliveryVehicleDetail } from '../../../components/delivery/vehicle/delivery-vehicle-detail';
import { useData } from '../../../hooks/use-data';
import { vehicleApi } from '../../../api/goal/operations/vehicles/vehicle';
import { useMounted } from '../../../hooks/use-mounted';

export const VehicleDetailContainer = () => {
  const { id } = useParams();
  const { title, currentCompany } = useApp();
  const mounted = useMounted();

  const [vehicleState, handleRefresh] = useData({
    sourceApi: vehicleApi.vehicleDetail,

    apiParameter: {
      id,
      companyId: currentCompany
    },
    mounted
  });

  return (
    <>
      <Helmet>
        <title>
          {title(`${vehicleState.data?.code || ''} ${vehicleState.data?.brand || ''}`)}{' '}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <DeliveryVehicleDetail dataVehicle={vehicleState.data} onRefresh={handleRefresh} />
      </Box>
    </>
  );
};
