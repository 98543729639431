import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import moment from 'moment';
// import { format } from 'date-fns';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as PlusIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';

import { LinkIf } from '../../link-if';
import { NumberColor } from '../../number-color';
import GoalRecordLink from '../../goal-record-link';
import GoalButton from '../../goal-button';

export const GREPendingsFaceleTable = ({
  data: dataProp,
  onRefresh,
  label = 'Documentos pendientes',
  linkTo
}) => {
  const [documentState, setDocumentState] = useState({ isLoading: true, reload: true });

  const handleRefresh = () => {
    onRefresh?.();
  };

  useEffect(() => {
    setDocumentState(dataProp);
  }, [dataProp]);

  if (!documentState.data) {
    return <LoadingContent loadingText="No  guías de remisión pendientes a FACELE..." />;
  }
  return (
    <Card variant="outlined">
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title={label} />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
              <Box sx={{ mb: 2 }}>
                <GoalButton color="primary" startIcon={<PlusIcon />} onClick={handleRefresh} />
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Serie</TableCell>
              <TableCell>Inicial</TableCell>
              <TableCell>Final</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Pendientes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentState.data?.map((item) => {
              const baseUrl = `${linkTo}/gre-pendings-facele?date=${moment(item.TaxDate).format(
                'YYYY-MM-DD'
              )}`;
              const baseAllUrl = `${linkTo}/?date=${moment(item.TaxDate).format('YYYY-MM-DD')}`;
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>
                    <GoalRecordLink
                      to={`${baseUrl}`}
                      label={moment(item.TaxDate).format('DD/MM/YYYY')}
                      tooltip={'Ver guías de remisión de la fecha'}
                    />
                  </TableCell>
                  <TableCell>{item.Serie}</TableCell>
                  <TableCell>{item.StartNumber}</TableCell>
                  <TableCell>{item.LastNumber}</TableCell>

                  <TableCell>
                    <LinkIf
                      condition={item.Count != 0}
                      to={`${baseAllUrl}&type=${item.ReceiptType}&serie=${item.Serie}`}
                    >
                      <NumberColor value={item.Count} format="0" upper />
                    </LinkIf>
                  </TableCell>
                  <TableCell>
                    <LinkIf
                      condition={item.Pendings != 0}
                      to={`${baseUrl}&type=${item.ReceiptType}&serie=${item.Serie}`}
                    >
                      <NumberColor value={item.Pendings} format="0" upper />
                    </LinkIf>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};

GREPendingsFaceleTable.propTypes = {
  data: Proptypes.object,
  onRefresh: Proptypes.func,
  label: Proptypes.string,
  linkTo: Proptypes.string
};
