import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator
} from '../../../utils/filter-operators';
import { Box, Button, Divider, Tab, Tabs } from '@mui/material';
import { Query } from '../../query';
import { Adjustments as AdjustmentsIcon } from '../../../icons/adjustments';
import { FilterDialog } from '../../filter-dialog';
import { QueuesTabsViews, QueuesViews } from '../../../api/managment/queue-definition';
import { useNavigate } from 'react-router-dom';

const filterProperties = [
  {
    name: '_id',
    label: '_ID',
    type: 'string'
  },
  {
    name: 'success',
    label: 'Estado',
    type: 'number'
  },
  {
    name: 'ObjectName',
    label: 'Nombre del objeto',
    type: 'string'
  },
  {
    name: 'ObjectCode',
    label: 'Código de objeto',
    type: 'string'
  },
  {
    name: 'processed',
    label: 'Procesada',
    type: 'number'
  },
  {
    name: 'discarded',
    label: 'Descartada',
    type: 'number'
  },
  {
    name: 'created_at',
    label: 'Creado en',
    type: 'string'
  },
  {
    name: 'updated_at',
    label: 'Actualizado en',
    type: 'string'
  }
];

const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator
];

const QueuesFilter = (props) => {
  const {
    disabled,
    filters = [],
    onFiltersApply,
    onFiltersClear,
    onQueryChange,
    onViewChange,
    query,
    selectedQueues = [],
    view = QueuesViews.ALL
  } = props;

  const navigate = useNavigate();
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  const handleViewChange = (value) => {
    onViewChange(value);
    navigate(`?view=${value}`);
  };

  return (
    <Box>
      <Box sx={{ px: { sm: 3 } }}>
        <Tabs
          onChange={(event, value) => handleViewChange?.(value)}
          allowScrollButtonsMobile
          value={view.toLowerCase()}
          variant="scrollable"
        >
          {QueuesTabsViews.map((option) => (
            <Tab disabled={disabled} key={option.label} label={option.label} value={option.value} />
          ))}
        </Tabs>
      </Box>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: {
            sm: selectedQueues.length > 0 ? 'auto 1fr auto' : '1fr auto',
            xs: 'auto'
          },
          justifyItems: 'flex-start',
          p: 3
        }}
      >
        <Query
          disabled={disabled}
          onChange={onQueryChange}
          sx={{
            order: {
              sm: 2,
              xs: 1
            }
          }}
          value={query}
        />
        <Button
          color="primary"
          disabled={disabled}
          onClick={() => setOpenFilterDialog(true)}
          startIcon={<AdjustmentsIcon />}
          size="large"
          sx={{ order: 3 }}
          variant={filters.length ? 'contained' : 'text'}
        >
          Filtrar
        </Button>
      </Box>
      <FilterDialog
        onApply={onFiltersApply}
        onClear={onFiltersClear}
        onClose={() => setOpenFilterDialog(false)}
        open={openFilterDialog}
        operators={filterOperators}
        properties={filterProperties}
      />
    </Box>
  );
};

QueuesFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  onFiltersApply: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedQueues: PropTypes.array,
  view: PropTypes.string
};

export default QueuesFilter;
