import { Avatar } from '@mui/material';
import { useApp } from '../../hooks/use-app';
import { capitalizeParagraph } from '../../hooks/use-split';
import { GoalMenu } from '../../components/common/goal-menu';
import GoalMenuItem from '../../components/common/goal-menu-item';

export default function CompanyPopover() {
  const { currentCompanyInfo, userCompanies, getImageUrl } = useApp();
  const handleChangeCompany = (companyId) => {
    window.open(`/app/${companyId}/dashboard`, '_blank');
  };

  return (
    <GoalMenu
      startIcon={
        <Avatar
          alt={currentCompanyInfo?.name}
          src={getImageUrl({ imageCode: currentCompanyInfo?.image_code, thumbMail: true })}
        />
      }
      iconOnly={true}
    >
      {userCompanies.map((option) => {
        const selected = option.id === currentCompanyInfo?.id;
        return (
          <GoalMenuItem
            key={option.id}
            label={capitalizeParagraph(option.short_name)}
            onClick={() => handleChangeCompany(option.id)}
            startIcon={
              <Avatar
                alt={option.name}
                src={getImageUrl({ imageCode: option.image_code, thumbMail: true })}
              />
            }
            checked={selected}
          />
        );
      })}
    </GoalMenu>
  );
}
