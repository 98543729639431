import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DocumetLogDialog } from '../../common/document-log-dialog';
import { PDFViewerdDialog } from '../../common/pdf-viewer-dialog';
import { XMLViewerdDialog } from '../../common/xml-viewer-dialog';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';

import { documentViews, mediaType } from '../../../api/purchase-definitions';
import { useApp } from '../../../hooks/use-app';
import GadminActions from '../../../contexts/gadmin-actions';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { JournalPrintingDialog } from '../printing/journal-printing-dialog';
import { GoalMenuDialog } from '../../menu/goal-menu-dialog';
import { AppRegistration, Check, Code, PictureAsPdf, RemoveRedEye } from '@mui/icons-material';
import { GoalMenu } from '../../common/goal-menu';
import GoalMenuItem from '../../common/goal-menu-item';

export const InvoiceMenu = (props) => {
  const { invoice, detailPath, apiHandler, afterProcessHandler, containerView } = props;
  const navigate = useNavigate();
  const { showNotify, showError } = useApp();
  const [menuActions, setMenuActions] = useState([]);

  const detailPathWithId = `${detailPath}/${invoice?.id}`;
  const currentPath = useLocation().pathname;
  const isCurrentPath = currentPath === detailPathWithId;

  const handleAfterProcess = () => {
    afterProcessHandler?.();
  };

  const handleEdit = () => {
    navigate(detailPathWithId);
  };

  const handleCheckCPE = async () => {
    const response = await apiHandler.checkCPE({ docEntry: invoice.docEntry || 0 });
    if (response.success) {
      showNotify(response.message);
      handleAfterProcess();
    } else {
      showError(response.message);
    }
  };

  const onConfirmConfirmationDialog = async ({ action }) => {
    try {
      const response = await apiHandler.processInvoice({
        type: action,
        payload: { id: invoice.id, view: containerView }
      });
      if (response.success) {
        showNotify(response.message);
        handleAfterProcess();
      } else {
        showError(`${response?.message}. Algunos registros no se procesaron `);
      }
    } catch (error) {
      showNotify(`Error al procesar  ${error}`, 'error');
      console.error(error);
    }
  };

  useEffect(() => {
    let actions = [];

    if (
      [
        documentViews.VIEW_DRAFT,
        documentViews.VIEW_VALIDATED,
        documentViews.VIEW_PROCESING
      ].includes(invoice.status)
    ) {
      actions.push({
        action: GadminActions.gCC_PURCHASE_FFEE_DISCARD,
        label: 'Descartar',
        prompt: '¿Está seguro que desea descartar los documentos seleccionados?'
      });
    }

    if ([documentViews.VIEW_DRAFT].includes(invoice.status)) {
      actions.push({
        action: GadminActions.gCC_PURCHASE_FFEE_CAB_VAL,
        label: 'Validar',
        prompt: '¿Está seguro que desea validar los documentos seleccionados?'
      });
    }
    if ([documentViews.VIEW_CANCELED].includes(invoice.status)) {
      actions.push({
        action: GadminActions.G2_PURCHASE_REACTIVE,
        label: 'Reactivar',
        prompt: '¿Está seguro que desea reactivar los documentos seleccionados?'
      });
    }
    if ([documentViews.VIEW_VALIDATED].includes(invoice.status)) {
      actions = [
        ...actions,
        {
          action: GadminActions.gCC_PURCHASE_FFEE_CONFIRM,
          label: 'Confirmar',
          prompt: '¿Está seguro que desea confirmar los documentos seleccionados?'
        },
        {
          action: GadminActions.gCC_PURCHASE_FFEE_NOCONFIRM,
          label: 'NO Confirmar',
          prompt: '¿Está seguro que desea NO confirmar los documentos seleccionados?'
        }
      ];
    }
    if ([documentViews.VIEW_PROCESING].includes(invoice.status)) {
      actions = [
        ...actions,
        {
          action: GadminActions.gCC_GPROC_QUEUE_UP,
          label: 'Encolar',
          prompt: '¿Está seguro que desea re-encolar los documentos seleccionados?'
        }
      ];
    }

    setMenuActions(actions);
  }, [invoice.status]);

  return (
    <GoalMenu>
      {!isCurrentPath && (
        <GoalMenuItem MenuComponent={GoalMenuItem} onClick={handleEdit} icon={<RemoveRedEye />}>
          Detalle
        </GoalMenuItem>
      )}
      <GoalMenuDialog
        // MenuComponent={GoalMenuDialog}
        startIcon={<DensityMediumIcon />}
        Component={DocumetLogDialog}
        componentProps={{
          objType: invoice.objType || 0,
          docEntry: invoice.docEntry || 0
        }}
        tooltip={'Ver sucesos'}
      >
        Sucesos
      </GoalMenuDialog>
      <GoalMenuDialog
        // MenuComponent={GoalMenuDialog}
        startIcon={<PictureAsPdf />}
        Component={PDFViewerdDialog}
        componentProps={{
          apiHandle: apiHandler.getInvoiceMedia,
          apiParams: { objType: invoice.objType, docEntry: invoice.docEntry, type: mediaType.PDF }
        }}
        tooltip={'Ver PDF'}
      >
        PDF
      </GoalMenuDialog>
      <GoalMenuDialog
        // MenuComponent={GoalMenuDialog}
        startIcon={<Code />}
        permission={GadminActions.gCC_PURCHASE_FFEE_DOWNLOAD_XML}
        Component={XMLViewerdDialog}
        componentProps={{
          apiHandle: apiHandler.getInvoiceMedia,
          apiParams: { objType: invoice.objType, docEntry: invoice.docEntry, type: mediaType.XML }
        }}
        tooltip={'Ver XML'}
      >
        XML
      </GoalMenuDialog>
      {(invoice?.transId || -1) !== -1 && (
        <GoalMenuDialog
          // MenuComponent={GoalMenuDialog}
          startIcon={<AppRegistration />}
          Component={JournalPrintingDialog}
          componentProps={{
            apiHandle: apiHandler.getInvoiceMedia,
            entity: {
              objType: invoice?.objType,
              docEntry: invoice?.docEntry,
              transId: invoice?.transId
            }
          }}
          tooltip={'Ver Asiento'}
        >
          Asiento
        </GoalMenuDialog>
      )}

      <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_SERVICE_UP}>
        <GoalMenuItem MenuComponent={GoalMenuItem} onClick={handleCheckCPE} iconName={<Check />}>
          Validar en SUNAT
        </GoalMenuItem>
      </AllowedGuard>
      {menuActions.map((item, index) => (
        <AllowedGuard permission={item.permission || item.action} key={index}>
          <GoalMenuItem
            MenuComponent={GoalMenuItem}
            key={index}
            needConfirmation={true}
            confirmation={{
              message: item.prompt,
              title: item.label
            }}
            onClick={() => {
              onConfirmConfirmationDialog(item);
            }}
          >{`${item.label}`}</GoalMenuItem>
        </AllowedGuard>
      ))}
    </GoalMenu>
  );
};

InvoiceMenu.propTypes = {
  invoice: PropTypes.object,
  detailPath: PropTypes.string.isRequired,
  apiHandler: PropTypes.object.isRequired,
  afterProcessHandler: PropTypes.func,
  containerView: PropTypes.string.isRequired
};
