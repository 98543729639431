import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';
import { usePopover } from '../../hooks/use-popover';

const GoalUserMenuContext = createContext();

const useGoalUserMenu = () => {
  return useContext(GoalUserMenuContext);
};

const GoalUserMenuProvider = ({ children }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  return (
    <GoalUserMenuContext.Provider value={{ anchorRef, open, handleOpen, handleClose }}>
      {children}
    </GoalUserMenuContext.Provider>
  );
};

GoalUserMenuProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { GoalUserMenuProvider, useGoalUserMenu };
