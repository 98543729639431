import { appDefinitions } from '../../app-definitions';
import { dataLoader } from '../../data-loader';

const endpointLocal = 'rindegastos/expenses';

class RindegastosExpensesApi {
  async getExpenses({
    companyId,
    view = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    search = '',
    sort = '',
    sortBy = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}`,
      params: {
        companyId: companyId,
        view: view,
        page: page,
        per_page: perPage,
        search: search,
        order_direction: sort,
        order_by: sortBy
      }
    });
    return response;
  }

  async getExpense({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/${id}`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }
}

export const rindegastosExpensesApi = new RindegastosExpensesApi();
