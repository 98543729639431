import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
import { receiptFeeApi as invoiceApi } from '../../api/receipt-fee';
import { useMounted } from '../../hooks/use-mounted';
import { useSelection } from '../../hooks/use-selection';
import { Plus as PlusIcon } from '../../icons/plus';
import gtm from '../../lib/gtm';
import { useApp } from '../../hooks/use-app';
import { useDialog } from '../../hooks/use-dialog';

import { DocumentAppendDialog } from '../../components/purchases/common/document-add';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { ReceiptsFeesFilter } from '../../components/purchases/receipt-fees/receipts-fees-filter';
import { ReceiptsFeesTable } from '../../components/purchases/receipt-fees/receipts-fees-table';
import { ReceiptsFeesStats } from '../../components/purchases/receipt-fees/receipts-fees-stats';
import { documnetStatus } from '../../api/purchase-definitions';
import { useDataBrowser } from '../../hooks/use-data-browser';

export const ReceiptsFees = () => {
  const mounted = useMounted();
  const app = useApp();

  const [
    invoicesState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller
  ] = useDataBrowser({
    sourceApi: invoiceApi.getInvoices,
    loadingMessage: 'Cargando documentos',
    mounted,
    defaults: {
      view: documnetStatus.Borrador
    }
  });

  const [selectedInvoices, handleSelect, handleSelectAll] = useSelection(invoicesState.documents);

  const [appendDialogOpen, handleOpenAppendDialog, handleCloseAppendDialog] = useDialog();
  const handleExitedDialog = () => {
    // if (selectedVariant) {
    //   setSelectedVariant(null);
    // }
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>{app.title('Recibos x honorarios')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                R x Honorarios
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.G2_PURCHASE_RRHH_UP}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  onClick={handleOpenAppendDialog}
                  variant="contained"
                >
                  Agregar
                </Button>
              </AllowedGuard>
              <Button
                color="primary"
                size="large"
                startIcon={<PlusIcon />}
                onClick={handleRefresh}
                variant="contained"
              >
                Refresh
              </Button>
            </Box>
          </Box>
          <ReceiptsFeesStats />
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <ReceiptsFeesFilter
              disabled={invoicesState.isLoading}
              filters={controller.filters}
              onFiltersApply={handleFiltersApply}
              onFiltersClear={handleFiltersClear}
              onQueryChange={handleQueryChange}
              onViewChange={handleViewChange}
              query={controller.query}
              selectedInvoices={selectedInvoices}
              view={controller.view}
              onRefresh={handleRefresh}
            />
            <Divider />
            <ReceiptsFeesTable
              error={invoicesState.error}
              invoices={invoicesState.documents}
              invoicesCount={invoicesState.documentsCount}
              isLoading={invoicesState.isLoading}
              onPageChange={handlePageChange}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              selectedInvoices={selectedInvoices}
              sort={controller.sort}
              sortBy={controller.sortBy}
            />
          </Card>
          <DocumentAppendDialog
            onClose={handleCloseAppendDialog}
            onExited={handleExitedDialog}
            afterSubmit={handleRefresh}
            uploaderApi={invoiceApi.uploadInvoice}
            // onVariantsChange={handleVariantsChange}
            open={appendDialogOpen}
            // variant={selectedVariant}
          />
        </Container>
      </Box>
    </>
  );
};
