// class vehicle-work-orders-api

import { appDefinitions } from '../../app-definitions';
import { dataLoader } from '../../data-loader';

const endPointLocal = 'fleet/fleet-vehicle-work-orders';

class VehicleWorkOrdersApi {
  async getWorkOrders({
    companyId,
    view = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    search = '',
    sort = '',
    sortBy = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `${endPointLocal}`,
      params: {
        companyId: companyId,
        view: view,
        page: page,
        per_page: perPage,
        search: search,
        order_direction: sort,
        order_by: sortBy
      }
    });
    return response;
  }

  async getWorkOrder({ number, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/${endPointLocal}/${number}?companyId=${companyId}`
    });
    return response;
  }

  async sendEmail({ workOrderNumber, values }) {
    const response = await dataLoader.loadGoalPost({
      action: `/${endPointLocal}/${workOrderNumber}/send-email`,
      changes: values
    });
    return response;
  }

  async getWorkOrderAttachments({ companyId, workOrderId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `${endPointLocal}/${workOrderId}/attachments?companyId=${companyId}`
    });
    return response;
  }

  async getWorkOrderServiceEntries({ number, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/${endPointLocal}/${number}/service-entries?companyId=${companyId}`
    });
    return response;
  }

  async sendWorkOrderToApproval({ workOrderNumber, values }) {
    const response = await dataLoader.goalPut({
      path: `/${endPointLocal}/${workOrderNumber}/send-to-approval`,
      changes: values
    });
    return response;
  }

  async approveWorkOrder({ workOrderNumber, values }) {
    const response = await dataLoader.goalPut({
      path: `/${endPointLocal}/${workOrderNumber}/approve`,
      changes: values
    });
    return response;
  }

  async activateWorkOrder({ workOrderNumber, values }) {
    const response = await dataLoader.goalPut({
      path: `/${endPointLocal}/${workOrderNumber}/activate`,
      changes: values
    });
    return response;
  }

  async completeWorkOrder({ workOrderNumber, values }) {
    const response = await dataLoader.goalPut({
      path: `/${endPointLocal}/${workOrderNumber}/complete`,
      changes: values
    });
    return response;
  }

  async getUsersEnabledToApprove({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `${endPointLocal}/users-enabled-to-approve?companyId=${companyId}`
    });
    return response;
  }
}

export const vehicleWorkOrdersApi = new VehicleWorkOrdersApi();
