import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { deliveryApi } from '../../../api/delivery';
import { useMounted } from '../../../hooks/use-mounted';
import { CommonChart } from '../../common/common-chart';
import { useData } from '../../../hooks/use-data';
import { useEffect } from 'react';

export const HRInvoicingStats = () => {
  const mounted = useMounted();

  const [invoiceState, handleRefresh] = useData({
    sourceApi: deliveryApi.getPedidosHrPendStats,
    loadingMessage: 'Cargando estadísticas de hojas de reparto',
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });

  useEffect(() => {
    handleRefresh();
  }, []);

  const { stats } = invoiceState.data || { stats: {} };
  return (
    <Card
      elevation={0}
      sx={{
        mb: 4
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Card>
              <Typography color="textSecondary" variant="overline">
                Hojas de reparto desde
              </Typography>
              <Typography color="textPrimary" sx={{ mb: 3 }} variant="h4">
                {invoiceState.data?.minDate}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Todos los comprobantes procesados <strong>.</strong>
              </Typography>
              <Button
                color="primary"
                size="medium"
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
                variant="contained"
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <CommonChart
              data={stats.documents}
              isLoading={invoiceState.isLoading}
              error={invoiceState.error}
              message={invoiceState.message}
              title="Doc todos"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <CommonChart
              data={stats.invoices}
              isLoading={invoiceState.isLoading}
              error={invoiceState.error}
              message={invoiceState.message}
              title="Doc pendientes"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <CommonChart
              data={stats.processed}
              isLoading={invoiceState.isLoading}
              error={invoiceState.error}
              message={invoiceState.message}
              title="Procesados"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <CommonChart
              data={stats.hrs}
              isLoading={invoiceState.isLoading}
              message={invoiceState.message}
              error={invoiceState.error}
              title="Hojas de reprto"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
