const ImportTypes = {
  JOURNAL: 'journal'
};

const dataTypeEnum = {
  DATE: 'DATE',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  BOLEANO: 'BOLEANO',
  ARCHIVE: 'ARCHIVE'
};
const dataTypeData = [
  {
    name: 'Fecha',
    code: 'DATE'
  },
  {
    name: 'Texto',
    code: 'TEXT'
  },
  {
    name: 'Número',
    code: 'NUMBER'
  },
  {
    name: 'Boleano',
    code: 'BOLEANO'
  },
  {
    name: 'Archivo',
    code: 'ARCHIVE'
  }
];

const mimeTypes = [
  {
    code: 'application/pdf',
    name: 'PDF'
  },
  {
    code: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    name: 'DOCX'
  },
  {
    code: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    name: 'XLSX'
  },
  {
    code: 'application/wps-office.xlsx',
    name: 'XLSX'
  },
  {
    code: 'application/vnd.ms-excel',
    name: 'XLS'
  },
  {
    code: 'application/xls',
    name: 'XLS'
  },
  {
    code: 'application/msword',
    name: 'DOC'
  },
  {
    code: 'application/zip',
    name: 'ZIP'
  },
  {
    code: 'application/vnd.rar',
    name: 'RAR'
  },
  {
    code: 'application/x-7z-compressed',
    name: '7Z'
  },
  {
    code: 'application/vnd.oasis.opendocument.text',
    name: 'ODT'
  },
  {
    code: 'application/vnd.oasis.opendocument.spreadsheet',
    name: 'ODS'
  },
  {
    code: 'application/vnd.oasis.opendocument.presentation',
    name: 'ODP'
  },
  {
    code: 'application/vnd.oasis.opendocument.graphics',
    name: 'ODG'
  },
  {
    code: 'application/vnd.oasis.opendocument.formula',
    name: 'ODF'
  },
  {
    code: 'application/vnd.oasis.opendocument.chart',
    name: 'ODC'
  },
  {
    code: 'application/vnd.oasis.opendocument.image',
    name: 'ODI'
  },
  {
    code: 'application/vnd.oasis.opendocument.text-master',
    name: 'OTM'
  },
  {
    code: 'application/vnd.oasis.opendocument.text-web',
    name: 'OTT'
  },
  {
    code: 'application/vnd.oasis.opendocument.text-template',
    name: 'OTT'
  },
  {
    code: 'application/vnd.oasis.opendocument.spreadsheet-template',
    name: 'OTS'
  },
  {
    code: 'application/vnd.oasis.opendocument.presentation-template',
    name: 'OTP'
  },
  {
    code: 'application/vnd.oasis.opendocument.graphics-template',
    name: 'OTG'
  },
  {
    code: 'application/vnd.oasis.opendocument.formula-template',
    name: 'OTF'
  },
  {
    code: 'application/vnd.oasis.opendocument.chart-template',
    name: 'OTC'
  },
  // txt
  {
    code: 'text/plain',
    name: 'TXT'
  },
  {
    code: 'text/csv',
    name: 'CSV'
  },
  {
    code: 'text/html',
    name: 'HTML'
  },
  {
    code: 'text/css',
    name: 'CSS'
  },
  {
    code: 'text/javascript',
    name: 'JS'
  },
  {
    code: 'text/xml',
    name: 'XML'
  },
  {
    code: 'text/x-python',
    name: 'PY'
  },
  {
    code: 'text/x-java-source',
    name: 'JAVA'
  },
  {
    code: 'text/x-csrc',
    name: 'C'
  },
  {
    code: 'text/x-c++src',
    name: 'C++'
  },
  {
    code: 'text/x-csharp',
    name: 'C#'
  },
  {
    code: 'text/x-php',
    name: 'PHP'
  },
  {
    code: 'text/x-ruby',
    name: 'RUBY'
  },
  {
    code: 'text/x-perl',
    name: 'PERL'
  },
  {
    code: 'text/x-shellscript',
    name: 'SHELL'
  },
  {
    code: 'text/x-sql',
    name: 'SQL'
  },
  {
    code: 'text/x-yaml',
    name: 'YAML'
  },
  {
    code: 'text/x-markdown',
    name: 'MARKDOWN'
  },
  {
    code: 'text/x-asm',
    name: 'ASM'
  },
  {
    code: 'text/x-haskell',
    name: 'HASKELL'
  },
  {
    code: 'text/x-lua',
    name: 'LUA'
  },
  {
    code: 'text/x-scala',
    name: 'SCALA'
  },
  {
    code: 'text/x-clojure',
    name: 'CLOJURE'
  },
  {
    code: 'text/x-go',
    name: 'GO'
  },
  {
    code: 'text/x-coffeescript',
    name: 'COFFEESCRIPT'
  },
  {
    code: 'text/x-swift',
    name: 'SWIFT'
  },
  {
    code: 'image/*',
    name: 'Imagen'
  },
  // image/jpg
  {
    code: 'image/jpg',
    name: 'JPEG'
  },
  {
    code: 'image/jpeg',
    name: 'JPG'
  },
  {
    code: 'image/png',
    name: 'PNG'
  },
  {
    code: 'image/gif',
    name: 'GIF'
  },
  {
    code: 'image/bmp',
    name: 'BMP'
  },
  {
    code: 'image/webp',
    name: 'WEBP'
  },
  {
    code: 'image/svg+xml',
    name: 'SVG'
  },
  {
    code: 'image/vnd.adobe.photoshop',
    name: 'PSD'
  },
  {
    code: 'image/x-icon',
    name: 'ICO'
  }
];

const mimeName = (code) => {
  const mimeType = mimeTypes.find((mimeType) => mimeType.code === code);
  return mimeType ? mimeType.name : `No definido ${code}`;
};

const defaultDataFileFormats = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/wps-office.xlsx',
  'application/xls',
  'text/xml',
  'text/csv',
  'text/plain'
];

export { ImportTypes, dataTypeEnum, dataTypeData, defaultDataFileFormats, mimeTypes, mimeName };
