import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import numeral from 'numeral';
import { Check as CheckIcon } from '../../../icons/check';
import { X as XIcon } from '../../../icons/x';
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import { Trash as TrashIcon } from '../../../icons/trash';
import { ImageDropzone } from '../../common/image-dropzone';
import { useApp } from '../../../hooks/use-app';
import GoalButton from '../../goal-button';
import { mimeName } from '../../../api/goal/importer/importer-definition';

export const DocumentAppendDialog = (props) => {
  const {
    open = false,
    onClose,
    onExited,
    onVariantsChange,
    variant,
    afterSubmit,
    uploaderApi,
    typeDocument = ['text/xml', 'application/pdf'],
    titleDocument = 'Agregar comprobantes',
    hoverDocument = 'Seleccione hasta 20 archivos',
    maxFileOne = false,
    handleDocuments,
    nameDocument = '',
    documentApi = false,
    maxFiles = 20,
    ...other
  } = props;
  const mode = 'add';
  const { showNotify, showError, showWaring } = useApp();
  const [saving, setSaving] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: variant?.image || '',
      files: [],
      submit: null
    },
    validationSchema: Yup.object().shape({
      image: Yup.string(),
      files: Yup.array().min(1, 'Seleccione almenos un archivo')
    }),
    onSubmit: async (values, helpers) => {
      try {
        setSaving(true);
        toast.success(mode === 'update' ? 'Variant updated' : 'Variant saved');
        onVariantsChange?.({ ...variant, ...values }, mode);
        const response = await uploaderApi(values);

        if (response.success) {
          if (documentApi) {
            await handleDocuments(formik.values, {
              name: nameDocument,
              code: response.data.code,
              path: response.data.path,
              fileName: response.data.file_name
            });
          } else {
            const count = response?.data?.count;
            if (count) {
              showNotify(`Procesados ${count}`);
            } else {
              showWaring(response.message);
            }
          }

          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          afterSubmit(response);
          onClose?.();
        } else {
          showError(response.message);
        }
      } catch (err) {
        showNotify(`Error al importar  ${err}`, 'error');
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
      setSaving(false);
    }
  });
  const addFiles = (files) => {
    const filteredFiles = files.filter((file) => {
      const allImages = typeDocument.find((type) => type === 'image/*');
      if (allImages) {
        return file.type.includes('image');
      }
      return typeDocument.includes(file.type);
    });

    formik.setFieldValue('files', [
      ...formik.values.files.filter(
        (item) => !filteredFiles.find((file) => file.name === item.name)
      ),
      ...filteredFiles
    ]);
  };

  const removeFiles = (fileValue) => {
    const remove = formik.values.files.filter((file) => file !== fileValue);
    formik.setFieldValue('files', remove);
  };

  // remove all files
  const removeAllFiles = () => {
    formik.setFieldValue('files', []);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
          formik.resetForm();
        }
      }}
      {...other}
    >
      <DialogTitle>{mode === 'update' ? 'Update Variant' : titleDocument}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {maxFiles > 1 && !maxFileOne ? (
              <Typography color="textPrimary" sx={{ mb: 1.25 }} variant="body2">
                Puede subir hasta {maxFiles} archivos
              </Typography>
            ) : (
              <Typography color="textSecondary" sx={{ mb: 1.25 }} variant="body2">
                Solo un archivo
              </Typography>
            )}
            <Typography color="textPrimary" variant="body2">
              Tipos de archivos permitidos
            </Typography>
            {typeDocument?.map((type) => (
              <Chip key={type} label={mimeName(type)} />
            ))}

            {formik.values.files ? (
              <>
                <List disablePadding>
                  {formik.values.files.map((item) => (
                    <ListItem
                      key={item.name}
                      disableGutters
                      divider
                      sx={{
                        px: 3,
                        py: 1.5
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography color="textPrimary" variant="subtitle2">
                            {item.name}
                          </Typography>
                        }
                        secondary={
                          <Box
                            sx={{
                              flex: 1,
                              mt: 0.5
                            }}
                          >
                            <Typography color="textSecondary" variant="body2">
                              {mimeName(item.type)}
                            </Typography>
                            <Typography color="textSecondary" variant="body2">
                              {numeral(item.size / 1024).format('0.0')} KB
                            </Typography>
                            <IconButton
                              color="error"
                              onClick={() => removeFiles(item)}
                              sx={{
                                right: 0,
                                position: 'absolute',
                                top: 25
                              }}
                            >
                              <TrashIcon />
                            </IconButton>
                          </Box>
                        }
                        sx={{ my: 0 }}
                      />
                    </ListItem>
                  ))}
                </List>

                <Box
                  sx={{
                    borderRadius: 1,
                    boxShadow: '0 0 0 1px rgba(24, 33, 77, 0.23)',
                    display: 'flex',
                    position: 'relative',
                    width: 'fit-content',
                    '& img': {
                      borderRadius: 1,
                      display: 'block',
                      maxWidth: 126
                    },
                    '&:hover': {
                      boxShadow: (theme) => `0 0 0 1px ${theme.palette.primary.main}`,
                      '& > button': {
                        display: 'block'
                      },
                      '& img': {
                        opacity: 0.3
                      }
                    }
                  }}
                >
                  <img alt={formik.values.name} src={formik.values.image} />
                  <IconButton
                    color="error"
                    onClick={() => formik.setFieldValue('image', '')}
                    sx={{
                      display: 'none',
                      left: 0,
                      position: 'absolute',
                      top: 0
                    }}
                  >
                    <TrashIcon />
                  </IconButton>
                </Box>
              </>
            ) : null}

            <Box sx={{ mt: 2, direction: 'row', gap: 3, display: 'flex', flex: 1 }}>
              <ImageDropzone
                // onDrop={(files) => formik.setFieldValue('files', URL.createObjectURL(files[0]))}
                onDrop={(files) => addFiles(files)}
                sx={{
                  minHeight: 126,
                  maxWidth: 326
                }}
                hoverDocument={hoverDocument}
              />
              <GoalButton
                onClick={() => {
                  removeAllFiles();
                }}
                disabled={!formik.values.files.length > 1}
                label="Limpiar"
                variant={'text'}
                startIcon={<TrashIcon />}
              />
            </Box>
          </Grid>
          {formik.errors.submit && (
            <Grid item xs={12}>
              <FormHelperText error>{formik.errors.submit}</FormHelperText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {maxFileOne ? (
          <GoalButton
            onClick={() => handleDocuments(formik.values, { name: nameDocument })}
            disabled={formik.values.files.length > 1 || formik.values.files.length === 0}
            label="Confirmar"
            startIcon={<CheckIcon />}
          />
        ) : (
          <GoalButton
            onClick={() => {
              formik.handleSubmit();
            }}
            loading={saving}
            disabled={formik.values.files.length > 1 && documentApi}
            label="Confirmar"
            startIcon={<CheckIcon />}
          />
        )}
        <GoalButton
          color="primary"
          onClick={onClose}
          variant="text"
          label="Cancelar"
          startIcon={<XIcon />}
        />
      </DialogActions>
    </Dialog>
  );
};

DocumentAppendDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  afterSubmit: PropTypes.func,
  onVariantsChange: PropTypes.func,
  uploaderApi: PropTypes.func,
  variant: PropTypes.object,
  typeDocument: PropTypes.array,
  titleDocument: PropTypes.string,
  hoverDocument: PropTypes.string,
  maxFileOne: PropTypes.bool,
  handleDocuments: PropTypes.func,
  nameDocument: PropTypes.string,
  documentApi: PropTypes.bool,
  maxFiles: PropTypes.number
};
