import PropTypes from 'prop-types';
import { useState } from 'react';
import Chart from 'react-apexcharts';
import { Box, Card, CardContent, Grid, List, ListItem, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { StatusBadge } from '../status-badge';
import LoadingContent from './loading-content';
import { RenderIf } from '../render-if';

import { NumberColor } from '../number-color';
import GoalButton from '../goal-button';
import plusCircleOutline from '@iconify/icons-eva/plus-circle-outline';
import minusCircleOutline from '@iconify/icons-eva/minus-circle-outline';
import { Icon } from '@iconify/react';

export const CommonChart = ({
  data,
  isLoading,
  error,
  title,
  message,
  format = '0',
  summary = 0,
  maxLines = 5
}) => {
  const theme = useTheme();
  const [showAll, setShowAll] = useState(false);

  const handleShwoAll = () => {
    setShowAll(!showAll);
  };
  if (!data || isLoading) {
    return (
      <LoadingContent
        loading={isLoading}
        loadingText={`No hay datos para ${title}`}
        sx={{
          minHeight: '5vh'
        }}
      />
    );
  }

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: data?.map((item) => {
      if (item.color.startsWith('#')) return item.color;
      // eslint-disable-next-line no-eval
      if (item.color.includes('.')) return eval(`theme.palette.${item.color}`);
      return item.color;
    }),
    dataLabels: {
      enabled: false
    },
    grid: {
      padding: {
        left: 0,
        right: 0
      }
    },
    labels: data?.map((item) => item.name),
    legend: {
      show: false
    },
    stroke: {
      width: 0
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      theme: theme.palette.mode
    }
  };

  const chartSeries = data?.map((item) => item.data);
  if (isLoading || !chartSeries) {
    return <LoadingContent loadingText={message} error={error} />;
  }

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: alpha(theme.palette.info.main, 0.1),
        mb: 1,
        minHeight: '350px'
      }}
      md={1}
    >
      <CardContent>
        <Box sx={{ py: 0 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              mb: 1
            }}
          >
            <Typography color="textPrimary" variant="h6">
              {title}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <RenderIf condition={!showAll && data?.length > maxLines}>
              <GoalButton
                startIcon={<Icon icon={plusCircleOutline} />}
                onClick={handleShwoAll}
                variant="outlined"
                iconOnly
              />
            </RenderIf>
            <RenderIf condition={showAll && data?.length >= maxLines}>
              <GoalButton
                startIcon={<Icon icon={minusCircleOutline} />}
                onClick={handleShwoAll}
                variant="outlined"
                iconOnly
              />
            </RenderIf>
          </Box>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Box sx={{ height: 12 }} />
              <Chart options={chartOptions} series={chartSeries} type="donut" width={120} />
              <Box sx={{ height: 12 }}>
                <NumberColor value={summary} format={format} align="inherit" variant="h5" />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <List sx={{ flex: 1 }}>
              {data?.map((item, index) => {
                if (!showAll && index >= maxLines) return null;
                return (
                  <ListItem disableGutters key={`${item.name}-${index}`} sx={{ display: 'flex' }}>
                    <StatusBadge color={item.color} sx={{ mr: 1 }} />
                    <Typography color="textSecondary" variant="body2">
                      {/* {capitalizeParagraph(item.name)} */}
                      {item.name}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <NumberColor value={item.data} format={format} />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CommonChart.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
  format: PropTypes.string,
  summary: PropTypes.number,
  maxLines: PropTypes.number
};
