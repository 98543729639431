import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../../icons/dots-vertical';
import { InvoiceStatus } from '../../../api/invoice-item-definitions';
import { RenderIf } from '../../render-if';
import { useApp } from '../../../hooks/use-app';

export const InvoiceItemMenu = (props) => {
  const { invoice } = props;
  const navigate = useNavigate();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const { appUrlBase } = useApp();

  const handleEdit = () => {
    handleClose();
    navigate(`${appUrlBase}/purchases/${invoice?.id}`);
  };

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef} {...props}>
        <DotsVerticalIcon />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <RenderIf condition={invoice?.status === InvoiceStatus.DRAFT}>
          <MenuItem onClick={handleEdit}>Editar</MenuItem>
        </RenderIf>
      </Menu>
    </>
  );
};

InvoiceItemMenu.propTypes = {
  invoice: PropTypes.object
};
