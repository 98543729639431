import { useState } from 'react';
import Proptypes from 'prop-types';
import {
  Box,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Pagination } from '../pagination';
import { ResourceError } from '../resource-error';
import { ResourceUnavailable } from '../resource-unavailable';
import Scrollbar from '../Scrollbar';
import GadminActions from '../../contexts/gadmin-actions';
// import { PromotionsForm } from './promotions-form';
import { CustomerModal } from '../delivery/monitoring/customer-modal';
import { ImportForm } from './import-form';

import { GoalRecordLink } from '../goal-record-link';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: '#'
  },
  {
    id: 'name',
    label: 'Nombre'
  },
  {
    id: 'path',
    label: 'Path'
  },
  {
    id: 'require_company',
    label: 'Requiere Empresa'
  }
];

export const ImportTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    sort = 'desc',
    sortBy = 'issueDate',
    handleRefresh
  } = props;

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  const [open, onClose] = useState(false);
  const [itemData, setitemEdit] = useState(null);

  const handleClickEditIcon = (item) => {
    setitemEdit(item);
    onClose(!open);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000, marginTop: 1, marginLeft: 3 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, index) => (
              <TableRow hover key={index}>
                <TableCell
                  sx={{
                    minWidth: 150
                  }}
                >
                  <GoalRecordLink onClick={() => handleClickEditIcon(document)} label={index + 1} />
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document?.name}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document?.path}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document?.require_company ? ' Si' : 'No'}
                  </Typography>
                </TableCell>
                {/* <TableCell sx={{ minWidth: 200 }}>
                  {document.arguments.map((arg, index) => (
                    <Box key={index} sx={{ marginTop: index === 0 ? 0 : 1 }}>
                      <Typography color="textSecondary" variant="body2">
                        Tipo: {arg.data_type}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        Nombre: {arg.name}
                      </Typography>
                      {arg.min !== '' && arg.min !== null && (
                        <Typography color="textSecondary" variant="body2">
                          Valor Mínimo: {arg.min}
                        </Typography>
                      )}
                      {arg.max !== '' && arg.max !== null && (
                        <Typography color="textSecondary" variant="body2">
                          Valor Máximo: {arg.max}
                        </Typography>
                      )}
                    </Box>
                  ))}
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />

      <CustomerModal
        open={open}
        onClose={onClose}
        permission={GadminActions.G2_CONTESTS_UP}
        widhtForm="lg"
      >
        <ImportForm
          update
          onClose={onClose}
          handleRefresh={handleRefresh}
          title="Importar"
          initialValues={itemData}
        />
      </CustomerModal>
    </Box>
  );
};

ImportTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  handleRefresh: Proptypes.func
};
