import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalApplicationApi {
  async getStageConfig() {
    const response = await dataLoader.goalGet({
      action: `/applications/applications/dc4ef54715eb0ce748d1789b7c122e7b/stage-config`
    });
    return response;
  }
}

export const goalApplicationApi = new GoalApplicationApi();
