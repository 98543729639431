import { useState } from 'react';
import { useDialog } from './use-dialog';

const useGoalDialog = (props) => {
  const {
    handleClose = () => {},
    apiHandler,
    apiParams,
    ...rest
  } = props || { handleClose: () => {} };

  const [open, openDialog, closeDialog] = useDialog();

  const [editMode, setEditMode] = useState(false);

  const handleReport = () => {
    handleClose?.();
    openDialog();
  };

  const onClose = () => {
    closeDialog();
    handleClose?.();
  };
  return [
    {
      open,
      openDialog,
      closeDialog: onClose,
      onClick: handleReport,
      apiHandler,
      apiParams,
      editMode,
      setEditMode,
      ...rest
    }
  ];
};
export { useGoalDialog };
