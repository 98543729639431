import { Box, Card, CardContent, CardHeader, Container } from '@mui/material';
import { useMounted } from '../../../hooks/use-mounted';
import { GMGrid } from '../../../components/gm/grid/gm-grid';
import { useApp } from '../../../hooks/use-app';
import { useGMDataSet } from '../../../components/gm/dataset/use-gm-dataset';
import { goalWarehousesApi } from '../../../api/goal/inventory/warehouses/warehouse';
import { Helmet } from 'react-helmet-async';
import { LoadingContent } from '../../../components/common/loading-content';
import { RefreshButton } from '../../../components/common/buttons/refresh-button';
import { showError, showNotify } from '../../../hooks/toasty';
import { SyncButton } from '../../../components/common/buttons/sync-button';
import { goalPriceListsApi } from '../../../api/goal/inventory/price-list/goal-price-list';

const columns = [
  {
    field: 'id',
    headerName: 'Código',
    width: 120,
    link: {
      to: (item) => `/inventory/price-lists/${item.ListNum}`,
      tooltip: 'Ver detalle de la lista de precios'
    }
  },
  { field: 'WhsName', headerName: 'Nombre', width: 250 },
  { field: 'StreetNo', headerName: 'Dirección', width: 250 },
  { field: 'Block', headerName: 'Ciudad', width: 130 },
  { field: 'state', headerName: 'Estado', width: 130 },
  { field: 'zip', headerName: 'Código postal', width: 130 }
];

export const PriceLists = () => {
  const { title, currentCompany } = useApp();
  const mounted = useMounted();

  const { handleRefresh, ...proceListsState } = useGMDataSet({
    sourceApi: goalPriceListsApi.getList,
    apiParameters: { companyId: currentCompany },
    loadingMessage: 'Cargando almacenes...',
    mounted,
    columns
  });

  const handleSync = async () => {
    try {
      const result = await goalWarehousesApi.sync({ companyId: currentCompany });
      if (result.success) {
        showNotify(result.message);
        handleRefresh();
      } else {
        showError(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderContent = () => {
    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1
        }}
        variant="outlined"
      >
        <CardHeader
          title="Almacenes"
          action={
            <>
              <SyncButton onClick={handleSync} />
              <RefreshButton onClick={handleRefresh} />
            </>
          }
        />

        <CardContent>
          <GMGrid {...proceListsState} />
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Almacenes')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
          paddingBottom: '1rem'
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            {proceListsState.isLoading ? (
              <LoadingContent
                loadingText={proceListsState.message}
                error={proceListsState.error}
                state={proceListsState}
              />
            ) : (
              renderContent()
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};
