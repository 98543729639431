import PropTypes from 'prop-types';
import moment from 'moment';

import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
// import { Trash as TrashIcon } from '../../../icons/trash';
// import { Plus as PlusIcon } from '../../../icons/plus';
import { NumberColor } from '../../number-color';

export const JournalHeader = (props) => {
  const { journalInfo } = props;

  if (!journalInfo) {
    return (
      <Card variant="outlined">
        <CardHeader title="Información del asiento - sin Datos" />
      </Card>
    );
  }

  return (
    <Card variant="outlined">
      <CardHeader title="Información del asiento" />
      <CardContent>
        <Grid container>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem
                label="Tipo"
                value={`${journalInfo.transType}-${journalInfo.seriesName}`}
              />
              <PropertyListItem label="Origen" value={`${journalInfo.baseRef}`} />
              <PropertyListItem label="Numero" value={`${journalInfo.number}`} />
              <PropertyListItem label="Transacción" value={`${journalInfo.transId}`} />
              <PropertyListItem label="Codigo Transacción" value={`${journalInfo.transCode}`} />
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem
                label="Fecha de Contabilización"
                value={moment(journalInfo.refDate).format('DD/MM/YYYY')}
              />
              <PropertyListItem
                label="Fecha de document"
                value={moment(journalInfo.taxDate).format('DD/MM/YYYY')}
              />
              <PropertyListItem
                label="Fecha de vencimiento"
                value={moment(journalInfo.dueDate).format('DD/MM/YYYY')}
              />
              <PropertyListItem label="Comentario" value={`${journalInfo.memo}`} />
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem label="Referencia1" align="right">
                {journalInfo.ref1}
              </PropertyListItem>
              <PropertyListItem label="Referencia2" align="right">
                {journalInfo.ref2}
              </PropertyListItem>
              <PropertyListItem label="Importe" align="right">
                <NumberColor value={journalInfo.locTotal} format="0.00" />
              </PropertyListItem>
              <PropertyListItem label="Fecha de creación" align="right">
                {moment(journalInfo.createDate).format('DD/MM/YYYY HH:mm:ss')}
              </PropertyListItem>
              <PropertyListItem label="Usuario" align="right">
                {journalInfo.userCode}
              </PropertyListItem>
              <PropertyListItem label="Ref Interna" align="right">
                {journalInfo.createdBy}
              </PropertyListItem>
            </PropertyList>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

JournalHeader.propTypes = {
  journalInfo: PropTypes.object.isRequired
};
