import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { financialReportApi } from '../../../api/financial-reports';
import { Status } from '../../../components/status';
import { useMounted } from '../../../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../../../icons/arrow-left';
import { useApp } from '../../../hooks/use-app';
import LoadingContent from '../../../components/common/loading-content';
import { useDownloadFile } from '../../../hooks/use-download-file';
import { JournalHeader } from '../../../components/financial/journal/journal-header';
import { JournalLines } from '../../../components/financial/journal/journal-lines';
import { Eye as EyeIcon } from '../../../icons/eye';
import { useDialog } from '../../../hooks/use-dialog';
import { JournalPrintingDialog } from '../../../components/purchases/printing/journal-printing-dialog';
import { useData } from '../../../hooks/use-data';
import { goalJournalsApi } from '../../../api/goal/financial/journals';
import { JournalLinesGroupTable } from '../../../components/financial/journal/journal-lines-group';
import { GoalButton } from '../../../components/goal-button';
import RefreshIcon from '@mui/icons-material/Refresh';

export const Journal = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title, currentCompany } = useApp();
  const [
    journalPrintingDialogOpen,
    handleOpenJournalPrintingDialog,
    handleCloseJournalPrintingDialog
  ] = useDialog();

  const [journalState, refreshJournal] = useData({
    sourceApi: goalJournalsApi.getJournal,
    apiParameter: {
      transid: id,
      companyId: currentCompany
    },
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });
  useEffect(() => {
    refreshJournal({ transid: id, companyId: currentCompany });
  }, [id]);

  const navigate = useNavigate();

  const { download } = useDownloadFile({
    apiDefinition: financialReportApi.journalDetailXls,
    apiParameters: {
      transid: id
    }
  });

  const renderContent = () => {
    if (journalState.isLoading || journalState.error || !journalState.data) {
      return <LoadingContent loadingText={journalState.message} error={journalState.error} />;
    }
    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box sx={{ mb: 2 }}>
            <Button
              color="primary"
              component="button"
              startIcon={<ArrowLeftIcon />}
              onClick={() => navigate(-1)}
              variant="text"
            >
              Regresar
            </Button>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              # SAP {journalState.data?.SeriesName}-{journalState.data?.DocNum}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Button
                color="primary"
                // component={RouterLink}
                size="large"
                startIcon={<EyeIcon />}
                onClick={handleOpenJournalPrintingDialog}
                variant="contained"
              >
                Asiento
              </Button>
              <GoalButton
                startIcon={<RefreshIcon />}
                onClick={refreshJournal}
                variant="contained"
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Status color="error.main" label={journalState.data?.memo} />
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <JournalHeader journalInfo={journalState.data} />
            </Grid>

            <Grid item xs={12}>
              <JournalLines journalInfo={journalState.data} onDownload={download} />
            </Grid>

            <Grid item xs={12}>
              <JournalLinesGroupTable journalInfo={journalState.data} />
            </Grid>
          </Grid>
        </Grid>
        <JournalPrintingDialog
          onClose={handleCloseJournalPrintingDialog}
          open={journalPrintingDialogOpen}
          entity={{
            objType: journalState.data?.transType,
            docEntry: journalState.data?.createdBy,
            transId: id
          }}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Asiento Diario')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};
