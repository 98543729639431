import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Card, Container, MenuItem, Typography } from '@mui/material';
import { useApp } from '../../../hooks/use-app';
import { useMounted } from '../../../hooks/use-mounted';
import gtm from '../../../lib/gtm';
import { Helmet } from 'react-helmet-async';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';
import QueuesTable from '../../../components/automations/queues/queues-table';
import QueuesFilter from '../../../components/automations/queues/queues-filter';
import LoadingContent from '../../../components/common/loading-content';
import { bulksApi } from '../../../api/managment/bulks';
import { QueuesViews } from '../../../api/managment/queue-definition';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { useSelection } from '../../../hooks/use-selection';
import { bulksShowFrom, dataBulksShowFrom } from '../../../api/managment/bulks-definitions';
import { SelectInputField } from '../../../components/select-field';

const QueuesContainer = () => {
  const mounted = useMounted();
  const { title, currentCompany } = useApp();
  const { search } = useLocation();
  const view = new URLSearchParams(search).get('view') || QueuesViews.ALL;
  const [showFrom, setShowFrom] = useState(bulksShowFrom.ME);

  const [
    queuesState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller
  ] = useDataBrowser({
    sourceApi: bulksApi.getQueues,
    loadingMessage: 'Cargando Queues...',
    mounted,
    defaults: {
      view: view,
      companyId: currentCompany,
      searchText: showFrom
    }
  });

  const [selectedQueues, , ,] = useSelection(queuesState.documents);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const viewFromAllUsers = (event) => {
    setShowFrom(event.target.value);
    controller.searchText = event.target.value;
    handleRefresh();
  };

  const renderContent = () => {
    if (!controller.refresh && (queuesState.isLoading || queuesState.error)) {
      return <LoadingContent loadingText={queuesState.message} error={queuesState.error} />;
    }

    return (
      <Card variant="outlined">
        <QueuesFilter
          disabled={queuesState.isLoading}
          filters={controller.filters}
          onFiltersApply={handleFiltersApply}
          onFiltersClear={handleFiltersClear}
          onQueryChange={handleQueryChange}
          onViewChange={handleViewChange}
          query={controller.query}
          selectedQueues={selectedQueues}
          view={controller.view}
        />
        <QueuesTable
          error={queuesState.error}
          queues={queuesState.documents}
          queuesCount={queuesState.documentsCount}
          isLoading={queuesState.isLoading}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          page={controller.page + 1}
          sort={controller.sort}
          sortBy={controller.sortBy}
        />
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title('Queues - Lista')} </title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default' }}>
        <Container maxWidth="false" sx={{ py: 2 }}>
          <Box sx={{ display: 'flex', pb: 3 }}>
            <Typography color="textPrimary" variant="h4">
              Queues
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box>
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                >
                  Refrescar
                </Button>
              </AllowedGuard>
            </Box>
            <AllowedGuard permission={GadminActions.gCC_GPROC_MNGMT}>
              <Box sx={{ ml: 1 }}>
                <SelectInputField
                  name="showFromAllUsers"
                  value={showFrom}
                  onChange={viewFromAllUsers}
                  disabled={queuesState.isLoading}
                >
                  {dataBulksShowFrom.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </SelectInputField>
              </Box>
            </AllowedGuard>
          </Box>
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

export default QueuesContainer;
