import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';

export const GoalIconButton = ({ icon, onClick, color, size, sx, disabled, children, tolltip }) => {
  const button = (
    <IconButton onClick={onClick} color={color} size={size} sx={sx} disabled={disabled}>
      {icon}
      {children}
    </IconButton>
  );

  return tolltip ? <Tooltip title={tolltip}>{button}</Tooltip> : button;
};

GoalIconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.string,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  tolltip: PropTypes.string
};
