import Proptypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem
} from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import { InputField } from '../../input-field';
import { useBolean } from '../../../hooks/use-bolean';

export const CustomerArticleForm = ({ update = false, title, initialValues = {}, onClose }) => {
  const { isTrue } = useBolean();
  const formik = useFormik({
    initialValues: {
      buy: isTrue(initialValues.buy) || false,
      sale: isTrue(initialValues.sale) || false,
      cod_maker: initialValues.cod_maker || '',
      percep_exist: isTrue(initialValues.percep_exist) || false,
      min_percep: initialValues.min_percep || '',
      percep: initialValues.percep || '',
      detrac_exist: isTrue(initialValues.detrac_exist) || false,
      min_detrac: initialValues.min_detrac || '',
      detrac: initialValues.detrac || '',
      inventory: isTrue(initialValues.inventory) || false,
      category: initialValues.category || '',
      family: initialValues.family || '',
      brand: initialValues.brand || '',
      line: initialValues.line || '',
      plus: isTrue(initialValues.plus) || false
    },
    validationSchema: Yup.object().shape({
      cod_maker: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      console.log(values);
    }
  });

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 5 }}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.buy}
                  onChange={formik.handleChange}
                  name="buy"
                  color="secondary"
                />
              }
              label="Compra"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.sale}
                  onChange={formik.handleChange}
                  name="sale"
                  color="secondary"
                />
              }
              label="Venta"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.percep_exist}
                  onChange={formik.handleChange}
                  name="percep_exist"
                  color="secondary"
                />
              }
              label="Pecep?"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              name="cod_maker"
              label="Cod. del Fabricante"
              value={formik.values.cod_maker}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.cod_maker && Boolean(formik.errors.cod_maker)}
              helperText={formik.touched.cod_maker && formik.errors.cod_maker}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              name="min_percep"
              label="Min. Percerp"
              value={formik.values.min_percep}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.min_percep && Boolean(formik.errors.min_percep)}
              helperText={formik.touched.min_percep && formik.errors.min_percep}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              name="percep"
              label="%Percerp"
              value={formik.values.percep}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.percep && Boolean(formik.errors.percep)}
              helperText={formik.touched.percep && formik.errors.percep}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.detrac_exist}
                  onChange={formik.handleChange}
                  name="detrac_exist"
                  color="secondary"
                />
              }
              label="Detrac?"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.inventory}
                  onChange={formik.handleChange}
                  name="inventory"
                  color="secondary"
                />
              }
              label="Inventario"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.plus}
                  onChange={formik.handleChange}
                  name="plus"
                  color="secondary"
                />
              }
              label="++"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              name="min_detrac"
              label="Min. Detrac"
              value={formik.values.min_detrac}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.min_detrac && Boolean(formik.errors.min_detrac)}
              helperText={formik.touched.min_detrac && formik.errors.min_detrac}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              name="detrac"
              label="%Detrac"
              value={formik.values.detrac}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.detrac && Boolean(formik.errors.detrac)}
              helperText={formik.touched.detrac && formik.errors.detrac}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <InputField
              select
              label="Categoria"
              name="category"
              value={formik.values.category}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.category && Boolean(formik.errors.category)}
              helperText={formik.touched.category && formik.errors.category}
            >
              <MenuItem value="">--</MenuItem>
              <MenuItem value="adulto">Adulto</MenuItem>
              <MenuItem value="avena">Avena</MenuItem>
            </InputField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              select
              label="Familia"
              name="family"
              value={formik.values.family}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.family && Boolean(formik.errors.family)}
              helperText={formik.touched.family && formik.errors.family}
            >
              <MenuItem value="">--</MenuItem>
              <MenuItem value="pañales">Pañales</MenuItem>
              <MenuItem value="galletas">Galletas</MenuItem>
            </InputField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              select
              label="Marca"
              name="brand"
              value={formik.values.brand}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.brand && Boolean(formik.errors.brand)}
              helperText={formik.touched.brand && formik.errors.brand}
            >
              <MenuItem value="">--</MenuItem>
              <MenuItem value="pañales">Pañales</MenuItem>
              <MenuItem value="galletas">Galletas</MenuItem>
            </InputField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              select
              label="Linea"
              name="line"
              value={formik.values.line}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.line && Boolean(formik.errors.line)}
              helperText={formik.touched.line && formik.errors.line}
            >
              <MenuItem value="">--</MenuItem>
              <MenuItem value="pañales">Pañales</MenuItem>
              <MenuItem value="galletas">Galletas</MenuItem>
            </InputField>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={update ? <PencilIcon /> : <PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                <Button
                  color="secondary"
                  size="large"
                  startIcon={<TrashIcon />}
                  onClick={() => onClose(false)}
                  sx={{
                    marginLeft: 1
                  }}
                >
                  Cerrar
                </Button>
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

CustomerArticleForm.propTypes = {
  update: Proptypes.bool,
  initialValues: Proptypes.object,
  onClose: Proptypes.func,
  title: Proptypes.string
  // handleRefresh: Proptypes.func
};
