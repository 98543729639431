import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { LoadingButton } from '@mui/lab';
import { WithApp } from '../../../contexts/app-context';
import { useMounted } from '../../../hooks/use-mounted';
import LoadingContent from '../../common/loading-content';

// ----------------------------------------------------------------------

export default WithApp(() => {
  const mounted = useMounted();
  const { keycloak } = useKeycloak();

  const [isProcessing, setIsProcessing] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (mounted.current) {
      if (location.hash) {
        if (location.hash.includes('state=') && location.hash.includes('code=')) {
          setIsProcessing(true);
        }
      }
    }
  }, [location.hash, mounted]);

  return (
    <>
      {!isProcessing && (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isProcessing}
          onClick={() => {
            setIsProcessing(true);
            keycloak.login({ redirectUri: window.location.href });
          }}
        >
          Empecemos!
        </LoadingButton>
      )}
      {isProcessing && <LoadingContent loadingText="Verificando sessión..." />}
    </>
  );
});
