import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest } from '../services/gadmin';

/* eslint class-methods-use-this:0 */

class FinancialReportApi {
  async getFESentsFacele() {
    const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
      type: 'sents'
    });
    return response;
  }

  async getFEPendingsFacele() {
    const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
      type: 'facele'
    });
    return response;
  }

  async fixFEPendings(props) {
    const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
      type: 'autofix',
      payload: props
    });
    return response;
  }

  async getFEPendingsSAP() {
    const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
      type: 'sap'
    });
    return response;
  }

  async getFEPendingsSAPDetail(payload) {
    const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
      type: 'pending-sap',
      payload
    });
    return response;
  }

  async getFESentFaceleDetail(payload) {
    const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
      type: 'sent-facele',
      payload
    });
    return response;
  }

  async getFEPendingsSAPDetailXls(payload) {
    const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
      type: 'pending-sap-xls',
      payload
    });
    return response;
  }

  async getFEPendingsFaceleDetailXls(payload) {
    const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
      type: 'pending-facele-xls',
      payload
    });
    return response;
  }

  async getFESentsFaceleDetailXls(payload) {
    const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
      type: 'sent-facele-xls',
      payload
    });
    return response;
  }

  async getFEPendingsFaceleDetail(payload) {
    const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
      type: 'pending-facele',
      payload
    });
    return response;
  }

  async faceleIncludeExclude(payload) {
    const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
      type: 'facele-exclude',
      payload
    });
    return response;
  }

  async faceleRemove(payload) {
    const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
      type: 'facele-remove',
      payload
    });
    return response;
  }

  async validateCPE(payload) {
    const response = await GadminRequest(GadminActions.gCC_GPRINT_VALIDATE_RECEIPT, {
      ...payload
    });
    return response;
  }

  async generateTargetJournals() {
    const response = await GadminRequest(GadminActions.gCC_ACCT_PROCESS, {
      type: 'generate-target-files'
    });
    return response;
  }

  async journalDetail({ transid }) {
    const response = await GadminRequest(GadminActions.gCC_ACCT_JOURNAL_VIEW, {
      type: 'detail',
      payload: { transid }
    });
    return response;
  }

  async journalDetailXls(payload) {
    const response = await GadminRequest(GadminActions.gCC_ACCT_JOURNAL_VIEW, {
      type: 'lines-xls',
      payload
    });
    return response;
  }
}

export const financialReportApi = new FinancialReportApi();
