import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Divider, Tab, Tabs, Typography } from '@mui/material';
import { Link as RouterLink, Outlet, useLocation, useParams, useNavigate } from 'react-router-dom';

// import gtm from '../../lib/gtm';
import { Plus as PlusIcon } from '../../icons/plus';
import { useApp } from '../../hooks/use-app';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import LoadingContent from '../../components/common/loading-content';
import { useMounted } from '../../hooks/use-mounted';
// import { CustomerOrdersTable } from '../../components/busines-partner/customer/customer-orders-table';
import { RenderIf } from '../../components/render-if';
import { useData } from '../../hooks/use-data';
import { useSplit } from '../../hooks/use-split';
import { useAuth } from '../../hooks/use-auth';
import { goalBaseBusinessPartnersApi } from '../../api/goal/base/goal-base-business-partners';
// import { goalBaseBusinessPartnersApi } from '../../api/goal/base/goal-base-business-partners';

const defaultTabs = [
  {
    href: '',
    label: 'Resumen',
    permission: GadminActions.gCC_SEARCH_SN,
    bpTypes: ['C', 'S']
  },
  {
    href: '/summary2',
    label: 'Resumen2',
    permission: GadminActions.gCC_SEARCH_SN,
    bpTypes: ['C', 'S']
  },
  {
    href: '/sales-orders',
    label: 'Pedidos',
    permission: GadminActions.gCC_BROWSE_ORDER_REG,
    bpTypes: ['C']
  },
  {
    href: '/returns',
    label: 'Devoluciones',
    permission: GadminActions.G2_CD_LIST,
    bpTypes: ['C']
  },
  {
    href: '/addresses',
    label: 'Direcciones',
    permission: GadminActions.G2_CD_LIST,
    bpTypes: ['C']
  },
  {
    href: '/divisions',
    label: 'Divisiones',
    permission: GadminActions.G2_CD_LIST,
    bpTypes: ['C']
  },
  {
    href: '/tables',
    label: 'Mesas',
    permission: GadminActions.G2_CD_LIST,
    bpTypes: ['C']
  },
  {
    href: '/documents',
    label: 'Documentos',
    permission: GadminActions.G2_CD_LIST,
    bpTypes: ['C', 'S']
  },
  {
    href: '/articles',
    label: 'Artículos',
    permission: GadminActions.G2_CD_LIST,
    bpTypes: ['S']
  },
  {
    href: '/electric_invoice',
    label: 'Facturas eléctricas',
    permission: GadminActions.G2_CD_LIST,
    bpTypes: ['S']
  }
];

export const Customer = () => {
  const app = useApp();
  const navigate = useNavigate();
  const { renderAllowed } = useAuth();
  const location = useLocation();
  const { appUrlBase, currentCompany } = useApp();

  const { cardcode: cardcodeQuery } = useParams();

  const mounted = useMounted();
  const { code: cardcode } = useSplit(cardcodeQuery);

  // const [documentState] = useData({
  //   sourceApi: customerApi.getCustomerSumary,
  //   apiParameter: { cardcode },
  //   loadingMessage: 'Cargando información del Socio de negocio',
  //   mounted
  // });

  const [documentState] = useData({
    sourceApi: goalBaseBusinessPartnersApi.getSummary,
    apiParameter: { companyId: currentCompany, id: cardcode },
    loadingMessage: 'Cargando detalle del resumen',
    mounted
  });

  // const [documentState] = useData({
  //   sourceApi: goalBaseBusinessPartnersApi.getSummaryId,
  //   apiParameter: { companyId: currentCompany, id: cardcode },
  //   loadingMessage: 'Cargando detalle del resumen',
  //   mounted
  // });

  console.log('documentState', documentState);
  // cardType -> api old, type -> goal api
  const tabs = defaultTabs
    ?.filter((tab) => tab.bpTypes.includes(documentState.data?.cardType || ''))
    ?.filter((tab) => renderAllowed(tab.permission, tab));

  // const handleOpenDialogWithNew = () => {
  //   setCurrentLine?.({ id: -1, name: 'Nuevo', value: '', code: 'ST_', module: 0 });
  //   handleOpenEditDialog?.();
  // };
  const currentTab = tabs.findIndex(
    (tab) => `${appUrlBase}/busines-partners/customers/${cardcode}${tab.href}` === location.pathname
  );

  const handleCreate = () => {
    navigate(`${appUrlBase}/busines-partners/new`, {
      replace: true
    });
  };

  const renderContent = () => {
    if (documentState.isLoading || documentState.error) {
      return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
    }
    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h5">
              {/* {documentState?.data?.id}-{documentState?.data?.full_name} */}
              {documentState?.data?.cardCode}-{documentState?.data?.cardName}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              {/* <Typography sx={{ marginRight: 2 }} color="textPrimary" variant="h4">
                {documentState?.data?.type}
              </Typography> */}
              <AllowedGuard permission={GadminActions.gCC_DOC_SUCCESS_ADD}>
                <Box sx={{ mb: 2 }}>
                  <Button
                    color="primary"
                    size="medium"
                    startIcon={<PlusIcon />}
                    onClick={handleCreate}
                    variant="contained"
                  />
                </Box>
              </AllowedGuard>
            </Box>
          </Box>
        </Box>
        {/* <QueuesStats /> */}
        {/* <CustomerOrdersTable
          setCurrentLine={setCurrentLine}
          handleOpenEditDialog={handleOpenEditDialog}
          onRefresh={handleRefresh}
          documentState={documentState?.data}
        /> */}
        {/* <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
          }}
          variant="outlined"
        >
          <Divider />
        </Card> */}
        {/* <GProcConfigEdit
          onClose={handleCloseEditDialog}
          onExited={handleExitedDialog}
          afterSubmit={handleRefresh}
          open={editDialogOpen}
          lineState={currentLine}
          refreshLine={setCurrentLine}
        /> */}
        <Divider />
        <RenderIf condition={currentTab !== -2}>
          <Tabs
            allowScrollButtonsMobile
            sx={{ mt: 4 }}
            value={currentTab > 0 ? currentTab : 0}
            variant="scrollable"
          >
            {tabs.map((option) => (
              <AllowedGuard key={option.href} permission={option.permission}>
                <Tab
                  component={RouterLink}
                  key={option.href}
                  label={option.label}
                  to={`${appUrlBase}/busines-partners/customers/${cardcode}${option.href}`}
                />
              </AllowedGuard>
            ))}
          </Tabs>
        </RenderIf>
        <Divider />
        <Outlet />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{app.title('Información del Cliente')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};
