import { useEffect, useState } from 'react';
import {
  comparatorsEnum,
  contains,
  endsWith,
  equal,
  greaterThan,
  isAfter,
  isBefore,
  isBlank,
  isNumber,
  isPresent,
  lessThan,
  notContains,
  notEqual,
  startsWith
} from '../utils/comparators';
import { CustomGridCell, numericTypes } from '../grid/custom-grid-cell';
import { isTrue } from '../../../hooks/use-bolean';

export const useGMDataSet = (props) => {
  const {
    columns: columnsProp,
    paginated = true,
    pageSize = 50,
    autoload = true,
    loadingMessage = 'Cargando datos...',
    sourceApi,
    apiParameters = {},
    afterLoad
  } = props;
  const [status, setStatus] = useState({
    loading: true,
    error: false,
    displayLoading: false,
    displayError: false,
    displayUnavailable: false,
    data: []
  });
  // const { linkTo } = useLink();
  const [columns, setColumns] = useState([]);

  const [data, setData] = useState({
    rawData: [],
    filteredData: [],
    searchedData: [],
    sortedData: [],
    rows: [],
    columns: columnsProp,
    page: 0,
    pageSize: pageSize,
    searchText: '',
    searchFields: [],
    filters: [],
    sort: 'asc',
    sortBy: '',
    paginated: paginated
  });
  const [searchColumns, setSearchColumns] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);
  const [sortColumns, setSortColumns] = useState([]);

  useEffect(() => {
    if (!columnsProp || !columnsProp.length) {
      return;
    }
    const newColumns = [];
    columnsProp.forEach((column) => {
      column.type = column.type || 'string';
      // for date columns
      if (column.type === 'date') {
        column.valueGetter = (params) => {
          if (params.value == null) {
            return '';
          }
          return new Date(params.value);
        };
        if (!column.renderCell) {
          column.renderCell = (params) => {
            const { value, row } = params;
            return <CustomGridCell value={value} row={row} column={column} />;
          };
        }
      }
      // for number columns
      if (numericTypes.includes(column.type)) {
        if (!column.renderCell) {
          const { type } = column;
          column.renderCell = (params) => {
            const { value, row } = params;
            return <CustomGridCell value={value} row={row} column={{ ...column, type: type }} />;
          };
        }
        column.type = 'number';
      }
      // for boolean
      if (column.type === 'boolean') {
        column.valueGetter = (params) => {
          if (params.value == null) {
            return false;
          }
          const val = isTrue(params.value);
          return val;
        };
        if (!column.renderCell) {
          column.renderCell = (params) => {
            const { value } = params;
            return <CustomGridCell value={value} column={column} />;
          };
        }
      }
      if (column.type === 'string') {
        if (!column.renderCell) {
          column.renderCell = (params) => {
            const { value, row } = params;
            return <CustomGridCell value={value} row={row} column={column} />;
          };
        }
      }

      newColumns.push(column);
    });

    setColumns(newColumns);

    setSearchColumns(
      columnsProp
        .filter((column) => column.searchable)
        .map((column) => {
          column.type = column.type || 'string';
          return column;
        })
    );
    setFilterColumns(
      columnsProp
        .filter((column) => column.filterable)
        .map((column) => {
          column.type = column.type || 'string';
        })
    );
    setSortColumns(
      columnsProp
        .filter((column) => column.sortable)
        .map((column) => {
          column.sortMode = column.sortMode || 'asc';
        })
    );
  }, [columnsProp]);

  useEffect(() => {
    if (autoload) {
      loadData(apiParameters).catch(console.error);
    }
  }, []);

  // aply filters when data.filtercolumns changes or data.RawData changes
  useEffect(() => {
    applyFilters(filterColumns);
  }, [filterColumns, data.rawData]);

  // aply search when  searchColumns changes or  searchText changes
  useEffect(() => {
    search(data.searchText);
  }, [searchColumns, data.searchText]);

  // aply sort when data.searchedData changes or sortColumns changes
  useEffect(() => {
    applySort();
  }, [data.searchedData, sortColumns]);

  // aply pagination when data.sortedData changes
  useEffect(() => {
    applyPagination(null);
  }, [data.sortedData]);

  const afterRerieved = async (result) => {
    if (!result.success) {
      setStatus((prevState) => ({
        ...prevState,
        ...result,
        loading: false,
        isLoaded: true,
        displayLoading: false,
        displayError: !result.success,
        displayUnavailable: result.success && !result.data
      }));
      return result;
    }
    setData((prevState) => ({
      ...prevState,
      rawData: result.data,
      filteredData: result.data,
      searchedData: result.data,
      sortedData: result.data,
      paginatedData: result.data,
      rows: result.data,
      pageCount: Math.ceil(result.data.length / pageSize)
    }));

    setStatus((prevState) => ({
      ...prevState,
      loading: false,
      isLoaded: true,
      data: result.data,
      displayUnavailable: !result.data
    }));
    await afterLoad?.(result);
  };

  const loadData = async (parameters) => {
    setStatus((prevState) => ({
      ...prevState,
      loading: true,
      message: loadingMessage,
      displayLoading: true,
      displayError: false,
      displayUnavailable: false
    }));

    try {
      const result = await sourceApi(parameters);
      await afterRerieved(result);
    } catch (err) {
      console.error('useDataError', err);
      setStatus((prevState) => ({
        ...prevState,
        loading: false,
        error: err.message,
        real: true,
        displayError: true
      }));
    }
  };

  const handleRefresh = async (parameters) => {
    let refreshParameters = parameters;

    // if parameter is not SyntheticBaseEvent
    if ((parameters && parameters._reactName === 'onClick') || !parameters) {
      refreshParameters = apiParameters;
    } else {
      refreshParameters = { ...apiParameters, ...parameters };
    }
    return await loadData(refreshParameters);
  };
  const descendingComparator = (a, b, sortBy) => {
    if (b[sortBy] < a[sortBy]) {
      return -1;
    }

    if (b[sortBy] > a[sortBy]) {
      return 1;
    }

    return 0;
  };

  const applySort = () => {
    let sortedData = data.searchedData;

    if (sortColumns && sortColumns.length) {
      sortedData = sortedData.sort((a, b) => {
        let result = 0;
        for (let index = 0; index < sortColumns.length; index++) {
          result = descendingComparator(a, b, sortColumns[index].name);
          if (result !== 0) {
            return result;
          }
        }
        return result;
      });
    }

    setData((prevState) => ({
      ...prevState,
      sortedData: sortedData
    }));
  };

  const applyPagination = (page) => {
    let paginatedData = data.sortedData;

    if (page === null) {
      paginatedData = paginated ? paginatedData?.slice(0, pageSize) : paginatedData;
    } else {
      paginatedData = paginatedData?.slice(page * pageSize, page * pageSize + pageSize);
    }

    setData((prevState) => ({
      ...prevState,
      page: page,
      rows: paginatedData || []
    }));
  };

  const applyFilters = (filters) => {
    let filteredData = data.rawData;
    if (filterColumns && filterColumns.length && filters && filters.length) {
      filteredData = filteredData.filter((row) => {
        let isAccepted = true;

        for (let index = 0; index < filters.length; index++) {
          switch (filters[index].operator) {
            case comparatorsEnum.EQUAL:
              isAccepted = equal(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.GREATER_THAN:
              isAccepted = greaterThan(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.LESS_THAN:
              isAccepted = lessThan(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.IS_AFTER:
              isAccepted = isAfter(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.IS_BEFORE:
              isAccepted = isBefore(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.IS_BLANK:
              isAccepted = isBlank(row[filters[index].property]);
              break;

            case comparatorsEnum.IS_PRESENT:
              isAccepted = isPresent(row[filters[index].property]);
              break;

            case comparatorsEnum.NOT_EQUAL:
              isAccepted = notEqual(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.CONTAINS:
              isAccepted = contains(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.NOT_CONTAINS:
              isAccepted = notContains(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.STARTS_WITH:
              isAccepted = startsWith(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.ENDS_WITH:
              isAccepted = endsWith(row[filters[index].property], filters[index].value);
              break;

            default:
              break;
          }

          if (!isAccepted) {
            break;
          }
        }

        return isAccepted;
      });
    }

    setData((prevState) => ({
      ...prevState,
      filteredData: filteredData
    }));
  };

  const search = async (searchText) => {
    // check if searchText is number
    // if yes, check if row[searchColumns[index].name] is number
    const numberSearch = isNumber(searchText);
    const rows = data.filteredData;
    let searchedData = rows;
    if (searchText && searchColumns && searchColumns.length) {
      searchedData = rows.filter((row) => {
        // If query exists, it looks only in customer id field

        let qualified = 0;
        for (let index = 0; index < searchColumns.length; index++) {
          let isAccepted = true;

          //  if isnumeric searchText
          //  if isnumeric row[searchFields[index].name]

          switch (searchColumns[index].type) {
            case 'string':
              isAccepted =
                isAccepted &&
                row[searchColumns[index].name]?.toLowerCase().includes(searchText.toLowerCase());
              break;
            case 'number':
              isAccepted =
                isAccepted &&
                numberSearch &&
                this.isNumber(row[searchColumns[index].name]) &&
                // eslint-disable-next-line eqeqeq
                row[searchColumns[index].name] == searchText;
              break;
            default:
              break;
          }
          qualified += isAccepted ? 1 : 0;
        }
        if (!qualified) return false;
      });
    }
    setData((prevState) => ({
      ...prevState,
      searchedData: searchedData
    }));
  };

  return { ...props, ...status, handleRefresh, columns, rows: data.sortedData || [] };
};
