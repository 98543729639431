import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalCustomerVehicleApi {
  async updateVehicleForm({ id, changes }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/delivery/master/vehicles/${id}`,
      changes
    });
    console.log(changes);
    return response;
  }

  // upload image
  async uploadImage({ id, companyId, ...others }) {
    const response = await dataLoader.loadGoalPost({
      action: `/fleet/fleet-vehicles/${id}/upload-image?companyId=${companyId}`,
      changes: others
    });

    return response;
  }
}

export const goalCustomeVehicleApi = new GoalCustomerVehicleApi();
