import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Typography,
  TableContainer
} from '@mui/material';
import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';
import TableHeadEnhanced from '../../common/table-head-enhanced';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID'
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Titulo'
  },
  {
    id: 'type_question',
    numeric: false,
    disablePadding: false,
    label: 'Tipo de Pregunta'
  },
  {
    id: 'question',
    numeric: false,
    disablePadding: false,
    label: 'Pregunta'
  },
  {
    id: 'options',
    numeric: false,
    disablePadding: false,
    label: 'Opciones'
  },
  {
    id: 'correct',
    numeric: false,
    disablePadding: false,
    label: 'Respuesta'
  },
  {
    id: 'score',
    numeric: false,
    disablePadding: false,
    label: 'Puntaje'
  },
  {
    id: 'apply_to',
    numeric: false,
    disablePadding: false,
    label: 'Aplicado'
  }
];

export const SurveyItemsTable = ({
  requestData: documentState,
  onSelect,
  onSelectAll,
  selectedItems,
  selectData,
  setSelectData,
  handleSetQuestions,
  isLoading,
  error,
  plusMinusSelect = false
}) => {
  if (isLoading || error) {
    return <LoadingContent loadingText="Cargando" error={error} />;
  }
  const handleSelectMultiple = (e, item) => {
    onSelect(e, item.id);
    if (!e.target.checked) {
      const updatedSelectData = selectData.filter((selectedItem) => selectedItem.id !== item.id);
      if (!plusMinusSelect) {
        handleSetQuestions(updatedSelectData);
      }

      setSelectData(updatedSelectData);
      return;
    }
    if (!plusMinusSelect) {
      handleSetQuestions([...selectData, item]);
    }
    setSelectData([...selectData, item]);
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: '60vh' }}>
        <Table sx={{ minWidth: 500 }}>
          <TableHeadEnhanced
            keyPrefix="survey-questions-column"
            headCells={headCells}
            // onRequestSort={onSortChange}
            // order={sort}
            // orderBy={sortBy}
            onSelectAllClick={onSelectAll}
            numSelected={selectedItems.length}
            rowCount={documentState.length}
          />
          {/* <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={
                    documentState.length > 0 && selectedItems.length === documentState.length
                  }
                  disabled
                  indeterminate={
                    selectedItems.length > 0 && selectedItems.length < documentState.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Titulo</TableCell>
              <TableCell>Tipo de Pregunta</TableCell>
              <TableCell>Pregunta</TableCell>
              <TableCell>Opciones</TableCell>
              <TableCell>Respuesta</TableCell>
              <TableCell>Puntaje</TableCell>
              {/* <TableCell>Aplicado</TableCell> 
            </TableRow>
          </TableHead> */}
          <TableBody>
            {documentState?.map((item, index) => (
              <TableRow
                key={item.id}
                hover
                selected={!!selectData.find((selectedItem) => selectedItem === item.id)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={!!selectData.find((selectedItem) => selectedItem.id === item.id)}
                    onChange={(event) => handleSelectMultiple(event, item)}
                  />
                </TableCell>
                {/* <TableCell>{numeral(item.id).format('0')}</TableCell> */}
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.type_question}</TableCell>
                <TableCell>{item.question}</TableCell>
                <TableCell>
                  {item.type_question === 'multiselect' ||
                  item.type_question === 'select' ||
                  item.type_question === 'range' ? (
                    item.options.map((option, index) => (
                      <Typography key={index} color="textSecondary" variant="body2">
                        {option}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      ---
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {item.type_question === 'multiselect' ? (
                    item.correct.map((item, index) => (
                      <Typography key={index} color="textSecondary" variant="body2">
                        {item}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      {item.correct}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.score}
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  {item.apply_to ? (
                    <>
                      {item.apply_to.slice(0, 5).map((item, index) => (
                        <Typography key={index} color="textSecondary" variant="body2">
                          {item?.name}
                        </Typography>
                      ))}
                      {item.apply_to.length > 5 && (
                        <Typography color="textSecondary" variant="body2">
                          ...
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      --
                    </Typography>
                  )}
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

SurveyItemsTable.propTypes = {
  requestData: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  selectedItems: PropTypes.array,
  selectData: PropTypes.array,
  setSelectData: PropTypes.func,
  handleSetQuestions: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  plusMinusSelect: PropTypes.bool
};
