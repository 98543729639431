import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Typography } from '@mui/material';
import { useMounted } from '../../hooks/use-mounted';
import { useData } from '../../hooks/use-data';
import LoadingContent from '../../components/common/loading-content';
import { goalSalesTeamsApi } from '../../api/goal/sales-teams/goal-sales-teams';
import { useApp } from '../../hooks/use-app';
import { AllowedGuard } from '../../hooks/use-allowed';
import { Refresh } from '../../icons/refresh';
import GadminActions from '../../contexts/gadmin-actions';
import { RenderIf } from '../../components/render-if';
import { SalesTeamsSumaryDetail } from '../../components/sales-teams/sales-teams-summary-detail';
import { SalesTeamsForm } from '../../components/sales-teams/form/sales-teams-form';

export const SalesTeamsSummary = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { currentCompany, title } = useApp();

  const [editMode, setEditmode] = useState(false);

  const [salesTeamsState, handleRefresh] = useData({
    sourceApi: goalSalesTeamsApi.getSalesTeamsId,
    apiParameter: { id, companyId: currentCompany },
    loadingMessage: 'Cargando informacion de la mesa',
    mounted
  });

  const [salesTeamsConfig] = useData({
    sourceApi: goalSalesTeamsApi.getSalesTeamsConfig,
    apiParameter: currentCompany,
    loadingMessage: 'Cargando informacion de la mesa',
    mounted
  });

  console.log('salesTeamsConfig', salesTeamsConfig.ss);

  if (salesTeamsState.isLoading || salesTeamsState.error) {
    return <LoadingContent loadingText={salesTeamsState.message} error={salesTeamsState.error} />;
  }
  if (!salesTeamsState.success) {
    return <LoadingContent loadingText={salesTeamsState.message} error={salesTeamsState.message} />;
  }
  if (salesTeamsState.data === null || salesTeamsState.data.length === 0) {
    return <LoadingContent loadingText="No hay datos" error={salesTeamsState.error} />;
  }
  return (
    <>
      <Helmet>
        <title>{title('Resumen mesa')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Resumen
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<Refresh />}
                  onClick={() => handleRefresh(id)}
                  variant="contained"
                />
              </AllowedGuard>
            </Box>
          </Box>
          <RenderIf
            condition={editMode}
            no={
              <SalesTeamsSumaryDetail
                item={salesTeamsState?.data}
                setEditMode={setEditmode}
                onRefresh={() => handleRefresh(id)}
              />
            }
          >
            <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
              <SalesTeamsForm
                title="Mesa"
                onClose={setEditmode}
                update
                initialValues={salesTeamsState?.data}
                handleRefresh={() => handleRefresh(id)}
              />
            </Card>
          </RenderIf>
        </Container>
      </Box>
    </>
  );
};
