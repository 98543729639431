const InventoryUnitTypes = {
  UNIT: 'unit',
  WEIGHT: 'weight',
  VOLUME: 'volume',
  LENGTH: 'length',
  AREA: 'area',
  TIME: 'time'
};

export { InventoryUnitTypes };
