import PropTypes from 'prop-types';
import { Card, CardHeader, Divider } from '@mui/material';
import { PromoAvalibleStockTable } from './avalible-stock-table';

export const PromoAvalibleStock = (props) => {
  const { stock } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Stock" />
      <Divider />
      <PromoAvalibleStockTable stock={stock} />
    </Card>
  );
};

PromoAvalibleStock.propTypes = {
  stock: PropTypes.array
};
