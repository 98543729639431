import Proptypes from 'prop-types';
import numeral from 'numeral';
import {
  Box,
  Card,
  CardActions,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { Pagination } from '../../pagination';
import { Status } from '../../status';
import TableHeadEnhanced from '../../common/table-head-enhanced';
import { GoalRecordLink } from '../../goal-record-link';

const columns = [
  {
    id: 'ItemCode',
    disablePadding: true,
    label: 'Codigo'
  },
  {
    id: 'ItenName',
    label: 'Descripción'
  },
  {
    id: 'CardName',
    label: 'Proveedor'
  },
  {
    id: 'lineName',
    label: 'Línea'
  },
  {
    id: 'price',
    label: 'Precio'
  },
  {
    id: 'validFor',
    label: 'Estado'
  }
];
const statusProducts = [
  { color: 'info.main', label: 'Activo', value: 'Y' },
  { color: 'error.main', label: 'Inactivo', value: 'N' }
];

export const ReturnsProductsTable = (props) => {
  const {
    products = [],
    productsCount = 0,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedProducts = [],
    sort = 'desc',
    sortBy = 'ItemName',
    onLoading = () => {}
  } = props;

  return (
    <Card variant="outlined">
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        }}
      >
        <TableContainer sx={{ maxHeight: '50vh', minHeight: '40vh' }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            aria-labelledby="Tabla de productos para devolución"
            size={'medium'}
            sx={{ minWidth: 500 }}
          >
            <TableHeadEnhanced
              keyPrefix="returns-product-column"
              headCells={columns}
              onRequestSort={onSortChange}
              order={sort}
              orderBy={sortBy}
              onSelectAllClick={onSelectAll}
            />

            <TableBody>
              {products?.map((item) => {
                console.log(item);
                const statusProduct = statusProducts.find(
                  (variant) => variant.value === item.validFor
                );
                return (
                  <TableRow
                    hover
                    key={item.id}
                    selected={
                      onselect &&
                      !!selectedProducts.find((selectedProduct) => selectedProduct === item.id)
                    }
                  >
                    {onSelect && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={
                            !!selectedProducts.find(
                              (selectedProduct) => selectedProduct === item.id
                            )
                          }
                          onChange={(event) => onSelect(event, item.id)}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <GoalRecordLink
                        to={`/inventory/products/${item.ItemCode}`}
                        label={item.ItemCode}
                      />
                    </TableCell>
                    <TableCell>{item.ItemName}</TableCell>
                    <TableCell>{`${item.CardCode}-  ${item.CardName}`}</TableCell>
                    <TableCell>{`${item.lineCode}-  ${item.lineName}`}</TableCell>
                    <TableCell>{numeral(item.price).format('0.00')}</TableCell>
                    <TableCell>
                      <Status color={statusProduct?.color} label={statusProduct?.label} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {onLoading()}
      </Box>
      <CardActions>
        <Pagination onPageChange={onPageChange} page={page} rowsCount={productsCount} />
      </CardActions>
    </Card>
  );
};

ReturnsProductsTable.propTypes = {
  products: Proptypes.array,
  productsCount: Proptypes.number,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedProducts: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  documentState: Proptypes.object,
  onLoading: Proptypes.func
};
