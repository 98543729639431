import PropTypes from 'prop-types';
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Status } from '../../status';
import GoalRecordLink from '../../goal-record-link';

const statusQueues = [
  { color: 'success', label: 'success', value: true },
  { color: 'error', label: 'no-success', value: false }
];

const BulkQueuesTable = ({ data }) => {
  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <TableContainer sx={{ maxHeight: '60vh', minHeight: '40vh' }}>
        <Table stickyHeader aria-label="sticky table" size={'medium'}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Nombre de objeto</TableCell>
              <TableCell>Código de objeto</TableCell>
              <TableCell>Procesada en</TableCell>
              <TableCell>Descartada?</TableCell>
              <TableCell>Creada en</TableCell>
              <TableCell>Actualizada en</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((queue, index) => {
              const statusQueue = statusQueues.find(
                (status) => status.value == (queue.success === true)
              );
              return (
                <TableRow key={`queue-of-a-bulk-${index}`} hover>
                  <TableCell>
                    <GoalRecordLink
                      to={`/automations/queues/${queue._id}`}
                      label={index + 1}
                      target="_blank"
                    />
                  </TableCell>
                  <TableCell>
                    <Chip label={statusQueue.label} color={statusQueue.color} variant="outlined" />
                  </TableCell>
                  <TableCell>{queue.ObjectName}</TableCell>
                  <TableCell>{queue.ObjectCode}</TableCell>
                  <TableCell>
                    <Status
                      color={queue.processed == '1' ? 'success.main' : 'warning.main'}
                      label={queue.processed == '1' ? 'Si' : 'No'}
                    />
                  </TableCell>
                  <TableCell>
                    <Status
                      color={queue.discarded == '1' ? 'error.main' : 'success.main'}
                      label={queue.discarded == '1' ? 'Si' : 'No'}
                    />
                  </TableCell>
                  <TableCell>{queue.created_at}</TableCell>
                  <TableCell>{queue.updated_at}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

BulkQueuesTable.propTypes = {
  data: PropTypes.array.isRequired
};

export default BulkQueuesTable;
