import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, Typography } from '@mui/material';
import { promoAssignedApi } from '../../api/promo-assigned';
import { PromoAssignedDetails } from '../../components/promo/assigned/assigned-details';
import { PromoAssignedLineItems } from '../../components/promo/assigned/assigned-line-items';
// import { PromoAssignedPayment } from '../components/promoAssigneds/promoAssigned-payment';
// import { PromoAssignedPaymentHistory } from '../components/promoAssigneds/promoAssigned-payment-history';
// import { PromoAssignedSendNotification } from '../components/promoAssigneds/promoAssigned-send-notification';
import { Status } from '../../components/status';
import { useMounted } from '../../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../../icons/arrow-left';
import { ExclamationOutlined as ExclamationOutlinedIcon } from '../../icons/exclamation-outlined';
import { useApp } from '../../hooks/use-app';
// import { PromoAssignedEdit } from '../../components/purchases/promoAssigneds/promoAssigned-edit';
// import { InvoicReference } from '../../components/purchases/promoAssigneds/promoAssigned-reference';
import { PromoAssignedLog } from '../../components/promo/assigned/assigned-log';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { RenderIf } from '../../components/render-if';
import LoadingContent from '../../components/common/loading-content';
import { PromoAssignedHeaders } from '../../components/promo/assigned/assigned-headers';
import { PromoAssignedTiming } from '../../components/promo/assigned/assigned-timing';
import { PromoAssignedEvals } from '../../components/promo/assigned/assigned-evals';
import { PromoAssignedOrderPreviewTable } from '../../components/promo/assigned/assigned-order-preview-table';
import { DocumentLog } from '../../components/purchases/common/document-log';
import GoalRecordLink from '../../components/goal-record-link';
import { useData } from '../../hooks/use-data';
import { goalOrdersApi } from '../../api/goal/presale/goal-orders';

export const PromoAssigned = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title, currentCompany } = useApp();
  const [editMode, setEditmode] = useState(false);

  const handleEdit = () => {
    if (editMode) {
      setEditmode(false);
      return;
    }
    if (!promoAssignedState.data) return;
    if (['D'].includes(promoAssignedState.data.status)) {
      setEditmode(true);
    }
  };

  const [promoAssignedState, refreshPromoAssigned] = useData({
    sourceApi: goalOrdersApi.getPromotionsDetail,
    apiParameter: { id, companyId: currentCompany },
    loadingMessage: 'Cargando documento',
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });

  useEffect(() => {
    refreshPromoAssigned({ id });
  }, [id]);

  const renderContent = () => {
    if (promoAssignedState.isLoading) {
      return <LoadingContent loadingText="Cargando documento" />;
    }

    if (promoAssignedState.error || !promoAssignedState.data) {
      return (
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              display: 'flex',
              flexDirection: 'column',
              p: 3
            }}
          >
            <ExclamationOutlinedIcon />
            <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
              {promoAssignedState.error}
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box sx={{ mb: 2 }}>
            <GoalRecordLink
              to={'/sales/orders'}
              label={'Pedidos'}
              startIcon={<ArrowLeftIcon fontSize="small" color="primary" sx={{ mr: 2 }} />}
            />
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              #{promoAssignedState.data?.document}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Status color="error.main" label={promoAssignedState.data?.EstadoMigrado} />
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <PromoAssignedDetails
                promoAssigned={promoAssignedState.data}
                setEditMode={handleEdit}
              />
            </Grid>

            <RenderIf condition={promoAssignedState.data.lines}>
              <Grid item xs={12}>
                <PromoAssignedOrderPreviewTable promoAssigned={promoAssignedState.data} />
              </Grid>
            </RenderIf>
            <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
              <RenderIf condition={promoAssignedState.hasPromo && promoAssignedState.data?.promo}>
                <Grid item xs={12}>
                  <PromoAssignedHeaders promoAssigned={promoAssignedState.data.promo} />
                </Grid>
              </RenderIf>
            </AllowedGuard>
            <RenderIf
              condition={promoAssignedState.data.promo?.lines && promoAssignedState.data?.promo}
            >
              <Grid item xs={12}>
                <PromoAssignedLineItems promoAssigned={promoAssignedState.data.promo} />
              </Grid>
            </RenderIf>
            <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
              <RenderIf condition={promoAssignedState.hasPromo}>
                <Grid item xs={12}>
                  <PromoAssignedEvals promoAssigned={promoAssignedState.data.promo} />
                </Grid>
              </RenderIf>
            </AllowedGuard>
            <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
              <RenderIf condition={promoAssignedState.hasPromo}>
                <Grid item xs={12}>
                  <PromoAssignedTiming promoAssigned={promoAssignedState.data.promo} />
                </Grid>
              </RenderIf>
            </AllowedGuard>
          </Grid>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <PromoAssignedLog promoAssigned={promoAssignedState.data} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DocumentLog document={{ objType: 124, docEntry: promoAssignedState.data.IdPedido }} />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Detalle de comprobante')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          // maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};
