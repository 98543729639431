import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import { Box, Button, Card, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { CurrencyDollar as CashIcon } from '../../../icons/currency-dollar';
import { Trash as TrashIcon } from '../../../icons/trash';
import { documnetStatus } from '../../../api/purchase-definitions';
import { documentApi } from '../../../api/document';
import { RenderIf } from '../../render-if';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { useApp } from '../../../hooks/use-app';
import { NumberColor } from '../../number-color';
import GoalRecordLink from '../../goal-record-link';
import { useLocation } from 'react-router-dom';
import { YesNo } from '../../yes-no';

const processOrder = {
  CANCEL: 'Cancelar',
  RETRY: 'Retry',
  INVOICE: 'Invoice',
  REACTIVATE: 'Reactive',
  AUTORIZE: 'Autorize',
  UNAUTORIZE: 'UnAutorize',
  CLOSE: 'Close'
};
export const CustomerOrderDetails = (props) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmDialogAction, setOpenConfirmDialogAction] = useState(null);
  const { orderInfo, setEditMode } = props;
  const { currency, currencySymbol, status } = orderInfo;
  const [editable, setEditable] = useState(false);

  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const { showNotify } = useApp();

  useEffect(() => {
    setEditable(status === documnetStatus.Borrador);
  }, [status]);
  const onCancelConfirmationDialog = () => {
    setOpenConfirmDialog(false);
  };
  const processOrderHandler = useCallback(async () => {
    let response = '';
    switch (openConfirmDialogAction) {
      case processOrder.CANCEL:
        response = await documentApi.cancelOrder(orderInfo.IdPedido);
        break;
      case processOrder.RETRY:
        response = await documentApi.retryOrder(orderInfo.IdPedido);
        break;
      case processOrder.INVOICE:
        response = await documentApi.invoiceOrder(orderInfo.IdPedido);
        break;
      case processOrder.REACTIVATE:
        response = await documentApi.reactivateOrder(orderInfo.IdPedido);
        break;
      case processOrder.AUTORIZE:
        response = await documentApi.autorizeOrder(orderInfo.IdPedido);
        break;
      case processOrder.UNAUTORIZE:
        response = await documentApi.unautorizeOrder(orderInfo.IdPedido);
        break;
      case processOrder.CLOSE:
        response = await documentApi.closeOrder(orderInfo.IdPedido);
        break;
      default:
    }
    if (response.success) {
      showNotify(response.message);
    } else {
      showNotify(response.message, 'error');
    }
    setOpenConfirmDialog(false);
  }, [openConfirmDialogAction]);

  const handleCancelOrder = async () => {
    setConfirmDialogMessage('¿Está seguro de cancelar la orden?');
    setOpenConfirmDialogAction(processOrder.CANCEL);
    setOpenConfirmDialog(true);
  };
  const handleRetryOrder = async () => {
    setConfirmDialogMessage('¿Está seguro de reprocesar la orden?');
    setOpenConfirmDialogAction(processOrder.RETRY);
    setOpenConfirmDialog(true);
  };
  const handleInvoiceOrder = async () => {
    setConfirmDialogMessage('¿Está seguro de facturar la orden?');
    setOpenConfirmDialogAction(processOrder.INVOICE);
    setOpenConfirmDialog(true);
  };
  const handleReactivateOrder = async () => {
    setConfirmDialogMessage('¿Está seguro de reactivar la orden?');
    setOpenConfirmDialogAction(processOrder.REACTIVATE);
    setOpenConfirmDialog(true);
  };

  const handleUnauthorizeOrder = async () => {
    setConfirmDialogMessage('¿Está seguro de NO autorizar la orden?');
    setOpenConfirmDialogAction(processOrder.UNAUTORIZE);
    setOpenConfirmDialog(true);
  };

  const handleAutHorizeOrder = async () => {
    setConfirmDialogMessage('¿Está seguro de autorizar la orden?');
    setOpenConfirmDialogAction(processOrder.AUTORIZE);
    setOpenConfirmDialog(true);
  };

  const handleCloseOrder = async () => {
    setConfirmDialogMessage('¿Está seguro de cerrar la orden?');
    setOpenConfirmDialogAction(processOrder.CLOSE);
    setOpenConfirmDialog(true);
  };

  const { pathname } = useLocation();

  const isOrderRoute = pathname.includes('/sales/orders');

  return (
    <Card variant="outlined">
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title="Información del pedido" />
          <Box sx={{ flexGrow: 1 }} />
        </Box>
      </Box>

      <Divider />
      <Grid container>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <PropertyList>
            <PropertyListItem label="Cliente">
              <GoalRecordLink
                to={`/busines-partners/customers/${orderInfo.CardCode}`}
                label={orderInfo.CardCode}
              />
              <Typography color="textSecondary" variant="body2">
                {orderInfo.CardName}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Término de pago" value={`#${orderInfo.PaymentName}`} />
            <PropertyListItem
              label="Almacén"
              value={`${orderInfo.WhsCode} - ${orderInfo.WhsName}`}
            />
            <PropertyListItem
              label="Vendedor"
              value={`${orderInfo.SlpCode}-${orderInfo.SlpName}`}
            />
            <PropertyListItem
              label="Mesa"
              value={`${orderInfo.CodigoMesa}-${orderInfo.mesaName}`}
            />
            <PropertyListItem label="Ruta" align={'right'}>
              {orderInfo.route_code}
            </PropertyListItem>
            <PropertyListItem label="Portafolio" align={'right'}>
              {orderInfo.portfolio_code}
            </PropertyListItem>
          </PropertyList>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <PropertyList>
            {!isOrderRoute && (
              <PropertyListItem label="Pedido">
                <GoalRecordLink
                  to={`/sales/orders/${orderInfo.IdPedido}`}
                  label={orderInfo.IdPedido}
                />
              </PropertyListItem>
            )}
            {isOrderRoute && (
              <PropertyListItem label="Numero" value={`${orderInfo.tipo}#${orderInfo.IdPedido}`} />
            )}
            <PropertyListItem label="Transacción GProc" value={`${orderInfo.PromoInstance}`} />
            <PropertyListItem
              label="Fecha de Pedido"
              value={moment(orderInfo.FechaPedido).format('DD/MM/YYYY')}
            />
            <PropertyListItem
              label="Fecha de Registro"
              value={moment(orderInfo.FechaRegistro).format('DD/MM/YYYY  HH:mm:ss')}
            />
            <PropertyListItem
              label="Fecha de proceso"
              value={moment(orderInfo.FechaMigrado).format('DD/MM/YYYY HH:mm:ss')}
            />
            <PropertyListItem label="Comentario" value={`${orderInfo.ObservacionError}`} />
          </PropertyList>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <PropertyList>
            <PropertyListItem
              label="Sede/Zona/Canal"
              value={`${orderInfo.CodigoSede}/${orderInfo.CodigoZona}/${orderInfo.CanalSede}`}
              align="right"
            />
            <PropertyListItem
              label="Total Digitado"
              value={numeral(orderInfo.TotalDigitado).format(`${currencySymbol} 0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Total Pedido"
              value={numeral(orderInfo.TotalPedido).format(`${currency}0,0.00`)}
              align="right"
            />
            <RenderIf condition={orderInfo.surchargeValue > 0}>
              <PropertyListItem
                label={`Recargos(${numeral(orderInfo.surchargeRate || 0).format(`0.00%`)} )`}
                align="right"
              >
                <NumberColor value={orderInfo.surchargeValue} format="0.00" upper />
              </PropertyListItem>
            </RenderIf>
            <PropertyListItem
              label="Total Migrado"
              value={numeral(orderInfo.TotalMigrado).format(`${'S/'}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Descuento"
              value={numeral(orderInfo.Descuento).format(`${'S/'}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Estado"
              value={`${orderInfo.EstadoMigrado}/${orderInfo.GCount}/${orderInfo.Retries}`}
              align="right"
            />

            {/* value={`${orderInfo.EstadoMigrado}/${orderInfo.GCount}/${orderInfo.Retries}`}
              align="right" */}
            {/* /> */}

            <PropertyListItem label="Lista de precios" align={'right'}>
              {orderInfo.pricelist}
            </PropertyListItem>
            <PropertyListItem label="Lista para unidades" align={'right'}>
              {orderInfo.baselist}
            </PropertyListItem>
            <PropertyListItem label="HR" align={'right'}>
              {orderInfo.hr}
            </PropertyListItem>
            <PropertyListItem label="Comprobantes" align="right">
              <Box xl={1}>
                {orderInfo.receipts?.map((item) => (
                  <GoalRecordLink
                    to={`/${'sales/receipts/'}${item.TransId}`}
                    key={item.TransId}
                    // label={`${item.TipoDocumento} ${item.SerieSunat}-${item.NumeroSunat}           ------>            ${item.DocTotal}`}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography color="textSecondary" variant="body2">
                        {item.TipoDocumento}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {item.SerieSunat}-{item.NumeroSunat}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {item.DocTotal}
                      </Typography>
                    </Box>
                  </GoalRecordLink>
                ))}
              </Box>
            </PropertyListItem>
          </PropertyList>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <PropertyList>
            <PropertyListItem label="Evaluar Promoción">
              <YesNo value={orderInfo.EvalPromo} />
            </PropertyListItem>
            <PropertyListItem label="Peso" align={'right'}>
              {orderInfo.weight}
            </PropertyListItem>
            <PropertyListItem label="Volumen" align={'right'}>
              {orderInfo.volume}
            </PropertyListItem>
            <PropertyListItem label="Tipo de Pedido" value={orderInfo.tipo} />
            <PropertyListItem label="Recargos">
              <YesNo value={orderInfo.applySurcharge} />
            </PropertyListItem>
            <PropertyListItem label="Tasa de Recargo">
              <NumberColor value={orderInfo.surchargeRate} format="0.00%" upper />
            </PropertyListItem>

            <PropertyListItem label="Impo. Recargo">
              <NumberColor value={orderInfo.surchargeValue} format="0.00" upper />
            </PropertyListItem>
          </PropertyList>
        </Grid>
        <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_CAB_UP}>
          <Button
            color="primary"
            size="large"
            startIcon={<PlusIcon />}
            onClick={() => {
              setEditMode(true);
            }}
            variant="contained"
            disabled={!editable}
          >
            Editar
          </Button>
        </AllowedGuard>
        <RenderIf condition={orderInfo.EstadoMigrado === 'D'}>
          <AllowedGuard permission={GadminActions.gCC_SAVE_AUTORIZAR_PEDIDO_RET}>
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={handleAutHorizeOrder}
              variant="contained"
            >
              Autorizar
            </Button>
          </AllowedGuard>
          <AllowedGuard permission={GadminActions.gCC_SAVE_ANULAR_PEDIDO_RET}>
            <Button
              color="secondary"
              size="large"
              startIcon={<TrashIcon />}
              onClick={handleUnauthorizeOrder}
              variant="outlined"
            >
              No-Autorizar
            </Button>
          </AllowedGuard>
        </RenderIf>
        <AllowedGuard permission={GadminActions.gCC_SAVE_ANULAR_PEDIDO_PROCESADO}>
          <RenderIf
            condition={
              orderInfo.EstadoMigrado !== 'G' &&
              orderInfo.EstadoMigrado !== 'W' &&
              orderInfo.EstadoMigrado !== 'D'
            }
          >
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={handleRetryOrder}
              variant="contained"
            >
              Reprocesar
            </Button>
          </RenderIf>
          <RenderIf condition={orderInfo.EstadoMigrado === 'W'}>
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={handleCloseOrder}
              variant="contained"
            >
              Cerrar
            </Button>
          </RenderIf>
          <RenderIf
            condition={
              orderInfo.tipo !== 1 &&
              orderInfo.EstadoMigrado === 'F' &&
              orderInfo.EstadoMigrado !== 'G'
            }
          >
            <Button
              color="primary"
              size="large"
              startIcon={<CashIcon />}
              onClick={handleInvoiceOrder}
              variant="contained"
            >
              Facturar
            </Button>
          </RenderIf>
          <RenderIf condition={['F', 'T', 'X', 'L'].includes(orderInfo.EstadoMigrado)}>
            <Button
              color="secondary"
              size="large"
              startIcon={<TrashIcon />}
              onClick={handleCancelOrder}
              variant="outlined"
            >
              Anular
            </Button>
          </RenderIf>
          <RenderIf condition={['A', 'E', 'S'].includes(orderInfo.EstadoMigrado)}>
            <Button
              color="primary"
              size="large"
              startIcon={<TrashIcon />}
              onClick={handleReactivateOrder}
              variant="contained"
            >
              Reactivar
            </Button>
          </RenderIf>
        </AllowedGuard>
      </Grid>
      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={onCancelConfirmationDialog}
        onConfirm={processOrderHandler}
        open={openConfirmDialog}
        title="Confirmación importante"
        variant="warning"
      />
    </Card>
  );
};

CustomerOrderDetails.propTypes = {
  orderInfo: PropTypes.object.isRequired,
  setEditMode: PropTypes.func,
  onRefresh: PropTypes.func
};
