import { createContext, useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from './theme-context';
import { UserContext } from './user-context';
import { getLocalSid } from '../services/gadmin';
import RouteReducer, { InitialtRoute } from './route-reducer';
import { useMounted } from '../hooks/use-mounted';
import { useData } from '../hooks/use-data';
import { goalMyAuthorizationApi } from '../api/goal/user/goal-my-authorization';
import { useSessionStorage } from '../hooks/local-access';
import { useAuth } from '../hooks/use-auth';

import { showNotify, showError, showWarning } from '../hooks/toasty';
import { goalApplicationApi } from '../api/goal/core/goal-application';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [name] = useState('GOAL');
  const [logoText] = useState('Tu meta, mas na!');
  const [unauthorized, setUnauthorized] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [sessionConfig, setSessionConfig] = useSessionStorage({});

  const [currentCompany, setCurrentCompany] = useSessionStorage('companyId', 0);
  const [currentCompanyInfo, setCurrentCompanyInfo] = useSessionStorage('companyInfo', {
    id: 0,
    name: ''
  });
  const [appUrlBase, setAppUrlBase] = useState('');
  const [reportPortalUrl, setReportPortalUrl] = useState('');
  const [initializing, setInitializing] = useState(true);
  const [noImageUrl, setNoImageUrl] = useState('');
  const mounted = useMounted();
  const { isLogged } = useAuth();

  const selectCompany = (company) => {
    setCurrentCompany(company.id);
    setCurrentCompanyInfo(company);
  };

  const [, handleRefreshStageConfigState] = useData({
    sourceApi: goalApplicationApi.getStageConfig,
    loadingMessage: 'Cargando informacion del aplicativo',
    mounted,
    defaults: {
      noAutoLoad: true
    },
    afterLoad: (result) => {
      if (!result?.data) {
        setSessionConfig({});
      } else {
        setSessionConfig(result.data || {});
      }
      setNoImageUrl(result.data?.no_image || 'no-image');
    }
  });

  const [companieState, handleRefreshCompanies] = useData({
    sourceApi: goalMyAuthorizationApi.getCompaniesList,
    loadingMessage: 'Cargando informacion de la empresa',
    mounted,
    defaults: {
      noAutoLoad: true
    },
    afterLoad: (result) => {
      if (result.success) {
        if (!currentCompany) {
          if ((result.data?.length || 0) === 0) {
            setUnauthorized(true);
            console.log('No hay empresas');
          } else {
            const defaultCompanyId = result?.data[0].id;
            if (defaultCompanyId) {
              selectCompany(result?.data[0]);
            }
          }
        }
        setInitializing(false);

        handleRefreshStageConfigState();
        // }
      }
    }
  });

  useEffect(() => {
    setAppUrlBase(`/app/${currentCompany}`);
  }, [currentCompany]);

  useEffect(() => {
    setReportPortalUrl(`${currentCompanyInfo.report_portal}`);
  }, [currentCompanyInfo]);

  const checkCompany = (selectedCompany) => {
    if (companieState?.data?.length === 0) {
      return false;
    }
    // eslint-disable-next-line eqeqeq
    const selectedItewm = companieState?.data?.find((item) => item.id == selectedCompany);
    // if (selectedItewm) {
    //   setCurrentCompany(selectedItewm.id);
    // }
    return selectedItewm;
  };
  const authenticationUrl = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/auth`;
  const localSid = getLocalSid();

  const [routeState, routeDispacher] = useReducer(RouteReducer, InitialtRoute);

  const title = (page) => `${page} | ${name}`;

  const handleShowHelp = () => {
    setShowHelp(!showHelp);
  };

  const getImageUrl = ({ imageCode, thumbMail = false }) => {
    const uniqueId = 'goal-image';
    const mediaServer = sessionConfig?.stage?.media_url || '';
    const code = imageCode || noImageUrl;
    if (thumbMail) return `${mediaServer}/q/${code}.${uniqueId}-thumb`;
    else return `${mediaServer}/q/${code}.${uniqueId}`;
  };

  // noImageUrl
  // const noImageUrl = `${mediaServer}/q/LH0-9x.goal-image`;

  useEffect(() => {
    if (isLogged) {
      // handleRefreshStageConfigState();
      handleRefreshCompanies();
    }
  }, [isLogged]);

  return (
    <AppContext.Provider
      value={{
        name,
        logoText,
        currentCompany,
        selectCompany,
        routeState,
        routeDispacher,
        // notify,
        localSid,
        title,
        showNotify,
        showError,
        showWarning,
        authenticationUrl,
        isLoggedIn: isLogged,
        companyState: companieState,
        checkCompany,
        appUrlBase,
        unauthorized,
        setUnauthorized,
        initializing,
        showHelp,
        handleShowHelp,
        getImageUrl,
        noImageUrl,
        currentCompanyInfo,
        userCompanies: companieState?.data || [],
        reportPortalUrl,
        sessionConfig
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
AppProvider.propTypes = { children: PropTypes.node.isRequired };

const WithApp = (Child) => {
  const WithAppWrapper = (props) => (
    <AppContext.Consumer>
      {(appContext) => (
        <ThemeContext.Consumer>
          {(themeContext) => (
            <UserContext.Consumer>
              {(userContext) => (
                <Child
                  key={1}
                  {...props}
                  appContext={appContext}
                  themeContext={themeContext}
                  userContext={userContext}
                />
              )}
            </UserContext.Consumer>
          )}
        </ThemeContext.Consumer>
      )}
    </AppContext.Consumer>
  );

  WithAppWrapper.displayName = `WithApp(${Child.displayName || Child.name || 'Component'})`;

  return WithAppWrapper;
};

export { AppProvider, WithApp, AppContext };
