import PropTypes from 'prop-types';
import { GoalUserMenu } from '../menu/goal-user-menu';
import { GoalUserMenuProvider } from '../../contexts/menu/goal-menu-context';

export const GoalMenu = ({ children, ...props }) => {
  return (
    <GoalUserMenuProvider>
      <GoalUserMenu {...props}>{children}</GoalUserMenu>
    </GoalUserMenuProvider>
  );
};

GoalMenu.propTypes = {
  children: PropTypes.node.isRequired
};

// export const GoalMenu = ({
//   label = 'Acciones',
//   children,
//   iconOnly = false,
//   startIcon
//   // controller
// }) => {
//   const { anchorRef, open, handleOpen, handleClose } = useGoalUserMenu();

//   return (
//     <>
//       <Box onClick={handleOpen} ref={anchorRef}>
//         {iconOnly && startIcon}
//         {!iconOnly && (
//           <GoalButton variant="outlined" startIcon={startIcon}>
//             {label}
//             <KeyboardArrowDownIcon />
//           </GoalButton>
//         )}
//       </Box>
//       <Menu
//         anchorEl={anchorRef.current}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'right'
//         }}
//         open={open}
//         onClose={handleClose}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'right'
//         }}
//       >
//         {children}
//         {/* {Children?.map(children, (mappedChild, index) => {
//           if (!mappedChild) return null;
//           let typeName = mappedChild?.type?.name || '-';

//           const child = ['AllowedGuard', 'RenderIf'].includes(typeName)
//             ? mappedChild?.props?.children
//             : mappedChild;
//           if (!child) return null;
//           typeName = child?.type?.name || '-';

//           if (['GoalMenuDialog.', 'GoalMenuItem'].includes(typeName)) {
//             return cloneElement(child, {
//               key: child.props.label || index,
//               onClose: handleClose,
//               onClick: async () => {
//                 await child.props?.onClick?.();
//                 handleClose();
//               }
//             });
//           }
//           if (typeName === 'ConfirmationMenuItem') {
//             return cloneElement(child, {
//               key: child.props.label || index,
//               onClose: handleClose,
//               onClick: async () => {
//                 await child.props?.onClick?.();
//                 handleClose();
//               },
//               onConfirm: async () => {
//                 await child.props?.onConfirm?.();
//                 handleClose();
//               },
//               onCancel: async () => {
//                 await child.props?.onCancel?.();
//                 handleClose();
//               }
//             });
//           }
//           if (['GoalMenuDialog'].includes(typeName)) {
//             return cloneElement(child, {
//               key: child.props.label || index,
//               onClose: handleClose
//             });
//           }
//           console.error('GoalMenu: Unkown child type', typeName);
//           return null;
//         })} */}
//       </Menu>
//     </>
//   );
// };

// GoalMenu.propTypes = {
//   startIcon: PropTypes.node,
//   children: PropTypes.node,
//   label: PropTypes.string,
//   iconOnly: PropTypes.bool,
//   controller: PropTypes.object.isRequired
// };
