import PropTypes from 'prop-types';
import {
  Box,
  Chip,
  Divider,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { Status } from '../../status';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import { Pagination } from '../../pagination';
import { QueuesViews } from '../../../api/managment/queue-definition';
import GoalRecordLink from '../../goal-record-link';

const headCells = [
  { id: '_id', label: '#' },
  { id: 'success', label: 'Estado' },
  { id: 'ObjectName', label: 'Nombre del objeto' },
  { id: 'ObjectCode', label: 'Código de objeto' },
  { id: 'processed', label: 'Procesada?' },
  { id: 'discarded', label: 'Descartada?' },
  { id: 'created_at', label: 'Creada en' },
  { id: 'updated_at', label: 'Actualizada en' }
];

const QueuesTable = (props) => {
  const {
    error,
    queues = [],
    queuesCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    sort = 'desc',
    sortBy = 'created_at',
    view = QueuesViews.ALL
  } = props;

  return (
    <Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {headCells.map((column) => (
                  <TableCell key={`queue-column-${column.id}`}>
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? sort : 'asc'}
                      disabled={isLoading}
                      onClick={(event) => onSortChange(event, column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={headCells.length}>
                    <Skeleton height={42} />
                    <Skeleton height={42} />
                    <Skeleton height={42} />
                  </TableCell>
                </TableRow>
              ) : queues.length > 0 ? (
                queues.map((queue, index) => {
                  return (
                    <TableRow hover key={`queue-item-${queue._id}`}>
                      <TableCell>
                        <GoalRecordLink
                          to={`/automations/queues/${queue._id}?view=${view.toLowerCase()}`}
                          label={index + 1}
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={queue.success ? 'success' : 'no-success'}
                          color={queue.success ? 'success' : 'error'}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>{queue.ObjectName}</TableCell>
                      <TableCell>{queue.ObjectCode}</TableCell>
                      <TableCell>
                        <Status
                          color={queue.processed == '1' ? 'success.main' : 'warning.main'}
                          label={queue.processed == '1' ? 'Si' : 'No'}
                        />
                      </TableCell>
                      <TableCell>
                        <Status
                          color={queue.discarded == '1' ? 'error.main' : 'success.main'}
                          label={queue.discarded == '1' ? 'Si' : 'No'}
                        />
                      </TableCell>
                      <TableCell>{queue.created_at}</TableCell>
                      <TableCell>{queue.updated_at}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={headCells.length}>
                    {error ? <ResourceError error={error} /> : <ResourceUnavailable />}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider sx={{ mt: 'auto' }} />
        <Pagination
          disabled={isLoading}
          onPageChange={onPageChange}
          page={page}
          rowsCount={queuesCount}
        />
      </Paper>
    </Box>
  );
};

QueuesTable.propTypes = {
  queues: PropTypes.array,
  queuesCount: PropTypes.number,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  page: PropTypes.number,
  sort: PropTypes.oneOf(['asc', 'desc']),
  sortBy: PropTypes.string,
  view: PropTypes.string
};

export default QueuesTable;
