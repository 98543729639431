import { useCallback, useEffect, useState } from 'react';

export const useTreeViewData = ({
  rootApi,
  childrenApi,
  apiParameter,
  loadingMessage,
  mounted,
  defaults
}) => {
  const [state, setState] = useState({
    isLoading: true,
    reload: !defaults?.noAutoLoad
  });
  const [controller, setController] = useState({
    reload: !defaults?.noAutoLoad,
    parameters: apiParameter
  });
  const [currentNode, setCurrentNode] = useState(null);

  const updatePaths = (data, parentPath) =>
    data?.map((item) => ({
      ...item,
      path: `${parentPath}/${item.id}`,
      parentPath: `${parentPath}/`
    }));

  const addItemToNestedArray = (array, itemId, childItem) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === itemId) {
        if (!array[i].children) {
          array[i].children = [];
        }
        // array[i].children.push(childItem);
        array[i].children = childItem;
        array[i].loaded = true;
        return true;
      }
      if (array[i].children) {
        if (addItemToNestedArray(array[i].children, itemId, childItem)) {
          return true;
        }
      }
    }
    return false;
  };

  const onLoadRoot = async (params) => {
    setState({ ...state, isLoading: true });
    const response = await rootApi({ ...controller.parameters, ...params });
    const newData = updatePaths(response.data, '');
    setState(() => ({ ...state, isLoading: false, data: newData }));
  };

  const onLoadChildren = async ({ parent, ...params }) => {
    setState({ ...state, isLoading: true });
    const response = await childrenApi(params);
    const newData = updatePaths(response.data, parent.path);
    setState(() => ({ ...state, isLoading: false, data: newData }));
  };

  const getDocument = useCallback(async () => {
    if (
      controller.reload &&
      currentNode != null &&
      !currentNode.loaded &&
      !!currentNode.has_children
    ) {
      if (mounted.current) {
        setState(() => ({
          ...state,
          isLoading: true,
          message: loadingMessage,
          displayLoading: true,
          displayError: false,
          displayUnavailable: false
        }));
      }
      try {
        const result = await childrenApi({ ...controller.parameters, id: currentNode.id });
        if (mounted.current) {
          const resultData = result.data;
          const newData = updatePaths(resultData, currentNode.path);
          if (addItemToNestedArray(state.data, currentNode.id, newData)) {
            setState(() => ({
              ...state,
              data: state.data,
              isLoading: false,
              reload: false,
              isLoaded: true,
              displayLoading: false,
              displayError: !result.success,
              displayUnavailable: result.success && !result.data
            }));
          }
        }
      } catch (err) {
        console.error('useDataError', err);
        if (mounted.current) {
          setState(() => ({
            isLoading: false,
            error: err.message,
            real: true,
            displayError: true
          }));
        }
      }
      controller.reload = false;
    }
  }, [
    controller,
    currentNode,
    state,
    mounted,
    childrenApi,
    loadingMessage,
    updatePaths,
    addItemToNestedArray
  ]);

  useEffect(() => {
    getDocument().catch(console.error);
  }, [getDocument]);

  const handleRefresh = (parameters) => {
    setController({
      ...controller,
      reload: true,
      parameters: { ...controller.parameters, ...parameters }
    });
  };

  const onSelectNode = (node) => {
    setCurrentNode(node);
    handleRefresh({ parent_id: node.id });
  };

  const onSearch = (searchtext) => {
    const searched = state.data.filter((item) => item.name.toLowerCase().includes(searchtext));
    return searched;
  };
  // TODO: implement element update
  // const updateElement = (data, id, newData) => {
  //   // eslint-disable-next-line no-plusplus
  //   for (let i = 0; i < data.length; i++) {
  //     if (data[i].id === id) {
  //       data[i] = newData;
  //       return true;
  //     }
  //     if (data[i].children) {
  //       if (updateElement(data[i].children, id, newData)) {
  //         return true;
  //       }
  //     }
  //   }
  //   return false;
  // };

  const actions = {
    onLoadRoot,
    onLoadChildren,
    handleRefresh,
    onSelectNode,
    onSearch
  };

  return [state, actions, currentNode];
};
