import PropTypes from 'prop-types';
import { InputLabel } from '@mui/material';

export const TitleLabel = ({ title }) => (
  <InputLabel
    sx={{
      marginBottom: 1,
      marginLeft: 0,
      color: 'black',
      fontWeight: 'bold'
    }}
  >
    {title}
  </InputLabel>
);
TitleLabel.propTypes = {
  title: PropTypes.string.isRequired
};

export default TitleLabel;
