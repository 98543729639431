import { dataLoader } from '../../data-loader';

class ProductClasificationsApi {
  async getProductClasificationsForAutocomplete({ companyId, level = 4, search_by = 'level' }) {
    const response = await dataLoader.loadGoalGetData({
      action: `inventory/master/product-clasifications/autocomplete`,
      params: { companyId, level, search_by }
    });
    return response;
  }
}

export const productClasificationsApi = new ProductClasificationsApi();
