import GadminActions from '../../contexts/gadmin-actions';
import { dataLoader } from '../data-loader';

class GoalCustomerReturnsApi {
  async getReturnsProducts(options) {
    const { filters, sort, sortBy, page, query, view, reload } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.G2_CD_LIST,
      cacheItem: 'customer-returns-avalible-products',
      apiParams: {
        type: 'avalible-products',
        view,
        payload: { filters, sort, sortBy, page, query, reload }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'ItemCode' },
        { type: 'string', name: 'ItemName' },
        { type: 'string', name: 'lineName' },
        { type: 'string', name: 'CardName' }
      ],
      ...options
    });
  }
}

const goalCustomerReturnsApi = new GoalCustomerReturnsApi();

export { goalCustomerReturnsApi };
