import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
import { queueApi } from '../../api/managment/queue';
import { QueuesFilter } from '../../components/managment/queues/queues-filter';
import { Refresh as RefreshIcon } from '../../icons/refresh';

import { QueuesStats } from '../../components/managment/queues/queues-stats';
import { QueuesTable } from '../../components/managment/queues/queues-table';
import { useMounted } from '../../hooks/use-mounted';
import { useSelection } from '../../hooks/use-selection';
import gtm from '../../lib/gtm';
import { useApp } from '../../hooks/use-app';
import { useDataBrowser } from '../../hooks/use-data-browser';
import { queueViews } from '../../api/managment/queue-definition';

// import { useDialog } from '../../hooks/use-dialog';

export const Queues = () => {
  const mounted = useMounted();
  const app = useApp();

  const [
    queuesState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller
    // handleSetController
  ] = useDataBrowser({
    sourceApi: queueApi.getQueues,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      view: queueViews.OPEN
    }
    // onPostRefresh: handleClearSelected
  });

  const [selectedQueues, handleSelect, handleSelectAll] = useSelection(queuesState.documents);
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>{app.title('GProc Queue')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Queues
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                color="primary"
                size="large"
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
                variant="contained"
              />
            </Box>
          </Box>
          <QueuesStats />
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <QueuesFilter
              disabled={queuesState.isLoading}
              filters={controller.filters}
              onFiltersApply={handleFiltersApply}
              onFiltersClear={handleFiltersClear}
              onQueryChange={handleQueryChange}
              onViewChange={handleViewChange}
              query={controller.query}
              selectedQueues={selectedQueues}
              view={controller.view}
              onRefresh={handleRefresh}
            />
            <Divider />
            <QueuesTable
              error={queuesState.error}
              queues={queuesState.documents}
              queuesCount={queuesState.documentsCount}
              isLoading={queuesState.isLoading}
              onPageChange={handlePageChange}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              selectedQueues={selectedQueues}
              sort={controller.sort}
              sortBy={controller.sortBy}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};
