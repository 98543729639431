import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

/**
 * A customizable table header component for use with Material-UI tables.
 * Supports column sorting and row selection.
 *
 * @component
 *
 * @param {Object} props - The props for the TableHeadEnhanced component.
 * @param {string} [props.keyPrefix] - Optional prefix for the keys of the table head cells to ensure uniqueness.
 * @param {Array<Object>} [props.headCells=[]] - Array of objects representing the column headers. Each object should contain:
 *   @param {string} headCells[].id - The unique identifier for the header cell.
 *   @param {string} headCells[].label - The text to display in the header cell.
 *   @param {string} [headCells[].align='left'] - Alignment of the cell content. Can be 'left', 'center', 'right', 'inherit', or 'justify'.
 *   @param {string} [headCells[].padding='normal'] - Padding of the cell. Can be 'checkbox', 'none', or 'normal'.
 * @param {number} [props.numSelected] - The number of selected rows in the table.
 * @param {function} [props.onRequestSort] - Function to call when a column header is clicked for sorting.
 *   Receives the event and the property by which the column is to be sorted.
 * @param {function} [props.onSelectAllClick] - Function to call when the "select all" checkbox is clicked.
 *   Receives the event as a parameter.
 * @param {string} [props.order='asc'] - The current sort direction. Can be 'asc' (ascending) or 'desc' (descending).
 * @param {string} [props.orderBy] - The `id` of the column that is currently sorted.
 * @param {number} [props.rowCount] - The total number of rows in the table.
 *
 * @returns {JSX.Element} The rendered table header component.
 *
 * @example
 * const headCells = [
 *   { id: 'name', label: 'Name', align: 'left' },
 *   { id: 'age', label: 'Age', align: 'right' },
 * ];
 *
 * <TableHeadEnhanced
 *   keyPrefix="example"
 *   headCells={headCells}
 *   numSelected={selected.length}
 *   order="asc"
 *   orderBy="name"
 *   rowCount={10}
 *   onSelectAllClick={handleSelectAllClick}
 *   onRequestSort={handleRequestSort}
 * />
 */
const TableHeadEnhanced = ({
  keyPrefix,
  headCells = [],
  onSelectAllClick,
  order = 'asc',
  orderBy,
  numSelected,
  rowCount,
  onRequestSort
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const getAlignHeadCell = (headCell) => {
    const alignCell = headCell.align ?? 'left';
    const isValid = ['center', 'inherit', 'justify', 'left', 'right'].includes(alignCell);
    return isValid ? alignCell : 'left';
  };

  const getPaddingHeadCell = (headCell) => {
    return ['checkbox', 'none', 'normal'].includes(headCell.padding) ? headCell.padding : 'normal';
  };

  return (
    <TableHead>
      <TableRow>
        {onSelectAllClick && rowCount > 0 && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all'
              }}
            />
          </TableCell>
        )}

        {headCells.map((headCell) => {
          const alignCell = getAlignHeadCell(headCell);
          const paddingCell = getPaddingHeadCell(headCell);

          return (
            <TableCell
              key={keyPrefix ? `${keyPrefix}-${headCell.id}` : `head-cell-${headCell.id}`}
              align={alignCell}
              padding={paddingCell}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={headCell.sx ? headCell.sx : {}}
            >
              {onRequestSort ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <>{headCell.label}</>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

TableHeadEnhanced.propTypes = {
  keyPrefix: PropTypes.string,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      align: PropTypes.string,
      padding: PropTypes.string
    })
  ),
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number
};

export default memo(TableHeadEnhanced);
