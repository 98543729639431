import PropTypes from 'prop-types';

import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { useState, useEffect } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
import { Document, Page, pdfjs } from 'react-pdf';
import GoalButton from './goal-button';

// import './pdf.css';

export default function PDFView(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { content } = props;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  /* To Prevent right click on screen */

  useEffect(() => {
    const noContext = (event) => {
      event.preventDefault();
    };
    document.addEventListener('contextmenu', noContext);
    return () => {
      document.removeEventListener('contextmenu', noContext);
    };
  }, []);

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);
  const doc = (
    <Document file={`data:application/pdf;base64,${content}`} onLoadSuccess={onDocumentLoadSuccess}>
      <Page pageNumber={pageNumber} />
    </Document>
  );

  return (
    <>
      <Card>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxHeight: '75vh'
          }}
        >
          {doc}
        </CardContent>
        {numPages > 1 && (
          <CardActions>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <Box>
                <Typography variant="body2">
                  {`Página ${pageNumber || (numPages ? 1 : '--')} of ${numPages || '--'}`}
                </Typography>
              </Box>
              <Box>
                <GoalButton
                  color="primary"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  variant="text"
                >
                  Anterior
                </GoalButton>
                <GoalButton
                  color="primary"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                  variant="text"
                >
                  Siguiente
                </GoalButton>
              </Box>
            </Box>
          </CardActions>
        )}
      </Card>
    </>
  );
}

PDFView.propTypes = {
  content: PropTypes.string
};
