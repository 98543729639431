import { dataLoader } from '../../data-loader';

const endpointLocal = 'rindegastos/expense-policies';

class RindegastosExpensePoliciesApi {
  async getCategories({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/${id}/categories`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }
}

export const rindegastosExpensePoliciesApi = new RindegastosExpensePoliciesApi();
