import Proptypes from 'prop-types';
import { format } from 'date-fns';
import {
  Box,
  Checkbox,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { Status } from '../../status';
import { NumberColor } from '../../number-color';
import { CustomerReturnsMenu } from './customer-returns-menu';
import { RenderIf } from '../../render-if';
import { useApp } from '../../../hooks/use-app';
import GoalRecordLink from '../../goal-record-link';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: 'ID'
  },
  {
    id: 'cardName',
    label: 'Socio de Negocio'
  },
  {
    id: 'slpName',
    label: 'Vendedor'
  },
  {
    id: 'zonName',
    label: 'Zona'
  },
  {
    id: 'whsname',
    label: 'Almacen'
  },
  {
    id: 'registerDate',
    label: 'Fecha de Registro'
  },
  {
    id: 'procStatus',
    label: 'Estado'
  },
  {
    id: 'actions',
    label: ''
  },
  {
    id: 'docTotal',
    label: 'Importe'
  },
  {
    id: 'registeredValue',
    label: 'Valor Registrado'
  },
  {
    id: 'document',
    label: 'Nota de Crédito'
  },
  {
    id: 'codJr',
    label: 'Jefe de reparto'
  },
  {
    id: 'cogProg',
    label: 'Despacho'
  }
];
/**
 * docTotal, registeredValue, programedValue, vatSum
 * Status
 * D Borrador
 * L Autorizado
 * W EN Reparto
 * F Facturando
 * Q Liquidado
 * C Cerrado
 */
const statusVariants = [
  { color: 'info.main', label: 'Borrador', value: 'D' },
  { color: 'warning.main', label: 'Autorizado', value: 'L' },
  { color: 'warning.main', label: 'Reparto', value: 'W' },
  { color: 'success.main', label: 'Programado', value: 'F' },
  { color: 'warning.main', label: 'Liquidado', value: 'Q' },
  { color: 'success.main', label: 'Cerrado', value: 'C' },
  { color: 'error.main', label: 'Anulado', value: 'E' },
  { color: 'success.main', label: 'Contabiliado', value: 'T' },
  { color: 'success.main', label: 'Prepagado', value: 'R' }
];

export const CustomerReturnsTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedDocuments = [],
    sort = 'desc',
    sortBy = 'issueDate'
  } = props;

  const { appUrlBase } = useApp();

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);
  const detailRoute = `/sales/returns/`;

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={documents.length > 0 && selectedDocuments.length === documents.length}
                  disabled={isLoading}
                  indeterminate={
                    selectedDocuments.length > 0 && selectedDocuments.length < documents.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document) => {
              const statusVariant = statusVariants.find(
                (variant) => variant.value === document.procStatus
              );

              return (
                <TableRow
                  hover
                  key={document.docEntry}
                  selected={
                    !!selectedDocuments.find(
                      (selectedCustomer) => selectedCustomer === document.docEntry
                    )
                  }
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        !!selectedDocuments.find(
                          (selectedCustomer) => selectedCustomer === document.id
                        )
                      }
                      onChange={(event) => onSelect(event, document.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <GoalRecordLink
                      to={`${detailRoute}${document.id}`}
                      label={document.id}
                      tooltip={`Detalle de nota de crédito - ${document.id}`}
                    />
                  </TableCell>
                  <TableCell>
                    <GoalRecordLink
                      to={`${appUrlBase}/busines-partners/customers/${document.cardCode}`}
                      label={document.cardCode}
                      tooltip={`Detalle de socio de negocio - ${document.cardName}`}
                    />
                    <Typography color="textSecondary" variant="body2">
                      {document.cardName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="body">
                      {document.slpCode}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {document.slpName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="body">
                      {`${document.codZon}-${document.codMesa}`}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {document.zonName}
                    </Typography>
                  </TableCell>
                  <TableCell>{document.whsCode}</TableCell>
                  <TableCell>{format(new Date(document.registerDate), 'yyyy-MM-dd')}</TableCell>
                  <TableCell>
                    <Status color={statusVariant?.color} label={statusVariant?.label} />
                  </TableCell>
                  <TableCell>
                    <CustomerReturnsMenu document={document} />
                  </TableCell>
                  <TableCell>
                    <NumberColor value={document.docTotal} />
                  </TableCell>
                  <TableCell>
                    <NumberColor value={document.registeredValue} />
                  </TableCell>
                  <TableCell>
                    <RenderIf condition={document.number !== ''} no="-">
                      {document.serie}#{document.number}
                    </RenderIf>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="body">
                      {document.codJr}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {document.jrName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="body">
                      {document.codProg}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {document.plaVeh}
                    </Typography>
                  </TableCell>
                  <TableCell>-</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
    </Box>
  );
};

CustomerReturnsTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedDocuments: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};
