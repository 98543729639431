import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Box, Button, Card, CardContent, CardHeader, Divider } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { customeOrderApi } from '../../../api/customer-order';

import { useMounted } from '../../../hooks/use-mounted';
import { useSelection } from '../../../hooks/use-selection';
import { Refresh as RefreshIcon } from '../../../icons/refresh';

import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';

import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { CustomerOrdersFilter } from '../../../components/document/orders/customer-orders-filter';
import { CustomerOrderssTable } from '../../../components/document/orders/customer-orders-table';
import { CustomerOrdersStats } from '../../../components/document/orders/customer-orders-stats';
// import { useQuery } from '../../../hooks/use-query';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { dateFromString } from '../../../utils/formatTime';
import { useSearchParams } from 'react-router-dom';
import { appFormats } from '../../../api/app-definitions';

export const CustomerOrders = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queried = {
    orderDate: searchParams.get('date'),
    type: searchParams.get('type'),
    subtype: searchParams.get('subtype')
  };

  const mounted = useMounted();
  const app = useApp();

  const [currentDate, setCurrentDate] = useState(dateFromString(queried.orderDate));

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const handleDateChanged = (newValue) => {
    setCurrentDate(newValue);
  };
  useEffect(() => {
    const dateString = moment(currentDate).format(appFormats.backendDateFormat);
    if (dateString !== controller.orderDate) {
      handleSetController.setMainFilter({ ...queried, orderDate: dateString });
      setSearchParams({ ...searchParams, date: dateString });
    }
  }, [currentDate]);

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller,
    handleSetController,
    orderEvents
  ] = useDataBrowser({
    sourceApi: customeOrderApi.getCustomeOrders,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: 'W'
    }
    // onPostRefresh: handleClearSelected
  });

  const { renderStatus } = orderEvents;

  const [selectedDocuments, handleSelect, handleSelectAll] = useSelection(returnsState.documents);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>{app.title('Pedidos digitados')} </title>
      </Helmet>
      <CustomerOrdersStats refresh={returnsState.isLoading} />

      <Card>
        <CardHeader
          title="Pedidos digitados"
          subheader={
            <>
              <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Fecha"
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  value={currentDate}
                  // onChange={handleChangeDate}
                  onAccept={handleDateChanged}
                  slotProps={{ textField: { variant: 'outlined' } }}
                />
              </LocalizationProvider>
            </>
          }
          action={
            <>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.gCC_BROWSE_VISITA_DIA}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </AllowedGuard>
            </>
          }
        />
        <CardContent>
          <CustomerOrdersFilter
            disabled={returnsState.isLoading}
            filters={controller.filters}
            onFiltersApply={handleFiltersApply}
            onFiltersClear={handleFiltersClear}
            onQueryChange={handleQueryChange}
            onViewChange={handleViewChange}
            query={controller.query}
            selectedDocuments={selectedDocuments}
            view={controller.view}
            onRefresh={handleRefresh}
          />
          <Divider />
          <CustomerOrderssTable
            error={returnsState.error}
            documents={returnsState.documents}
            documentsCount={returnsState.documentsCount}
            isLoading={returnsState.isLoading}
            onPageChange={handlePageChange}
            onSelect={handleSelect}
            onSelectAll={handleSelectAll}
            onSortChange={handleSortChange}
            page={controller.page + 1}
            selectedDocuments={selectedDocuments}
            sort={controller.sort}
            sortBy={controller.sortBy}
            onLoad={renderStatus}
          />
        </CardContent>
      </Card>
    </>
  );
};
