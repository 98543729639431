import Proptypes from 'prop-types';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { Pagination } from '../pagination';
import { ResourceError } from '../resource-error';
import { ResourceUnavailable } from '../resource-unavailable';
import { GoalRecordLink } from '../../components/goal-record-link';
import TableHeadEnhanced from '../common/table-head-enhanced';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: 'Cod. '
  },
  {
    id: 'name',
    label: 'Nombre'
  },
  {
    id: 'status',
    label: 'Activo'
  },
  {
    id: 'customer_channels_id',
    label: 'Canales'
  },
  {
    id: 'zones',
    label: 'Zonas'
  },
  {
    id: 'prices',
    label: 'Precios'
  },
  {
    id: 'routes',
    label: 'Rutas'
  },
  {
    id: 'sellers',
    label: 'Vendedores'
  },
  {
    id: 'providers',
    label: 'Proveedores'
  },
  {
    id: 'category',
    label: 'Categorias'
  },
  {
    id: 'brands',
    label: 'Marcas'
  },
  {
    id: 'products',
    label: 'Productos'
  },
  {
    id: 'wallet',
    label: 'Cartera'
  }
];

export const SalesTeamsTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    sort = 'desc',
    sortBy = 'issueDate'
  } = props;

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  const detailRoute = `/commercial/sales-teams/`;

  return (
    <Card variant="outlined">
      <CardContent>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: '60vh' }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              aria-labelledby="Tabla de equipos de ventas"
              sx={{ minWidth: 1000, marginTop: 1, marginLeft: 3 }}
            >
              <TableHeadEnhanced
                keyPrefix="vehicle-meter-column"
                headCells={columns}
                onRequestSort={onSortChange}
                order={sort}
                orderBy={sortBy}
              />

              <TableBody>
                {documents.map((document, index) => (
                  <TableRow hover key={index}>
                    <TableCell
                      sx={{
                        minWidth: 150
                      }}
                    >
                      <GoalRecordLink to={`${detailRoute}${document.id}`} label={document.code} />
                    </TableCell>
                    <TableCell sx={{ minWidth: 200 }}>
                      <Typography color="textSecondary" variant="body2">
                        {document.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {document.status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {document?.indicators?.channel_count}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {document?.indicators?.zone_count}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {document?.indicators?.price_list_count}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {document?.indicators?.territory_count}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {document?.indicators?.salesman_count}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {document?.indicators?.salesman_count}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {document?.indicators?.supplier_count}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {document?.indicators?.brand_count}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {document?.indicators?.product_count}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {document?.indicators?.portfolio_count}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {displayLoading && (
          <Box sx={{ p: 2 }}>
            <Skeleton height={42} />
            <Skeleton height={42} />
            <Skeleton height={42} />
          </Box>
        )}
        {displayError && (
          <ResourceError
            error={error}
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        {displayUnavailable && (
          <ResourceUnavailable
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
      </CardContent>
      <CardActions>
        <Pagination
          disabled={isLoading}
          onPageChange={onPageChange}
          page={page}
          rowsCount={documentsCount}
        />
      </CardActions>
    </Card>
  );
};

SalesTeamsTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};
