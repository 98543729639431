import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { useData } from '../../hooks/use-data';
import { vehicleWorkOrdersApi } from '../../api/goal/vehicle/vehicle-work-orders-api';
import { WorkOrdersViews } from '../../api/goal/operations/work-orders/work-orders-definitions';

const VehicleWorkOrderContext = createContext();

const useVehicleWorkOrder = () => {
  return useContext(VehicleWorkOrderContext);
};

const VehicleWorkOrderProvider = ({ children }) => {
  const { workReference } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { search } = useLocation();
  const view = (new URLSearchParams(search).get('view') || WorkOrdersViews.ALL).toLowerCase();

  const [editMode, setEditMode] = useState(false);

  const [dataState, handleRefresh] = useData({
    sourceApi: vehicleWorkOrdersApi.getWorkOrder,
    apiParameter: { companyId: currentCompany, number: workReference, view: view },
    loadingMessage: 'Cargando información de la orden de trabajo...',
    mounted
  });

  const value = {
    view: view,
    loading: dataState.isLoading,
    vehicleWorkOrder: dataState.data ?? {},
    handleRefresh,
    error: dataState.displayError ? dataState.message : false,
    message: dataState.message,
    editMode,
    setEditMode
  };

  return (
    <VehicleWorkOrderContext.Provider value={value}>{children}</VehicleWorkOrderContext.Provider>
  );
};

VehicleWorkOrderProvider.propTypes = { children: PropTypes.node.isRequired };

export { VehicleWorkOrderProvider, useVehicleWorkOrder };
