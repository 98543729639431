import { Helmet } from 'react-helmet-async';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
// import { Status } from '../../components/status';
import { useMounted } from '../../hooks/use-mounted';
import { useApp } from '../../hooks/use-app';
import { ReturnsProductsTable } from '../../components/document/returns/returns-products-table';
import { CustomerReturnsProductsFilter } from '../../components/document/returns/returns-products-filter';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import RefreshIcon from '@mui/icons-material/Refresh';
import GoalButton from '../../components/goal-button';
import { goalCustomerReturnsApi } from '../../api/goal/customer-return-api';
import { useDataBrowser } from '../../hooks/use-data-browser';

export const CustomerReturnsProducts = () => {
  const mounted = useMounted();
  const { title } = useApp();

  const [
    productState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller,
    ,
    events
  ] = useDataBrowser({
    sourceApi: goalCustomerReturnsApi.getReturnsProducts,
    loadingMessage: 'Buscando productos para devolución...',
    mounted
  });
  const { renderStatus } = events;
  // const [selectedProducts, handleSelect, handleSelectAll] = useSelection(
  //   productState.data?.products
  // );

  const renderContent = () => (
    <Card>
      <CardHeader
        title="Productos para devolución"
        action={
          <AllowedGuard permission={GadminActions.G2_CD_LIST}>
            <GoalButton startIcon={<RefreshIcon />} onClick={handleRefresh}>
              Actualizar
            </GoalButton>
          </AllowedGuard>
        }
      />
      <CardContent>
        <Grid container>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          />
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <CustomerReturnsProductsFilter
                disabled={productState.isLoading}
                filters={controller.filters}
                onFiltersApply={handleFiltersApply}
                onFiltersClear={handleFiltersClear}
                onQueryChange={handleQueryChange}
                onViewChange={handleViewChange}
                query={controller.query}
                // selectedProducts={selectedProducts}
                view={controller.view}
              />
              <ReturnsProductsTable
                products={productState.documents}
                productsCount={productState.documentsCount}
                onPageChange={handlePageChange}
                // onSelect={handleSelect}
                // onSelectAll={handleSelectAll}
                onSortChange={handleSortChange}
                page={controller.page + 1}
                // selectedProducts={selectedProducts}
                sort={controller.sort}
                sortBy={controller.sortBy}
                documentState={productState}
                onLoading={renderStatus}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <>
      <Helmet>
        <title> {title('Productos para devolución')}</title>
      </Helmet>
      {/* <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      > */}
      {renderContent()}
      {/* </Box> */}
    </>
  );
};
