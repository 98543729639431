import { useState } from 'react';
import Proptypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import { Trash as TrashIcon } from '../../../icons/trash';
import { ConfirmationButton } from '../../common/confirmation-button';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: '#'
  },
  {
    id: 'title',
    label: 'Titulo'
  },
  {
    id: 'type_question',
    label: 'Tipo de Pregunta'
  },
  {
    id: 'question',
    label: 'Pregunta'
  },
  {
    id: 'options',
    label: 'Opciones'
  },
  {
    id: 'correct',
    label: 'Respuesta'
  },
  {
    id: 'score',
    label: 'Puntaje'
  },
  // {
  //   id: 'apply_to',
  //   label: 'Aplicado'
  // },
  {
    id: 'action',
    label: ''
  }
];

export const SurveySurveyDetailTable = (props) => {
  const {
    error,
    documents = [],
    isLoading,
    onSelect,
    onSelectAll,
    selectedDocuments = [],
    // idDetail,
    // handleRefresh,
    onDelete,
    canDelete
  } = props;

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  const handleClickDelete = async (idDelete) => {
    onDelete?.([idDelete]);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <TableContainer sx={{ maxHeight: '60vh' }}>
        <Table sx={{ minWidth: 1000 }}>
          {/* <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={documents.length > 0 && selectedDocuments.length === documents.length}
                  disabled={isLoading}
                  indeterminate={
                    selectedDocuments.length > 0 && selectedDocuments.length < documents.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel disabled={isLoading}>{column.label}</TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead> */}
          <TableBody>
            {documents.map((document, index) => (
              <TableRow
                hover
                key={index}
                selected={
                  !!selectedDocuments.find((selectedCustomer) => selectedCustomer === document.id)
                }
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      !!selectedDocuments.find(
                        (selectedCustomer) => selectedCustomer === document.id
                      )
                    }
                    onChange={(event) => onSelect(event, document.id)}
                  />
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {index + 1}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.title}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.type_question}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.question}
                  </Typography>
                </TableCell>
                <TableCell>
                  {document.type_question === 'multiselect' ||
                  document.type_question === 'select' ||
                  document.type_question === 'range' ? (
                    document.options.map((option, index) => (
                      <Typography key={index} color="textSecondary" variant="body2">
                        {option}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      ---
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {document.type_question === 'multiselect' ? (
                    document.correct.map((item, index) => (
                      <Typography key={index} color="textSecondary" variant="body2">
                        {item}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      {document.correct}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.score}
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  {document.apply_to ? (
                    <>
                      {document.apply_to.slice(0, 5).map((item, index) => (
                        <Typography key={index} color="textSecondary" variant="body2">
                          {item?.name}
                        </Typography>
                      ))}
                      {document.apply_to.length > 5 && (
                        <Typography color="textSecondary" variant="body2">
                          ...
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      --
                    </Typography>
                  )}
                </TableCell> */}
                <TableCell>
                  {canDelete && (
                    <ConfirmationButton
                      iconOnly={true}
                      icon={<TrashIcon />}
                      // label="Eliminar"
                      title="Eliminar pregunta"
                      // onConfirm={handleRefreshDates}
                      onConfirm={() => handleClickDelete(document.id)}
                      message="Se proce a eliminar la pregunta"
                      tooltip="Eliminar pregunta"
                      // loading={refreshingDates}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
    </Box>
  );
};

SurveySurveyDetailTable.propTypes = {
  documents: Proptypes.array,
  error: Proptypes.bool,
  isLoading: Proptypes.bool,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  selectedDocuments: Proptypes.array,
  onDelete: Proptypes.func,
  canDelete: Proptypes.bool
};
