import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMounted } from '../../../hooks/use-mounted';
import { DeleteButton, RefreshButton } from '../../../components/common/buttons';
import { useApp } from '../../../hooks/use-app';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { CustomerOrdersStats } from '../../../components/document/orders/customer-orders-stats';
import { dateFromString } from '../../../utils/formatTime';
import { useSearchParams } from 'react-router-dom';
import { GMGrid } from '../../../components/gm/grid/gm-grid';
import { goalOrdersApi } from '../../../api/goal/presale/goal-orders';
import { useGMDataSet } from '../../../components/gm/dataset/use-gm-dataset';
import { useGridApiRef } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { NumberColor } from '../../../components/number-color';
import { PieChart } from '@mui/x-charts/PieChart';

const columns = [
  {
    field: 'id',
    headerName: 'Número',
    type: 'int',
    link: {
      to: (item) => `/sales/orders/${item.id}`,
      tooltip: 'Ver detalle del pedido'
    }
  },
  {
    field: 'CodigoCliente',
    headerName: 'Código de Socio',
    link: {
      to: (item) => `/busines-partners/customers/${item.CodigoCliente}`,
      tooltip: 'Ver detalle del socio de negocio'
    }
  },
  { field: 'CodigoDomicilio', headerName: 'Código de Domicilio' },
  { field: 'cardName', headerName: 'Socio de Negocio', minWidth: 300 },
  { field: 'zonName', headerName: 'Zona' },
  { field: 'FechaPedido', headerName: 'Fecha de Pedido', type: 'date' },
  { field: 'statusName', headerName: 'Estado' },
  { field: 'TotalPedido', headerName: 'Importe', type: 'total' },
  { field: 'DiscountPercent', headerName: 'Dscto Fin', type: 'discount' },
  { field: 'TotalMigrado', headerName: 'Total Migrado', type: 'total' },
  { field: 'diferenceValue', headerName: 'Diferencia', type: 'total' },
  { field: 'slpName', headerName: 'Vendedor', minWidth: 200 },
  { field: 'mesaName', headerName: 'Mesa' },
  { field: 'PaymentName', headerName: 'Forma de Pago' },
  { field: 'hr', headerName: 'Hojade reparto' },
  { field: 'codjr', headerName: 'Jefe de reparto' },
  { field: 'cogprog', headerName: 'Despacho' },
  { field: 'CodigoAlmacen', headerName: 'Código de Almacen' },
  { field: 'whsName', headerName: 'Almacen', minWidth: 200 },
  { field: 'numLines', headerName: 'Líneas', type: 'int' }
];

const CustomerOrderSummary = ({ customerTotals, onUnselectCustomer }) => {
  return (
    <Card variant="outlined" sx={{ marginY: '1em' }}>
      <CardHeader title="Resumen de Pedidos seleccionados" />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cliente</TableCell>
                <TableCell>Importe</TableCell>
                <TableCell>Documentos</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerTotals.map(({ customerName, totalSelected, selectedCount }) => (
                <TableRow key={customerName}>
                  <TableCell>{customerName}</TableCell>
                  <TableCell>
                    <NumberColor value={totalSelected} />
                  </TableCell>
                  <TableCell>{selectedCount}</TableCell>
                  <TableCell>
                    <DeleteButton onClick={() => onUnselectCustomer(customerName)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

CustomerOrderSummary.propTypes = {
  customerTotals: PropTypes.arrayOf(
    PropTypes.shape({
      customerName: PropTypes.string.isRequired,
      totalSelected: PropTypes.number.isRequired,
      selectedCount: PropTypes.number.isRequired
    })
  ).isRequired,
  onUnselectCustomer: PropTypes.func.isRequired
};

export const CustomerOrders = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queried = {
    orderDate: searchParams.get('date'),
    type: searchParams.get('type'),
    subtype: searchParams.get('subtype')
  };
  const mounted = useMounted();
  const { currentCompany, title } = useApp();

  const [currentDate, setCurrentDate] = useState(dateFromString(queried.orderDate));
  const [customerTotals, setCustomerTotals] = useState([]);
  const [mesaTotals, setMesaTotals] = useState([]);

  const handleDateChanged = (newValue) => {
    setCurrentDate(newValue);
  };

  const apiRef = useGridApiRef();

  const { handleRefresh, ...returnsState } = useGMDataSet({
    sourceApi: goalOrdersApi.getCustomeOrdersList,
    apiParameters: { companyId: currentCompany, view: 'D' },
    loadingMessage: 'Cargando almacenes...',
    mounted,
    columns
  });

  const handleSelectionChange = () => {
    const selectedRows = apiRef.current.getSelectedRows();
    const totals = Array.from(selectedRows.values()).reduce((acc, row) => {
      const customerName = row.cardName;
      const mesaName = row.mesaName;
      if (!acc[customerName]) {
        acc[customerName] = { customerName, totalSelected: 0, selectedCount: 0 };
      }
      if (!acc[mesaName]) {
        acc[mesaName] = { mesaName, totalSelected: 0 };
      }
      acc[customerName].totalSelected += Number(row.TotalPedido || 0);
      acc[customerName].selectedCount += 1;
      acc[mesaName].totalSelected += Number(row.TotalPedido || 0);
      return acc;
    }, {});
    setCustomerTotals(Object.values(totals).filter((item) => item.customerName));
    setMesaTotals(Object.values(totals).filter((item) => item.mesaName));
  };

  useEffect(() => {
    apiRef.current.subscribeEvent('rowSelectionChange', handleSelectionChange);
  }, [apiRef]);

  const handleUnselectCustomer = (customerName) => {
    const selectedRows = apiRef.current.getSelectedRows();
    selectedRows.forEach((row, id) => {
      if (row.cardName === customerName) {
        apiRef.current.selectRow(id, false);
      }
    });
    setCustomerTotals((prevTotals) =>
      prevTotals.filter((total) => total.customerName !== customerName)
    );
  };

  const pieData = {
    labels: mesaTotals.map((item) => item.mesaName),

    series: mesaTotals.map((item) => item.totalSelected),
    colors: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40']
  };

  return (
    <>
      <Helmet>
        <title>{title('Pedidos digitados')} </title>
      </Helmet>
      <CustomerOrdersStats refresh={returnsState.isLoading} />
      {customerTotals.length > 0 && (
        <CustomerOrderSummary
          customerTotals={customerTotals}
          onUnselectCustomer={handleUnselectCustomer}
        />
      )}
      {mesaTotals.length > 0 && (
        <Card variant="outlined" sx={{ marginY: '1em' }}>
          <CardHeader title="Totales por Mesa" />
          <CardContent>
            {/* <PieChart width={400} height={400} colors={pieData.colors} series={pieData.series} /> */}
          </CardContent>
        </Card>
      )}
      <Card variant="outlined" sx={{ marginY: '1em' }}>
        <CardHeader
          title="Pedidos digitados"
          subheader={
            <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="Fecha"
                inputFormat="dd/MM/yyyy"
                maxDate={new Date()}
                value={currentDate}
                onAccept={handleDateChanged}
                slotProps={{ textField: { variant: 'outlined' } }}
              />
            </LocalizationProvider>
          }
          action={
            <Box sx={{ mb: 2 }}>
              <AllowedGuard permission={GadminActions.gCC_BROWSE_VISITA_DIA}>
                <RefreshButton onClick={handleRefresh} />
              </AllowedGuard>
            </Box>
          }
        />
        <CardContent>
          <GMGrid {...returnsState} apiRef={apiRef} />
        </CardContent>
      </Card>
    </>
  );
};
