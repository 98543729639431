import PropTypes from 'prop-types';
import { TablePagination } from '@mui/material';

const TablePaginationEnhanced = ({
  pagination: { total = 0, current_page = 1, per_page = 10 } = {},
  onPageChange = () => {},
  onRowsPerPageChange = () => {},
  sx = {}
}) => {
  const handleChangeRowsPerPage = (event) => {
    const newPerPage = parseInt(event.target.value, 10);
    onRowsPerPageChange(event, newPerPage, { total, current_page, per_page });
  };

  return (
    <TablePagination
      component="div"
      count={total}
      page={current_page - 1}
      onPageChange={(event, newPage) =>
        onPageChange(event, newPage + 1, { total, current_page, per_page })
      }
      rowsPerPage={per_page}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage="Filas por página:"
      showFirstButton
      showLastButton
      getItemAriaLabel={(type) => {
        switch (type) {
          case 'first':
            return 'Ir a la primera página';
          case 'last':
            return 'Ir a la última página';
          case 'next':
            return 'Ir a la siguiente página';
          case 'previous':
            return 'Ir a la página anterior';
          default:
            return '';
        }
      }}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}–${to} de ${count !== -1 ? count : `más de ${to}`}`
      }
      sx={sx}
    />
  );
};

TablePaginationEnhanced.propTypes = {
  pagination: PropTypes.shape({
    total: PropTypes.number,
    current_page: PropTypes.number,
    per_page: PropTypes.number
  }),
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  sx: PropTypes.object
};

export default TablePaginationEnhanced;
