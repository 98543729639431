import { useState } from 'react';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ApprovalIcon from '@mui/icons-material/Approval';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useGoalMenu } from '../../../hooks/use-goal-menu';
import { GoalMenu } from '../../common/goal-menu';
import { GoalMenuDialog } from '../../menu/goal-menu-dialog';
import { PDFViewerdDialog } from '../../common/pdf-viewer-dialog';
import { vehicleApi } from '../../../api/goal/operations/vehicles/vehicle';
import { useApp } from '../../../hooks/use-app';
import { ConfirmationMenuItem } from '../../common/confirmation-menu-item';
import { vehicleWorkOrdersApi } from '../../../api/goal/vehicle/vehicle-work-orders-api';
import { useDialog } from '../../../hooks/use-dialog';
import GoalCustomDialog from '../../goal-custom-dialog';

import { useGoalTheme } from '../../../hooks/use-goal-theme';

const ACTIONS = {
  is_draft: {
    label: 'Enviar para aprobación',
    icon: <SendIcon />,
    successMessage: 'Órden de trabajo enviada para aprobación correctamente',
    errorMessage: 'Hubo un error al enviar la órden de trabajo para aprobación'
  },
  is_pending_approval: {
    label: 'Aprobar',
    icon: <ApprovalIcon />,
    successMessage: 'Órden de trabajo aprobada correctamente',
    errorMessage: 'Hubo un error al aprobar la órden de trabajo'
  },
  is_approved: {
    label: 'Activar',
    icon: <PowerSettingsNewIcon />,
    successMessage: 'Órden de trabajo activada correctamente',
    errorMessage: 'Hubo un error al activar la órden de trabajo'
  },
  is_active: {
    label: 'Completar',
    icon: <CheckCircleIcon />,
    successMessage: 'Órden de trabajo completada correctamente',
    errorMessage: 'Hubo un error al completar la órden de trabajo'
  }
};

export const WorkOrdersMenu = ({ workOrder, handleRefresh }) => {
  const { currentCompany, showNotify, showError } = useApp();
  const { isMobile } = useGoalTheme();
  const [menuController] = useGoalMenu();

  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [titleContactsDialog, setTitleContactsDialog] = useState('');
  const [openContactsDialog, handleOpenContactsDialog, handleCloseContactsDialog] = useDialog();

  const handleConfirmChangeStatus = async (workOrder) => {
    const actionKey = Object.keys(ACTIONS).find((key) => workOrder.fleet_status?.[key] === 1);

    if (actionKey) {
      const action = ACTIONS[actionKey];
      switch (actionKey) {
        case 'is_draft':
          setSelectedWorkOrder(workOrder);
          setTitleContactsDialog(
            `${ACTIONS[actionKey].label} la órden de trabajo ${workOrder.work_order_number}`
          );
          handleOpenContactsDialog();
          break;
        case 'is_pending_approval': {
          const response = await vehicleWorkOrdersApi.approveWorkOrder({
            workOrderNumber: workOrder.work_order_number,
            values: { company_id: currentCompany }
          });
          if (response?.success) {
            showNotify(response.message || action.successMessage);
            handleRefresh();
          } else {
            showError(response.message || action.errorMessage);
          }
          break;
        }
        case 'is_approved': {
          const response = await vehicleWorkOrdersApi.activateWorkOrder({
            workOrderNumber: workOrder.work_order_number,
            values: { company_id: currentCompany }
          });
          if (response?.success) {
            showNotify(response.message || action.successMessage);
            handleRefresh();
          } else {
            showError(response.message || action.errorMessage);
          }
          break;
        }
        case 'is_active': {
          const response = await vehicleWorkOrdersApi.completeWorkOrder({
            workOrderNumber: workOrder.work_order_number,
            values: { company_id: currentCompany }
          });
          if (response?.success) {
            showNotify(response.message || action.successMessage);
            handleRefresh();
          } else {
            showError(response.message || action.errorMessage);
          }
          break;
        }
        default:
          break;
      }
    }
  };

  const handleChangeStatusWorkOrder = async (contact = []) => {
    let response = null;

    const actionKey = Object.keys(ACTIONS).find(
      (key) => selectedWorkOrder.fleet_status?.[key] === 1
    );
    const action = ACTIONS[actionKey];

    if (actionKey) {
      switch (actionKey) {
        case 'is_draft':
          response = await vehicleWorkOrdersApi.sendWorkOrderToApproval({
            workOrderNumber: selectedWorkOrder.work_order_number,
            values: { company_id: currentCompany, user_id: contact.id }
          });
          break;
        default:
          break;
      }
    }

    if (response?.success) {
      showNotify(response.message || action.successMessage);
      return true;
    } else {
      showError(response.message || action.errorMessage);
      return false;
    }
  };

  return (
    <>
      <GoalMenu
        startIcon={<MoreVertIcon fontSize="medium" />}
        iconOnly={true}
        controller={menuController}
      >
        {Object.keys(ACTIONS).map((actionKey) => {
          const action = ACTIONS[actionKey];
          if (workOrder?.fleet_status?.[actionKey]) {
            return (
              <ConfirmationMenuItem
                key={actionKey}
                label={action.label}
                confirmationMessage={`Vas a ${action.label} la órden de trabajo ${workOrder.work_order_number}.`}
                startIcon={action.icon}
                onConfirm={() => handleConfirmChangeStatus(workOrder)}
              />
            );
          }
          return null;
        })}

        <ConfirmationMenuItem
          label="Enviar correo"
          confirmationMessage={'¿Estás seguro de enviar el correo?'}
          helpText={'Enviar correo con la orden de trabajo'}
          startIcon={<EmailIcon />}
          onConfirm={async () => {
            const response = await vehicleWorkOrdersApi.sendEmail({
              workOrderNumber: workOrder.work_order_number,
              values: { company_id: currentCompany }
            });
            if (response.success) {
              showNotify(response.message || 'Correo enviado correctamente');
            } else {
              showError(response.message || 'Hubo un error al enviar el correo');
            }
          }}
        />
        <GoalMenuDialog
          onClose={menuController.handleClose}
          startIcon={<PictureAsPdf />}
          Component={PDFViewerdDialog}
          componentProps={{
            apiHandle: vehicleApi.getWorkOrderPdf,
            apiParams: { id: workOrder.work_order_number, companyId: currentCompany }
          }}
          tooltip={'Ver PDF'}
        >
          PDF
        </GoalMenuDialog>
      </GoalMenu>

      {openContactsDialog && (
        <GoalCustomDialog
          open={openContactsDialog}
          handleClose={handleCloseContactsDialog}
          maxWidth="md"
          title={titleContactsDialog}
          fullScreen={isMobile}
          withoutFooter
        >
          {/* <WorkOrderContactsNotification
            companyId={currentCompany}
            onClose={handleCloseContactsDialog}
            onConfirm={handleChangeStatusWorkOrder}
            handleRefresh={handleRefresh}

          /> */}
        </GoalCustomDialog>
      )}
    </>
  );
};

WorkOrdersMenu.propTypes = {
  workOrder: PropTypes.object.isRequired,
  handleRefresh: PropTypes.func
};
