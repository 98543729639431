import { useEffect, useState } from 'react';
import { useApp } from './use-app';

export const useFormChecker = ({ formik, setSaving }) => {
  const { showWarning } = useApp();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const errors = Object.values(formik.errors).join('');
    setErrors(errors);
  }, [formik.errors]);
  const checkForm = () => {
    if (errors) {
      showWarning(`Hay errores en el formulario: ${errors}`);
      setSaving?.(false);
      return false;
    }
    return true;
  };
  return [checkForm];
};
