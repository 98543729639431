import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Typography } from '@mui/material';
import { useMounted } from '../../../hooks/use-mounted';
import { useApp } from '../../../hooks/use-app';
import { RenderIf } from '../../../components/render-if';
import { SurveySumaryDetail } from '../../../components/survey/survey/survey-summary-detail';
import { SurveySummaryDetailEdit } from '../../../components/survey/survey/survey-summary-detail-edit';
import GadminActions from '../../../contexts/gadmin-actions';
import { useData } from '../../../hooks/use-data';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { goalCustomeSurveyApi } from '../../../api/goal/survey/goal-customer-survey';
import LoadingContent from '../../../components/common/loading-content';
import { Refresh } from '../../../icons/refresh';
import { SurveyLines } from '../../../components/survey/survey/survey-lines';

export const SurveyDetailContainer = () => {
  const { id } = useParams();
  const { currentCompany, title } = useApp();

  const [editMode, setEditmode] = useState(false);

  const mounted = useMounted();

  const [detailSurveyState, handleRefreshDetail] = useData({
    sourceApi: goalCustomeSurveyApi.getSurveyDetail,
    apiParameter: { id, companyId: currentCompany },
    loadingMessage: 'Cargando informacion de la encuesta',
    mounted
  });

  if (detailSurveyState.isLoading && !detailSurveyState.data) {
    return (
      <LoadingContent
        loadingText={detailSurveyState.loadingMessage}
        error={detailSurveyState.error}
        loading={detailSurveyState.isLoading}
      />
    );
  }

  // const handleSetQuestions = (data) => {
  //   console.log('data', data);
  // };

  // const handleExitedDialog = () => {};

  // const handleRefreshFilter = () => {
  //   handleRefreshDetail(id);
  //   setSelectRender(selectRender + 1);
  // };

  // const handleUpdateScore = async (itemDelete) => {
  //   const filteredQuestions = detailSurveyState.data.selectQuestions.filter(
  //     (item) => item.id !== itemDelete.id
  //   );
  //   const addSelectQuestions = {
  //     ...detailSurveyState.data,
  //     point: detailSurveyState.data.point - itemDelete.score,
  //     selectQuestions: filteredQuestions
  //   };
  //   const response = await goalCustomeSurveyApi.updateSurveySurveyForm(
  //     detailSurveyState.data._id,
  //     addSelectQuestions
  //   );
  //   if (response.success) {
  //     showNotify(`${response?.message}. Cambio exitoso `);
  //     // setSelectData([]);
  //     handleRefreshFilter();
  //     handleClearSelectedDetail();
  //   } else {
  //     showError(`${response?.message}. No se proceso la acción `);
  //   }
  // };

  const handleRefresh = () => {
    handleRefreshDetail({
      id,
      companyId: currentCompany
    });
  };

  return (
    <>
      <Helmet>
        <title>{title('Detalle')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Encuesta
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<Refresh />}
                  onClick={handleRefreshDetail}
                  variant="contained"
                  sx={{
                    marginRight: 1
                  }}
                />
              </AllowedGuard>
            </Box>
          </Box>
          <RenderIf
            condition={editMode}
            no={<SurveySumaryDetail item={detailSurveyState.data} setEditMode={setEditmode} />}
          >
            <SurveySummaryDetailEdit
              initialValues={detailSurveyState.data}
              onClose={setEditmode}
              onRefresh={handleRefresh}
            />
          </RenderIf>
          <Card variant="outlined" sx={{ pb: 3, mb: 3, mt: 4 }}>
            {detailSurveyState.data?.selectQuestions && (
              <SurveyLines
                lines={detailSurveyState.data?.selectQuestions}
                onRefresh={handleRefresh}
                survey={detailSurveyState.data}
              />
            )}
          </Card>
        </Container>
      </Box>
    </>
  );
};
