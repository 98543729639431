import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardHeader, Divider } from '@mui/material';
import { useData } from '../../../../hooks/use-data';
import { useMounted } from '../../../../hooks/use-mounted';
import { goalInventoryApi } from '../../../../api/goal/inventory/goal-inventory';
import { RenderIf } from '../../../render-if';
import { ProductSumaryDetail } from './product-sumary-detail';
import LoadingContent from '../../../common/loading-content';
import GadminActions from '../../../../contexts/gadmin-actions';
import { ProductForm } from '../../../../containers/inventory/product-form';
import { useApp } from '../../../../hooks/use-app';
import EditButton from '../../../common/buttons/edit-button';
import { GoalRecordLink } from '../../../goal-record-link';
import RefreshButton from '../../../common/buttons/refresh-button';

const ProductResumenPanel2 = () => {
  const { itemcode } = useParams();
  const mounted = useMounted();
  const [editMode, setEditMode] = useState(false);

  const { currentCompany } = useApp();

  const [productState, handleRefresh] = useData({
    sourceApi: goalInventoryApi.getProductListId,
    apiParameter: { companyId: currentCompany, id: itemcode },
    loadingMessage: 'Cargando informacion del producto',
    mounted
  });
  const galleryPath = `/inventory/products/${itemcode}/gallery`;

  return (
    // <Box
    //   sx={{
    //     backgroundColor: 'background.default',
    //     flexGrow: 1
    //   }}
    // >
    //   <Container
    //     maxWidth="zl"
    //     sx={{
    //       display: 'flex',
    //       flexDirection: 'column',
    //       height: '100%'
    //     }}
    //   >
    <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
      <CardHeader
        title="Detalle del producto"
        action={
          <>
            {!editMode && (
              <>
                <GoalRecordLink
                  to={galleryPath}
                  label={'Galería'}
                  variant={'button'}
                  tooltip={'Galería de imágenes del producto'}
                />
                <EditButton
                  onClick={() => {
                    setEditMode(true);
                  }}
                  permission={GadminActions.G2_CONTESTS_UP}
                />

                <RefreshButton
                  onClick={() => handleRefresh({ companyId: currentCompany, id: itemcode })}
                />
              </>
            )}
          </>
        }
        sx={{ pb: 1 }}
      />

      <Divider />
      {!productState.isLoading && productState.success ? (
        <RenderIf
          condition={editMode}
          no={
            <ProductSumaryDetail
              itemcode={itemcode}
              item={productState?.data}
              setEditMode={setEditMode}
            />
          }
        >
          <ProductForm
            update
            onClose={setEditMode}
            initialValues={productState?.data}
            itemcode={itemcode}
            handleRefresh={() => handleRefresh({ companyId: currentCompany, id: itemcode })}
          />
        </RenderIf>
      ) : (
        <LoadingContent />
      )}
    </Card>
    //   </Container>
    // </Box>
  );
};

export { ProductResumenPanel2 };
