import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Box, Breadcrumbs, Button, DialogActions, Grid, InputLabel, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormSaver } from '../../hooks/use-form-saver';
import { goalMaintenanceInventoryApi } from '../../api/goal/maintenance/goal-maintenance';
import { useFormChecker } from '../../hooks/use-form-checker';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../icons/plus';
import { Pencil as PencilIcon } from '../../icons/pencil';
import { X as XIcon } from '../../icons/x';
import { InputField } from '../input-field';
import { useApp } from '../../hooks/use-app';
import { goalCustomeContestApi } from '../../api/goal/contest/goal-customer-contest';
import { PropertyList } from '../property-list';
import { CompanyInputDisabled } from '../common/company-input-disabled';
import { AutocompleteField } from '../autocomplete-field';
import { goalIncidentsApi } from '../../api/goal/incidents/goal-incidents';
import { AutocompleteMultiselectField } from '../autocomplete-multiselect-field';
import { documentStatusName, documentStatusViews } from '../../api/app-definitions';

export const IncidentsForm = ({
  update = false,
  title = '',
  initialValues = {},
  onClose,
  handleRefresh
}) => {
  const { currentCompany } = useApp();
  const [dialogSaving, setDialogSaving] = useState(false);
  const [incidentsType, setTypeIncidents] = useState([]);
  const [employeePositionList, setEmployeePositionList] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [items, setItems] = useState(['']);

  const RelationalIncidens = [
    { code: 'CL', name: 'Cliente' },
    { code: 'PR', name: 'Producto' },
    { code: 'CO', name: 'Comprobante' }
  ];

  const handleAfterSave = () => {
    // onClose(false);
    // handleRefresh();
  };

  const [, handleAdd] = useFormSaver({
    originalData: initialValues,
    apiUpdateHandler: goalMaintenanceInventoryApi.updateBrandForm,
    apiAddHandler: goalIncidentsApi.postIncidenceForm,
    id: '_id',
    handleAfterSave
  });

  const formik = useFormik({
    initialValues: {
      company_id: initialValues.company_id || currentCompany,
      name: initialValues.name || '',
      type_incidents: initialValues.type_incidents || '',
      type_incidents_select: initialValues.type_incidents_select || '',
      type_incidents_select_code: initialValues.type_incidents_select_code || '',
      employee_position: initialValues.employee_position || [],
      description: initialValues.description || '',
      relational: initialValues.relational || [],
      image_code: initialValues.image_code || null,
      status: initialValues.status || documentStatusViews.VIEW_DRAFT,
      statusName: initialValues.status_name || documentStatusName.VIEW_DRAFT_NAME,
      resolved: initialValues.resolved || false
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values, helpers) => {
      if (update) {
        console.log('update', values);
        onClose(false);
        handleRefresh();
      } else {
        const data = { ...values, relational: inputValues };
        console.log('create incidents', data);
        await handleAdd({ values: data, helpers });
      }
    }
  });

  useEffect(() => {
    getApiData();
  }, []);

  const getApiData = async () => {
    const [incidentsType, employeePositionList, referencesList, referencesTypeList] =
      await Promise.all([
        goalIncidentsApi.getTypeIncidents({ companyId: currentCompany }),
        goalCustomeContestApi.getEmployeePositions({ companyId: currentCompany }),
        goalIncidentsApi.getIncidentsReferences(),
        goalIncidentsApi.getIncidentsReferencesTypes({ companyId: currentCompany })
      ]);

    if (incidentsType.success && incidentsType.data.length !== 0) {
      setTypeIncidents(incidentsType.data);
    }
    if (employeePositionList.success && employeePositionList.data.length !== 0) {
      setEmployeePositionList(employeePositionList.data);
    }
    if (referencesList.success && referencesList.data.length !== 0) {
      console.log('references', referencesList.data);
    }
    if (referencesTypeList.success && referencesTypeList.data.length !== 0) {
      console.log('references type', referencesTypeList.data);
    }
  };

  const getNameFromCode = (code) => {
    const item = RelationalIncidens.find((item) => item.code === code);
    return item ? item.name : '---';
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const newInputValues = [...inputValues];
    newInputValues[index] = { ...newInputValues[index], [name]: value };
    setInputValues(newInputValues);
  };

  const handleAddItem = () => {
    setItems([...items, '']);
  };

  const [checkForm] = useFormChecker({ formik, setSaving: setDialogSaving });

  useEffect(() => {
    if (dialogSaving && !formik.isSubmitting) {
      checkForm();
      formik.submitForm();
      if (dialogSaving) setDialogSaving(false);
    }
  }, [dialogSaving]);

  useEffect(() => {
    setDialogSaving(formik.isSubmitting);
  }, [formik.isSubmitting]);

  const handleChange = (event, value) => {
    formik.setFieldValue('employee_position', value);
  };

  const renderSelectTypeIncidents = (type) => {
    switch (type) {
      case '':
        return false;
      case 'VEHICLE':
        return (
          <AutocompleteField
            label="Seleccionar vehículo"
            name="type_incidents_select"
            placeholder="Seleccione vehículo"
            fullWidth
            options={employeePositionList || [{ name: 'Cargando', code: 'Cargando' }]}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option) => option.name || ''}
            onChange={(e, value) => {
              formik.setFieldValue('type_incidents_select_code', value?.code);
              formik.setFieldValue('type_incidents_select', value);
            }}
            value={formik.values.type_incidents_select}
            sx={{ marginBottom: '1em' }}
            error={
              formik.touched.type_incidents_select_code &&
              Boolean(formik.errors.type_incidents_select_code)
            }
            helperText={
              formik.touched.type_incidents_select_code && formik.errors.type_incidents_select_code
            }
          />
        );
      case 'WAREHOUSE':
        return (
          <AutocompleteField
            label="Seleccionar almacén"
            name="type_incidents_select"
            placeholder="Seleccione almacén"
            fullWidth
            options={employeePositionList || [{ name: 'Cargando', code: 'Cargando' }]}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option) => option.name || ''}
            onChange={(e, value) => {
              formik.setFieldValue('type_incidents_select_code', value?.code);
              formik.setFieldValue('type_incidents_select', value);
            }}
            value={formik.values.type_incidents_select}
            sx={{ marginBottom: '1em' }}
            error={
              formik.touched.type_incidents_select_code &&
              Boolean(formik.errors.type_incidents_select_code)
            }
            helperText={
              formik.touched.type_incidents_select_code && formik.errors.type_incidents_select_code
            }
          />
        );
      case 'LOCAL':
        return (
          <AutocompleteField
            label="Seleccionar local"
            name="type_incidents_select"
            placeholder="Seleccione local"
            fullWidth
            options={employeePositionList || [{ name: 'Cargando', code: 'Cargando' }]}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option) => option.name || ''}
            onChange={(e, value) => {
              formik.setFieldValue('type_incidents_select_code', value?.code);
              formik.setFieldValue('type_incidents_select', value);
            }}
            value={formik.values.type_incidents_select}
            sx={{ marginBottom: '1em' }}
            error={
              formik.touched.type_incidents_select_code &&
              Boolean(formik.errors.type_incidents_select_code)
            }
            helperText={
              formik.touched.type_incidents_select_code && formik.errors.type_incidents_select_code
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 5 }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                marginBottom: 1,
                marginLeft: 0,
                color: 'black',
                fontWeight: 'bold'
              }}
            >
              {title}
            </InputLabel>
            <PropertyList>
              <InputField
                label="Título"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{ marginBottom: '1em' }}
              />
              <CompanyInputDisabled currentCompany={currentCompany} />
              <InputField
                select
                label="Tipo de incidencia"
                name="type_incidents"
                value={formik.values.type_incidents}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.type_incidents && Boolean(formik.errors.type_incidents)}
                helperText={formik.touched.type_incidents && formik.errors.type_incidents}
                sx={{ marginBottom: '1em' }}
              >
                {incidentsType.map((item) => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </InputField>
              {renderSelectTypeIncidents(formik.values.type_incidents)}
              {/* <AutocompleteField
                label="Seleccionar puestos"
                name="employee_position"
                placeholder="Seleccione puestos"
                fullWidth
                options={employeePositionList || [{ name: 'Cargando', code: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('employee_position_code', value?.code);
                  formik.setFieldValue('employee_position', value);
                }}
                value={formik.values.employee_position}
                sx={{ marginBottom: '1em' }}
                error={
                  formik.touched.employee_position_code &&
                  Boolean(formik.errors.employee_position_code)
                }
                helperText={
                  formik.touched.employee_position_code && formik.errors.employee_position_code
                }
              /> */}
              <AutocompleteMultiselectField
                handleChange={handleChange}
                options={employeePositionList}
                label="Seleccionar puestos"
                placeholder="Seleccionar puestos"
                sx={{ marginBottom: '1em' }}
                selectedOptions={formik.values.employee_position}
                bgColor="#98E091"
                colorText="#13A405"
                error={formik.touched.employee_position && Boolean(formik.errors.employee_position)}
                helperText={formik.touched.employee_position && formik.errors.employee_position}
              />
              <InputField
                label="Descripción"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                multiline
                rows={4}
                fullWidth
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                sx={{ marginBottom: '1em' }}
              />
            </PropertyList>
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginTop: 4 }}>
            <PropertyList>
              <Grid container spacing={2}>
                {items.map((item, index) => (
                  <Grid item xs={12} md={10} key={index}>
                    <InputField
                      select
                      label="Relacionar con"
                      name={`relational_${index}`}
                      value={inputValues[index] ? inputValues[index][`relational_${index}`] : ''}
                      onChange={(e) => handleInputChange(e, index)}
                      fullWidth
                      sx={{ marginBottom: '1em' }}
                    >
                      {RelationalIncidens.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </InputField>
                  </Grid>
                ))}
                <Grid item xs={12} md={2}>
                  <Button
                    color="primary"
                    size="medium"
                    startIcon={<PlusIcon />}
                    variant="contained"
                    sx={{ marginTop: '2em' }}
                    onClick={handleAddItem}
                    disabled={items.length > 2}
                  />
                </Grid>
              </Grid>
              <Breadcrumbs sx={{ marginTop: 4 }} separator="›" aria-label="breadcrumbs">
                {inputValues.map((item, index) => {
                  const relationalValue = item[`relational_${index}`];

                  return (
                    <Box key={relationalValue} color="primary">
                      {getNameFromCode(relationalValue)}
                    </Box>
                  );
                })}
              </Breadcrumbs>
            </PropertyList>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={update ? <PencilIcon /> : <PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                {update && (
                  <Button
                    color="secondary"
                    size="large"
                    startIcon={<XIcon />}
                    onClick={() => onClose(false)}
                    sx={{
                      marginLeft: 1
                    }}
                  >
                    Cerrar
                  </Button>
                )}
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

IncidentsForm.propTypes = {
  initialValues: Proptypes.object,
  update: Proptypes.bool,
  onClose: Proptypes.func,
  title: Proptypes.string,
  handleRefresh: Proptypes.func
};
