import Proptypes from 'prop-types';
import GoalRecordLink from './goal-record-link';

export const LinkIf = (props) => {
  const { children, condition, ...other } = props;
  if (!condition) return other.label || children || '-';
  return <GoalRecordLink {...other}>{children}</GoalRecordLink>;
};

LinkIf.propTypes = {
  children: Proptypes.node,
  condition: Proptypes.oneOfType([Proptypes.bool, Proptypes.string, Proptypes.number])
};
