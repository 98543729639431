import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputField } from '../input-field';
import { Plus as PlusIcon } from '../../icons/plus';
import { Trash as TrashIcon } from '../../icons/trash';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { compareRecordChanged } from '../../hooks/use-item-change';
import { documentApi } from '../../api/document';
import { useApp } from '../../hooks/use-app';
import { LoadingButton } from '@mui/lab';

export const DocumentLogAdd = (props) => {
  const { open, onClose, onExited, afterSubmit, document, ...other } = props;

  const [documentState, setDocumentState] = useState({ message: '' });
  const { showNotify } = useApp();

  const handleChanges = async (values) => {
    let saved = { success: false };
    try {
      const changes = compareRecordChanged(documentState, values);
      const response = await documentApi.addDocumentLog({
        objType: documentState.objType,
        docEntry: documentState.docEntry,
        ...changes
      });
      if (response.success) {
        showNotify(response.message);
      } else {
        showNotify(response.message, 'error');
      }

      saved = { ...saved, success: response.success };
    } catch (error) {
      saved = { ...saved, error, success: false };
    }
    return saved;
  };
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    if (document)
      setDocumentState({
        ...documentState,
        objType: document.objType,
        docEntry: document.docEntry
      });
  }, [document]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      objType: documentState?.objType || -1,
      docEntry: documentState?.docEntry || -1,
      message: documentState?.message || '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().min(10).max(255).required('Mensage es requerido')
    }),
    onSubmit: async (values, helpers) => {
      try {
        const saved = await handleChanges(values);
        if (!saved.success) throw new Error(saved.error);
        showNotify(`Cuceso guardado`);
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        afterSubmit();
        onClose();
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
          formik.resetForm();
        }
      }}
      {...other}
    >
      <DialogTitle id="scroll-dialog-title">
        <CardHeader title="Anotar suceso al documento" />
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <Typography variant="body2" color="textSecondary" component="p">
            Puedes anotar un suceso al documento, para que el usuario que lo revise pueda verlo.
          </Typography>
        </DialogContentText>
        <Divider />
        <form
          onSubmit={async (values, helpers) => {
            await formik.handleSubmit(values, helpers);
          }}
        >
          <Grid container>
            <Grid item md={12} xs={12} sx={{ border: 0 }}>
              <InputField
                error={Boolean(formik.touched.message && formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
                name="message"
                label="Nuevo Suceso"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.message}
                multiline
                fullWidth
                rows={4}
                sx={{ m: 1 }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              {formik.errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <AllowedGuard permission={GadminActions.gCC_DOC_SUCCESS_ADD}>
          <LoadingButton
            color="primary"
            size="large"
            startIcon={<PlusIcon />}
            variant="contained"
            type="submit"
            loading={formik.isSubmitting}
            onClick={formik.handleSubmit}
          >
            Guardar
          </LoadingButton>
        </AllowedGuard>
        <Button
          color="secondary"
          size="large"
          startIcon={<TrashIcon />}
          onClick={async () => {
            onClose();
          }}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DocumentLogAdd.propTypes = {
  document: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired
  // setEditMode: PropTypes.func.isRequired
};
