import { dataLoader } from '../../data-loader';

class GoalOrdersApi {
  async getCustomeOrdersList(options) {
    const response = await dataLoader.goalGet({
      action: '/pre-sale/orders',
      params: options
    });
    return response;
  }

  async enqueueOrder({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/pre-sale/orders/${id}/enqueue`,
      params: { companyId }
    });
    return response;
  }

  // get details of a specific order
  async getOrderDetail({ id, companyId }) {
    const response = await dataLoader.goalGet({
      action: `/pre-sale/orders/${id}?companyId=${companyId}`
    });
    return response;
  }

  // get promotion details of a specific order
  async getPromotionsDetail({ id, companyId }) {
    const response = await dataLoader.goalGet({
      action: `/pre-sale/orders/${id}/promotions?companyId=${companyId}`
    });
    return response;
  }
}

export const goalOrdersApi = new GoalOrdersApi();
