import { useEffect } from 'react';

import moment from 'moment';
// import { format } from 'date-fns';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  CardContent,
  TableContainer
} from '@mui/material';

import LoadingContent from '../../common/loading-content';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';

import { NumberColor } from '../../number-color';
import { deliveryApi } from '../../../api/delivery';
import { HrMenu } from './hr-menu';
import TableHeadEnhanced from '../../common/table-head-enhanced';
import { useMounted } from '../../../hooks/use-mounted';
import { useData } from '../../../hooks/use-data';
import { LinkIf } from '../../link-if';

const headCells = [
  { id: 'HR', label: 'HR' },
  { id: 'F Programación', label: 'F Programación' },
  { id: 'F Despacho', label: 'F Despacho' },
  { id: 'Zona', label: 'Zona' },
  { id: 'Placa', label: 'Placa' },
  { id: 'Pendientes', label: 'Pendientes' },
  { id: 'Facturados', label: 'Facturados' },
  { id: 'Documentos', label: 'Documentos' },
  { id: 'Estado', label: 'Estado' }
];

export const HRInvoicingTable = () => {
  const { mounted } = useMounted();

  const [hrsState, refreshHrs] = useData({
    sourceApi: deliveryApi.getHRInvoicing,
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });

  useEffect(() => {
    refreshHrs();
  }, []);

  if (hrsState.isLoading) {
    return <LoadingContent loadingText="Cargando hojas de reparto" loading={hrsState.isLoading} />;
  }
  return (
    <>
      <Card variant="outlined">
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <CardHeader title="Hojas de reparto facturando" />
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <AllowedGuard permission={GadminActions.gCC_GPROC_MNGMT}>
                <Box sx={{ mb: 2 }}>
                  <Button
                    color="primary"
                    size="medium"
                    startIcon={<RefreshIcon />}
                    onClick={refreshHrs}
                    variant="contained"
                  />
                </Box>
              </AllowedGuard>
            </Box>
          </Box>
        </Box>

        <Divider />
        <Card variant="outlined">
          <CardContent>
            <TableContainer sx={{ maxHeight: '60vh' }}>
              <Table sx={{ minWidth: 500 }}>
                <TableHeadEnhanced
                  keyPrefix="vehicle-meter-column"
                  headCells={headCells}
                  // onRequestSort={handleRequestSort}
                  // order={sort}
                  // orderBy={sortBy}
                />

                <TableBody>
                  {hrsState.data?.map((item) => (
                    <TableRow key={item.HR}>
                      {/* <TableCell>{numeral(item.id).format('0')}</TableCell> */}
                      <TableCell>{item.HR}</TableCell>
                      <TableCell>{moment(item.fecprog).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{moment(item.fecdsp).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{item.sede}</TableCell>
                      <TableCell>{item.plaveh}</TableCell>
                      <TableCell>
                        <LinkIf
                          to={`/${'delivery/invoicing/pendings/'}${item.HR}`}
                          condition={item.pendings > 0}
                        >
                          <NumberColor value={item.pendings} minColor="success.main" format="0" />
                        </LinkIf>
                      </TableCell>

                      <TableCell>
                        <LinkIf
                          to={`/${'delivery/invoicing/invoiced/'}${item.HR}`}
                          condition={item.invoiced > 0}
                        >
                          <NumberColor value={item.invoiced} minColor="success.main" format="0" />
                        </LinkIf>
                      </TableCell>
                      <TableCell>
                        <LinkIf
                          to={`/${'delivery/invoicing/all/'}${item.HR}`}
                          condition={item.documents > 0}
                        >
                          <NumberColor value={item.documents} minColor="success.main" format="0" />
                        </LinkIf>
                      </TableCell>
                      <TableCell>{item.Estado}</TableCell>
                      <TableCell>
                        <HrMenu id={item.HR} vehicle={item.plaveh} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Card>
    </>
  );
};
