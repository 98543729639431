import PropTypes from 'prop-types';

import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import LoadingContent from '../../common/loading-content';
import TableHeadEnhanced from '../../common/table-head-enhanced';
import { NumberColor } from '../../number-color';
import GoalRecordLink from '../../goal-record-link';

// id, promo_instance_id, promo_id, whscode, itemcode, onhand, iscommited, cstock, position, created_at, updated_at, ItemName, CardCode, CardName
const columns = [
  { id: 'id', label: '#' },
  { id: 'promo_instance_id', label: 'Transacción' },
  { id: 'promo_id', label: 'Promo' },
  { id: 'whscode', label: 'Almacén' },
  //   { id: 'CardCode', label: 'Card Code' },
  { id: 'CardName', label: 'Proveedor' },
  //   { id: 'itemcode', label: 'Producto' },
  { id: 'ItemName', label: 'Producto' },
  { id: 'onhand', label: 'Fisico' },
  { id: 'iscommited', label: 'Comprometido' },
  { id: 'min_stock', label: 'Mínimo' },
  { id: 'cstock', label: 'Disponible' },
  { id: 'position', label: 'Posicion' }
  //   { id: 'created_at', label: 'Created At' },
  //   { id: 'updated_at', label: 'Updated At' }
];

export const PromoAvalibleStockTable = (props) => {
  const { stock } = props;
  if (!stock || stock.length === 0)
    return <LoadingContent loadingText="No hay información del stock" />;
  return (
    <TableContainer sx={{ maxHeight: '60vh' }}>
      <Table
        stickyHeader
        aria-label="sticky table"
        aria-labelledby="Tabla de odómetros de un vehículo"
        size={'medium'}
      >
        <TableHeadEnhanced
          keyPrefix="promo-avalible-stock-column"
          headCells={columns}
          // onRequestSort={handleRequestSort}
          // order={sort}
          // orderBy={sortBy}
        />
        <TableBody>
          {stock?.map((item) => (
            <TableRow key={item.id}>
              <TableCell> {item.id}</TableCell>
              <TableCell> {item.promo_instance_id}</TableCell>
              <TableCell> {item.promo_id}</TableCell>
              <TableCell> {item.whscode}</TableCell>
              <TableCell>
                <GoalRecordLink
                  to={`/busines-partners/customers/${item.CardCode}`}
                  label={item.CardCode}
                />
                <Typography variant="body2" color="textSecondary">
                  {item.CardName}
                </Typography>
              </TableCell>
              <TableCell>
                <GoalRecordLink to={`/inventory/products/${item.itemcode}`} label={item.itemcode} />
                <Typography variant="body2" color="textSecondary">
                  {item.ItemName}
                </Typography>
              </TableCell>
              <TableCell>
                <NumberColor value={item.onhand} format="0,0" />
              </TableCell>
              <TableCell>
                <NumberColor value={item.iscommited} format="0,0" />
              </TableCell>
              <TableCell>
                <NumberColor value={item.min_stock} format="0,0" />
              </TableCell>
              <TableCell>
                <NumberColor value={item.cstock} format="0,0" />
              </TableCell>
              <TableCell>
                <NumberColor value={item.position} format="0,0" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PromoAvalibleStockTable.propTypes = {
  stock: PropTypes.array
};
