import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
import { ncServiceApi as invoiceApi } from '../../api/nc-service';
import { useMounted } from '../../hooks/use-mounted';
import { useSelection } from '../../hooks/use-selection';
import { Plus as PlusIcon } from '../../icons/plus';
import gtm from '../../lib/gtm';
import { useApp } from '../../hooks/use-app';
import { useDialog } from '../../hooks/use-dialog';
import { useDataBrowser } from '../../hooks/use-data-browser';
import { Refresh as RefreshIcon } from '../../icons/refresh';

import { DocumentAppendDialog } from '../../components/purchases/common/document-add';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { NCsServiceFilter } from '../../components/purchases/nc-service/ncs-service-filter';
import { NCsServiceTable } from '../../components/purchases/nc-service/ncs-service-table';
import { NCsServiceStats } from '../../components/purchases/nc-service/ncs-service-stats';
import { documnetStatus } from '../../api/purchase-definitions';

export const CreditNotesService = () => {
  const mounted = useMounted();
  const app = useApp();
  const [
    invoicesState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller
  ] = useDataBrowser({
    sourceApi: invoiceApi.getInvoices,
    loadingMessage: 'Cargando documentos',
    mounted,
    defaults: {
      view: documnetStatus.Borrador
    }
  });

  const [selectedInvoices, handleSelect, handleSelectAll] = useSelection(invoicesState.documents);

  const [appendDialogOpen, handleOpenAppendDialog, handleCloseAppendDialog] = useDialog();
  const handleExitedDialog = () => {
    // if (selectedVariant) {
    //   setSelectedVariant(null);
    // }
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);
  return (
    <>
      <Helmet>
        <title>{app.title('Notas de crédito - Servicio')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                NC - Servicio
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                color="primary"
                size="large"
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
                variant="contained"
              >
                Refrescar
              </Button>

              <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_CAB_UP}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  onClick={handleOpenAppendDialog}
                  variant="contained"
                >
                  Agregar
                </Button>
              </AllowedGuard>
            </Box>
          </Box>
          <NCsServiceStats />
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <NCsServiceFilter
              disabled={invoicesState.isLoading}
              filters={controller.filters}
              onFiltersApply={handleFiltersApply}
              onFiltersClear={handleFiltersClear}
              onQueryChange={handleQueryChange}
              onViewChange={handleViewChange}
              onRefresh={handleRefresh}
              query={controller.query}
              selectedInvoices={selectedInvoices}
              view={controller.view}
            />
            <Divider />
            <NCsServiceTable
              error={invoicesState.error}
              invoices={invoicesState.documents}
              invoicesCount={invoicesState.documentsCount}
              isLoading={invoicesState.isLoading}
              onPageChange={handlePageChange}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              selectedInvoices={selectedInvoices}
              sort={controller.sort}
              sortBy={controller.sortBy}
              onRefresh={handleRefresh}
            />
          </Card>
          <DocumentAppendDialog
            onClose={handleCloseAppendDialog}
            onExited={handleExitedDialog}
            afterSubmit={handleRefresh}
            uploaderApi={invoiceApi.uploadInvoice}
            open={appendDialogOpen}
          />
        </Container>
      </Box>
    </>
  );
};
