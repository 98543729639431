// class vehicle-work-orders-api

import { dataLoader } from '../../data-loader';

const endPointLocal = '/fleet/fleet-vehicle-work-orders';

class VehicleWorkOrdersApi {
  async getWorkOrders(options) {
    const { filters, sort, sortBy, page, query, view, reload, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `${endPointLocal}`,
      cacheItem: 'workOrders',
      apiParams: {
        companyId,
        type: 'list',
        view,
        payload: { filters, sort, sortBy, page, query, reload }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'string', name: 'work_order_number' },
        { type: 'string', name: 'status_name' },
        { type: 'string', name: 'fleet_vehicle_name' },
        { type: 'string', name: 'vendor_name' },
        { type: 'string', name: 'work_order_date' },
        { type: 'string', name: 'due_date' },
        { type: 'string', name: 'approved_at' },
        { type: 'string', name: 'currency_code' },
        { type: 'number', name: 'total_amount' }
      ],
      ...options
    });
  }

  // sync from fleet manager
  async syncFromFleet({ companyId }) {
    const response = await dataLoader.goalGet({
      action: `${endPointLocal}/sync-from-fleet?companyId=${companyId}`
    });
    return response;
  }

  async getWorkOrder({ number, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `${endPointLocal}/${number}?companyId=${companyId}`
    });
    return response;
  }

  async sendEmail({ workOrderNumber, values }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endPointLocal}/${workOrderNumber}/send-email`,
      changes: values
    });
    return response;
  }

  async getWorkOrderAttachments({ companyId, workOrderId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `${endPointLocal}/${workOrderId}/attachments?companyId=${companyId}`
    });
    return response;
  }

  async getWorkOrderServiceEntries({ number, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `${endPointLocal}/${number}/service-entries?companyId=${companyId}`
    });
    return response;
  }

  async sendWorkOrderToApproval({ workOrderNumber, values }) {
    const response = await dataLoader.goalPut({
      path: `${endPointLocal}/${workOrderNumber}/send-to-approval`,
      changes: values
    });
    return response;
  }

  async approveWorkOrder({ workOrderNumber, values }) {
    const response = await dataLoader.goalPut({
      path: `${endPointLocal}/${workOrderNumber}/approve`,
      changes: values
    });
    return response;
  }

  async activateWorkOrder({ workOrderNumber, values }) {
    const response = await dataLoader.goalPut({
      path: `${endPointLocal}/${workOrderNumber}/activate`,
      changes: values
    });
    return response;
  }

  async completeWorkOrder({ workOrderNumber, values }) {
    const response = await dataLoader.goalPut({
      path: `${endPointLocal}/${workOrderNumber}/complete`,
      changes: values
    });
    return response;
  }

  async closeWorkOrder({ workOrderNumber, values }) {
    const response = await dataLoader.goalPut({
      path: `${endPointLocal}/${workOrderNumber}/close`,
      changes: values
    });
    return response;
  }

  async getUsersEnabledToApprove({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `${endPointLocal}/users-enabled-to-approve?companyId=${companyId}`
    });
    return response;
  }
}

export const vehicleWorkOrdersApi = new VehicleWorkOrdersApi();
