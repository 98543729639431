import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Typography } from '@mui/material';

export const NumberColor = ({
  value = 0,
  minValue = 0,
  minColor = 'error.main',
  color = 'inherit',
  format = '0.00',
  upper = false,
  prefix,
  align = 'right',
  variant = 'body2',
  withoutSpace = false,
  ...rest
}) => {
  const numericValue = numeral(value || 0);
  const [currentColor, setCurrentColor] = useState(color);

  useEffect(() => {
    if (upper) {
      setCurrentColor(value > minValue ? minColor : color);
    } else {
      setCurrentColor(value <= minValue ? minColor : color);
    }
  }, [value]);

  return (
    <Typography
      sx={{
        color: currentColor,
        ml: 1.75
      }}
      variant={variant}
      align={align}
      {...rest}
    >
      {prefix}
      {withoutSpace ? '' : ' '}
      {numericValue.format(format)}
    </Typography>
  );
};

NumberColor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minValue: PropTypes.number,
  minColor: PropTypes.string,
  color: PropTypes.string,
  format: PropTypes.string,
  upper: PropTypes.bool,
  prefix: PropTypes.any,
  align: PropTypes.string,
  variant: PropTypes.string,
  withoutSpace: PropTypes.bool
};
