const ConstantYesNo = {
  YES: 'Y',
  NO: 'N'
};

const ConstantStatus = {
  ALL: 'ALL',
  ACTIVE: 'A',
  INACTIVE: 'I',
  DELETED: 'D'
};

export { ConstantYesNo, ConstantStatus };
