import PropTypes from 'prop-types';
import { Card, CardHeader, Divider, Button, Box, Grid } from '@mui/material';

import LoadingContent from '../../common/loading-content';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';
import { useDialog } from '../../../hooks/use-dialog';
import { DocumentLogAdd } from '../../common/document-log-add';

import { useMounted } from '../../../hooks/use-mounted';
import { DocumentLogTable } from './document-log-table';
import { useData } from '../../../hooks/use-data';
import { goalGprocApi } from '../../../api/goal/core/goal-gproc';
import { useApp } from '../../../hooks/use-app';
import { useEffect } from 'react';

export const DocumentLog = (props) => {
  const { document } = props;
  const mounted = useMounted();
  const { currentCompany } = useApp();
  const [appendDialogOpen, handleOpenAppendDialog, handleCloseAppendDialog] = useDialog();

  const handleExitedDialog = () => {
    // if (selectedVariant) {
    //   setSelectedVariant(null);
    // }
  };
  const refreshParameters = {
    objType: document.objType,
    docEntry: document.docEntry,
    companyId: currentCompany
  };

  const [documentLogState, handleRefresh] = useData({
    sourceApi: goalGprocApi.getDocumentLog,
    apiParameter: refreshParameters,
    loadingMessage: 'cargando log del documento',
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });

  useEffect(() => {
    handleRefresh(refreshParameters);
  }, [document]);

  if (documentLogState.isLoading || documentLogState.error) {
    return <LoadingContent loadingText={documentLogState.message} />;
  }
  return (
    <Card variant="outlined">
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title="Ultimos Sucesos del Comprobante" />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button
              color="primary"
              size="medium"
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
              variant="contained"
            />
            <AllowedGuard permission={GadminActions.gCC_DOC_SUCCESS_ADD}>
              <Button
                color="primary"
                size="medium"
                startIcon={<PlusIcon />}
                onClick={handleOpenAppendDialog}
                variant="contained"
              />
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Grid item xs={12} xl={12}>
        <DocumentLogTable
          setCurrentLine={null}
          // handleOpenEditDialog
          onRefresh={handleRefresh}
          documentState={documentLogState}
        />
      </Grid>
      <DocumentLogAdd
        onClose={handleCloseAppendDialog}
        onExited={handleExitedDialog}
        afterSubmit={handleRefresh}
        // uploaderApi={documentApi.uploadDocument}
        // onVariantsChange={handleVariantsChange}
        open={appendDialogOpen}
        // variant={selectedVariant}
        document={document}
      />
    </Card>
  );
};

DocumentLog.propTypes = {
  document: PropTypes.object.isRequired
};
