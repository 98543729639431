import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link
} from '@mui/material';
import { Base64 } from 'js-base64';
import XMLViewer from 'react-xml-viewer';
import { isXml } from '../../utils/mime';
import { RenderIf } from '../render-if';
import { useData } from '../../hooks/use-data';
import { useMounted } from '../../hooks/use-mounted';
import LoadingContent from './loading-content';

export const XMLViewerdDialog = (props) => {
  const { open = false, onClose, onExited, apiHandle, apiParams, ...other } = props;
  const mounted = useMounted();

  const [documentState, handleRefresh] = useData({
    sourceApi: apiHandle,
    apiParameter: apiParams,
    loadingMessage: 'Cargando XML',
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });
  useEffect(() => {
    if (open) handleRefresh();
  }, [apiParams, open]);
  const body = () => {
    if (documentState.isLoading || documentState.error) {
      return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
    }

    if (documentState.data) {
      const { fileType, content } = documentState.data || {};
      return !content || !isXml(fileType) ? (
        'Sin contenido'
      ) : (
        <XMLViewer xml={Base64.decode(content)} />
      );
    }

    return 'Sin contenido';
  };
  const link = () => {
    const { fileName, fileType, content } = documentState.data || {};
    return (
      <RenderIf condition={isXml(fileType)} no="No Encontrado">
        <Link
          href={`data:${fileType};base64,${content}`}
          download={fileName}
          variant="body2"
          alt="Download"
        >
          {fileName}
        </Link>
      </RenderIf>
    );
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
        }
      }}
      {...other}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Archivo {link()}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {body()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} variant="text">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

XMLViewerdDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  afterSubmit: PropTypes.func,
  onVariantsChange: PropTypes.func,
  variant: PropTypes.object,
  apiHandle: PropTypes.func,
  apiParams: PropTypes.object
};
