import { Helmet } from 'react-helmet-async';
import { Card, CardContent, CardHeader, Container, Grid } from '@mui/material';
import { useApp } from '../../hooks/use-app';
import { PendingsFacele } from '../../components/financial/fe-status/pendings-facele';
import { PendingsSAP } from '../../components/financial/fe-status/pendings-sap';
import { SentsFacele } from '../../components/financial/fe-status/sents-facele';
import { GREPendingsFacele } from '../../components/financial/gre-status/gre-pendigs-facele';

export const AccountingDashboard = () => {
  const app = useApp();
  return (
    <>
      <Helmet>
        <title>{app.title('Administración')} </title>
      </Helmet>

      <Container
        maxWidth="zl"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Dashboard Comprobantes" />
              <CardContent>
                <PendingsSAP />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <PendingsFacele />
          </Grid>
          <Grid item xs={12}>
            <GREPendingsFacele />
          </Grid>
          <Grid item xs={12}>
            <SentsFacele />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
