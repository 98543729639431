import PropTypes from 'prop-types';
import numeral from 'numeral';

import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { NumberColor } from '../../number-color';
import { Sumary } from '../../sumary';
import { TableSearchBar } from '../../TableSearchBar';
import { useState } from 'react';
import GoalRecordLink from '../../goal-record-link';

export const CustomerOrderLinesTable = (props) => {
  const { orderInfo } = props;

  const [lines, setLines] = useState(orderInfo.lines);

  return (
    <div>
      <Scrollbar>
        <TableSearchBar
          lines={orderInfo.lines}
          setLines={setLines}
          searchFields={['itemCode', 'itemName', 'unitCode', 'promoCodes']}
        />
        <Box
          sx={{
            maxHeight: 500,
            overflowY: 'auto'
          }}
        >
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell sx={{ minWidth: 300 }}>Producto</TableCell>
                <TableCell>UM</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Precio Base</TableCell>
                <TableCell>Dscto (%)</TableCell>
                <TableCell>Precio Final</TableCell>
                <TableCell>Sub Total</TableCell>
                <TableCell>IGV</TableCell>
                <TableCell>Total Linea</TableCell>
                <TableCell>Promo</TableCell>
                <TableCell>Unidades</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lines?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{numeral(item.id).format('0')}</TableCell>
                  <TableCell sx={{ minWidth: 300 }}>
                    <Box xl={2}>
                      <GoalRecordLink
                        to={`/inventory/products/${item.itemCode}`}
                        label={item.itemCode}
                      />
                      <Typography color="textSecondary" variant="body2">
                        {item.itemName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography color="inherit" variant="body2">
                        {item.unitCode}
                      </Typography>
                      <NumberColor value={item.factorUM} format="0" prefix="x" />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <NumberColor value={item.quantity} format="0.0" />
                  </TableCell>
                  <TableCell>
                    <NumberColor value={item.priceBefDi} format="0.00" />
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={item.promoCodes !== '' ? 'textSecondary' : 'textPrimary'}
                      variant="body3"
                    >
                      <NumberColor value={item.discPrcnt} format="0.00" upper minValue={0.01} />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <NumberColor value={item.price} format="0.0000" />
                  </TableCell>
                  <TableCell>
                    {numeral(item.lineTotal).format('0.00')}
                    {/* {numeral(item.lineTotal).format(`${item.currencySymbol}0,0.00`)} */}
                  </TableCell>
                  <TableCell sx={{ width: 150 }}>
                    <Box>
                      <Typography color="inherit" variant="body2">
                        {numeral(item.vatSum).format(`${item.currencySymbol}0,0.00`)}
                      </Typography>
                      {/* <Typography color="textSecondary" variant="body2"></Typography> */}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: 150 }}>
                    {numeral(item.gTotal).format(`${item.currencySymbol}0,0.00`)}
                  </TableCell>
                  <TableCell>{item.promoCodes}</TableCell>
                  <TableCell>
                    <NumberColor value={item.units} format="0" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Sumary values={orderInfo.lines} column="gTotal" />
      </Scrollbar>
    </div>
  );
};

CustomerOrderLinesTable.propTypes = {
  orderInfo: PropTypes.object.isRequired
};
