import { dataLoader } from '../../../data-loader';

class GoalPriceListsApi {
  async getList({ companyId }) {
    const response = await dataLoader.goalGet({
      action: `/inventory/price-lits`,
      params: { companyId }
    });
    return response;
  }

  // sync
  async sync({ companyId }) {
    const response = await dataLoader.goalPost({
      action: `/inventory/price-lits/sync?companyId=${companyId}`,
      params: { companyId }
    });
    return response;
  }
}

export const goalPriceListsApi = new GoalPriceListsApi();
