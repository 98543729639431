import Proptypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { Pagination } from '../../pagination';
import { Status } from '../../status';
import { NumberColor } from '../../number-color';
import { statusVariants } from '../../../api/customer-order-definitions';
import { RenderIf } from '../../render-if';
import TableHeadEnhanced from '../../common/table-head-enhanced';
import { GoalRecordLink } from '../../goal-record-link';
// import { CustomerReturnsMenu } from './customer-returns-menu';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: 'Número'
  },
  {
    id: 'cardName',
    label: 'Socio de Negocio'
  },
  {
    id: 'zonName',
    label: 'Zona'
  },
  {
    id: 'whsName',
    label: 'Almacen'
  },
  {
    id: 'FechaPedido',
    label: 'Fecha de Pedido'
  },
  {
    id: 'EstadoMigrado',
    label: 'Estado'
  },
  {
    id: 'TotalPedido',
    label: 'Importe'
  },
  {
    id: 'DiscountPercent',
    label: 'Dscto Fin'
  },
  {
    id: 'TotalMigrado',
    label: 'Total Migrado'
  },
  {
    id: 'diferenceValue',
    label: 'Diferencia'
  },
  {
    id: 'slpName',
    label: 'Vendedor'
  },
  {
    id: 'codjr',
    label: 'Jefe de reparto'
  },
  {
    id: 'cogprog',
    label: 'Despacho'
  },
  {
    id: 'numLines',
    label: 'Líneas'
  }
  //   {
  //     id: 'actions',
  //     label: ''
  //   },
];
/**
 * docTotal, registeredValue, programedValue, vatSum
 * Status
 * D Borrador
 * L Autorizado
 * W EN Reparto
 * F Facturando
 * Q Liquidado
 * C Cerrado
 */

export const CustomerOrderssTable = (props) => {
  const {
    documents = [],
    documentsCount = 0,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedDocuments = [],
    sort = 'desc',
    sortBy = 'issueDate',
    onLoad = () => {}
  } = props;

  const detailRoute = `/sales/orders/`;

  return (
    <Card variant="outlined">
      <CardContent>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: '60vh', minHeight: '40vh' }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              aria-labelledby="Tabla de pedidos de cliente"
              size={'medium'}
            >
              <TableHeadEnhanced
                keyPrefix="customer-orders-column"
                headCells={columns}
                onRequestSort={onSortChange}
                order={sort}
                orderBy={sortBy}
                onSelectAllClick={onSelectAll}
                numSelected={selectedDocuments.length}
                rowCount={documentsCount}
              />

              <TableBody>
                {documents.map((document) => {
                  const statusVariant = statusVariants.find(
                    (variant) => variant.value === document.EstadoMigrado
                  );
                  return (
                    <TableRow
                      hover
                      key={document.docEntry}
                      selected={
                        !!selectedDocuments.find(
                          (selectedCustomer) => selectedCustomer === document.docEntry
                        )
                      }
                    >
                      {onSelect && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              !!selectedDocuments.find(
                                (selectedCustomer) => selectedCustomer === document.id
                              )
                            }
                            onChange={(event) => onSelect(event, document.id)}
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        <GoalRecordLink to={`${detailRoute}${document.id}`} label={document.id} />
                      </TableCell>
                      <TableCell sx={{ minWidth: 280 }}>
                        <GoalRecordLink
                          to={`/busines-partners/customers/${document.CodigoCliente}`}
                          label={document.CodigoCliente}
                          tooltip={'Ver Socio de Negocio'}
                        />

                        <Typography color="textSecondary" variant="body2">
                          {document.cardName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="inherit" variant="body">
                          {`${document.CodigoZona}-${document.CodigoMesa}`}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {document.zonName}
                        </Typography>
                      </TableCell>
                      <TableCell>{document.CodigoAlmacen}</TableCell>
                      <TableCell>{moment(document.FechaPedido).format('YYYY-MM-DD')}</TableCell>
                      <TableCell>
                        <RenderIf condition={document.EstadoMigrado === 'D'}>
                          <Typography color="inherit" variant="body">
                            {`${document.PaymentName}-${document.FlagRet}`}
                          </Typography>
                        </RenderIf>
                        <Status color={statusVariant?.color} label={statusVariant?.label} />
                      </TableCell>
                      <TableCell>{numeral(document.TotalPedido).format(`${'S/'}0,0.00`)}</TableCell>
                      <TableCell>
                        {numeral(document.DiscountPercent).format(`${''}0,0.00`)}
                      </TableCell>
                      <TableCell>
                        {numeral(document.TotalMigrado).format(`${'S/'}0,0.00`)}
                      </TableCell>
                      <TableCell>
                        {numeral(document.diferenceValue).format(`${'S/'}0,0.00`)}
                      </TableCell>
                      <TableCell>
                        <Typography color="inherit" variant="body">
                          {document.CodigoVendedor}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {document.slpName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="inherit" variant="body">
                          {`${document.codJr || '-'}`}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {document.jrName || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="inherit" variant="body">
                          {`${document.codprog || '-'}`}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {document.plaVeh || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <NumberColor value={document.numLines} format="0" />
                      </TableCell>
                      {/* <TableCell>
                    <CustomerReturnsMenu document={document} />
                  </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {onLoad()}
        {/* {displayLoading && (
          <Box sx={{ p: 2 }}>
            <Skeleton height={42} />
            <Skeleton height={42} />
            <Skeleton height={42} />
          </Box>
        )}
        {displayError && (
          <ResourceError
            error={error}
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        {displayUnavailable && (
          <ResourceUnavailable
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )} */}
      </CardContent>
      <CardActions>
        <Pagination onPageChange={onPageChange} page={page} rowsCount={documentsCount} />
      </CardActions>
    </Card>
  );
};

CustomerOrderssTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedDocuments: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  onLoad: Proptypes.func
};
