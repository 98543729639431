import { appDefinitions } from '../../app-definitions';
import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalBaseBusinessPartnersApi {
  async getSummary({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/base/business-partners/${id}/summary?companyId=${companyId}`
    });
    return response;
  }

  async getSummaryId({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/base/business-partners/${id}?companyId=${companyId}`
    });
    return response;
  }

  async postCustomerForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: `/base/business-partners`,
      changes: values
    });
    return response;
  }

  async updateCustomerForm({ id, values }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/base/business-partners/${id}`,
      changes: values
    });
    return response;
  }

  async getBussinesPartnerTypes() {
    const response = await dataLoader.loadGoalGetData({
      action: '/base/business-partners-resources/business-partner-types'
    });
    return response;
  }

  async getBussinesPartnerResourcesGroup({ companyId, typeCode }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/base/business-partners-resources/business-partner-groups?companyId=${companyId}&typeCode=${typeCode}`
    });
    return response;
  }

  async getBussinesPartnerPersonTypes() {
    const response = await dataLoader.loadGoalGetData({
      action: '/base/business-partners-resources/business-partner-person-types'
    });
    return response;
  }

  async getBussinesPartnerShippingTypes(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/base/business-partners-resources/business-partner-shipping-types?companyId=${companyId}`
    });
    return response;
  }

  async getBussinesPartnerBusinessTypes() {
    const response = await dataLoader.loadGoalGetData({
      action: '/base/business-partners-resources/business-types'
    });
    return response;
  }

  async getBussinesPartnerSearchId(company, cardType, taxId, taxIdType) {
    const response = await dataLoader.loadGoalGetData({
      action: `/base/business-partners-resources/find-by-tax-id?companyId=${company}&cardType=${cardType}&taxId=${taxId}&taxIdType=${taxIdType}  `
    });
    return response;
  }

  async getBussinesPartnerDocumentTypes() {
    const response = await dataLoader.loadGoalGetData({
      action: '/base/business-partners-resources/business-partner-tax-id-types'
    });
    return response;
  }

  async getBussinesPartnerSearchRUC(ruc) {
    const response = await dataLoader.loadGoalGetData({
      action: `/base/business-partners-resources/get-by-ruc?ruc=${ruc}`
    });
    return response;
  }

  async getBussinesPartnerUbigeo(company) {
    const response = await dataLoader.loadGoalGetData({
      action: `/base/business-partners-resources/ubigeos?companyId=${company}`
    });
    return response;
  }

  async getBussinesPartnerEmployeePosition(company) {
    const response = await dataLoader.loadGoalGetData({
      action: `/base/business-partners-resources/employee-positions?companyId=${company}`
    });
    return response;
  }

  async getBussinesPartnerTPaymentTerms(company) {
    const response = await dataLoader.loadGoalGetData({
      action: `/base/business-partners-resources/payment-terms?companyId=${company}`
    });
    return response;
  }

  async getAddresses({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/base/business-partners/${id}/addresses?companyId=${companyId}`
    });
    return response;
  }

  async getAddressesList({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/base/business-partners-addresses/${id}?companyId=${companyId}`
    });
    return response;
  }

  async postAddressCustomer({ values, companyId }) {
    const response = await dataLoader.loadGoalPost({
      action: `/base/business-partners-addresses?companyId=${companyId}`,
      changes: values
    });
    return response;
  }

  async updateAddressCustomer({ id, values }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/base/business-partners-addresses/${id}`,
      changes: values
    });
    return response;
  }

  async getBussinesPartnerAutocomplete({ companyId, idsToExclude = '' }) {
    const itemsToExclude = idsToExclude != '' ? `&idsToExclude=${idsToExclude}` : ``;
    const response = await dataLoader.loadGoalGetData({
      action: `/base/business-partners/autocomplete?companyId=${companyId}${itemsToExclude}`
    });
    return response;
  }

  async getBussinesPartnersSearch({
    companyId,
    idsToExclude = '',
    search = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    view = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `base/business-partners/search`,
      params: { companyId, idsToExclude, search, page, per_page: perPage, view: view }
    });
    return response;
  }

  async getBussinesPartnersForSelect(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      companyId,
      searchText,
      idsToExclude = ''
    } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `base/business-partners/autocomplete`,
      cacheItem: 'business-partners',
      apiParams: {
        companyId,
        idsToExclude,
        type: 'list-business-partners',
        view,
        searchText,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'full_name' }
      ],
      ...options
    });
  }
}

export const goalBaseBusinessPartnersApi = new GoalBaseBusinessPartnersApi();
