import { useParams } from 'react-router-dom';
import { useApp } from '../../../hooks/use-app';
import { useMounted } from '../../../hooks/use-mounted';
import { Helmet } from 'react-helmet-async';
import { Box, Card } from '@mui/material';
import BulkQueuesTable from '../../../components/automations/bulks/bulk-queues-table';
import { useData } from '../../../hooks/use-data';
import LoadingContent from '../../../components/common/loading-content';
import { bulksApi } from '../../../api/managment/bulks';

const BulkQueues = () => {
  const { id } = useParams();
  const { title, currentCompany } = useApp();
  const mounted = useMounted();

  const [documentState] = useData({
    sourceApi: bulksApi.getQueuesOfOneBulk,
    apiParameter: { companyId: currentCompany, id },
    loadingMessage: `Cargando Queues del Bulk ${id}`,
    mounted
  });

  const renderContent = () => {
    if (documentState.isLoading || documentState.error || !documentState.data) {
      return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
    }

    const data = documentState.data || [];

    return (
      <Card variant="outlined">
        <BulkQueuesTable data={data} />
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title(`Queues del Bulk con id ${id}`)} </title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default' }}>{renderContent()}</Box>
    </>
  );
};

export default BulkQueues;
