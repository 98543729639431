import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Grid, ListItem, Switch } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { GoalListIcon } from '../../icon/goal-list-icon';
import SendIcon from '@mui/icons-material/Send';
import GoalButton from '../../goal-button';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

export const SurveyQuestionView = (props) => {
  const { item, setEditMode } = props;
  return (
    <Card>
      <CardHeader
        avatar={<GoalListIcon icon={<QuestionAnswerIcon />} />}
        title="Detalle de pregunta"
        action={
          setEditMode && (
            <GoalButton
              color="primary"
              onClick={() => setEditMode(item)}
              startIcon={<EditIcon />}
              iconOnly
              tooltip="Editar pregunta"
            />
          )
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <PropertyList>
              <PropertyListItem label="ID" value={item._id} />
              <PropertyListItem label="Pregunta" value={item.question} />
              <PropertyListItem label="Título" value={item.title} />
              <PropertyListItem label="Tipo de respuesta" value={item.type_question} />
              {item.options && (
                <>
                  <PropertyListItem label="Opciones" />
                  {item.options.map(
                    (option) =>
                      option && (
                        <ListItem key={option}>
                          <GoalListIcon icon={<SendIcon fontSize="small" />} />
                          {option}
                        </ListItem>
                      )
                  )}
                </>
              )}
              <PropertyListItem label="Respuesta Correscta">{item.correct}</PropertyListItem>
              <PropertyListItem label="Obligatorio" align={'right'}>
                <Switch
                  edge="end"
                  checked={item.required}
                  disabled
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-wifi'
                  }}
                />
              </PropertyListItem>
              <PropertyListItem label="Puntos" value={item.score} align={'right'} />
            </PropertyList>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PropertyList>
              <PropertyListItem label="Categoría">
                {item.category} - {item.categoryName}
              </PropertyListItem>
              <PropertyListItem label="Subcategoría">
                {item.subcategory} - {item.subcategoryName}
              </PropertyListItem>
              <PropertyListItem label="Requiere foto" align={'right'}>
                <Switch edge="end" checked={item.atach_image} disabled />
              </PropertyListItem>
            </PropertyList>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PropertyList>
              <PropertyListItem label="Aplicar para" />
              {item.apply_to?.map((apply) => (
                <ListItem key={apply.id}>
                  <GoalListIcon icon={<SendIcon fontSize="small" />} />
                  {apply.name}
                </ListItem>
              ))}
              <PropertyListItem label="Subcategoría" value={item.subcategory} />
            </PropertyList>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
SurveyQuestionView.propTypes = {
  item: PropTypes.object,
  setEditMode: PropTypes.func
};
