import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import { useApp } from '../../../hooks/use-app';
import GadminActions from '../../../contexts/gadmin-actions';
import { GoalTabs } from '../../../hooks/use-tabs';
import { useBulk } from '../../../contexts/automations/bulk-context';
import GoalButton from '../../../components/goal-button';
import LoadingContent from '../../../components/common/loading-content';
import { ArrowLeft } from '@mui/icons-material';
import { Refresh as RefreshIcon } from '../../../icons/refresh';

const defaultTabs = [
  {
    href: '',
    label: 'Detalle',
    permission: GadminActions.G2_BULK_LIST
  },
  {
    href: '/queues',
    label: 'Queues',
    permission: GadminActions.G2_QUEUE_LIST
  }
];

export const BulkTab = () => {
  const { appUrlBase, title } = useApp();
  const { loading, bulk, handleRefresh, error } = useBulk();

  const parentRoute = `${appUrlBase}/automations/bulks`;

  const renderContent = () => {
    if (loading || error) {
      return <LoadingContent loadingText="Cargando información de procesos masivos" />;
    }

    if (bulk && bulk.length === 0) {
      const message = `No se encontro el Bulk con id ${bulk._id}`;
      return <LoadingContent loadingText={message} />;
    }

    return (
      <Box sx={{ py: 2 }}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <GoalButton
              color="primary"
              size="large"
              label="Ver todos los Bulks"
              startIcon={<ArrowLeft />}
              component={RouterLink}
              to={`${parentRoute}`}
              variant="outlined"
            />
            <Box sx={{ flexGrow: 1 }} />
            <Typography variant="h5">Bulk id: {bulk._id}</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              size="large"
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
              variant="contained"
            >
              Refresh
            </Button>
          </Box>
        </Box>
        <GoalTabs basePath={`${parentRoute}/${bulk._id}`} tabs={defaultTabs} />
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title('Detalle del Bulk')} </title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default' }}>
        <Container maxWidth="zl" sx={{ height: '100%' }}>
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};
