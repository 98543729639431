import { Card, CardContent, CardHeader, Container, Grid, Paper, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useMounted } from '../../../hooks/use-mounted';
import { useParams } from 'react-router-dom';
import { useApp } from '../../../hooks/use-app';
import { useData } from '../../../hooks/use-data';
import { goalWarehousesApi } from '../../../api/goal/inventory/warehouses/warehouse';
import LoadingContent from '../../../components/common/loading-content';
import { RefreshButton } from '../../../components/common/buttons/refresh-button';

export const WarehouseResume = () => {
  const { id } = useParams();
  const { title, currentCompany } = useApp();
  const mounted = useMounted();

  const [warehouseState, handleRefresh] = useData({
    sourceApi: goalWarehousesApi.getDetail,
    apiParameter: { companyId: currentCompany, warehouseId: id },
    loadingMessage: 'Cargando almacen...',
    mounted
  });

  const renderContent = () => {
    const { data } = warehouseState;

    if (warehouseState.isLoading || warehouseState.error || !data) {
      return (
        <LoadingContent
          loadingText={warehouseState.message}
          error={warehouseState.error}
          state={warehouseState}
        />
      );
    }

    return (
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" color="textPrimary">
              {data.WhsCode}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {data.WhsName}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" color="textPrimary">
              {data?.Street}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {data?.ZipCode}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Detalle de almacén')} </title>
      </Helmet>
      <Paper>
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Card>
            <CardHeader
              title="Detalle de almacen"
              action={<RefreshButton onClick={handleRefresh} />}
            />
          </Card>

          {renderContent()}
        </Container>
      </Paper>
    </>
  );
};
