import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Card,
  DialogActions,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem
} from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../icons/plus';
import { InputField } from '../../components/input-field';
import { Search } from '../../icons/search';
import { PropertyList } from '../../components/property-list';
import { ConfirmationDialogCustomer } from './customer-create-modal';
import { useApp } from '../../hooks/use-app';
import { goalBaseBusinessPartnersApi } from '../../api/goal/base/goal-base-business-partners';
import { Eye } from '../../icons/eye';
import { ConfirmationDialog } from '../../components/confirmation-dialog';
import { InputLabelField, SelectInputField } from '../../components/select-field';
import { AutocompleteField } from '../../components/autocomplete-field';
import { ConstantStatus } from '../../api/application-enums';
import { useBolean } from '../../hooks/use-bolean';
import { useData } from '../../hooks/use-data';
import { useMounted } from '../../hooks/use-mounted';

export const BusinessPartnersForm = ({ initialValues = {} }) => {
  const { currentCompany, showNotify, showError, appUrlBase } = useApp();
  const mounted = useMounted();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const [typeOfPartner, setTypeOfPartner] = useState([]);
  const [typeOfPartnerState, setTypeOfPartnerState] = useState(0);
  const [businessPersonType, setBusinessPersonType] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [businessDocumentType, setBusinessDocumentType] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [listCustomer, setListCustomer] = useState([]);

  const [businesGroupTypeState] = useData({
    sourceApi: goalBaseBusinessPartnersApi.getBussinesPartnerResourcesGroup,
    apiParameter: { companyId: currentCompany, typeCode: '%' },
    mounted
  });

  const [flags, setFlags] = useState({
    isCustomer: false,
    isVendor: false,
    isEmployee: false,
    isFreelancer: false,
    isCompany: false,
    isPerson: false,
    isForeign: false,
    loaded: false,
    selectedPersonType: null,
    selectedDocumentType: null,
    isNew: false
  });

  const { isTrue } = useBolean();

  const handleSelectDocumentType = (value) => {
    const selected = businessDocumentType.find((item) => item.id === value) ?? {};
    setFlags((prevFlags) => ({
      ...prevFlags,
      selectedDocumentType: selected
    }));

    return selected;
  };

  const handleSelectPersonType = (value) => {
    const selected = businessPersonType.find((item) => item.code === value) ?? {};
    setFlags((prevFlags) => ({
      ...prevFlags,
      selectedPersonType: selected
    }));
    return selected;
  };

  useEffect(() => {
    if (flags.loaded) {
      const selectedType = flags.selectedPersonType;
      const selectedDocType = flags.selectedDocumentType;
      setFlags((prevFlags) => ({
        ...prevFlags,
        isCustomer: isTrue(initialValues.is_customer),
        isVendor: isTrue(initialValues.is_vendor),
        isEmployee: isTrue(initialValues.is_employee),
        isFreelancer: isTrue(initialValues.is_freelancer),
        isCompany: isTrue(selectedType?.isCompany),
        isPerson: isTrue(selectedType?.isPerson),
        isForeign: isTrue(selectedType?.isForeign),
        isRUC: isTrue(selectedDocType?.properties?.isRUC),
        isDNI: isTrue(selectedDocType?.properties?.isDNI),
        isNew: true
      }));
    }
  }, [flags.loaded]);

  useEffect(() => {
    getCustomerData();
  }, []);

  const getCustomerData = async () => {
    const [
      typeOfPartnerData,
      bussinessPersonTypeData,
      bussinessTypeData,
      bussinessDocumentTypeData,
      locationData
    ] = await Promise.all([
      goalBaseBusinessPartnersApi.getBussinesPartnerTypes(),
      goalBaseBusinessPartnersApi.getBussinesPartnerPersonTypes(),
      goalBaseBusinessPartnersApi.getBussinesPartnerBusinessTypes(),
      goalBaseBusinessPartnersApi.getBussinesPartnerDocumentTypes(),
      goalBaseBusinessPartnersApi.getBussinesPartnerUbigeo(currentCompany)
    ]);

    if (typeOfPartnerData.success && typeOfPartnerData.data.length !== 0) {
      setTypeOfPartner(typeOfPartnerData.data);
    }
    if (bussinessPersonTypeData.success && bussinessPersonTypeData.data.length !== 0) {
      setBusinessPersonType(bussinessPersonTypeData.data);
    }
    if (bussinessTypeData.success && bussinessTypeData.data.length !== 0) {
      setBusinessType(bussinessTypeData.data);
    }
    if (bussinessDocumentTypeData.success && bussinessDocumentTypeData.data.length !== 0) {
      setBusinessDocumentType(bussinessDocumentTypeData.data);
    }
    if (locationData.success && locationData.data.length !== 0) {
      setLocationData(locationData.data);
    }
    handleSelectPersonType(initialValues.person_type);
    handleSelectDocumentType(initialValues.tax_id_type_id);

    setFlags((prevFlags) => ({
      ...prevFlags,
      loaded: true
    }));
  };

  const formik = useFormik({
    initialValues: {
      company_id: initialValues.company_id || currentCompany ? currentCompany : '',
      // business_partner_group_id: initialValues.business_partner_group_id || null,
      name: initialValues.name || '',
      full_name: initialValues.full_name || '',
      person_type: initialValues.person_type || '',
      first_name: initialValues.first_name || '',
      last_name: initialValues.last_name || '',
      last_name2: initialValues.last_name2 || '',
      comercial_name: initialValues.comercial_name || '',
      vendor_code: initialValues.vendor_code || '',
      tax_id_type_id: initialValues.tax_id_type_id || '',
      tax_id: initialValues.tax_id || '',
      business_type_id: initialValues.business_type_id || '',
      // preferd_channel_id: initialValues.preferd_channel_id || '',
      email: initialValues.email || '',
      type_of_partnert_code: initialValues.type_of_partnert_code || '',
      ubigeo: initialValues.ubigeo || '',
      ubigeo_code: initialValues.ubigeo_code || '',
      ubigeo_id: initialValues.ubigeo_id || '',
      reference: initialValues.reference || '',
      address: initialValues.address || '',
      address_type: initialValues.address_type || 'B',
      // code: initialValues.code || '',
      district: initialValues.district || '',
      province: initialValues.province || '',
      department: initialValues.department || '',
      status: initialValues.status || ConstantStatus.ACTIVE
    },
    validationSchema: Yup.object().shape({
      // name: Yup.string().required('Este campo es obligatorio'),
      // business_partner_group_id: Yup.string().required('Este campo es obligatorio'),
      person_type: Yup.string().required('Este campo es obligatorio'),
      tax_id_type_id: Yup.string().required('Este campo es obligatorio'),
      tax_id: Yup.string().required('Este campo es obligatorio'),
      email: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      console.log(values);

      const newValues = { ...values };
      delete newValues.ubigeo;

      if (newValues.tax_id_type_id !== 1) {
        newValues.full_name = `${newValues.first_name} ${newValues.last_name} ${newValues.last_name2}`;
        newValues.name = `${newValues.first_name} ${newValues.last_name} ${newValues.last_name2}`;
      }
      console.log('newValues', newValues);
      const response = await goalBaseBusinessPartnersApi.postCustomerForm({
        values: newValues
      });
      if (response.success) {
        showNotify(`${response?.message}. Creación exitoso `);
        navigate(`${appUrlBase}/busines-partners/customers/${response.data.id}`, {
          replace: true
        });
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
    }
  });

  const handleTypePersonLegal = () => {
    formik.setFieldValue('tax_id_type_id', 1);
  };
  const handleTypePersonNatural = () => {
    formik.setFieldValue('tax_id_type_id', 3);
  };
  const handleSearch = async () => {
    // if (formik.values.type_of_partnert_code === '') {
    //   setErrorMessage('Seleccione un tipo de socio');
    //   setTimeout(() => {
    //     setErrorMessage(null);
    //   }, 3000);
    //   return;
    // }
    if (formik.values.tax_id_type_id === '') {
      setErrorMessage('Seleccione un documento');
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      return;
    }
    if (formik.values.tax_id_type_id === 3 && formik.values.tax_id.length !== 8) {
      setErrorMessage('Ingrese un número válido de DNI');
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      return;
    }
    if (formik.values.tax_id_type_id === 1 && formik.values.tax_id.length !== 11) {
      setErrorMessage('Ingrese un número válido de RUC');
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      return;
    }

    const resp = await goalBaseBusinessPartnersApi.getBussinesPartnerSearchId(
      currentCompany,
      formik.values.type_of_partnert_code,
      formik.values.tax_id,
      formik.values.tax_id_type_id
    );
    console.log('resp', resp);
    // && resp.data.length > 0
    if (resp.success && resp.data.length > 0) {
      setModalOpen(true);
      setConfirmDialogMessage('El cliente ya esta registrado, ¿Quieres continuar?');
      setListCustomer(resp.data);
    } else if (resp.success && resp.data.length === 0) {
      handleConfirm();
    } else {
      setListCustomer([]);
    }
  };

  const handleConfirm = async () => {
    if (formik.values.tax_id_type_id !== 1) {
      setModalOpen(false);
      return;
    }
    const result = await goalBaseBusinessPartnersApi.getBussinesPartnerSearchRUC(
      formik.values.tax_id
    );
    console.log('result ruc', result);
    if (result.success) {
      const { address, nombre, ubigeo } = result.data;
      formik.setFieldValue('address', address);
      formik.setFieldValue('full_name', nombre);
      formik.setFieldValue('name', nombre);

      const zoneFilter = locationData.filter((item) => item.code === ubigeo);
      formik.setFieldValue('district', zoneFilter[0]?.district_name);
      formik.setFieldValue('province', zoneFilter[0]?.province_name);
      formik.setFieldValue('department', zoneFilter[0]?.departament_name);
      formik.setFieldValue('ubigeo_code', zoneFilter[0]?.code);
      formik.setFieldValue('ubigeo_id', zoneFilter[0]?.id);
      // formik.setFieldValue('address_type', zoneFilter[0]?.type);
      formik.setFieldValue('ubigeo', zoneFilter[0]);
    } else {
      setErrorMessage(result.apiMessage);
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }
    setModalOpen(false);
  };
  const handleChangeType = () => {
    setTypeOfPartnerState(typeOfPartnerState + 1);
    formik.setFieldValue('business_partner_group_id', '');
  };

  useEffect(() => {
    const selectedType = flags.selectedPersonType;
    const selectedDocType = flags.selectedDocumentType;
    setFlags((prevFlags) => ({
      ...prevFlags,
      isCustomer: isTrue(formik.values.is_customer),
      isVendor: isTrue(formik.values.is_vendor),
      isEmployee: isTrue(formik.values.is_employee),
      isFreelancer: isTrue(formik.values.is_freelancer),
      isCompany: isTrue(selectedType?.isCompany),
      isPerson: isTrue(selectedType?.isPerson),
      isForeign: isTrue(selectedType?.isForeign),
      isRUC: isTrue(selectedDocType?.properties?.isRUC),
      isDNI: isTrue(selectedDocType?.properties?.isDNI)
    }));
  }, [formik.values]);
  const businesGroupType = businesGroupTypeState.data || [];
  return (
    <Box
      sx={{
        minWidth: 'auto',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#fff',
        borderRadius: 2,
        padding: 2
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Card
          sx={{
            padding: 5
          }}
        >
          <Grid
            container
            spacing={8}
            sx={{
              marginBottom: 2
            }}
          >
            <Grid item md={4} xs={12}>
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold'
                }}
              >
                Socio de negocio
              </InputLabel>
              <PropertyList>
                <Box sx={{ display: 'flex', gap: '1em' }}>
                  {false && (
                    <InputField
                      select
                      label="Tipo de socio"
                      name="type"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      fullWidth
                      sx={{ marginBottom: '1em' }}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                      helperText={formik.touched.type && formik.errors.type}
                    >
                      {typeOfPartner.length === 0 ? (
                        <MenuItem value="">No hay datos</MenuItem>
                      ) : (
                        typeOfPartner.map((item, index) => (
                          <MenuItem key={index} value={item.code} onClick={handleChangeType}>
                            {item.name}
                          </MenuItem>
                        ))
                      )}
                    </InputField>
                  )}
                </Box>
                <InputField
                  select
                  label="Grupo de cliente"
                  name="business_partner_group_id"
                  value={
                    businesGroupType.length === 0 ? '' : formik.values.business_partner_group_id
                  }
                  onChange={formik.handleChange}
                  fullWidth
                  error={
                    formik.touched.business_partner_group_id &&
                    Boolean(formik.errors.business_partner_group_id)
                  }
                  helperText={
                    formik.touched.business_partner_group_id &&
                    formik.errors.business_partner_group_id
                  }
                  sx={{ marginBottom: '1em' }}
                >
                  {businesGroupType.length === 0 ? (
                    <MenuItem value="">No hay datos</MenuItem>
                  ) : (
                    businesGroupType.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  )}
                </InputField>
                <InputField
                  select
                  label="Tipo Persona"
                  name="person_type"
                  value={businessPersonType.length === 0 ? '' : formik.values.person_type}
                  onChange={(e) => {
                    if (handleSelectPersonType(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                  fullWidth
                  error={formik.touched.person_type && Boolean(formik.errors.person_type)}
                  helperText={formik.touched.person_type && formik.errors.person_type}
                  sx={{ marginBottom: '1em' }}
                >
                  {businessPersonType.length === 0 ? (
                    <MenuItem value="">No hay datos</MenuItem>
                  ) : (
                    businessPersonType.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.code}
                        onClick={
                          item.code === 'TPJ' ? handleTypePersonLegal : handleTypePersonNatural
                        }
                      >
                        {item.name}
                      </MenuItem>
                    ))
                  )}
                </InputField>
                <InputField
                  select
                  label="Documento de identidad"
                  name="tax_id_type_id"
                  value={businessDocumentType.length === 0 ? '' : formik.values.tax_id_type_id}
                  onChange={(e) => {
                    if (handleSelectDocumentType(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                  fullWidth
                  error={formik.touched.tax_id_type_id && Boolean(formik.errors.tax_id_type_id)}
                  helperText={formik.touched.tax_id_type_id && formik.errors.tax_id_type_id}
                  sx={{ marginBottom: '1em' }}
                >
                  {businessDocumentType.length === 0 ? (
                    <MenuItem value="">No hay datos</MenuItem>
                  ) : (
                    businessDocumentType.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  )}
                </InputField>
                {!flags.isRUC && (
                  <>
                    <InputField
                      placeholder="Número Doc."
                      name="tax_id"
                      value={formik.values.tax_id}
                      onChange={formik.handleChange}
                      label="Numero de Documento"
                      fullWidth
                      error={formik.touched.tax_id && Boolean(formik.errors.tax_id)}
                      helperText={formik.touched.tax_id && formik.errors.tax_id}
                      sx={{ marginBottom: '1em' }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ cursor: 'pointer', marginRight: 1 }}>
                            <Search onClick={handleSearch} />
                          </InputAdornment>
                        )
                      }}
                    />
                    {errorMessage && (
                      <Alert sx={{ marginBottom: 2 }} severity="warning">
                        {errorMessage}
                      </Alert>
                    )}
                  </>
                )}
                {flags.isRUC && (
                  <>
                    <InputField
                      placeholder="Ingrese RUC"
                      name="tax_id"
                      value={formik.values.tax_id}
                      onChange={formik.handleChange}
                      label="RUC"
                      fullWidth
                      error={formik.touched.tax_id && Boolean(formik.errors.tax_id)}
                      helperText={formik.touched.tax_id && formik.errors.tax_id}
                      sx={{ marginBottom: '1em' }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ cursor: 'pointer', marginRight: 1 }}>
                            <Search onClick={handleSearch} />
                          </InputAdornment>
                        )
                      }}
                    />
                    {errorMessage && (
                      <Alert sx={{ marginBottom: 2 }} severity="warning">
                        {errorMessage}
                      </Alert>
                    )}
                  </>
                )}
                {flags.isPerson && (
                  <>
                    <InputField
                      name="last_name"
                      label="Apellido paterno"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      fullWidth
                      error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                      helperText={formik.touched.last_name && formik.errors.last_name}
                      sx={{ marginBottom: '1em' }}
                    />
                    <InputField
                      name="last_name2"
                      label="Apellido materno"
                      value={formik.values.last_name2}
                      onChange={formik.handleChange}
                      fullWidth
                      error={formik.touched.last_name2 && Boolean(formik.errors.last_name2)}
                      helperText={formik.touched.last_name2 && formik.errors.last_name2}
                      sx={{ marginBottom: '1em' }}
                    />
                    <InputField
                      name="first_name"
                      label="Nombres"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      fullWidth
                      error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                      helperText={formik.touched.first_name && formik.errors.first_name}
                      sx={{ marginBottom: '1em' }}
                    />
                  </>
                )}

                {!flags.isPerson && (
                  <>
                    <InputField
                      name="name"
                      label="Nombre corto"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      fullWidth
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      sx={{ marginBottom: '1em' }}
                      disabled={flags.isPerson}
                    />
                    <InputField
                      name="full_name"
                      label="Nombre completo"
                      value={formik.values.full_name}
                      onChange={formik.handleChange}
                      fullWidth
                      error={formik.touched.full_name && Boolean(formik.errors.full_name)}
                      helperText={formik.touched.full_name && formik.errors.full_name}
                      sx={{ marginBottom: '1em' }}
                      disabled={flags.isPerson}
                    />
                  </>
                )}
              </PropertyList>
            </Grid>
            <Grid item md={4} xs={12}>
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold'
                }}
              >
                Información empresa
              </InputLabel>
              <InputField
                name="comercial_name"
                label="Nombre comercial"
                value={formik.values.comercial_name}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.comercial_name && Boolean(formik.errors.comercial_name)}
                helperText={formik.touched.comercial_name && formik.errors.comercial_name}
                sx={{ marginBottom: '1em' }}
                message="El nombre comercial es la identidad bajo la cual se da a conocer al público."
              />
              <InputField
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                sx={{ marginBottom: '1em' }}
                message="Es una referencia a la dirección de correo electrónico de contacto o comunicación proporcionada por la empresa."
              />
              <InputLabelField title="Giro de Negocio" />
              <SelectInputField
                label="Giro de Negocio"
                name="business_type_id"
                value={businessType.length === 0 ? '' : formik.values.business_type_id}
                onChange={formik.handleChange}
                fullWidth
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '200px',
                      width: '250px'
                    }
                  }
                }}
              >
                {businessType.length === 0 ? (
                  <MenuItem value="">No hay datos</MenuItem>
                ) : (
                  businessType.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                )}
              </SelectInputField>
              {formik.touched.business_type_id && formik.errors.business_type_id && (
                <FormHelperText error>{formik.errors.business_type_id}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </Card>

        <Card
          sx={{
            padding: 5,
            marginTop: 4
          }}
        >
          <Grid
            container
            spacing={8}
            sx={{
              marginBottom: 2
            }}
          >
            <Grid item md={4} xs={12}>
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold'
                }}
              >
                Direccion
              </InputLabel>
              <PropertyList>
                {/* <InputField
                  name="code"
                  label="Código"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                  sx={{ marginBottom: '1em' }}
                /> */}
                <AutocompleteField
                  label="Localidad"
                  name="ubigeo"
                  placeholder="Seleccione localidad"
                  fullWidth
                  options={locationData || []}
                  getOptionLabel={(option) => option.name || ''}
                  isOptionEqualToValue={(option) => option.name || ''}
                  onChange={(e, value) => {
                    formik.setFieldValue('district', value?.district_name);
                    formik.setFieldValue('province', value?.province_name);
                    formik.setFieldValue('department', value?.departament_name);
                    formik.setFieldValue('ubigeo_id', value?.id);
                    formik.setFieldValue('ubigeo_code', value?.code);
                    // formik.setFieldValue('address_type', value?.type);
                    formik.setFieldValue('ubigeo', value);
                    if (value === null) {
                      formik.setFieldValue('district', '');
                      formik.setFieldValue('province', '');
                      formik.setFieldValue('department', '');
                      formik.setFieldValue('ubigeo_id', '');
                      formik.setFieldValue('ubigeo_code', '');
                    }
                  }}
                  value={formik.values.ubigeo}
                  sx={{ marginBottom: '1em' }}
                  // error={formik.touched.ubigeo && Boolean(formik.errors.ubigeo)}
                  // helperText={formik.touched.ubigeo && formik.errors.ubigeo}
                />
                <InputField
                  name="address"
                  label="Dirección completa"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                  sx={{ marginBottom: '1em' }}
                  disabled={flags.isForeign}
                  message="Es la descripción detallada y precisa de la ubicación de un lugar o residencia, de manera que pueda ser identificado y localizado de manera efectiva."
                />
                <InputField
                  name="reference"
                  label="Referencia"
                  value={formik.values.reference}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.reference && Boolean(formik.errors.reference)}
                  helperText={formik.touched.reference && formik.errors.reference}
                  sx={{ marginBottom: '1em' }}
                  message="Se proporciona para ayudar a identificar o ubicar un lugar específico en una localidad o área geográfica."
                />
              </PropertyList>
            </Grid>
            <Grid item md={4} xs={12}>
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold'
                }}
              >
                Localidad
              </InputLabel>
              <PropertyList>
                {/* <InputField
                  name="address_type"
                  label="Tipo"
                  value={formik.values.address_type}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.address_type && Boolean(formik.errors.address_type)}
                  helperText={formik.touched.address_type && formik.errors.address_type}
                  sx={{ marginBottom: '1em' }}
                  disabled
                /> */}
                <InputField
                  name="district"
                  label="Distrito"
                  value={formik.values.district}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.district && Boolean(formik.errors.district)}
                  helperText={formik.touched.district && formik.errors.district}
                  sx={{ marginBottom: '1em' }}
                  disabled
                />
                <InputField
                  name="province"
                  label="Provincia"
                  value={formik.values.province}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.province && Boolean(formik.errors.province)}
                  helperText={formik.touched.province && formik.errors.province}
                  sx={{ marginBottom: '1em' }}
                  disabled
                />
                <InputField
                  name="department"
                  label="Departamento"
                  value={formik.values.department}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.department && Boolean(formik.errors.department)}
                  helperText={formik.touched.department && formik.errors.department}
                  sx={{ marginBottom: '1em' }}
                  disabled
                />
              </PropertyList>
            </Grid>
          </Grid>
        </Card>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
      <ConfirmationDialogCustomer
        message={confirmDialogMessage}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
        Component={(props) => <ComponentList {...props} list={listCustomer} />}
      />
    </Box>
  );
};

const ComponentList = ({ list }) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [codeRute, setCodeRute] = useState(false);
  const { appUrlBase } = useApp();

  const handleClientRute = (code) => {
    setCodeRute(code);
    setModalOpen(true);
  };
  const handleConfirm = () => {
    navigate(`${appUrlBase}/busines-partners/customers/${codeRute}`, {
      replace: true
    });
    setModalOpen(false);
  };
  return (
    <Box>
      <List sx={{ width: '100%', bgcolor: 'rgba(232, 92, 66, 0.2)' }} aria-label="client">
        {list.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            secondaryAction={
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => handleClientRute(item.cardCode)}
              >
                <Eye />
              </IconButton>
            }
          >
            <ListItemButton>
              <ListItemText primary={item.cardName} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <ConfirmationDialog
        message="Ir al cliente , perderas los datos del Formulario, ¿Estas seguro de continuar?"
        onCancel={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
      />
    </Box>
  );
};

BusinessPartnersForm.propTypes = {
  initialValues: Proptypes.object
  // handleRefresh: Proptypes.func
};

ComponentList.propTypes = {
  list: Proptypes.array
};
