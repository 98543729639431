import PropTypes from 'prop-types';
import numeral from 'numeral';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';

import { NumberColor } from '../../number-color';
import GoalRecordLink from '../../goal-record-link';
import TableHeadEnhanced from '../../common/table-head-enhanced';

const columns = [
  { id: 'lineIndex', label: '#', minWidth: 50 },
  // { id: 'itemCode', label: 'Producto', minWidth: 100 },
  { id: 'itemName', label: 'Producto', minWidth: 100 },
  { id: 'unitCode', label: 'Presentacion', minWidth: 100 },
  { id: 'quantity', label: 'Cantidad', minWidth: 100 },
  { id: 'priceBefDi', label: 'Precio Base', minWidth: 100 },
  { id: 'discPrcnt', label: 'Dscto (%)', minWidth: 100 },
  { id: 'price', label: 'Precio Final', minWidth: 100 },
  { id: 'lineTotal', label: 'SubTotal', minWidth: 100 },
  { id: 'vatSum', label: 'IGV', minWidth: 100 },
  { id: 'gTotal', label: 'Total', minWidth: 100 },
  { id: 'promoCodes', label: 'Promo', minWidth: 100 },
  { id: 'units', label: 'Unidades', minWidth: 100 },
  { id: 'whsCode', label: 'Almacén', minWidth: 100 }
];

export const PromoAssignedTable = (props) => {
  const { promoAssigned } = props;
  return (
    <TableContainer sx={{ maxHeight: '60vh' }}>
      <Table
        stickyHeader
        aria-label="sticky table"
        aria-labelledby="Tabla de lineas de promoción asignada"
        size={'medium'}
      >
        <TableHeadEnhanced keyPrefix="vehicle-assigned-column" headCells={columns} />

        <TableBody>
          {promoAssigned.lines?.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{numeral(item.lineIndex).format('0')}</TableCell>
              <TableCell sx={{ minWidth: 300 }}>
                <Box xl={2}>
                  <GoalRecordLink
                    to={`/inventory/products/${item.itemCode}`}
                    label={item.itemCode}
                  />
                  <Typography color="textSecondary" variant="body2">
                    {item.itemName}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ minWidth: 100 }}>
                <NumberColor
                  prefix={`${item.unitCode} x `}
                  value={item.factorUM}
                  format="0"
                  color="textSecondary"
                  variant="body2"
                />
              </TableCell>
              <TableCell>
                <NumberColor value={item.quantity} format="0.0" />
              </TableCell>
              <TableCell>
                <NumberColor value={item.priceBefDi} format="0.0" />
              </TableCell>
              <TableCell>
                <NumberColor
                  value={item.discPrcnt}
                  format="0.00"
                  upper
                  minValue={0.01}
                  color={item.promoCodes !== '' ? 'textSecondary' : 'textPrimary'}
                  variant="body3"
                />
              </TableCell>
              <TableCell>
                <NumberColor value={item.price} format="0.0000" />
              </TableCell>
              <TableCell>
                <NumberColor value={item.lineTotal} format="0.00" />
              </TableCell>
              <TableCell sx={{ width: 150 }}>
                <NumberColor value={item.vatSum} format="0.00" />
              </TableCell>
              <TableCell sx={{ width: 150 }}>
                <NumberColor value={item.gTotal} format="0.00" />
              </TableCell>
              <TableCell>{item.promoCodes}</TableCell>
              <TableCell>
                <NumberColor value={item.units} format="0" />
              </TableCell>
              <TableCell>{item.whsCode}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PromoAssignedTable.propTypes = {
  promoAssigned: PropTypes.object.isRequired
};
