import { Helmet } from 'react-helmet-async';
import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import { invoiceApi } from '../../api/invoice';
import { InvoicesFilter } from '../../components/purchases/invoices-service/invoices-filter';

import { InvoicesStats } from '../../components/purchases/invoices-service/invoices-stats';
import { InvoicesTable } from '../../components/purchases/invoices-service/invoices-table';
import { useMounted } from '../../hooks/use-mounted';
import { useSelection } from '../../hooks/use-selection';
import { Plus as PlusIcon } from '../../icons/plus';
import { Refresh as RefreshIcon } from '../../icons/refresh';
import { useApp } from '../../hooks/use-app';
import { useDialog } from '../../hooks/use-dialog';
import { useDataBrowser } from '../../hooks/use-data-browser';

import { DocumentAppendDialog } from '../../components/purchases/common/document-add';
import GadminActions from '../../contexts/gadmin-actions';
import { documnetStatus } from '../../api/purchase-definitions';
import GoalButton from '../../components/goal-button';
import { GoalAccordion } from '../../components/goal-accordion';

export const Invoices = () => {
  const mounted = useMounted();
  const app = useApp();
  const [
    invoicesState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller
  ] = useDataBrowser({
    sourceApi: invoiceApi.getInvoices,
    loadingMessage: 'Cargando documentos',
    mounted,
    defaults: {
      view: documnetStatus.Borrador
    }
  });

  const [selectedInvoices, handleSelect, handleSelectAll] = useSelection(invoicesState.documents);

  const [appendDialogOpen, handleOpenAppendDialog, handleCloseAppendDialog] = useDialog();
  const handleExitedDialog = () => {
    // if (selectedVariant) {
    //   setSelectedVariant(null);
    // }
  };

  return (
    <>
      <Helmet>
        <title>{app.title('Facturas de servicios')} </title>
      </Helmet>

      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1
        }}
        variant="outlined"
      >
        <CardHeader
          title="Facturas de servicios"
          action={
            <>
              <GoalButton
                color="primary"
                startIcon={<PlusIcon />}
                onClick={handleOpenAppendDialog}
                permission={GadminActions.gCC_PURCHASE_FFEE_CAB_UP}
              />
              <GoalButton startIcon={<RefreshIcon />} onClick={handleRefresh} />
            </>
          }
        />
        <CardContent>
          <GoalAccordion title={'Estadísticas'}>
            <InvoicesStats />
          </GoalAccordion>
        </CardContent>
        <CardContent>
          <InvoicesFilter
            disabled={invoicesState.isLoading}
            filters={controller.filters}
            onFiltersApply={handleFiltersApply}
            onFiltersClear={handleFiltersClear}
            onQueryChange={handleQueryChange}
            onViewChange={handleViewChange}
            query={controller.query}
            selectedInvoices={selectedInvoices}
            view={controller.view}
            onRefresh={handleRefresh}
          />
          <Divider />
          <InvoicesTable
            error={invoicesState.error}
            invoices={invoicesState.documents}
            invoicesCount={invoicesState.documentsCount}
            isLoading={invoicesState.isLoading}
            onPageChange={handlePageChange}
            onSelect={handleSelect}
            onSelectAll={handleSelectAll}
            onSortChange={handleSortChange}
            page={controller.page + 1}
            selectedInvoices={selectedInvoices}
            sort={controller.sort}
            sortBy={controller.sortBy}
            onRefresh={handleRefresh}
          />
        </CardContent>
      </Card>
      <DocumentAppendDialog
        onClose={handleCloseAppendDialog}
        onExited={handleExitedDialog}
        afterSubmit={handleRefresh}
        uploaderApi={invoiceApi.uploadInvoice}
        open={appendDialogOpen}
      />
    </>
  );
};
