import PropTypes from 'prop-types';
import {
  Chip,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip
} from '@mui/material';
import TableHeadEnhanced from '../../common/table-head-enhanced';
import GoalRecordLink from '../../goal-record-link';
import { ResourceUnavailable } from '../../resource-unavailable';
import {
  WorkOrdersGetTextColor,
  WorkOrdersViews
} from '../../../api/goal/operations/work-orders/work-orders-definitions';
import TablePaginationEnhanced from '../../common/table-pagination-enhanced';
import { WorkOrdersMenu } from './work-orders-menu';

const headCells = [
  { id: 'work_order_number', label: 'Referencia', align: 'left' },
  { id: 'status_name', label: 'Estado en Fleet', align: 'left' },
  { id: 'actions', label: '', align: 'left' },
  { id: 'vehicle.name', label: 'Vehículo', align: 'left' },
  { id: 'vendor.name', label: 'Proveedor', align: 'left' },
  { id: 'work_order_date', label: 'Fecha de emisión', align: 'left' },
  { id: 'due_date', label: 'Fecha de vencimiento', align: 'left' },
  { id: 'approved_at', label: 'Aprobado el', align: 'left' },
  { id: 'currency_code', label: 'Moneda', align: 'left' },
  { id: 'total_amount', label: 'Monto Total', align: 'left' },
  { id: 'is_notified', label: 'Notificado', align: 'left' }
];

const WorkOrdersTable = ({
  data = [],
  isLoading,
  pagination,
  onPageChange,
  onChangeRowsPerPage,
  onSortChange,
  sort = 'asc',
  sortBy = 'username',
  view = WorkOrdersViews.ALL,
  handleRefresh,
  basePath = `/operations/work-orders`
}) => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: '60vh', minHeight: '40vh' }}>
        <Table stickyHeader aria-label="sticky table" size={'small'} sx={{ minWidth: 100 }}>
          <TableHeadEnhanced
            keyPrefix="work-order-column"
            headCells={headCells}
            onRequestSort={onSortChange}
            order={sort}
            orderBy={sortBy}
          />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={headCells.length}>
                  <Skeleton height={42} />
                  <Skeleton height={42} />
                  <Skeleton height={42} />
                </TableCell>
              </TableRow>
            ) : data.length > 0 ? (
              data.map((item) => {
                return (
                  <TableRow hover key={item.id}>
                    <TableCell sx={{ minWidth: 130 }}>
                      <GoalRecordLink
                        to={`${basePath}/${item.work_order_number}?view=${view}`}
                        label={item.work_order_number}
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip title={item?.fleet_status?.descr || ''}>
                        <Chip
                          label={item.status_name}
                          color="primary"
                          sx={{
                            color: WorkOrdersGetTextColor(item?.fleet_status?.display_color),
                            fontWeight: 'bold',
                            backgroundColor: item?.fleet_status?.display_color ?? 'transparent'
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <WorkOrdersMenu workOrder={item} handleRefresh={handleRefresh} />
                    </TableCell>
                    <TableCell>{item?.fleet_vehicle?.name || ''}</TableCell>
                    <TableCell>{item?.vendor?.name || ''}</TableCell>
                    <TableCell>{item.work_order_date}</TableCell>
                    <TableCell>{item.due_date}</TableCell>
                    <TableCell>{item.approved_at}</TableCell>
                    <TableCell>{item.currency_code}</TableCell>
                    <TableCell>{item.total_amount}</TableCell>
                    <TableCell>
                      <Switch checked={item.is_notified === true} name="Is notified" />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={headCells.length}>
                  <ResourceUnavailable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePaginationEnhanced
        pagination={pagination}
        onPageChange={onPageChange}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Paper>
  );
};

WorkOrdersTable.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.object,
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onSortChange: PropTypes.func,
  page: PropTypes.number,
  sort: PropTypes.oneOf(['asc', 'desc']),
  sortBy: PropTypes.string,
  view: PropTypes.string,
  handleRefresh: PropTypes.func,
  basePath: PropTypes.string
};

export default WorkOrdersTable;
