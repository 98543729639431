import { appDefinitions } from '../../../app-definitions';
import { dataLoader } from '../../../data-loader';

class VehicleApi {
  async getVehicleList(options) {
    const { filters, sort, sortBy, page, query, view, reload, companyId } = options;
    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: '/fleet/fleet-vehicles',
      cacheItem: 'vehicle-report-list',
      apiParams: {
        type: 'list',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        view,
        companyId
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'code' },
        { type: 'string', name: 'color' },
        { type: 'string', name: 'brand' },
        { type: 'string', name: 'owner_name' }
      ],
      ...options
    });
  }

  async vehicleDetail(options) {
    const { id, ...others } = options;
    const resutl = await dataLoader.goalGet({
      action: '/fleet/fleet-vehicles/' + id,
      params: others
    });
    return resutl;
  }

  async getVehicle({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/fleet/fleet-vehicles/${id}?companyId=${companyId}`
    });
    return response;
  }

  async getVehicleWorkOrders({
    companyId,
    id,
    view = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    search = '',
    sort = '',
    sortBy = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `/fleet/fleet-vehicles/${id}/work-orders`,
      params: {
        companyId: companyId,
        view: view,
        page: page,
        per_page: perPage,
        search: search,
        order_direction: sort,
        order_by: sortBy
      }
    });
    return response;
  }

  async getVehicleMeters(options) {
    const { filters, sort, sortBy, page, query, view, reload, companyId, id } = options;
    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/fleet/fleet-vehicles/${id}/meters`,
      cacheItem: 'permissions',
      apiParams: {
        companyId,
        type: 'list',
        view,
        payload: { filters, sort, sortBy, page, query, reload }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'number', name: 'vehicle_id' },
        { type: 'string', name: 'meter_value' },
        { type: 'string', name: 'meter_type' }
      ],
      ...options
    });
  }

  async createMeter({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: `/fleet/fleet-vehicles/meters`,
      changes: values
    });
    return response;
  }

  // get work order pdf
  async getWorkOrderPdf({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/fleet/fleet-vehicle-work-orders/${id}/pdf?companyId=${companyId}`
    });
    return response;
  }

  async getContactsByDepartament({ companyId, id }) {
    const response = await dataLoader.goalGet({
      action: `fleet/fleet-vehicles/${id}/contacts-by-departament`,
      params: { companyId: companyId }
    });
    return response;
  }
}

export const vehicleApi = new VehicleApi();
