import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalGprocApi {
  async refreshDispatchDates() {
    const response = await dataLoader.loadGoalGetData({
      action: `/delivery/dispatch/dispatchs/refresh-dates`
    });
    return response;
  }

  async refreshActiveDirectoryObjects(params) {
    const response = await dataLoader.loadGoalGetData({
      action: `/automations/processors/update-ad-objects-as-job`,
      params
    });
    return response;
  }

  async reindexDatabase(params) {
    const response = await dataLoader.loadGoalGetData({
      action: `/automations/processors/reindex-database`,
      params
    });
    return response;
  }

  // queue all ncs
  async queueAllNcs(params) {
    const response = await dataLoader.loadGoalGetData({
      action: `/automations/processors/queue-all-ncs`,
      params
    });
    return response;
  }

  // clean-portfolio-customers
  async cleanPortfolioCustomers(params) {
    const response = await dataLoader.loadGoalGetData({
      action: `/automations/processors/clean-portfolio-customers`,
      params
    });
    return response;
  }

  // refresh-portfolio-customers
  async refreshPortfolioCustomers(params) {
    const response = await dataLoader.loadGoalGetData({
      action: `/automations/processors/refresh-portfolio-customers`,
      params
    });
    return response;
  }

  //  update prices as job
  async updatePricesAsJob(params) {
    const response = await dataLoader.loadGoalGetData({
      action: `/automations/processors/update-prices-as-job`,
      params
    });
    return response;
  }

  async refreshTVendoConfig(params) {
    const response = await dataLoader.loadGoalGetData({
      action: `/tvendo/tvendo-resources/config`,
      params
    });
    return response;
  }

  // grant all permissions
  async grantAllPermissions(params) {
    const response = await dataLoader.loadGoalGetData({
      action: `/automations/processors/grant-all-permissions`,
      params
    });
    return response;
  }

  // reset cache
  async resetCache(params) {
    const response = await dataLoader.loadGoalGetData({
      action: `/automations/processors/reset-cache`,
      params
    });
    return response;
  }

  // get document log
  async getDocumentLog({ objType, docEntry, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/core/goal-application/${companyId}/document-logs?objType=${objType}&docEntry=${docEntry}&companyId=${companyId}`
    });
    return response;
  }
}

export const goalGprocApi = new GoalGprocApi();
