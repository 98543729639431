import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import { NumberColor } from '../../number-color';
import { Sumary } from '../../sumary';

export const JournalLinesTable = (props) => {
  const { journalInfo } = props;
  return (
    <Card>
      <CardHeader title="Lineas del asiento" />
      <CardContent>
        <TableContainer sx={{ maxHeight: '60vh', overflow: 'auto' }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            aria-labelledby="Tabla de lineas de asiento"
            sx={{ minWidth: 500 }}
          >
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Cuenta</TableCell>
                <TableCell sx={{ minWidth: 300 }}>Cuenta/Nombre de SN</TableCell>
                <TableCell>Débito</TableCell>
                <TableCell>Crédito</TableCell>
                <TableCell>C Costo</TableCell>
                <TableCell>Ref1</TableCell>
                <TableCell>Ref2</TableCell>
                <TableCell>F Conta</TableCell>
                <TableCell>F Doc</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {journalInfo.lines?.map((item) => (
                <TableRow key={item.line}>
                  <TableCell>{numeral(item.line).format('0')}</TableCell>
                  <TableCell>{numeral(item.account).format('0')}</TableCell>
                  <TableCell sx={{ minWidth: 300 }}>
                    <Box xl={2}>
                      <Typography color="inherit" variant="body2">
                        {item.ShortName}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {item.accountName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <NumberColor value={item.Debit} format="0.00" />
                  </TableCell>
                  <TableCell>
                    <NumberColor value={item.Credit} format="0.00" />
                  </TableCell>
                  <TableCell>{item.profitCode}</TableCell>
                  <TableCell>{item.Ref1}</TableCell>
                  <TableCell>{item.Ref2}</TableCell>
                  <TableCell>{moment(item.refDate).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{moment(item.taxDate).format('DD/MM/YYYY')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Sumary values={journalInfo.lines} column="gTotal" />
        </TableContainer>
      </CardContent>
    </Card>
  );
};

JournalLinesTable.propTypes = {
  journalInfo: PropTypes.object.isRequired
};
