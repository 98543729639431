import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Modal, Box, Card } from '@mui/material';
import ChangeIcon from '@mui/icons-material/ChangeCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { useApp } from '../../hooks/use-app';
import { CommonImageSelector } from './common-image-selector';
import { GoalIconButton } from '../goal-icon-button';

const GoalImageGallery = ({
  images = [],
  sizeImage = '180px',
  canEdit = false,
  ownerEntity,
  imageHandlerApi,
  afterUpload: onRefresh,
  imageCodes
}) => {
  const { getImageUrl, noImageUrl, currentCompany, showError } = useApp();
  const [openImage, setOpenImage] = useState(null);
  const [selectingImage, setSelectingImage] = useState(false);
  const [updatable, setUpdatable] = useState(false);
  const [imageList, setImageList] = useState([]);

  const handleOpenImage = (imageUrl) => {
    setOpenImage(imageUrl);
  };

  const handleCloseImage = () => {
    setOpenImage(null);
  };
  useEffect(() => {
    // split item.image_code by space or comma
    const splitedImages = imageCodes?.split(/[\s,]+/);
    if (splitedImages) {
      const list = splitedImages.map((image_code, index) => ({
        id: index,
        width: 1,
        height: 1,
        title: 'Goal Image',
        image_code
      }));
      setImageList([...images, ...list]);
    } else {
      setImageList(images);
    }
  }, [imageCodes]);

  const handleImageError = (e) => {
    // check if e.target.src contains the noImageUrl
    if (e.target.src.includes(noImageUrl)) {
      // set src from assets
      e.target.src = '/static/images/pic-share-logo.png';
    } else {
      e.target.onerror = null; // Prevents infinite loop in case the fallback image also fails
      e.target.src = noImageUrl; // URL to your dummy or fallback image
    }
  };

  const handleAfterUpload = async (image) => {
    const result = await imageHandlerApi({
      ...ownerEntity,
      companyId: currentCompany,
      image_code: image.code,
      company_id: currentCompany
    });
    if (result.success) {
      // item.image_code = image.code;
      onRefresh?.(result);
    } else {
      showError(result.message);
    }
    setSelectingImage(false);
  };

  useEffect(() => {
    setUpdatable(!!(canEdit && imageHandlerApi && ownerEntity));
    setSelectingImage(false);
  }, [canEdit, imageHandlerApi, ownerEntity]);

  const imageGrid = (
    <Grid container spacing={2}>
      <Card sx={{ maxWidth: sizeImage, maxHeight: sizeImage, objectFit: 'contain' }}>
        {imageList.map((image) => (
          <img
            key={image.image_code}
            src={getImageUrl({ imageCode: image.image_code, thumbMail: true })}
            alt={image.title || 'Image'}
            onClick={() => handleOpenImage(getImageUrl({ imageCode: image.image_code }))}
            onError={handleImageError}
          />
        ))}
      </Card>
      {updatable && (
        <GoalIconButton
          onClick={() => setSelectingImage(true)}
          tolltip="Cambiar imagen"
          icon={<ChangeIcon color="primary" />}
          sx={{ position: 'absolute', top: 30, right: 30 }}
        />
      )}
    </Grid>
  );
  if (updatable && (selectingImage || !imageList || imageList.length === 0)) {
    return (
      <>
        <CommonImageSelector afterUpload={handleAfterUpload} />
        {selectingImage && (
          <GoalIconButton
            onClick={() => setSelectingImage(false)}
            sx={{ position: 'absolute', top: 30, right: 30 }}
            icon={<CancelIcon color="primary" />}
            tolltip="Cancelar"
            iconOnly
          />
        )}
      </>
    );
  }

  return (
    <>
      {imageGrid}
      <Modal open={openImage !== null} onClose={handleCloseImage}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4
          }}
        >
          <GoalIconButton
            onClick={handleCloseImage}
            sx={{ position: 'absolute', top: 30, right: 30 }}
            icon={<CloseIcon />}
            tolltip="Cerrar"
          />

          {/* <IconButton >
            <CloseIcon />
          </IconButton> */}
          <img
            src={openImage}
            alt="Full Image"
            style={{ width: '100%' }}
            onError={handleImageError}
          />
        </Box>
      </Modal>
    </>
  );
};
GoalImageGallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      image_code: PropTypes.string.isRequired,
      title: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      id: PropTypes.number
    })
  ),
  sizeImage: PropTypes.string,
  canEdit: PropTypes.bool,
  ownerEntity: PropTypes.object,
  imageHandlerApi: PropTypes.func,
  afterUpload: PropTypes.func,
  imageCodes: PropTypes.string
};

export default GoalImageGallery;
