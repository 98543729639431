import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Box, Button, Card, Container, Divider, Grid, IconButton, Typography } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useMounted } from '../../../hooks/use-mounted';
import { useSelection } from '../../../hooks/use-selection';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { ArrowRight } from '../../../icons/arrow-right';
import { ArrowLeft } from '../../../icons/arrow-left';

import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';

import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
// import { useQuery } from '../../../hooks/use-query';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { PresaleMonitoringTable } from '../../../components/presale/monitoring/presale-monitoring-table';
import { PresaleMonitoringFilter } from '../../../components/presale/monitoring/presale-monitoring-filter';
import { PresaleMonitoringMap } from '../../../components/presale/monitoring/presale-monitoring-map';
import { goalPresaleMonitoringApi } from '../../../api/goal/presale/goal-presale-monitoring';
import { usePusher } from '../../../hooks/use-pusher';
import { monitoringPresaleApi } from '../../../ws-api/sale/presale-monitoring/presale-locations-api';
import { useData } from '../../../hooks/use-data';
import { useParams, useSearchParams } from 'react-router-dom';
import { dateFromString } from '../../../utils/formatTime';
import { appFormats } from '../../../api/app-definitions';
import { MapItemMenu } from '../menu-map/map-item-menu';

export const PresaleMonitoring = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { teamCode } = useParams();

  const queried = {
    orderDate: searchParams.get('date'),
    type: searchParams.get('type'),
    subtype: searchParams.get('subtype')
  };

  const mounted = useMounted();
  const { title, currentCompany } = useApp();

  const [currentDate, setCurrentDate] = useState(dateFromString(queried.orderDate));

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  useEffect(() => {
    handleSetController.setMainFilter({
      ...queried,
      orderDate: moment(currentDate).format(appFormats.backendDateFormat)
    });
  }, []);

  const handleChangeDate = (newValue) => {
    setCurrentDate(newValue);
  };

  useEffect(() => {
    const dateString = moment(currentDate).format(appFormats.backendDateFormat);
    if (dateString !== controller.orderDate) {
      handleSetController.setMainFilter({ ...queried, orderDate: dateString });
      setSearchParams({ ...searchParams, date: dateString });
    }
  }, [currentDate]);

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalPresaleMonitoringApi.getPresaleMonitoring,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: teamCode || '000',
      id: currentCompany
    }
    // onPostRefresh: handleClearSelected
  });

  const [positionRealTime, setPositionRealTime] = useState([]);

  const [selectedDocuments, handleSelect, handleSelectAll] = useSelection(returnsState.documents);

  const [customerData, setCustomerData] = useState([]);

  const [tabState] = useData({
    sourceApi: goalPresaleMonitoringApi.getSalesTeam,
    apiParameter: { companyId: currentCompany },
    loadingMessage: 'Cargando mesas',
    mounted
  });

  const [, handleRefreshCustomer] = useData({
    sourceApi: goalPresaleMonitoringApi.getPreSaleCustomerLocations,
    apiParameter: { companyId: currentCompany, teamId: teamCode },
    loadingMessage: 'Cargando clientes',
    mounted,
    defaults: {
      noAutoLoad: true
    },
    afterLoad: (result) => {
      setCustomerData(result.data);
    }
  });

  const [polygonsState, handleRefreshPolygon] = useData({
    sourceApi: goalPresaleMonitoringApi.getPreSalePolygons,
    apiParameter: { companyId: currentCompany, teamId: teamCode },
    loadingMessage: 'Cargando Poligonos',
    mounted
  });

  const handleRefreshPreSale = (value = true) => {
    handleRefreshCustomer({ companyId: currentCompany, teamId: teamCode });
    handleRefreshPolygon({ companyId: currentCompany, teamId: teamCode });
    if (value) handleRefresh({ companyId: currentCompany, teamId: teamCode });
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const [locationState, setLocationState] = useState({
    x: -13.4274296,
    y: -76.1567574
  });

  const [, setWsConnection] = usePusher({
    api: monitoringPresaleApi.listenSellersLocations,
    parameters: { team: teamCode },
    callback: (data) => {
      console.log('data', data);
      console.log('response', data.response);
      console.log('success', data.response.success);
      if (data.response.success) {
        console.log('entro');
        setPositionRealTime(data.response.data);
      }
    }
  });

  useEffect(() => {
    setWsConnection({ team: teamCode });
  }, [teamCode]);

  const [hidePolygon, setHidePolygon] = useState(true);
  const [hideCustomers, setHideCustomers] = useState(true);
  const [hideSellers, setHideSellers] = useState(true);
  const [showList, setShowList] = useState(true);
  const [zoomState, setZoomState] = useState(10);

  const showListMap = () => {
    setShowList(!showList);
  };

  const handleClickLocation = (lat, long) => {
    if (lat && long) {
      setLocationState({
        x: long,
        y: lat
      });
    }
    setZoomState(40);
  };
  return (
    <>
      <Helmet>
        <title>{title('Monitoreo pre ventas')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
          }}
          variant="outlined"
        >
          <Grid
            container
            spacing={2}
            sx={{
              marginBottom: 2
            }}
          >
            <Grid item xs={12} md={12}>
              {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}> */}
              {/* <Box sx={{ display: 'flex', alignItems: 'center' }}> */}
              <PresaleMonitoringFilter
                disabled={returnsState.isLoading}
                filters={controller.filters}
                onFiltersApply={handleFiltersApply}
                onFiltersClear={handleFiltersClear}
                onQueryChange={handleQueryChange}
                onViewChange={handleViewChange}
                query={controller.query}
                selectedDocuments={selectedDocuments}
                view={controller.view}
                onRefresh={handleRefreshPreSale}
                tabsList={tabState.isLoading ? [] : tabState?.data}
                teamCode={teamCode}
              />
              {/* </Box> */}
              {/* </Box> */}
            </Grid>

            {showList && (
              <Grid item xs={12} md={3}>
                <Box sx={{ py: 4 }}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <Typography color="textPrimary" variant="h4" align="center">
                      Vendedores
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {/* <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="Fecha"
                        inputFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        value={currentDate}
                        // onChange={handleChangeDate}
                        onAccept={handleChangeDate}
                        slotProps={{ textField: { variant: 'outlined' } }}
                      />
                    </LocalizationProvider> */}
                    <Box sx={{ flexGrow: 1 }} />
                  </Box>
                </Box>
                {/* <PresaleMonitoringFilter
                  disabled={returnsState.isLoading}
                  filters={controller.filters}
                  onFiltersApply={handleFiltersApply}
                  onFiltersClear={handleFiltersClear}
                  onQueryChange={handleQueryChange}
                  onViewChange={handleViewChange}
                  query={controller.query}
                  selectedDocuments={selectedDocuments}
                  view={controller.view}
                  onRefresh={handleRefreshPreSale}
                  tabsList={tabState.isLoading ? [] : tabState?.data}
                  teamCode={teamCode}
                />
                <Divider /> */}
                <PresaleMonitoringTable
                  error={returnsState.error}
                  documents={returnsState.documents}
                  documentsCount={returnsState.documentsCount}
                  isLoading={returnsState.isLoading}
                  onPageChange={handlePageChange}
                  onSelect={handleSelect}
                  onSelectAll={handleSelectAll}
                  onSortChange={handleSortChange}
                  page={controller.page + 1}
                  selectedDocuments={selectedDocuments}
                  sort={controller.sort}
                  sortBy={controller.sortBy}
                  setLocationState={handleClickLocation}
                />
                <Divider />
              </Grid>
            )}
            <Grid item xs={12} md={showList ? 9 : 12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={showListMap} sx={{ mr: 1, color: 'text.primary' }}>
                    {showList ? <ArrowLeft color="primary" /> : <ArrowRight color="primary" />}
                  </IconButton>
                  <MapItemMenu
                    setHidePolygon={setHidePolygon}
                    hidePolygon={hidePolygon}
                    setHideCustomers={setHideCustomers}
                    hideCustomers={hideCustomers}
                    setHideSellers={setHideSellers}
                    hideSellers={hideSellers}
                    setZoomState={setZoomState}
                  />
                </Box>
                <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_DIG}>
                  <Button
                    color="primary"
                    size="large"
                    startIcon={<RefreshIcon />}
                    onClick={handleRefreshPreSale}
                    variant="contained"
                  />
                </AllowedGuard>
              </Box>
              <PresaleMonitoringMap
                locationState={locationState}
                documentsProp={
                  positionRealTime.length === 0 ? returnsState.documents : positionRealTime
                }
                zoomMap={zoomState}
                nameLocation
                // customerProps={customerLocationState.data}
                customerProps={customerData}
                polygonsProps={polygonsState.data}
                hidePolygon={hidePolygon}
                hideCustomers={hideCustomers}
                hideSellers={hideSellers}
              />
            </Grid>
          </Grid>
        </Card>
        {false && (
          <Container
            maxWidth={false}
            sx={{
              height: '100%'
            }}
          >
            {false && (
              <Box sx={{ py: 4 }}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  {/* <Typography color="textPrimary" variant="h4">
                Seguimiento
              </Typography> */}
                  <Box sx={{ flexGrow: 1 }} />
                  <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Fecha"
                      inputFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      value={currentDate}
                      // onChange={handleChangeDate}
                      onAccept={handleChangeDate}
                      slotProps={{ textField: { variant: 'outlined' } }}
                    />
                  </LocalizationProvider>
                  <Box sx={{ flexGrow: 1 }} />
                </Box>
              </Box>
            )}
          </Container>
        )}
      </Box>
    </>
  );
};
