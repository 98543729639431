// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactKeycloakProvider } from '@react-keycloak/web';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './contexts/app-context';
import { ThemeProvider } from './contexts/theme-context';
import { UserProvider } from './contexts/user-context';
import { AuthProvider } from './contexts/jwt-auth-context';
import keycloak from './keycloak-js';
import Snowfall from 'react-snowfall';
import { RenderIf } from './components/render-if';
import { SnackbarProvider } from 'notistack';

// eslint-disable-next-line no-unused-vars
const eventLogger = (event, error) => {
  // console.log('onKeycloakEvent', event, error);
};

// eslint-disable-next-line no-unused-vars
const tokenLogger = (tokens) => {
  // console.log('onKeycloakTokens', tokens);
};

// ----------------------------------------------------------------------
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    {/*  render snowfall only on december */}
    <RenderIf condition={new Date().getMonth() === 12}>
      <Snowfall />
    </RenderIf>
    <ReactKeycloakProvider authClient={keycloak} onEvent={eventLogger} onTokens={tokenLogger}>
      <SnackbarProvider
        maxSnack={3}
        domRoot={typeof document !== 'undefined' ? document.body : null}
      >
        <AuthProvider>
          <AppProvider>
            <ThemeProvider>
              <UserProvider>
                {/* <ReactKeycloakProvider authClient={keycloak} onEvent={eventLogger} onTokens={tokenLogger}> */}
                <HelmetProvider>
                  <BrowserRouter>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <App />
                    </LocalizationProvider>
                  </BrowserRouter>
                </HelmetProvider>
              </UserProvider>
            </ThemeProvider>
          </AppProvider>
        </AuthProvider>
      </SnackbarProvider>
    </ReactKeycloakProvider>
  </>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
